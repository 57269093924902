import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import usStateJson from './../../../data/ZignaAi/us-states.json'
import Data from "../../../data/DataAudit/npi_distribution_across_county.json"

import * as d3 from 'd3'
import { useRef } from 'react'
import {uniqBy,sumBy,maxBy} from 'lodash'
const checkProviderType=(provider_type,type)=>{
	if(type=="FACILITY"){
		return provider_type=="HOSPITAl"||provider_type=="ANCILLARY"
	}
	if(type=="PROFESSIONAL"){
		return provider_type.includes("PROFESSIONAL")
	}
}
const StateMap=(props)=>{
	const inputRef = useRef(null);


	

		let map
		useEffect(() => {
			console.log(JSON.parse(JSON.stringify(uniqBy(props.mapData.filter(i=>checkProviderType(i.provider_type,props.type)&&i.month==props.month),i=>i.stateperoraclertafile))))
			drawMap(uniqBy(props.mapData.filter(i=>checkProviderType(i.provider_type,props.type)&&i.month==props.month),i=>i.stateperoraclertafile))
     
		}, [props.type,props.mapData?.length,props.payer,props.month,props.type])

		const drawMap = (stateData) => {
			d3.select(inputRef.current).select('svg').remove()
			let data = stateData.map(i=>({
id:i.stateperoraclertafile,
value:sumBy(props.mapData.filter(j=>checkProviderType(j.provider_type,props.type)&&j.stateperoraclertafile==i.stateperoraclertafile),j=>j._col3)
			}))
		

			var margin_choropleth = {
					top: 10,
					left: 10,
					bottom: 10,
					right: 10,
				},
				width_choropleth = parseInt(d3.select(inputRef.current).style('width')),
				width_choropleth =
					width_choropleth - margin_choropleth.left - margin_choropleth.right,
				mapRatio = 0.5,
				height_choropleth = width_choropleth * mapRatio

			// D3 Projection
			var projection = d3
				.geoAlbersUsa()
				.scale(width_choropleth)
				.translate([width_choropleth / 2, height_choropleth / 2])

			// Define path generator
			var path = d3.geoPath().projection(projection)

			var viewboxwidth = width_choropleth * 1
			var viewboxheight = height_choropleth

			// d3.json(usStateJson, function (json) {

			var svg_choropleth = d3
				.select(inputRef.current)
				.append('svg')
				.attr('preserveAspectRatio', 'xMidYMid meet')
				.attr('viewBox', '0 0 ' + viewboxwidth + ' ' + viewboxheight + '')

			map = svg_choropleth.append('g').attr('id', 'usStates')
			let usMergedCustomData = [
				...usStateJson.features
					?.concat(data)
					.reduce(
						(m, o) =>
							m.set(
								o?.id,
								Object.assign(m.get(o.id) || {}, o)
							),
						new Map()
					)
					.values(),
			]
		
			let max=maxBy(usMergedCustomData,i=>i.value)

		

			if(
				props.db?.includes("wellmark")&&usMergedCustomData.find(i=>i.id=="IA"&&i?.value)
			){
				props.setSelectedState(usMergedCustomData.find(i=>i.id=="IA"))

			}else
			if(props.db?.includes("excellus")&&usMergedCustomData.find(i=>i.properties?.name=="New York"&&i?.value))
			{
				props.setSelectedState(usMergedCustomData.find(i=>i.properties?.name=="New York"))
			}
			else{
				props.setSelectedState(usMergedCustomData.find(i=>i?.value))
			}

			

			
			max=max?.value
			map
				.selectAll('path')
				.data(usMergedCustomData)
				.enter()
				.append('path')
				.attr('d', path)
				.style('stroke', '#aaa')
				.style('stroke-width', '1')
				.style('fill', function (d) {
					// console.log(d)
					// if(d.id==props.selectedState?.id)
					// return "red"
					return d?.value >1? `rgba(94, 63, 147, ${0.3+d?.value/max})` : '#eaECF5'
				})
				.on('click', clicked)
			let zoomBtnPositionX = viewboxwidth - 30
			let zoomBtnPositionY = viewboxheight - 70
			svg_choropleth
				.append('g')
				.attr('class', 'D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomIn')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>+')
				.on('click', zoomIn)
			svg_choropleth
				.select('g.D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomOut')
				.attr('transform', 'translate(0,28)')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>─')
				.on('click', zoomOut)

			const zoom = d3.zoom().scaleExtent([1, 8]).on('zoom', zoomed)

			function zoomed() {
				map
					.selectAll('path') // To prevent stroke width from scaling
					.attr('transform', d3.event.transform)
			}

			svg_choropleth.call(zoom)
			let zoomfactor = 1
			function zoomOut() {
				if (zoomfactor > 1) {
					zoomfactor = zoomfactor - 0.5
					zoom.scaleTo(svg_choropleth.transition().duration(750), zoomfactor)
				}
			}
			function zoomIn() {
				zoomfactor = zoomfactor + 0.5
				zoom.scaleTo(svg_choropleth.transition().duration(750), zoomfactor)
			}
			function clicked(d) {
				console.log(d)
				props?.setSelectedState(d)
			}
		}
		return (
			
					<div id="usamap" className="d3Map" ref={inputRef}></div>
				
			
		)
	}

export default StateMap