import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Card, Button } from "@themesberg/react-bootstrap";
import { inject, observer } from "mobx-react";
import Select from "../../ui/Dropdown";
import GroupedDropdown from "../../ui/GroupedDropdown";
import { getPermissions } from "../../../PermisionObject";
import { useLocation } from "react-router-dom";
export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(
    ({
      zigna_aiStore,
      filterSet,
      setFilterSet,
      setPayerFilter,
      payerFilter,
      setError,
      authStore,
    }) => {
      let location = useLocation();
      const [selectedRegion, setSelectedRegion] = useState(null);
      const [selectedProvider, setSelectedProvider] = useState(null);
      const [selectedTin, setSelectedTin] = useState([]);

      const updateFilterSet = (field, value) => {
        zigna_aiStore?.resetProviderExtractChartResult();

        let index = filterSet.findIndex((i) => i.field == field);
        let updatedFilerset = [];
        if (index > -1) {
          updatedFilerset = [...filterSet.slice(0, index)];
        } else {
          updatedFilerset = [...filterSet];
        }
        if (value?.name?.length || value?.length)
          return [...updatedFilerset, { field, value }];
        else return [...updatedFilerset];
      };
      const updatePayerFilter = (field, value) => {
        zigna_aiStore.professionalRateChoiceData = [];
        zigna_aiStore.professionalRateChoiceCount = 0;
        zigna_aiStore.professionalType = [];
        let index = payerFilter.findIndex((i) => i.field == field);
        let updatedFilerset = [...payerFilter];
        if (index > -1) {
          updatedFilerset[index] = { field, value };
        } else {
          updatedFilerset = [...payerFilter, { field, value }];
        }
        return [...updatedFilerset];
      };
      useEffect(() => {
        refetchFilterData([]);
      }, [location]);

      useEffect(() => {
        zigna_aiStore.professionalRateChoiceData = [];
        zigna_aiStore.professionalRateChoiceCount = 0;
      }, [filterSet]);

      const refetchFilterData = (filters) => {
        zigna_aiStore.getProfessionalOptionalFilters({
          filters: filters.map((i) => {
            return {
              field: i.field,
              value: i.value?.value
                ? i.value.value
                : i.value?.map((j) => j.value),
            };
          }),
        });
      };

      const onSubmitForm = () => {
        if (filterSet.length == 0) {
          setError(true);
          return;
        }
        zigna_aiStore.getRateChoiceDataByTin({
          fields: [
            ...filterSet.map((i) => {
              return {
                field: i.field,
                value: i.value?.value
                  ? i.value.value
                  : i.value?.map((j) => j.value),
              };
            }),
            ...payerFilter.map((i) => {
              return {
                field: i.field,
                value: i.value?.value
                  ? i.value.value
                  : i.value?.map((j) => j.value),
              };
            }),
          ],
        });
      };

      const isDemo = getPermissions(
        authStore?.currentUser?.client_name
      )?.isDemo;

      const billingCodeGroups = {
        Anes: "Anes",
        Surg: "Surg",
        Rad: "Rad",
        "Path/Lab": "Path",
        Med: "Med",
        "E&M": "E&M",
      };
      const billingCodeGroupLabels = {
        Anes: "Anes (00001-09999)",
        Surg: "Surg (10000-69999)",
        Rad: "Rad (70000-79999)",
        "Path/Lab": "Path/Lab (80000-89999 )",
        Med: "Med (90000-99199,99500-99999)",
        "E&M": "E&M (99200-99499)",
      };
      const billingCodeGroupings = useMemo(() => {
        //if (!zigna_aiStore.professionalOptionalFilter?.billing_code) return [];
        return Object.keys(billingCodeGroups).map((groupName, index) => {
          return {
            id: index,
            // name: `${groupName} (${billingCodeGroups[groupName].map(range=>`${range[0]}-${range[1]}`).join(",")})`,
            name: billingCodeGroupLabels[groupName],
            value: billingCodeGroups[groupName],
          };
        });
      }, []);

      return (
        <Card border="light" className="shadow-sm filterSection">
          <Card.Body>
            <Row>
              <Col sm={"auto"}>
                <Row className="px-2">
                  <h5>Filter </h5>
                  <hr />
                </Row>
                <Row>
                  {!isDemo ? (
                    <>
                      <Col className=" col-auto col-auto">
                        <div className="mb-3">
                          <label className="form-label">
                            Fee Schedule
                            {/* <span className='text-danger'>*</span> */}
                          </label>
                          <div className="d-flex align-items-center h45 NPI-select">
                            <Select
                              menuPortalTarget={document.body}
                              className={`select-menu`}
                              value={
                                filterSet.find(
                                  (i) => i.field == "provider_type"
                                )?.value || null
                              }
                              isMulti
                              allowSelectAll={true}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onMenuClose={() =>
                                filterSet.find(
                                  (i) => i.field == "provider_type"
                                )?.value?.length
                                  ? refetchFilterData(filterSet)
                                  : null
                              }
                              onChange={(e, data) => {
                                setFilterSet(
                                  updateFilterSet("provider_type", e)
                                );
                                //refetchFilterData(updateFilterSet("provider_type",e))
                              }}
                              //zigna_aiStore.getProfessionalOptionalFilters({filters:[{field:"provider_type",value:e.name}]})

                              // onChange={(e, data) => {
                              // 	setSelectedProvider(e)
                              // 	setFilterSet(updateFilterSet("provider_type",e))
                              // 	refetchFilterData(updateFilterSet("provider_type",e))
                              // 	//zigna_aiStore.getProfessionalOptionalFilters({filters:[{field:"provider_type",value:e.name}]})

                              // }}

                              getOptionLabel={(option) => `${option.name}`}
                              options={
                                zigna_aiStore.professionalOptionalFilter
                                  ?.provider_type || []
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Region
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "region")?.value ||
                            null
                          }
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("region", e));

                            refetchFilterData(updateFilterSet("region", e));
                          }}
                          getOptionLabel={(option) => `${option.id}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.region ||
                            []
                          }
                        />
                      </div>
                    </div>
                  </Col>

                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">Tax ID</label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "tin")?.value ||
                            null
                          }
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("tin", e));

                            //refetchFilterData(updateFilterSet("tin",e))
                          }}
                          onMenuClose={() => refetchFilterData(filterSet)}
                          getOptionLabel={(option) => `${option.name}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.tin || []
                          }
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                        {/* <button
				type="button"
				className="NPI-submit btn btn-primary btn-sm"
				disabled={!zigna_aiStore.drgCostBreakdownSelected.code_category?.length}
				onClick={()=>{
					zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
					zigna_aiStore.drgcostbreakdownData=[]
				
				    zigna_aiStore.getDDRGcostreportingEntity()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button> */}
                      </div>
                    </div>
                  </Col>

                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">Payer</label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find(
                              (i) => i.field == "reporting_entity_name"
                            )?.value || null
                          }
                          onChange={(e, data) => {
                            setFilterSet(
                              updateFilterSet("reporting_entity_name", e)
                            );
                            //refetchFilterData(updateFilterSet("billing_code",e))
                          }}
                          onMenuClose={() => refetchFilterData(filterSet)}
                          getOptionLabel={(option) => `${option.name}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.reporting_entity_name?.filter(
                              (v) => v.name !== "CMS"
                            ) || []
                          }
                          isClearable={filterSet
                            .find((i) => i.field == "reporting_entity_name")
                            ?.value?.some((v) => !v.name == "CMS")}
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={"auto"}>
                <Row className="px-2">
                  <h5>Billing Filters</h5>
                  <hr />
                </Row>
                <Row>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Billing Code Grouping
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        {console.log(billingCodeGroupings)}
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find(
                              (i) => i.field == "billing_code_range"
                            )?.value || null
                          }
                          onChange={(e, data) => {
                            setFilterSet(
                              updateFilterSet("billing_code_range", e)
                            );
                            refetchFilterData(
                              updateFilterSet("billing_code_range", e)
                            );
                            // setFilterSet(updateFilterSet("billing_code", null));
                            //refetchFilterData(updateFilterSet("billing_code",e))
                          }}
                          // onMenuClose={() => refetchFilterData(filterSet)}
                          getOptionLabel={(option) => `${option.name}`}
                          options={billingCodeGroupings}
                          // allowSelectAll={true}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">Billing Code</label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "billing_code")
                              ?.value || null
                          }
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("billing_code", e));
                            //refetchFilterData(updateFilterSet("billing_code",e))
                          }}
                          onMenuClose={() => refetchFilterData(filterSet)}
                          getOptionLabel={(option) => `${option.name}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter
                              ?.billing_code || []
                          }
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={"auto"}>
                <Row className="px-2">
                  <h5>Taxonomy Filters</h5>
                  <hr />
                </Row>
                <Row>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        NUCC Grouping
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          //disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "group1")?.value ||
                            null
                          }
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("group1", e));
                          }}
                          onMenuClose={() => refetchFilterData(filterSet)}
                          getOptionLabel={(option) => `${option.name}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.group1 ||
                            []
                          }
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        NUCC Classification
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <GroupedDropdown
                          options={
                            zigna_aiStore.professionalOptionalFilter?.group2 ||
                            []
                          }
                          getOptionLabel={(option) => `${option.name}`}
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onMenuClose={() => refetchFilterData(filterSet)}
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("group2", e));
                          }}
                        />
                        {/* <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "group2")?.value ||
                            null
                          }
                          onMenuClose={() => refetchFilterData(filterSet)}
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("group2", e));
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.group2 ||
                            []
                          }
                          isMulti
                          allowSelectAll={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        /> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={"auto"}>
                <Row className="px-2">
                  <h5>Payer Rate Filters</h5>
                  <hr />
                </Row>
                <Row>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Rate Type
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          //disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            payerFilter.find((i) => i.field == "rate_type")
                              ?.value || {
                              name: "Non-facility",
                              value: "non_facility",
                            }
                          }
                          onChange={(e, data) => {
                            setPayerFilter(updatePayerFilter("rate_type", e));

                            //refetchFilterData(updateFilterSet("rate_type", e));
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={[
                            {
                              name: "Facility",
                              value: "facility",
                            },
                            {
                              name: "Non-facility",
                              value: "non_facility",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Most Frequent Rate
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            payerFilter.find((i) => i.field == "mfr")
                              ?.value || {
                              name: "All NPI's",
                              value: "all",
                            }
                          }
                          onChange={(e, data) => {
                            setPayerFilter(updatePayerFilter("mfr", e));

                            // refetchFilterData(updateFilterSet("mfr", e));
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={[
                            {
                              name: "All NPI's",
                              value: "all",
                            },
                            {
                              name: "Most Frequent Rate",
                              value: "frequent",
                            },
                            {
                              name: "Most Frequent Rate and NPP Indicator",
                              value: "frequent_npp",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Multiple Rates Reported
                        {/* <span className='text-danger'>*</span> */}
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            payerFilter.find((i) => i.field == "mrr")
                              ?.value || {
                              name: "All Rates",
                              value: "all",
                            }
                          }
                          onChange={(e, data) => {
                            setPayerFilter(updatePayerFilter("mrr", e));

                            // refetchFilterData(updateFilterSet("mrr", e));
                          }}
                          getOptionLabel={(option) => `${option.name}`}
                          options={[
                            {
                              name: "All Rates",
                              value: "all",
                            },
                            {
                              name: "Highest $ Reported",
                              value: "highest",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className=" col-auto">
                    <label className="form-label">{"   "}</label>
                    <div className="d-flex align-item-center">
                      <Button
                        //    disabled={

                        // 	!selectedProvider
                        //    }
                        variant="primary"
                        size="sm"
                        className="submitFilter"
                        onClick={(e) => {
                          onSubmitForm();
                        }}
                        type="button"
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }
  )
);
