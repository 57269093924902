import React from 'react'
import {
	Col,
	Row,
	Form,
	Card,
	Button,
	FormCheck,
	Container,
	InputGroup,
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUser,
	faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

export const PasswordReset = inject('authStore')(
	observer(({ authStore }) => {
		const didMount = useRef(false);

		let [confirmPassword, setconfirmPassword] = useState(null);
		let [isPasswordCorrect, setisPasswordCorrect] = useState(true);
		const isPasswordMatch = () => {
			authStore.newPassword = authStore.newPassword == null ? '': authStore.newPassword
			confirmPassword = confirmPassword ==  null ? '':confirmPassword;
			if(authStore.newPassword && confirmPassword) {
				if(confirmPassword != authStore.newPassword) {
					setisPasswordCorrect(false)
				}
				else {
					setisPasswordCorrect(true)
				}
			}
		}
		useEffect(() => {
			 if ( confirmPassword != null ) {
				isPasswordMatch()
			}
			// isPasswordMatch()
			return () => {
				// isPasswordMatch()
			};
		}, [confirmPassword]);
		return (
			<>
				<Form className="mt-4">
					<Form.Group id="username" className="mb-4">
						<Form.Label>New Password</Form.Label>
						<InputGroup
							className={`${authStore.username == '' && 'invalid_input'}`}
						>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faUser} />
							</InputGroup.Text>
							<Form.Control
								autoFocus
								required
								type="password"
								placeholder="Password"
								value={authStore.newPassword}
								isInvalid={authStore.setNewPassword == ''}
								onChange={(e) => authStore.setNewPassword(e.target.value)}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group>
						<Form.Group id="password" className="mb-4">
							<Form.Label>Confirm Password</Form.Label>
							<InputGroup
								className={`${authStore.password == '' && 'invalid_input'}`}
							>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faUnlockAlt} />
								</InputGroup.Text>
								<Form.Control
									required
									type="password"
									placeholder="Password"
									value={confirmPassword}
									isInvalid={confirmPassword == ''}
									onChange={(e) => {setconfirmPassword(e.target.value,isPasswordMatch)
														// isPasswordMatch()
														}}
									// onBlur={()=>isPasswordMatch()}
								/>
							</InputGroup>
							{!isPasswordCorrect&&<div className='text-danger'>Password didn't match. Try again</div>}
						</Form.Group>
					</Form.Group>
					<Button
						variant="primary"
						type="button"
						className="w-100"
						onClick={() => {
							authStore.resetPassword()
						}}
					>
						{authStore.btnLoading ? (
							<>
								<span
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>{' '}
								Loading...
							</>
						) : (
							'Reset'
						)}
					</Button>
				</Form>
			</>
		)
	})
)
