import React from 'react'
import {useMemo, useState} from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'
import 'xlsx';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import * as d3 from 'd3'
import * as XLSX from 'xlsx-js-style';
import RequestExtraData from '../common/RequestExtraData'

/** For future use - formatting only */
const formatTableNumbers = (data = [], cols) => {
	return  data.map(row => {
		let updatedRow = {...row}
		const rowCountData=[]
		cols.forEach(elem => {
			updatedRow[elem] = (updatedRow[elem] || 0)
			rowCountData.push((updatedRow[elem] || 0))
		})
		const rowD=d3.extent(rowCountData);
		const middleRow=(rowD[0]+rowD[1])/2;
		updatedRow.colorScale=d3.scaleLinear().domain([rowD[0], (rowD[0]+(middleRow/2)), middleRow,(rowD[1]-(middleRow/2)), rowD[1]]).range(["#F1FFF1","#E5FFCC","#FFFF99","#FFE5CC", "#FF9999"]).interpolate(d3.interpolateCubehelix.gamma(1.2))
		
		return updatedRow
	})	
}

const sortByColname = (arr = [], colname = 'provider_name') => {
	return [...arr].sort((a, b) => {
		if (a[colname] < b[colname]) {
			return -1;
		}
		if (a[colname] > b[colname]) {
			return 1;
		}
		return 0;
	});
}
const transformToAverageData = (arr) => {
	// const cols = []
	const rows = {}
	const columnsForKey = ['npi', 'reporting_entity_name','classification', 'billing_code', 'provider_name']
	const getKey = (obj) => {
		return columnsForKey.reduce((acc, key) => {
			acc = `${acc}-${obj[key] || '*'}`
			return acc
		}, '')
	}
	arr.forEach((col) => {
		const key = getKey(col)		
		if(!rows[key]) rows[key] =  [];
		if(col.reporting_entity_name) {
			// cols.push(col.reporting_entity_name)
			const newData={...col,[col.reporting_entity_name]: col?.negotiated_rate || 0}
			rows[key].push(newData) 					
		}
	})
	for(const keyEle in rows){
		const obj={
			billing_code: rows[keyEle][0].billing_code, 
			npi: rows[keyEle][0].npi,
			classification: rows[keyEle][0].classification,				
			provider_name: rows[keyEle][0].provider_name,
			reporting_entity_name:rows[keyEle][0].reporting_entity_name,
			negotiated_rate:  (rows[keyEle].reduce((partialSum, a) => partialSum + a.negotiated_rate, 0)/rows[keyEle].length),
		}				
		// obj[rows[keyEle][0].reporting_entity_name]=obj.negotiated_rate;
		rows[keyEle]=obj;
		
	}
	return Object.values(rows);
}
const transformToColumns = (arr) => {
	let newData=transformToAverageData(arr)
	const cols = []
	const rows = {}
    const columnsForKey = ['npi', 'classification', 'billing_code', 'provider_name']
    const getKey = (obj) => {
        return columnsForKey.reduce((acc, key) => {
            acc = `${acc}-${obj[key] || '*'}`
            return acc
        }, '')
    }
	newData.forEach((col) => {
        const key = getKey(col)
		if(!rows[key]) rows[key] = { provider_name: col.provider_name, ...col }
		if(col.reporting_entity_name) {
			cols.push(col.reporting_entity_name)
			rows[key] = {
				...rows[key],
				[col.reporting_entity_name]: col?.negotiated_rate || 0
			}
		}
	})
	return [rows, [...new Set(cols)]]
}

export default inject('zigna_aiStore', 'authStore', 'providerPriceCompareStore')(
	observer(({ zigna_aiStore, authStore, providerPriceCompareStore, procCategory }) => {

		//const rowList = providerPriceCompareStore.resultData || []
		const [rows, cols] = transformToColumns(providerPriceCompareStore?.categoryExtractResults || [])
		const [showFormatted, setShowFormatted] = useState(true)
		const rowList = Object.values(rows)

		const generateCols = useMemo(() => {		
		  return (cols || []).map(elem => {
			return {
				Header: elem,
				accessor: data => data[elem],
				align: 'right',
				width: 50,
				...(['npi', 'provider_name', 'classification', 'billing_code', 'provider_name'].includes(elem)?{}:{
					Cell: ({value,cell}) =>{ return value==0?'':<div style={{ textAlign: 'right', backgroundColor:cell.row.original?.colorScale(value) }}>{convertPrice(value)}</div>}
				}),
				...( authStore?.currentUser?.payer === elem  ? {
					getProps: (state, rowInfo, column) => {
						return {
							style: {
								background: '#f5e8ff'
							},
						};
					},
				} : {})
			}
		}).sort((a, b) => {
			if (a.Header < b.Header) {
				return -1;
			}
			if (a.Header > b.Header) {
				return 1;
			}
			return 0;
		})
	});

		let rawDataColumns = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'NPI',
						accessor: 'npi',
						width: 50,					
					},
					{
						Header: 'Reporting Entity Name',
						accessor: 'reporting_entity_name',
						width: 50,	
					},
					{
						Header: 'Provider Name',
						accessor: 'provider_name',
						width: 50,					
					},
					{
						Header: 'Speciality',
						accessor: 'classification',
						width: 50,					
					},
					{
						Header: 'Billing Code',
						accessor: 'billing_code',
						width: 50,					
					},
					{
						Header: 'Rate',
						accessor: 'negotiated_rate',
						width: 50,	
						Cell: ({value,cell}) =>{ return <div style={{ textAlign: 'right' }}>{Number(value).toFixed(2)}</div>}				
					},
				],
			},
		];

		const columns = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'NPI',
						accessor: 'npi',
						width: 50,					
					},
					{
						Header: 'Provider Name',
						accessor: 'provider_name',
						width: 50,					
					},
					{
						Header: 'Speciality',
						accessor: 'classification',
						width: 50,					
					},
					// {
					// 	Header: 'Payer',
					// 	accessor: 'reporting_entity_name',
					// 	width: 50,					
					// },
					{
						Header: 'Billing Code',
						accessor: 'billing_code',
						width: 50,					
					},
					...generateCols
				],
			},
		];

		const columnsFormated= ['npi','provider_name','classification','billing_code',...generateCols.map((d)=>d.Header) ];
		const coulmnsRaw= ['npi','reporting_entity_name','provider_name','classification','billing_code','negotiated_rate']

		const downloadExcel = (data, columns) => {			
			let allColumns=columns;		
			let worksheet={};
			 
			data.forEach((item, index) => {
				if(index===0 && columns && columns.length===0){
				 allColumns=Object.keys(item);				 
				}
				allColumns.forEach((e,i)=>{
					if(e!=='colorScale'){
						let columnIndex= String.fromCharCode(i + 65);
						if(index===0){
						const rowNumberHeader = columnIndex + 1;						
						worksheet[rowNumberHeader] ={'t': 's', 'v': e}
						}
						const isStringColumns= ['npi','provider_name','classification','billing_code','reporting_entity_name','negotiated_rate'].includes(e);
						const rowNumber = columnIndex +(index+2);
						if(isStringColumns || !item.colorScale){
							if(e=='negotiated_rate'){
								worksheet[rowNumber] ={'t': 's', 'v': Number(item[e]).toFixed(2)};
							}else{
								worksheet[rowNumber] ={'t': 's', 'v': item[e]};
							}
						}else{
							if(item[e]===0){
							worksheet[rowNumber] ={'t': 's', 'v': ''};
							}else{
							worksheet[rowNumber] ={'t': 'n', 'v': Number(item[e]).toFixed(2), 's':{fill: {patternType: 'solid', fgColor: { rgb: d3.color(item['colorScale'](item[e])).formatHex().replace(/#/g,"")} } }};
							}
						}
					}
				})				
			})
			  worksheet['!ref'] = 'A1:'+String.fromCharCode((allColumns.length) + 65) + (data.length + 2);
			  const cols1 = allColumns.map((e)=>{				
				return  {width:(e==='description'?30: 10) }
			  });
				// console.log(cols)
			worksheet['!cols'] = cols1;
				// const worksheet = XLSX.utils.json_to_sheet(data);
			const workbook = XLSX.utils.book_new();
			
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
			//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
			XLSX.writeFile(workbook, "DataSheet.xlsx");
		  };

		

		return (
			<Card border="light" className="shadow-sm">
				<Card.Header>
					<Row className="align-items-center">
						<Col>
						{ ((providerPriceCompareStore?.categoryExtractResults || []).length >= process.env.REACT_APP_MAX_OD_ROW_LIMIT && providerPriceCompareStore.isValidFilter()) ? (<RequestExtraData onConfirm={(fields) => {
									providerPriceCompareStore.placeCategoryExtractRequest(fields, zigna_aiStore.setNotification)
								}} />) : null}
							<h5>
							Extracted Results By - {procCategory}
							<span style={{ float: 'right' }}>
							<BootstrapSwitchButton
								checked={showFormatted}
								onlabel='Show Raw Data'
								onstyle='danger'
								offlabel='Show Formatted Data'
								offstyle='success'
								width={180}
								size='sm'
								onChange={(checked) => {
									setShowFormatted(checked)
								}}
							/>
							</span>
							<span className="downloadBtn ">
								<svg
									width="20"
									height="20"
									viewBox="0 0 123 121"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_4_39)">
										<path
											d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
											fill="#B54491"
										/>
									</g>
									<defs>
										<clipPath id="clip0_4_39">
											<rect width="122.88" height="120.89" fill="white" />
										</clipPath>
									</defs>
								</svg>
								<span className="ml-5" onClick={()=>downloadExcel(formatTableNumbers(sortByColname(rowList || []), cols), columnsFormated)}>
									Formatted Data
								</span>
							</span>
							<span className="downloadBtn ">
								<svg
									width="20"
									height="20"
									viewBox="0 0 123 121"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_4_39)">
										<path
											d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
											fill="#B54491"
										/>
									</g>
									<defs>
										<clipPath id="clip0_4_39">
											<rect width="122.88" height="120.89" fill="white" />
										</clipPath>
									</defs>
								</svg>
								<span className="ml-5" onClick={()=>downloadExcel(sortByColname(providerPriceCompareStore?.categoryExtractResults || []), coulmnsRaw)}>
									Raw Data
								</span>
							</span>
							</h5>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body style={{ overflow: 'scroll' ,maxHeight:"90vh" }}>
					{
						showFormatted ? (
							<CommonTable
								columns={columns}
								data={formatTableNumbers(sortByColname(rowList|| []), cols)}
								className="zignaAi_table_1"
								stickyTop={-15}
								sticky
							/>
						) : (
							<CommonTable
								columns={rawDataColumns}
								data={sortByColname(providerPriceCompareStore?.categoryExtractResults || [])}
								className="zignaAi_table_1"
								stickyTop={-15}
								sticky
							/>
						)
					}
				</Card.Body>
			</Card>
		)
	})
)
