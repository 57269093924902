import {
  makeAutoObservable,
  runInAction,
  reaction,
  observable,
  computed,
} from "mobx";
import { getAvg, getMax } from "../../../../Utilities";
// import { getAvg, getMax, getMin, sortJsonArr } from '../Utilities'
import { sortJsonArr, getMin } from "./../../../../Utilities";

export class ProvidersCPTExtractStore {
  //Common variables start
  userType = localStorage.getItem("userType") || null;
  //Common variables end
  service;
  constructor(service) {
    makeAutoObservable(this);
    this.service = service;
  }
  // filter
  regionList = [];
  bcodeList = [];
  allPayers = [];
  proceduresCatList = [];
  level1DescList = [];
  resultData = [];
  getRegionList = async ({ procCategory }) => {
    let response = await this.service.getCategoryFilteredRegionList({
      billingCodeType: procCategory[0],
    });
    this.regionList = response.data;
  };
  getUserType = () => {
    const mapping = {
      professional: "professional",
      facility: "institutional",
      ancillary: "institutional",
    };
    return mapping[this.userType];
  };
  getLevel1DescList = async ({ procCategory }) => {
    this.level1DescList = [];
    let response = await this.service.getLevel1DescList({
      userType: this.getUserType(),
      billingCodeType: procCategory[0],
      region: this.selectedFilterData.region.value,
    });
    this.level1DescList = response.data;
  };
  getBcodeList = async () => {
    this.bcodeList = [];
    let response = await this.service.getBcodeList({
      userType: this.getUserType(),
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
    });
    this.bcodeList = response.data;
  };
  getCategoryFilteredPayerList = async () => {
    this.allPayers = [];
    let response = await this.service.getCategoryFilteredPayerList({
      userType: this.getUserType(),
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      bcode: this.selectedFilterData.bcode?.map((el) => el.value) || [],
    });
    this.allPayers = response.data;
  };

  selectedFilterData = {
    region: null,
    level1Desc: null,
    procedureCat: null,
    bcode: null,
    payers: null,
  };
  setFilterData = (key, value) => {
    this.selectedFilterData[key] = value;
  };
  resetFilterData = () => {
    this.selectedFilterData = {
      region: null,
      level1Desc: null,
      procedureCat: null,
      bcode: null,
      payers: null,
    };
  };
  resetResults = () => {
    this.resultData = [];
  };
  // filter

  isValidFilter = () => {
    let self = this;
    let isValid = true;
    Object.keys(this.selectedFilterData).map((key) => {
      if (self.selectedFilterData[key] == null) {
        self.selectedFilterData[key] = "";
      }
      if (
        key != "region" &&
        key != "level1desc" &&
        key != "procedureCat" &&
        key != "bcode" &&
        key != "payers" &&
        self.selectedFilterData[key] == ""
      ) {
        isValid = false;
      }
    });
    return isValid;
  };
  extractResults = async () => {
    console.log(this.isValidFilter());
    this.resultData = [];
    if (this.isValidFilter()) {
      let response = await this.service.extractResults({
        userType: this.getUserType(),
        billingCodeType: this.selectedFilterData.procedureCat,
        region: this.selectedFilterData.region.value,
        description: this.selectedFilterData.level1Desc.value,
        bcode: this.selectedFilterData.bcode?.map((el) => el.value) || [],
        payers:
          (this.selectedFilterData.payers || []).map((el) => el.value) || [],
      });
      this.resultData = response?.data;
    }
  };
}
