import React from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'
export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore, calcData }) => {
		return (
			<Row className="priceWidget">
				{calcData?.priceCategoryList.map((item, i) => (
					<Col xs={12} sm={4} className="" key={`${item.id}_category1`}>
						<Card border="light" className="">
							<Card.Body>
								<h5 className="fw400">{item.priceCat}</h5>
								<div className="d-flex align-items-center gap-2">
									{/* <div> */}
									{iconSelector(i)}
									{/* <img src={competitiveIcn} /> */}
									{/* </div> */}
									<div className="numLg numColor">
										{calcData.label=='percentage'? Number(item.value).toFixed(2)+'%':convertPrice(item.value)}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
			// 	</Card.Body>
			// </Card>
		)
	})
)
const iconSelector = (value) => {
	let icon
	if (value == 0) {
		icon = (
			<svg
				width="25"
				height="26"
				viewBox="0 0 25 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect
					x="1"
					y="1"
					width="4"
					height="23"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<mask id="path-2-inside-1_369_241" fill="white">
					<path d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z" />
				</mask>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L26.3437 19.0796L26.3509 17.0797L24.3509 17.0725L24.3438 19.0725ZM24.3223 25.0725L24.3152 27.0725L26.3152 27.0796L26.3223 25.0796L24.3223 25.0725ZM22.3438 19.0654L22.3224 25.0653L26.3223 25.0796L26.3437 19.0796L22.3438 19.0654ZM24.3295 23.0725L4.00747 23L3.9932 26.9999L24.3152 27.0725L24.3295 23.0725ZM4.01461 21L24.3366 21.0725L24.3509 17.0725L4.02888 17L4.01461 21Z"
					fill="#8B6CBC"
					mask="url(#path-2-inside-1_369_241)"
				/>
				<path
					d="M9.00068 15.037C9.02113 15.5889 9.48511 16.0198 10.037 15.9993L19.0308 15.6662C19.5828 15.6458 20.0136 15.1818 19.9931 14.6299C19.9727 14.078 19.5087 13.6471 18.9568 13.6676L10.9623 13.9637L10.6662 5.96915C10.6458 5.41725 10.1818 4.98641 9.62988 5.00685C9.07798 5.02729 8.64714 5.49127 8.66758 6.04318L9.00068 15.037ZM22.2672 0.319549L9.26721 14.3195L10.7328 15.6805L23.7328 1.68045L22.2672 0.319549Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else if (value == 1) {
		icon = (
			<svg
				width="32"
				height="33"
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					x="2"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<rect
					x="12"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<rect
					x="23"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<path
					d="M31.3536 4.35355C31.5488 4.15829 31.5488 3.84171 31.3536 3.64645L28.1716 0.464466C27.9763 0.269204 27.6597 0.269204 27.4645 0.464466C27.2692 0.659728 27.2692 0.976311 27.4645 1.17157L30.2929 4L27.4645 6.82843C27.2692 7.02369 27.2692 7.34027 27.4645 7.53553C27.6597 7.7308 27.9763 7.7308 28.1716 7.53553L31.3536 4.35355ZM0 4.5H31V3.5H0V4.5Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else if (value == 2) {
		icon = (
			<svg
				width="25"
				height="26"
				viewBox="0 0 25 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect
					x="1"
					y="1"
					width="4"
					height="23"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<mask id="path-2-inside-1_369_241" fill="white">
					<path d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z" />
				</mask>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L26.3437 19.0796L26.3509 17.0797L24.3509 17.0725L24.3438 19.0725ZM24.3223 25.0725L24.3152 27.0725L26.3152 27.0796L26.3223 25.0796L24.3223 25.0725ZM22.3438 19.0654L22.3224 25.0653L26.3223 25.0796L26.3437 19.0796L22.3438 19.0654ZM24.3295 23.0725L4.00747 23L3.9932 26.9999L24.3152 27.0725L24.3295 23.0725ZM4.01461 21L24.3366 21.0725L24.3509 17.0725L4.02888 17L4.01461 21Z"
					fill="#8B6CBC"
					mask="url(#path-2-inside-1_369_241)"
				/>
				<path
					d="M24 2C24 1.44771 23.5523 0.999999 23 0.999999L14 1C13.4477 1 13 1.44772 13 2C13 2.55228 13.4477 3 14 3L22 3L22 11C22 11.5523 22.4477 12 23 12C23.5523 12 24 11.5523 24 11L24 2ZM10.7071 15.7071L23.7071 2.70711L22.2929 1.29289L9.29289 14.2929L10.7071 15.7071Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	}
	return icon
}
