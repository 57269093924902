import { useState } from 'react';
import { Button, Alert, Modal, Form } from '@themesberg/react-bootstrap'
import RequestMoreModal from './RequestMoreModal'

function RequestExtraData({ onConfirm, message, disabled, withAlert = true, children, dbname }) {
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState('')
  const [db, setDb] = useState({ id: dbname, label: dbname, value: dbname })

  const handleClose = () => {
    setLabel('')
    setShow(false)
  };
  const handleConfirm = () => {
    onConfirm({ label, db: db.value })
    setLabel('')
    setShow(false)
  };
  const handleShow = () => setShow(true);

  return (
    <>
      {
        withAlert ?
          <Alert variant="warning">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <span>{message || `The selected filters returned ${process.env.REACT_APP_MAX_OD_ROW_LIMIT} rows which is being displayed here, it can have more rows which are hidden for 
                optimization purpose. you can place a request to get all data by clicking on Raise request.`}
              </span>
              <Button

                disabled={disabled}
                style={{ marginLeft: 4, whiteSpace: 'nowrap' }} size="sm" onClick={handleShow} variant="outline-warning">
                Raise request
              </Button>
            </div>
          </Alert>
          :
          <div onClick={handleShow}>{children}</div>
      }

      <RequestMoreModal
        show={show}
        handleClose={handleClose}
        setLabel={setLabel}
        label={label}
        handleConfirm={handleConfirm}
        db={db}
        setDb={setDb}
      />
    </>
  );
}

export default RequestExtraData;