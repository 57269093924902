import React from 'react'
import { TAG_COLORS } from '../../../constants';

const Tag = (props) => {
    return (
        <div
            style={{
                display: 'inline-block',
                padding: 2,
                borderRadius: 6,
                ...(TAG_COLORS[props.type] || TAG_COLORS['info'])
            }}
        >
            {props.value}
        </div>
    );
}

export default Tag