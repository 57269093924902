import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import HospitalCostDiffWidget from '../../components/ZignaAi/costDiffReport/HospitalCostDiffWidget'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		useEffect(() => {
			zigna_aiStore.getHopitalCostDiffList()
		})
		return (
			<>
				<Row>
					<Col xs={12} xl={12}>
						<Row>
							<Col xs={12} className="">
								<HospitalCostDiffWidget />
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		)
	})
)
