import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Badge } from "@themesberg/react-bootstrap";
import { inject, observer } from "mobx-react";
import Select from "../../ui/Dropdown";
import { getPermissions } from "../../../PermisionObject";
import { useLocation } from "react-router-dom";
export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(
    ({
      zigna_aiStore,
      filterSet,
      setFilterSet,
      setError,
      authStore,
      onSubmit,
      handleSaveFilters,
      setInsightsData,
      setPayerFilter,
      payerFilter
    }) => {
      let location = useLocation();
      const [selectedRegion, setSelectedRegion] = useState(null);
      const [selectedProvider, setSelectedProvider] = useState(null);
      const [selectedTin, setSelectedTin] = useState([]);
      const [saveFilters, setSaveFilters] = useState(true)

      const updateFilterSet = (field, value) => {
        // zigna_aiStore?.resetProviderExtractChartResult();

        let index = filterSet.findIndex((i) => i.field == field);
        let updatedFilerset = [];
        if (index > -1) {
          updatedFilerset = [...filterSet.slice(0, index)];
        } else {
          updatedFilerset = [...filterSet];
        }
        if (value?.name?.length || value?.length)
          return [...updatedFilerset, { field, value }];
        else return [...updatedFilerset];
      };
      useEffect(() => {
        refetchFilterData([]);
      }, [location]);

      useEffect(() => {
        // zigna_aiStore.professionalRateChoiceData = [];
        // zigna_aiStore.professionalRateChoiceCount = 0;
      }, [filterSet]);

      const refetchFilterData = (filters) => {
        console.log({ filters });
        if (!zigna_aiStore.professionalFilterMaster)
          zigna_aiStore.getProfessionalOptionalFilters2({
            filters: filters.map((i) => {
              console.log(i);
              return {
                field: i.field,
                value: ["region", "rate_type", "rates_only"].includes(i.field)
                  ? i.value.name
                  : ["most_frequent_rate","multiple_rates_reported"].includes(i.field)
                  ? i.value.value
                  : i.value?.map((j) => j.value),
              };
            }),
          });
      };

      const updatePayerFilter = (field, value) => {
        zigna_aiStore.professionalRateChoiceData = [];
        zigna_aiStore.professionalRateChoiceCount = 0;
        zigna_aiStore.professionalType = [];
        let index = payerFilter.findIndex((i) => i.field == field);
        let updatedFilerset = [...payerFilter];
        if (index > -1) {
          updatedFilerset[index] = { field, value };
        } else {
          updatedFilerset = [...payerFilter, { field, value }];
        }
        return [...updatedFilerset];
      };

      const onSubmitForm = async () => {

        console.log(filterSet.length);
        if (filterSet.length == 0) {
          setError(true);
          return;
        }
        onSubmit();
        const insightsData = await zigna_aiStore.getRateInsightsByTin({
          // fields: filterSet.map((i) => {
          //   return {
          //     field: i.field,
          //     value: ["tin", "rate_type", "rates_only"].includes(i.field)
          //       ? [i.value.value]
          //       : ["most_frequent_rate","multiple_rates_reported"].includes(i.field)
          //       ? i.value.value
          //       : i.value?.map((j) => j.name),
          //   };
          // }),
          fields: [
            ...filterSet.map((i) => {
              return {
                field: i.field,
                value: ["tin", "rate_type", "rates_only"].includes(i.field)
                ? [i.value.value]
                : ["most_frequent_rate","multiple_rates_reported"].includes(i.field)
                ? i.value.value
                : i.value?.map((j) => j.value),
              };
            }),
            ...payerFilter.map((i) => {
              return {
                field: i.field,
                value: ["rate_type"].includes(i.field)
                ? [i.value.value]
                : ["most_frequent_rate","multiple_rates_reported"].includes(i.field)
                ? i.value.value
                : i.value?.map((j) => j.value),
              };
            }),
          ],
        });
        setInsightsData(insightsData)
        queueMicrotask(() => {
          if (saveFilters) {
            //  zigna_aiStore.saveUserFilters({ page_name:"rateInsights", saved_filters:filterSet})
            handleSaveFilters()
          }
        })
      };

      const isDemo = getPermissions(
        authStore?.currentUser?.client_name
      )?.isDemo;

      return (
        <Card border="light" className="shadow-sm filterSection">
          <Card.Body className="p-2">
            {/* <h5>Filter </h5> */}
            <Row>
              {/* <Col className=" col-auto col-auto">
                    <div className="mb-3">
                      <label className="form-label">
                        Region
                      </label>
                      <div className="d-flex align-items-center h45 NPI-select">
                        <Select
                          disabled={!selectedProvider}
                          className={`select-menu`}
                          value={
                            filterSet.find((i) => i.field == "region")?.value ||
                            null
                          }
                          onChange={(e, data) => {
                            setFilterSet(updateFilterSet("region", e));

                            refetchFilterData(updateFilterSet("region", e));
                          }}
                          getOptionLabel={(option) => `${option.id}`}
                          options={
                            zigna_aiStore.professionalOptionalFilter?.region ||
                            []
                          }
                        />
                      </div>
                    </div>
                  </Col> */}

              <Col className=" col-auto col-auto">
                <div className="mb-1">
                  <label className="form-label">Tax ID</label>
                  <div className="d-flex align-items-center h45 NPI-select">
                    <Select
                      disabled={!selectedProvider}
                      className={`select-menu`}
                      value={
                        filterSet.find((i) => i.field == "tin")?.value || null
                      }
                      onChange={(e, data) => {
                        setFilterSet(updateFilterSet("tin", e));

                        // refetchFilterData(updateFilterSet("tin", e));
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={
                        zigna_aiStore.professionalFilterMaster?.tin || []
                      }
                      allowSelectAll={true}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>

                {/* {filterSet.find((i) => i.field == "tin")?.value && (
                  <Badge bg="primary" className="px-2 py-2 mx-2">
                    TIN: {filterSet.find((i) => i.field == "tin")?.value?.value}
                  </Badge>
                )} */}
              </Col>



              <Col className=" col-auto col-auto">
                <div className="mb-1">
                  <label className="form-label">Payers</label>
                  <div className="d-flex align-items-center h45 NPI-select">
                    <Select
                      disabled={!selectedProvider}
                      className={`select-menu`}
                      value={
                        filterSet.find((i) => i.field == "rates_only")
                          ?.value || {
                          id: 1,
                          name: "Exclude No Individual NPI",
                          value: "rates_only",
                        }
                      }
                      onChange={(e, data) => {
                        setFilterSet(updateFilterSet("rates_only", e));

                        // refetchFilterData(updateFilterSet("rates_only", e));
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={[
                        {
                          id: 1,
                          name: "Exclude No Individual NPI",
                          value: "rates_only",
                        },
                        {
                          id: 2,
                          name: "Include No Individual NPI",
                          value: "all",
                        },
                      ]}
                      allowSelectAll={true}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>
              </Col>

              {/* <Col className="col-12">
							<div >

						
						<span className='text-danger'>*</span> &nbsp;- &nbsp;<small>Mandatory Filter</small> 
						</div>
						</Col> */}
            </Row>
            <Row className="pt-2">
              <b>Payer Rate Filters</b>
              <Col className=" col-auto col-auto">
                <div className="mb-1">
                  <label className="form-label">Rate Type</label>
                  <div className="d-flex align-items-center h45 NPI-select">
                    <Select
                      disabled={!selectedProvider}
                      className={`select-menu`}
                      value={
                        payerFilter.find((i) => i.field == "rate_type")
                        ?.value || {
                          name: "Non facility",
                          value: "Non_Facility",
                      }
                    }
                      onChange={(e, data) => {
                        setPayerFilter(updatePayerFilter("rate_type", e));

                        // refetchFilterData(updateFilterSet("rate_type", e));
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={[
                        {
                          // id: 1,
                          name: "Facility",
                          value: "Facility",
                        },
                        {
                          // id: 2,
                          name: "Non Facility",
                          value: "Non_Facility",
                        },
                      ]}
                      allowSelectAll={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>
              </Col>

              <Col className=" col-auto col-auto">
                <div className="mb-1">
                  <label className="form-label">Most Frequent Rate</label>
                  <div className="d-flex align-items-center h45 NPI-select">
                    <Select
                      disabled={!selectedProvider}
                      className={`select-menu`}
                      value={
                        payerFilter.find((i) => i.field == "most_frequent_rate")
                          ?.value || {
                          // id: 2,
                          name: "All NPIs",
                          value: "all",
                        }
                      }
                      onChange={(e, data) => {
                        setPayerFilter(updatePayerFilter("most_frequent_rate", e));

                        // refetchFilterData(updateFilterSet("rate_type", e));
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={[
                        {
                          name: "All NPI's",
                          value: "all",
                        },
                        {
                          name: "Most Frequent Rate",
                          value: "most_frequent_rate",
                        },
                      ]}
                      allowSelectAll={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>
              </Col>
              <Col className=" col-auto col-auto">
                <div className="mb-1">
                  <label className="form-label">Multiple Rates Reported</label>
                  <div className="d-flex align-items-center h45 NPI-select">
                    <Select
                      disabled={!selectedProvider}
                      className={`select-menu`}
                      value={
                        payerFilter.find((i) => i.field == "multiple_rates_reported")
                          ?.value || {
                          // id: 2,
                          name: "All Rates",
                          value: "all",
                        }
                      }
                      onChange={(e, data) => {
                        setPayerFilter(updatePayerFilter("multiple_rates_reported", e));

                        // refetchFilterData(updateFilterSet("rate_type", e));
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={[
                        {
                          name: "All Rates",
                          value: "all",
                        },
                        {
                          name: "Highest $ Reported",
                          value: "highest_rate_reported",
                        },
                      ]}
                      allowSelectAll={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                    />
                  </div>
                </div>
              </Col>
              <Col className="d-flex align-items-center col-auto col-auto">
                <label className="d-flex align-items-center">
                  <input
                    style={{ width: "18px", height: "18px" }}
                    type="checkbox"
                    checked={saveFilters}
                    onChange={(e) => {
                      setSaveFilters(e.target.checked)
                    }}
                  ></input>
                  <span className="p-2">Save Filter</span>
                </label>
              </Col>

              <Col className=" col-auto">
                <label className="form-label">{"   "}</label>
                <div className="d-flex align-item-center">
                  <Button
                    //    disabled={

                    // 	!selectedProvider
                    //    }
                    variant="primary"
                    size="sm"
                    className="submitFilter"
                    onClick={(e) => {
                      onSubmitForm();
                    }}
                    type="button"
                  >
                    Submit
                  </Button>
                </div>
              </Col>

            </Row>
            <Row>
              <Col className="flex">
                {filterSet.find((i) => i.field == "tin")?.value && (
                  <Badge bg="primary" className="px-2 py-2 mx-2">
                    TIN: {filterSet.find((i) => i.field == "tin")?.value?.value}
                  </Badge>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      );
    }
  )
);
