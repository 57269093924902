import React, { useState } from "react";
import { Card, Col, Row } from "@themesberg/react-bootstrap";

import CategoryWidgets from "../../components/ZignaAi/competitiveLandscape/CategoryWidgets";

import { inject, observer } from "mobx-react";
import StateMapWidget from "../../components/ZignaAi/competitiveLandscape/StateMapWidget";
import RegionalMapWidget from "./../../components/ZignaAi/competitiveLandscape/RegionalMapWidget";
import PayerListWidget from "./../../components/ZignaAi/competitiveLandscape/PayerListWidget";
import { useEffect } from "react";

import OverviewWidget from "../../components/ZignaAi/overviewDrgCPT/OverviewWidget";
import TopProceduresWidget from "../../components/ZignaAi/overviewDrgCPT/TopProceduresWidget";
import { useHistory } from "react-router-dom";
import FilterWidget from "../../components/ZignaAi/competitiveLandscape/FilterWidget";

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    const [pageId, setpageId] = useState();
    let history = useHistory();

    useEffect(() => {
      zigna_aiStore.resetTopOverviewList();

      setLocationVar(history.location.pathname);
      const unlisten = history.listen((location) => {
        setLocationVar(location.pathname);
      });
      return () => {
        unlisten();
      };
    }, [history, zigna_aiStore.userType]);
    const setLocationVar = (path) => {
      if (path === "/topCpt") {
        setpageId("CPT");
        zigna_aiStore.getCPTDrgEntityList(false);
        zigna_aiStore.getOverviewCPTList();
      } else if (path === "/topDrg") {
        setpageId("DRG");
        zigna_aiStore.getCPTDrgEntityList(true);
        zigna_aiStore.getOverviewDRGList();
      }
    };

    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={12} className="mb-4">
            <FilterWidget filterParent={pageId} />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} xl={12}>
            <Card border="light" className="">
              <Card.Header>
                <Row className="align-items-center">
                  <Col>
                    <h5>Overview Top {pageId}s</h5>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className="0">
                  {/* <Col xs={12} xl={6} >
										<OverviewWidget />
									</Col> */}
                  {zigna_aiStore?.topOverviewList["topProcedures"]?.length ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType === "ancillary"
                          ? "Procedures (Preferred Hospitals)"
                          : "Procedures (Community)"}{" "}
                      </h5>
                      <TopProceduresWidget
                        dataField={"topProcedures"}
                        disbaledClick={pageId !== "CPT"}
                      />
                    </Col>
                  ) : null}

                  {pageId === "CPT" &&
                  zigna_aiStore?.topOverviewList["topProceduresL4"]?.length ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType === "ancillary"
                          ? "Procedures(Preferred Hospitals - Level 4)"
                          : "Procedures (Community - Level 4)"}{" "}
                      </h5>
                      <TopProceduresWidget
                        dataField={"topProceduresL4"}
                        color={"#9E2377"}
                      />
                    </Col>
                  ) : pageId === "CPT" &&
                    zigna_aiStore?.topOverviewList["topProcedures"]?.length ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType === "ancillary"
                          ? "Procedures(Preferred Hospitals - Level 4)"
                          : "Procedures (Community - Level 4)"}{" "}
                      </h5>
                      <br />
                      <br />
                      <br />
                      <center>
                        <p className="mt-6">
                          Click on a bar to drill down to see the top 10 codes
                        </p>
                      </center>
                    </Col>
                  ) : null}

                  {zigna_aiStore?.topOverviewList["topProceduresNotPref"]
                    ?.length ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType == "ancillary"
                          ? "Procedures (Non Preferred Hospitals)"
                          : "Procedures (Enhanced)"}{" "}
                      </h5>
                      <TopProceduresWidget
                        dataField={"topProceduresNotPref"}
                        disbaledClick={pageId !== "CPT"}
                      />
                    </Col>
                  ) : null}

                  {pageId === "CPT" &&
                  zigna_aiStore?.topOverviewList["toptopProceduresNotPrefL4"]
                    ?.length ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType == "ancillary"
                          ? "Procedures(Non Preferred Hospitals - Level 4) "
                          : "Procedures (Enhanced - Level 4)"}{" "}
                      </h5>
                      <TopProceduresWidget
                        dataField={"toptopProceduresNotPrefL4"}
                        color={"#9E2377"}
                      />
                    </Col>
                  ) : zigna_aiStore?.topOverviewList["topProceduresNotPref"]
                      ?.length && pageId === "CPT" ? (
                    <Col xs={12} xl={6}>
                      <h5>
                        Top {pageId}{" "}
                        {zigna_aiStore.userType === "facility" ||
                        zigna_aiStore.userType == "ancillary"
                          ? "Procedures(Non Preferred Hospitals - Level 4) "
                          : "Procedures (Enhanced - Level 4)"}{" "}
                      </h5>
                      <br />
                      <br />
                      <br />
                      <center>
                        <p className="mt-6">
                          Click on a bar to drill down to see the top 10 codes
                        </p>
                      </center>
                    </Col>
                  ) : null}

                  {!zigna_aiStore?.topOverviewList["topProcedures"]?.length &&
                  !zigna_aiStore?.topOverviewList["topProceduresNotPref"]
                    ?.length ? (
                    <p>
                      <center>Please Reload Data</center>
                    </p>
                  ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  })
);
