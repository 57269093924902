

export default [
    {
        "id": 1,
        "read": false,
        //"image": Profile1,
        "sender": "Spider Man",
        "time": "a few moments ago",
        "link": "#",
        "message": `Lorem Ipsum`
    },
    {
        "id": 2,
        "read": false,
        //"image": Profile2,
        "sender": "Spider Man",
        "time": "2 hrs ago",
        "link": "#",
        "message": `Lorem Ipsum`
    }
]