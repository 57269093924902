import React, { useState, useEffect } from 'react'
import _debounce from 'lodash.debounce'
import { useBlockLayout, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'

export const CommonFixedColTable = ({ columns, data, className }) => {

	const [windowWidth, setwindowWidth] = useState(window.innerWidth)

	useEffect(() => {
		const handleResize = _debounce(() => setwindowWidth(window.innerWidth), 100)

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		}
	}, [])
	
	console.log(windowWidth)
	// Use the state and functions returned from useTable to build your UI
	

	const rowOnClick = (row) => {
		console.log(`clicked ${row.type} for Strike ${row.strike}`)
	}

let { getTableProps, getTableBodyProps, headerGroups, rows, footerGroups,prepareRow } =
		useTable({
			columns,
			data,
		},
		windowWidth<=1400 ? useBlockLayout:null,
		windowWidth<=1400 && windowWidth>768 ? useSticky: null
		)
		let Result = () =>
	{
		return (<table
			{...getTableProps()}
			className={`table table-borderless ${className}`}
			// key={windowWidth}
		>
			<thead>

				{
					headerGroups.map((headerGroup, i) => (
					headerGroup.headers.map(
							(column) =>{
								console.log(column.getHeaderProps())
							})
					))
				}
				{headerGroups.map((headerGroup, i) => (
					<tr 
					{...headerGroup.getHeaderGroupProps()} 
					
					onClick={() => {}}>
						{headerGroup.headers.map(
							(column) =>
								(column.render('Header') != '' || windowWidth<=1400 )&& 
								(
									<th
										{...column.getHeaderProps()}
										colSpan={column?.columns?.length}
										rowSpan={column?.columns?.length == 1 ? 2 : ''}
									>
										{column.render('Header')}
									</th>
								)
						)}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								const [target, column] = cell.column.id.split('.')
								return (
									<td
										{...cell.getCellProps()}
										onClick={
											column === 'strike'
												? undefined
												: () => rowOnClick(cell.row.original[target])
										}
									>
										{cell.render(
											'Cell',
											cell.column.getProps &&
												cell.column.getProps(row.state, row, cell.column)
										)}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
			<tfoot>
				{footerGroups?.map((group, i) => (
					i===0&&
					<tr {...group?.getFooterGroupProps()}>
						{group.headers.map((column) => (
							column?.render('Footer') !='' &&(
							<td 
							{...column?.getHeaderProps()}
								colSpan={column?.columns?.length}
								rowSpan={column?.columns?.length == 1 ? 2 : ''}
							>{column?.render('Footer')}</td>)
						))}
					</tr>
				))}
			</tfoot>
		</table>)}
	// Render the UI for your table
	return (
		
			<Result />
	)
}
