import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'
import FilterWidget from '../../components/ZignaAi/ProcedureCostByHospital/FilterWidget'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import PriceComparision from '../../components/ZignaAi/FilteredDrgList/PriceComparision'
import GroupCHart from '../../components/ZignaAi/ProcedureCostByHospital/GroupCHart'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let location = useLocation();
		useEffect(() => {
			
			zigna_aiStore.filteredDRGData = {}
			zigna_aiStore.filteredDRGRawData = []
		
			zigna_aiStore.filteredDRGSelected = {
				hospital: null,
				ancillary:null,
				payers: null,
				billingCodes: null
			}
		},[zigna_aiStore.userType, location])
		useEffect(() => {
			zigna_aiStore.filteredDRGData = {}
			zigna_aiStore.filteredDRGRawData = []
			zigna_aiStore.filteredDRGSelected = {
				hospital: null,
				ancillary:null,
				payers: null,
				billingCodes: null
			}
		},[])


		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4">
						<FilterWidget />
					</Col>
					<Row className={` ${zigna_aiStore.procedureCostByHospitalData.length ==0&&'blurDiv'} m-0 p-0`}>
						<Col xs={12} xl={12} className="mb-4">
						{
							zigna_aiStore.procedureCostByHospitalData.length ==0?
							<div className='blurOverlay'><h5>Please select Filter</h5></div>
							:<GroupCHart/>
						}
						</Col>

						
						
					</Row>
				</Row>
			</>
		)
	})
)