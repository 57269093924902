import React, { useEffect, useState } from 'react'
import { Col, Row,Card } from '@themesberg/react-bootstrap'

import { inject, observer } from 'mobx-react'

import DrgCodesByHospital from '../../components/ZignaAi/DataAuditReferenceTable/Tables/DrgCodesByHospital'


import _ from 'lodash'


export default inject('zigna_aiStore','authStore')(
	observer(({ zigna_aiStore ,authStore}) => {





		return (
		
          
        	<DrgCodesByHospital dataset={zigna_aiStore.DRgBYHospital}/>
         
					
		)
		
	})
)




