// import { handleResponse } from '@/_helpers';
import { url } from './Config'
import axios from 'axios'
import React, { Component } from 'react'

export class AuthenticationService extends Component {
	login = (username='test', password='test') => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: { username, password },
		}
		let users = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User' }];
		// return new Promise((resolve, reject)=> {
		// 	setTimeout(() => {
		// 		const params = JSON.parse(requestOptions.body);
        //             const user = users.find(x => x.username === params.username && x.password === params.password);
        //             if (!user) return resolve({ status: 400, data:{ message: 'Username or password is incorrect'} })
        //             return resolve({
		// 				status: 200,
		// 				data:{
		// 					id: user.id,
		// 					username: user.username,
		// 					firstName: user.firstName,
		// 					lastName: user.lastName,
		// 					token: (new Date()).getTime() + (1 * 60 * 1000)
		// 				}
                        
        //             });
		// 	}, 2000);
					
		// })
		return axios.post(`${url}/login`, requestOptions.body, requestOptions.headers)
		// return fetch(`${url}/users/authenticate`, requestOptions)
		//     .then(handleResponse)
		//     .then(user => {
		//         // store user details and jwt token in local storage to keep user logged in between page refreshes
		//         localStorage.setItem('currentUser', JSON.stringify(user));
		//         // currentUserSubject.next(user);
		//         currentUserSubject = user;
		//         return user;
		//     });
	}
	validateToken = (token) => {
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `bearer ${token}`
			 },
			body: {},
		}
		return axios.post(`${url}/validateToken`, requestOptions.body, {headers: requestOptions.headers})
	}
	exchangeToken = (token, dbname) => {
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `bearer ${token}`
			 },
			body: { dbname, exchange: true },
		}
		return axios.post(`${url}/exchangeToken`, requestOptions.body, {headers: requestOptions.headers})
	}
	invalidateToken = (token) => {
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `bearer ${token}`
			 },
			body: {},
		}
		return axios.post(`${url}/invalidateToken`, requestOptions.body, {headers: requestOptions.headers})
	}
	logout = () => {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser')
	}
	resetPassword = (value, token) => {	
		const requestOptions = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `bearer ${token}`
			 },
			body: {
				password: value
			},
		}
		return axios.post(`${url}/freshResetPassword`, requestOptions.body, {headers: requestOptions.headers})	
	}
}
