import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
var SI_SYMBOL = ["k", "k", "M", "G", "T", "P", "E"];

export const convertPrice = (
  number,
  { skipCurrency = false, maximumFractionDigits = 0 } = {}
) => {
  if (number == "" || number == null) {
    return "";
  }
  if (skipCurrency) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: maximumFractionDigits,
    }).format(number);
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maximumFractionDigits,
  }).format(number);
};

export const convertPriceOld = (number) => {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if zero, we don't need a suffix
  if (number == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);
  var scaled;
  if (tier == 0) {
    scaled = number / 1000;
  } else {
    // scale the number
    scaled = number / scale;
  }
  // format number and add suffix
  return "$" + scaled.toFixed(1) + suffix;
};
export const getAvg = (data, key) => {
  let filterData = data?.filter((x) => {
    return x[key] !== "" && typeof x[key] == "number" && x[key];
  });
  return filterData.reduce((acc, x) => acc + x[key], 0) / filterData?.length;
};
export const getMin = (data, key) => {
  return data?.filter((x) => x[key] == Math.min(...data.map((x) => x[key])));
};
export const getMax = (data, key) => {
  return data?.filter((x) => x[key] == Math.max(...data.map((x) => x[key])));
};
export const sortJsonArr = (data, key, type) => {
  let result = [];
  if (type == "asc") {
    result = data?.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
  } else if (type == "dsc") {
    result = data?.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
  } else {
    result = data;
  }
  return result;
};
export const addString = (value, type, str) => {
  let result;
  if (typeof value == "number") {
    result = type == "pre" ? str + "" + value : value + "" + str;
  } else {
    result = value;
  }
  return result;
};
export const toCurrency = (amount, currency = "") => {
  let neg;
  if (typeof amount == "string") {
    neg = amount?.charAt(0);
    amount = amount?.replace(/\D/g, "");
    amount = amount?.replace(/\./g, "");
    amount = amount?.replace(/\-/g, "");
  }

  var numAmount = new Number(amount);
  amount = numAmount.toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "," + c : c;
  });

  if (neg == "-") return currency + "" + neg + amount;
  else return currency + "" + amount;
};
export const getBgColorBasedNum = (values) => {
  let bgColor = "";
  if (values <= -5) {
    bgColor = "darkRed";
  } else if (values > -5 && values < 0) {
    bgColor = "lightRed";
  } else if (values > 0 && values < 5) {
    bgColor = "yellow";
  } else if (values >= 5 && values < 10) {
    bgColor = "ligthGreen";
  } else if (values >= 10) {
    bgColor = "darkGreen";
  }
  return bgColor;
};

export const convertToMonth = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    monthNames[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
  );
};

export const convertToQuater = (date) => {
  const monthNames = [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4];
  return `Q${monthNames[new Date(date).getMonth()]}-${new Date(
    date
  ).getFullYear()}`;
};

export const getPercentageChange = (newValue = 0, previousValue = 0) => {
  if (previousValue == 0) {
    return "";
  }

  let diffirence = (((newValue - previousValue) * 100) / previousValue).toFixed(
    2
  );
  if (!diffirence || diffirence == 0) return "";
  let symbol = diffirence < 0 ? <> &#8595;</> : <> &#8593;</>;
  return (
    <div
      style={{
        color: diffirence < 0 ? "red" : diffirence > 0 ? "green" : "black",
        fontWeight: "bold",
      }}
    >
      {diffirence}%
    </div>
  );
};

export const getSymbol = (newValue = 0, previousValue = 0) => {
  if (previousValue == 0) {
    return "";
  }
  let diffirence = (((newValue - previousValue) * 100) / previousValue).toFixed(
    1
  );
  if (!diffirence || diffirence == 0) return "";
  let symbol =
    diffirence < 0 ? (
      <FontAwesomeIcon
        icon={faArrowDown}
        style={{ fontSize: 12, marginLeft: 2 }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faArrowUp}
        style={{ fontSize: 12, marginLeft: 2 }}
      />
    );
  return (
    <span
      style={{
        color: diffirence < 0 ? "red" : diffirence > 0 ? "green" : "black",
        fontWeight: "bold",
      }}
    >
      {symbol}
    </span>
  );
};

export const getMonthFromString = (str, returnmonth = false) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const [prefix, monthStr, dayStr] = str.split(" ");
  let monthStr = str.includes('Stage') ? "Stage" : str.includes("stage") ? "stage" : "";
  monthNames.forEach(element => {
    if (str.toLowerCase().match(element.toLowerCase())){
      monthStr = element
    }
  })
  if (returnmonth) {
    return monthStr;
  } else return monthNames.findIndex((i) => i == monthStr);
};

export const getYearFromString = (str) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const [prefix, monthStr, yrStr] = str.split(" ");
  let yrStr = '';
  monthNames.forEach(element => {
    if (str.toLowerCase().match(element.toLowerCase())){
      yrStr = str.toLowerCase().split(str.toLowerCase().match(element.toLowerCase()))[1].replace(/[^\d]/g,'')
    }
  })
  return Number(yrStr) || yrStr;
};
