export  function getValueLegend(value) {
    console.log(value);
    if (value >= 657 && value < 1597) {
      return "Unconcentrated";
    } else if (value >= 1597 && value < 2600) {
      return "Moderate";
    } else if (value >= 2600 && value < 4978) {
      return "High";
    } else if (value >= 4978 && value < 8246) {
      return "Very High";
    } else {
      return "N/A"; // If the value doesn't fall into any range
    }
  }
  

  export  function getLegendColor(value) {
    console.log(value);
    if (value >= 657 && value < 1597) {
      return "#90c6e4";
    } else if (value >= 1597 && value < 2600) {
      return "#f9d276";
    } else if (value >= 2600 && value < 3800) {
      return "#f38251";
    } else if (value >= 3800 && value < 5000) {
      return "#c10100";
    } else {
      return "#eaECF5"; // If the value doesn't fall into any range
    }
  }