import { useState } from 'react';
import { Button, Alert, Modal, Form } from '@themesberg/react-bootstrap'
import RequestMoreModal from './RequestMoreModal'

function ReRequestExtraData({ onConfirm, status, dbname }) {
  const [show, setShow] = useState(false);
  const [label, setLabel] = useState('')
  const [db, setDb] = useState({ id: dbname, label: dbname, value: dbname })
  const handleClose = () => {
    setLabel('')
    setShow(false)
  };
  const handleConfirm = () => {
    onConfirm({ label, db: db?.value })
    setLabel('')
    setShow(false)
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button size='sm' onClick={handleShow} disabled={status != "Completed"} variant="outline-primary">Re-run Query</Button>
      <RequestMoreModal
        show={show}
        handleClose={handleClose}
        setLabel={setLabel}
        db={db}
        setDb={setDb}
        label={label}
        handleConfirm={handleConfirm}
        isRe={true}
      />
    </>
  );
}

export default ReRequestExtraData;