import {Pagination} from '@themesberg/react-bootstrap';
import React from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import "../scss/pagination.css"


 const CustomPagination=({active ,pages,setActive})=>{
    let items = [];
    for (let number = 0; number < pages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={()=>setActive(number)}>
          {number+1}
        </Pagination.Item>,
      );
    }
    return(
<div>
<PaginationControl

    page={active+1}
    between={4}
    total={pages*100}
    limit={100}
    changePage={(page) => {
      console.log(page-1)
      setActive(page-1)
    }}
    ellipsis={1}
  />
   
  </div>
    )
}


export default CustomPagination