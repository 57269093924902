import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import usStateJson from './../../../data/ZignaAi/us-states.json'
import usStateJson1 from './../../../data/ZignaAi/us-states_counties_zip.json'
import Data from "../../../data/DataAudit/npi_distribution_across_county.json"

import * as d3 from 'd3'
import * as topojson from 'topojson'
import _ from "lodash"

import "./style.css"
import { useRef } from 'react'
const checkProviderType=(provider_type,type)=>{
	if(type=="FACILITY"){
		return provider_type=="HOSPITAl"||provider_type=="ANCILLARY"
	}
	if(type=="PROFESSIONAL"){
		return provider_type.includes("PROFESSIONAL")
	}
}
const Regionalmap=(props)=>{
	const inputRef = useRef(null);
	let [maxVal,setMax]=useState(1)
		useEffect(() => {
			let data = props.mapData.filter(i=>i.stateperoraclertafile==props.selectedState?.id&&checkProviderType(i.provider_type,props.type)&&i.month==props.month).map(i=>({
				zip:i.postal_code_text+"",
				
				...i
			}))
			
		
			
		
			drawMap([
				...topojson
				.feature(usStateJson1, usStateJson1.objects.zip_codes_for_the_usa)
				.features?.filter(
					(item) =>
						item?.properties?.state?.toLowerCase() ==
						props.selectedState?.id?.toLowerCase()
				)
					?.concat(data)
					.reduce(
						(m, o) =>
							m.set(o?.properties?.zip, Object.assign(m.get(o.zip) || {}, o)),
						new Map()
					)
					.values(),
			],data)
		}, [props.selectedState?.id,props?.payer,props.mapData.length,props.month])
		
		let colorsHospital={
			5:"#b30000",
		
			25:"#0d88e6",
			
			50:"#ebdc78",
			
			1000:"#5ad45a",
			
		
		}
		let colorsAncialry={
			10:"#b30000",
		
			20:"#0d88e6",
			
			30:"#ebdc78",
			
			1000000:"#5ad45a",
		}

		let colorProfessional={
			25:"#b30000",
		
			50:"#0d88e6",
			
			100:"#ebdc78",
			
			1000000:"#5ad45a",
		}
		const getColor=(val,max)=>{

			if(props.type=="FACILITY"){

				let keys=[5,25,50,10000]
			
				return colorsHospital[keys.find(i=>i>=val)]
			
			}
else
if(props.type=="ANCILLARY"){
	let keys=[10,20,30,1000000]

	return colorsAncialry[keys.find(i=>i>=val)]
}
else
{
	let keys=[25,50,100,1000000]

	return colorProfessional[keys.find(i=>i>=val)]

}

			

		


		} 
		function drawMap(usMergedCustomData,data) {
			d3.select(inputRef.current).select('svg').remove()
			d3.select(inputRef.current).select('div').remove()
			const tooltip = d3.select(inputRef.current).append('div')
			.attr('class', 'tooltip')
			.attr('id', 'tooltip')
			.style('opacity', 0);
let max=_.maxBy(usMergedCustomData,i=>i._col3||0)?._col3||1
		
setMax(max)
	var margin_choropleth = {
					top: 10,
					left: 10,
					bottom: 10,
					right: 10,
				},
				width = parseInt(d3.select(inputRef.current).style('width')),
				width = width - margin_choropleth.left - margin_choropleth.right,
				mapRatio = 0.5,
				height = width * mapRatio

			// D3 Projection
			var projection = d3.geoAlbersUsa()
			// .scale(width)
			// .translate([width / 2, height / 2])

			// Define path generator
			var path = d3.geoPath().projection(projection)

			var viewboxwidth = width * 1
			var viewboxheight = height

			// d3.json(usStateJson, function (json) {
			var centered = null
			var formatComma = d3.format(',')
			var fill = d3.scaleLog().domain([10, 500]).range(['brown', 'steelblue'])

			var svg = d3
				.select(inputRef.current)
				.append('svg')
				.attr('preserveAspectRatio', 'xMidYMid meet')
				.attr('viewBox', '0 0 ' + viewboxwidth + ' ' + viewboxheight + '')

			let us = usStateJson
			// var states = topojson.feature(us, us.objects.states),
			let state = us.features.filter(function (d) {
				return d.id == props.selectedState?.id
			})[0]
			projection.scale(1).translate([0, 0])

			var b = path.bounds(state),
				s =
					0.95 /
					Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height),
				t = [
					(width - s * (b[1][0] + b[0][0])) / 2,
					(height - s * (b[1][1] + b[0][1])) / 2,
				]

			projection.scale(s).translate(t)

			svg
				.append('path')
				.datum(state)
				.attr('class', 'outline')
				.attr('d', path)
				.attr('id', 'land')

			let map = svg.append('g').attr('id', 'usCounties')
			
			map
				.selectAll('path')
				// .data(topojson.feature(us, us.objects.counties).features)
				.data(usMergedCustomData)
				.enter()
				.append('path')
				.attr('d', path)
				.attr('county-id', function (d) {
					return d?.id
				})
				// .attr('clip-path', 'url(#clip-land)')
				.attr('class', 'county')
				.style('fill', function (d) {
		
					return d?._col3>0 ? getColor(d._col3)||"#ebdc78" : '#eaECF5'
				}).on('mouseover', (d) => {
					const record=data.find(i=>i.zip==d?.zip)
					if(!record)
					return null
					tooltip.style('opacity', 0.9).style('left', `${d3.event.offsetX}px`)
					.style('top', `${d3.event.offsetY }px`);
					tooltip.html(() => {
					 
					  // Could not find result
					  return `NPI COUNT : ${d._col3}`;
					})
					
					  
				  })
				  .on('mouseout', (d) => {
					tooltip.style('opacity', 0);
				  })
			  
				.append('title')
				.html((d) => d.hospital)
			let zoomBtnPositionX = viewboxwidth - 30
			let zoomBtnPositionY = viewboxheight - 70
			svg
				.append('g')
				.attr('class', 'D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomIn')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>+')
				.on('click', zoomIn)
			svg
				.select('g.D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomOut')
				.attr('transform', 'translate(0,28)')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>─')
				.on('click', zoomOut)
			const zoom = d3.zoom().scaleExtent([1, 8]).on('zoom', zoomed)

			function zoomed() {
				map
					.selectAll('path') // To prevent stroke width from scaling
					.attr('transform', d3.event.transform)
			}

			svg.call(zoom)
			let zoomfactor = 1
			function zoomOut() {
				if (zoomfactor > 1) {
					zoomfactor = zoomfactor - 0.5
					zoom.scaleTo(svg.transition().duration(750), zoomfactor)
				}
			}
			function zoomIn() {
				zoomfactor = zoomfactor + 0.5
				zoom.scaleTo(svg.transition().duration(750), zoomfactor)
			}
		}
		return (
			<Row>
				<Col xs={2}>
{["#b30000",

	"#0d88e6",
	"#ebdc78",
	"#5ad45a"
		
		].map((i,index)=>{


	return(
		<div className='d-flex align-items-center'>
			<div style={{width:12,height:50,background:i}}></div>
			<div style={{fontSize:12,paddingLeft:3}}>
{index==0&&(props.type=="FACILITY"?"1-5":props.type=="ANCILLARY"?"1-10":props.type=="PROFESSIONAL"?"1-25":"1-25")}
{index==1&&(props.type=="FACILITY"?"5-25":props.type=="ANCILLARY"?"10-20":props.type=="PROFESSIONAL"?"25-50":"25-50")}
{index==2&&(props.type=="FACILITY"?"25-50":props.type=="ANCILLARY"?"20-30":props.type=="PROFESSIONAL"?"50-100":"50-100")}
{index==3&&(props.type=="FACILITY"?">50":props.type=="ANCILLARY"?">30":props.type=="PROFESSIONAL"?">100":">100")}
			</div>
		</div>
	)

})}
				</Col>
				<Col xs={10}>
				<div style={{position:"relative"}} id="usCountyMap" ref={inputRef}  className="d3Map">
						
						</div>
				</Col>
			</Row>
			
				
				
		
				
					
				
			
		)
	}

export default Regionalmap