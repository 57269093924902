import React, { useRef } from 'react'
import { Col, Row, Card } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { ResponsiveLine } from '@nivo/line'
import { useTheme } from '@nivo/core'
import { DirectionArrow } from '../common/DirectionArrow'
import { addString } from '../../../Utilities'
import truncate from 'truncate-html'
import PriceWidget from './PriceWidget'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore, calcData }) => {
		const chartRef = useRef()
		let chartMargin = { top: 50, right: 80, bottom: 100, left: 140 }
	
		const CustomTick = (tick) => {
			const theme = useTheme()
			let chartHeight =
				chartRef.current.clientHeight - (chartMargin.top + chartMargin.bottom)
			const canvas = document.createElement('canvas')
			const context = canvas.getContext('2d')
			context.font = getComputedStyle(document.body).font
			let fontWidth = 70
			return (
				<g transform={`translate(${tick.x},${-(chartHeight / 2)})`}>
					<rect
						x={-fontWidth / 2}
						y={-14}
						rx={'3'}
						ry={'3'}
						width={fontWidth}
						height={24}
						fill="#BFC4DC"
					/>

					<text
						textAnchor="middle"
						dominantBaseline="middle"
						style={{
							...theme.axis.ticks.text,
							// fill: '#333',
							fontSize: 12,
							fontWeight: 600,
						}}
					>
						{truncate(tick.value, 8)}
												<title>{tick.value}</title>

					</text>
				</g>
			)
		}
		const DataLine = ({
			series,
			lineGenerator,
			xScale,
			yScale,
			height,
			width,
		}) =>
			series.map(({ id, data, color }, index) => (
				<line key={index}
					x1="-100"
					y1={(height - (chartMargin.top + chartMargin.bottom)) / 2}
					x2={width}
					y2={(height - (chartMargin.top + chartMargin.bottom)) / 2}
					style={{ stroke: '#BFC4DC', strokeWidth: 2 }}
				/>
			))
		const GradientLine = ({
			series,
			lineGenerator,
			xScale,
			yScale,
			height,
			width,
		}) => {
			return series.map(({ id, data, color, position }, index) => {
				
				let firstNegativeIndex = data
					.map((item, i) => (item.data.priceDiff < 0 ? i : undefined))
					.filter((x) => x)
				let dataLength = data.length
				let lengthAvg = 100 / dataLength
				return (
					<>
						<defs>
							<linearGradient id="gradient">
								{
									<>
										<stop
											offset={(lengthAvg * firstNegativeIndex[0] - 5) + '%'}
											stopColor="#239E45"
										></stop>
										<stop
											offset={lengthAvg * firstNegativeIndex[0] + 2 + '%'}
											stopColor="#9D4E20"
										></stop>
										<stop
											offset={lengthAvg * firstNegativeIndex[0] + 5 + '%'}
											stopColor="#E83B24"
										></stop>
										
										<stop offset="1%" stopColor="#F14331"></stop>
									</>
								}
							</linearGradient>
						</defs>
						<path
							key={id}
							d={lineGenerator(
								data.map((d) => ({
									x: xScale(d.data.x),
									y: yScale(d.data.y),
								}))
							)}
							fill="none"
							stroke="url(#gradient)"
							style={{
								strokeWidth: 3,
							}}
						/>
						{data.map((item,i) => {
							let circleColor = item.data.priceDiff > 0 ? '#239E45' : '#F14331'
							let textPosX = item.data.priceDiff > 0 ? 20 : -10
							let textPosY = item.data.priceDiff > 0 ? 20 : -10
							return (
								<g key={i}
									transform={`translate(${item.position.x},${item.position.y})`}
								>
									<text x={0} y={textPosY}>
										{(calcData.label=='percentage'? addString(Math.abs(item.data.priceDiff), 'suf', '%'):addString(Math.abs(item.data.priceDiff), 'pre', '$')) }
									</text>
									<circle
										cx={0}
										cy={0}
										r="4"
										stroke={circleColor}
										strokeWidth="3"
										fill="#fff"
									/>
								</g>
							)
						})}
					</>
				)
			})
		}
		const BottomLabel = ({
			series,
			lineGenerator,
			xScale,
			yScale,
			height,
			width,
		}) => {
			return series.map(({ id, data, color }, index) => {
				return (
					<>
						<g  key={index} transform={`translate(${-120},${height - 100})`}>
							<text>
								<tspan x="0" dy=".6em">
									% Difference
								</tspan>
								<tspan x="0" dy="1.2em">
									From
								</tspan>
								<tspan x="0" dy="1.2em">
									Avg
								</tspan>
							</text>
						</g>
						{data.map((lineData, ind) => {
							return (
								<g key={ind}
									transform={`translate(${lineData.position.x - 15},${
										height - 100
									})`}
								>
									<foreignObject width="60" height="50">
										<DirectionArrow
											value={Number(lineData.data?.priceDiffPercentage)}
										/>
									</foreignObject>
								</g>
							)
						})}
					</>
				)
			})
		}
		return (
			<Card border="light" className="shadow-sm priceDiffAvgWidget">
				<Card.Header>
					<Row className="align-items-center">
						<Col xs={12} lg={6} className="mb-4 w-100-1024 ">
								<h5>Price Difference from Average ({calcData.label})</h5>
							</Col>
							<Col xs={12} lg={6} className="w-100-1024 ">
								<PriceWidget calcData={calcData}/>
							</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<Col
						xs={12}
						sm={12}
						style={{ height: '350px' }}
						ref={chartRef}
						id="priceDiffAvgWidgetChart"
					>
						<div className="bg-boxPattern"></div>
						<ResponsiveLine
							layers={[
								DataLine,
								'grid',
								'markers',
								'axes',
								'areas',
								'crosshair',
								'line',
								'slices',
								'points',
								'mesh',
								'legends',
								GradientLine,
								BottomLabel,
							]}
							colors={["transparent"]}
							data={calcData?.priceDiffAvgChartList}
							margin={chartMargin}
							xScale={{ type: 'point' }}
							yScale={{
								type: 'linear',
								min: 'auto',
								max: 'auto',
								stacked: true,
								reverse: false,
							}}
							yFormat=" >-.2f"
							enableGridX={false}
							enableGridY={false}
							axisTop={null}
							axisRight={null}
							// axisBottom={null}
							axisLeft={null}
							axisBottom={{
								orient: 'bottom',
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: '',
								legendOffset: 36,
								legendPosition: 'middle',
								renderTick: CustomTick,
							}}
							enablePoints={false}
							pointSize={10}
							pointColor={(d) => {
								return 'black'
							}}
							pointBorderWidth={2}
							// pointBorderColor={{ from: 'serieColor' }}
							pointLabelYOffset={-12}
							useMesh={true}
							legends={[]}
							curve="monotoneX"
						/>

						<div className="bg-white p-1 d-inline-block chartLegendCustom chartLegendCustom1">
							<DirectionArrow value={-1} textColor={'neutral'}>
								Higher than Average
							</DirectionArrow>
						</div>
						<div className="bg-white p-1 d-inline-block chartLegendCustom chartLegendCustom2">
							<DirectionArrow value={1} textColor={'neutral'}>
								Lesser than Average
							</DirectionArrow>
						</div>
					</Col>
				</Card.Body>
			</Card>
		)
	})
)

const iconSelector = (value) => {
	let icon
	if (value == 0) {
		icon = (
			<svg
				width="25"
				height="26"
				viewBox="0 0 25 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect
					x="1"
					y="1"
					width="4"
					height="23"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<mask id="path-2-inside-1_369_241" fill="white">
					<path d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z" />
				</mask>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L26.3437 19.0796L26.3509 17.0797L24.3509 17.0725L24.3438 19.0725ZM24.3223 25.0725L24.3152 27.0725L26.3152 27.0796L26.3223 25.0796L24.3223 25.0725ZM22.3438 19.0654L22.3224 25.0653L26.3223 25.0796L26.3437 19.0796L22.3438 19.0654ZM24.3295 23.0725L4.00747 23L3.9932 26.9999L24.3152 27.0725L24.3295 23.0725ZM4.01461 21L24.3366 21.0725L24.3509 17.0725L4.02888 17L4.01461 21Z"
					fill="#8B6CBC"
					mask="url(#path-2-inside-1_369_241)"
				/>
				<path
					d="M9.00068 15.037C9.02113 15.5889 9.48511 16.0198 10.037 15.9993L19.0308 15.6662C19.5828 15.6458 20.0136 15.1818 19.9931 14.6299C19.9727 14.078 19.5087 13.6471 18.9568 13.6676L10.9623 13.9637L10.6662 5.96915C10.6458 5.41725 10.1818 4.98641 9.62988 5.00685C9.07798 5.02729 8.64714 5.49127 8.66758 6.04318L9.00068 15.037ZM22.2672 0.319549L9.26721 14.3195L10.7328 15.6805L23.7328 1.68045L22.2672 0.319549Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else if (value == 1) {
		icon = (
			<svg
				width="32"
				height="33"
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					x="2"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<rect
					x="12"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<rect
					x="23"
					y="10"
					width="5"
					height="22"
					fill="white"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<path
					d="M31.3536 4.35355C31.5488 4.15829 31.5488 3.84171 31.3536 3.64645L28.1716 0.464466C27.9763 0.269204 27.6597 0.269204 27.4645 0.464466C27.2692 0.659728 27.2692 0.976311 27.4645 1.17157L30.2929 4L27.4645 6.82843C27.2692 7.02369 27.2692 7.34027 27.4645 7.53553C27.6597 7.7308 27.9763 7.7308 28.1716 7.53553L31.3536 4.35355ZM0 4.5H31V3.5H0V4.5Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else if (value == 2) {
		icon = (
			<svg
				width="25"
				height="26"
				viewBox="0 0 25 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect x="1" y="1" width="4" height="23" fill="white" />
				<rect
					x="1"
					y="1"
					width="4"
					height="23"
					stroke="#8B6CBC"
					strokeWidth="2"
				/>
				<mask id="path-2-inside-1_369_241" fill="white">
					<path d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z" />
				</mask>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L24.3223 25.0725L4.00034 25L4.02175 19L24.3438 19.0725Z"
					fill="white"
				/>
				<path
					d="M24.3438 19.0725L26.3437 19.0796L26.3509 17.0797L24.3509 17.0725L24.3438 19.0725ZM24.3223 25.0725L24.3152 27.0725L26.3152 27.0796L26.3223 25.0796L24.3223 25.0725ZM22.3438 19.0654L22.3224 25.0653L26.3223 25.0796L26.3437 19.0796L22.3438 19.0654ZM24.3295 23.0725L4.00747 23L3.9932 26.9999L24.3152 27.0725L24.3295 23.0725ZM4.01461 21L24.3366 21.0725L24.3509 17.0725L4.02888 17L4.01461 21Z"
					fill="#8B6CBC"
					mask="url(#path-2-inside-1_369_241)"
				/>
				<path
					d="M24 2C24 1.44771 23.5523 0.999999 23 0.999999L14 1C13.4477 1 13 1.44772 13 2C13 2.55228 13.4477 3 14 3L22 3L22 11C22 11.5523 22.4477 12 23 12C23.5523 12 24 11.5523 24 11L24 2ZM10.7071 15.7071L23.7071 2.70711L22.2929 1.29289L9.29289 14.2929L10.7071 15.7071Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	}
	return icon
}
