import React from 'react'
import { Accordion, Card, Col, Row,ButtonGroup,Button,Badge } from '@themesberg/react-bootstrap'
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import scatterdata from "./data/scatter.json"
import _ from "lodash"
export default function ScatterPlot({region}) {

    let filteredData=scatterdata.filter(i=>i.region==region)
    console.log(filteredData)

    const data = [
        {
          id: 'A',
          data: filteredData.map(i=>({
           x:i.avg_rate,
           y:i.quality,
           size:i.utilization,
           provider_name:i.provider_name
          }))
         
        },
      ];

      const customTooltip = (point) => {
        // You can access the data point and its properties
        const { x, y, size ,provider_name} = point.node.data;
      
        // Format the tooltip content as you like
        return (
          <div className='bg-white border rounded p-2'>
            <h6>{provider_name}</h6>
            <strong>Cost:</strong> {new Intl.NumberFormat().format(x)}<br />
            <strong>Quality:</strong> {y}<br />
            <strong>Utilization:</strong> {size}
          </div>
        );
      }

  return (
<Card border="light" >
<Card.Body className='d-flex flex-column align-items-center justify-content-center'>

<h3>Scatter {region}</h3>
<div style={{ width: '100%', height: '400px' }}>
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 20, right: 30, bottom: 60, left: 80 }}
        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        xFormat=".2f"
        yScale={{ type: 'linear', min: 'auto', max: 'auto' ,reverse: true }}
        yFormat=".2f"
        axisTop={null}
        axisRight={null}
        tooltip={customTooltip}
        onClick={item=>console.log(item)}
        nodeSize={{
            key: 'data.size', // Use the 'size' property from the data
            values: [ _.minBy(filteredData, 'utilization').utilization, _.maxBy(filteredData, 'utilization').utilization], // Define the range of dot sizes based on your data
            sizes: [8,24], // Map the values to the desired dot sizes
          }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Cost',
          legendPosition: 'middle',
          legendOffset: 46,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Quality',
          legendPosition: 'middle',
          legendOffset: -60,
        }}
        colors={{ scheme: 'nivo' }}

        blendMode="multiply"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
                            
                            </Card.Body>
                            </Card>
  )
}
