import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Form,
  Badge,
} from "@themesberg/react-bootstrap";
import { inject, observer } from "mobx-react";
import Select from "../../ui/Dropdown";

import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import * as XLSX from "xlsx";
import { useLocation } from "react-router-dom";

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#e7e3f9",
    height: 44,
    minHeight: 44,
    minWidth: 100,
    width: 240,
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#c3b9f1",
      };
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#c3b9f1",
    };
  },
};
export default inject("zigna_aiStore")(
  observer(({ zigna_aiStore, hideModifier }) => {
    const [providerType, setProviderType] = useState({
      id: "facility",
      name: "Facility",
      value: "facility",
    });
    let location = useLocation();
    useEffect(() => {
      zigna_aiStore.getPCHcodeType(providerType.value);
      zigna_aiStore.setPCHfilterData("codeType", null);
      zigna_aiStore.setPCHfilterData("reporting_entity", null);
      zigna_aiStore.setPCHfilterData("city", null);
      zigna_aiStore.setPCHfilterData("l1description", null);
      zigna_aiStore.setPCHfilterData("negotiatedType", null);
      zigna_aiStore.resetPCHList();
    }, [location]);

    return (
      <Card border="light" className="shadow-sm filterSection">
        <Card.Body>
          <h5>Filter </h5>
          <Row>
            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Provider type <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={providerType}
                    onChange={(e, data) => {
                      setProviderType(e);
                      zigna_aiStore.getPCHcodeType(e.value);
                      zigna_aiStore.setPCHfilterData("codeType", null);
                      zigna_aiStore.setPCHfilterData("negotiatedType", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );

                      zigna_aiStore.setPCHfilterData("l1description", null);
                      zigna_aiStore.setPCHfilterData("reporting_entity", null);
                      zigna_aiStore.setPCHfilterData("city", null);

                      zigna_aiStore.resetPCHList();
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={[
                      {
                        id: "ancillary",
                        name: "Ancillary",
                        value: "ancillary",
                      },
                      { id: "facility", name: "Facility", value: "facility" },
                    ]}
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Code type <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={
                      zigna_aiStore.procedureCostByHospitalSelected.codeType
                    }
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData("negotiatedType", null);
                      zigna_aiStore.setPCHfilterData("l1description", null);
                      zigna_aiStore.setPCHfilterData("reporting_entity", null);
                      zigna_aiStore.setPCHfilterData("city", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );
                      zigna_aiStore.setPCHfilterData("codeType", e);
                    }}
                    isMulti
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp.codeType?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp.codeType
                        : []
                    }
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                  <button
                    type="button"
                    disabled={
                      zigna_aiStore.procedureCostByHospitalSelected.codeType ==
                        "" ||
                      zigna_aiStore.procedureCostByHospitalSelected.codeType ==
                        undefined ||
                      zigna_aiStore.procedureCostByHospitalSelected.codeType ==
                        null ||
                      !zigna_aiStore.procedureCostByHospitalSelected.codeType
                        ?.length
                    }
                    className="NPI-submit btn btn-primary btn-sm"
                    onClick={() => {
                      zigna_aiStore.setPCHfilterData("negotiatedType", null);
                      zigna_aiStore.setPCHfilterData("l1description", null);
                      zigna_aiStore.setPCHfilterData("reporting_entity", null);
                      zigna_aiStore.setPCHfilterData("city", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );
                      zigna_aiStore.getPCHnegitiatedType(providerType.value);

                      zigna_aiStore.resetPCHList();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="dropdown-arrow"
                      size="lg"
                    />
                  </button>
                </div>
              </div>
            </Col>
            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Negotiated type <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={
                      zigna_aiStore.procedureCostByHospitalSelected
                        .negotiatedType
                    }
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData("negotiatedType", e);
                      zigna_aiStore.setPCHfilterData("l1description", null);
                      zigna_aiStore.setPCHfilterData("reporting_entity", null);
                      zigna_aiStore.setPCHfilterData("city", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );

                      zigna_aiStore.getPCHl1description(providerType.value);
                      zigna_aiStore.resetPCHList();
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp.negotiatedType
                        ?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp
                            .negotiatedType
                        : []
                    }
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Procedure <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={
                      zigna_aiStore.procedureCostByHospitalSelected
                        .l1description
                    }
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData("l1description", e);
                      zigna_aiStore.setPCHfilterData("reporting_entity", null);
                      zigna_aiStore.setPCHfilterData("city", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );
                      zigna_aiStore.getPCHreportingEntity(providerType.value);
                      zigna_aiStore.resetPCHList();
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp.l1description
                        ?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp
                            .l1description
                        : []
                    }
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Reporting entity name <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={
                      zigna_aiStore.procedureCostByHospitalSelected
                        .reporting_entity
                    }
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData("reporting_entity", e);
                      zigna_aiStore.setPCHfilterData("city", null);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );
                      zigna_aiStore.getPCHcity(providerType.value);
                      zigna_aiStore.resetPCHList();
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp.reporting_entity
                        ?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp
                            .reporting_entity
                        : []
                    }
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Region <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={zigna_aiStore.procedureCostByHospitalSelected.city}
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData("city", e);
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        null
                      );
                      zigna_aiStore.getPCHbillingCodeModifiers(
                        providerType.value
                      );
                      zigna_aiStore.resetPCHList();
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp.city?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp.city
                        : []
                    }
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">Billing code modifier </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    className={`select-menu`}
                    value={
                      zigna_aiStore.procedureCostByHospitalSelected
                        .billing_code_modifier
                    }
                    onChange={(e, data) => {
                      zigna_aiStore.setPCHfilterData(
                        "billing_code_modifier",
                        e
                      );
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    styles={customStyles}
                    options={
                      zigna_aiStore.procedureCostByHospitaltemp
                        .billing_code_modifier?.length
                        ? zigna_aiStore.procedureCostByHospitaltemp
                            .billing_code_modifier
                        : []
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className=" col-auto">
              <label className="form-label">{"   "}</label>
              <div className="d-flex align-item-center">
                <Button
                  variant="primary"
                  size="sm"
                  className="submitFilter"
                  onClick={(e) => {
                    zigna_aiStore.getProcedureCostByHospital(
                      providerType.value
                    );
                  }}
                  type="button"
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  })
);
