import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button } from "@themesberg/react-bootstrap";
import { inject, observer } from "mobx-react";
import Select from "../../ui/Dropdown";

import { useLocation } from "react-router-dom";
export default inject("zigna_aiStore")(
  observer(({ zigna_aiStore, filterSet, setFilterSet, setError }) => {
    let location = useLocation();
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedTin, setSelectedTin] = useState([]);

    const updateFilterSet = (field, value) => {
      zigna_aiStore?.resetProviderExtractChartResult();

      let index = filterSet.findIndex((i) => i.field == field);
      let updatedFilerset = [];
      if (index > -1) {
        updatedFilerset = [...filterSet.slice(0, index)];
      } else {
        updatedFilerset = [...filterSet];
      }
      if (value?.name?.length || value?.length)
        return [...updatedFilerset, { field, value }];
      else return [...updatedFilerset];
    };
    useEffect(() => {
      refetchFilterData([]);
    }, [location]);

    useEffect(() => {
      zigna_aiStore.professionalRateChoiceData = [];
      zigna_aiStore.professionalRateChoiceCount = 0;
    }, [filterSet]);

    const refetchFilterData = (filters) => {
      zigna_aiStore.getProfessionalOptionalFilters({
        filters: filters.map((i) => {
          return {
            field: i.field,
            value:
              i.field == "region" ? i.value.name : i.value?.map((j) => j.name),
          };
        }),
      });
    };

    const onSubmitForm = () => {
      if (filterSet.length == 0) {
        setError(true);
        return;
      }
      zigna_aiStore.getRateChoiceData({
        fields: filterSet.map((i) => {
          return {
            field: i.field,
            value:
              i.field == "region" ? i.value.name : i.value?.map((j) => j.name),
          };
        }),
      });
    };

    return (
      <Card border="light" className="shadow-sm filterSection">
        <Card.Body>
          <h5>Filter </h5>
          <Row>
            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Fee Schedule
                  {/* <span className='text-danger'>*</span> */}
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    menuPortalTarget={document.body}
                    className={`select-menu`}
                    value={
                      filterSet.find((i) => i.field == "provider_type")
                        ?.value || null
                    }
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onMenuClose={() =>
                      filterSet.find((i) => i.field == "provider_type")?.value
                        ?.length
                        ? refetchFilterData(filterSet)
                        : null
                    }
                    onChange={(e, data) => {
                      setFilterSet(updateFilterSet("provider_type", e));
                      //refetchFilterData(updateFilterSet("provider_type",e))
                    }}
                    //zigna_aiStore.getProfessionalOptionalFilters({filters:[{field:"provider_type",value:e.name}]})

                    // onChange={(e, data) => {
                    // 	setSelectedProvider(e)
                    // 	setFilterSet(updateFilterSet("provider_type",e))
                    // 	refetchFilterData(updateFilterSet("provider_type",e))
                    // 	//zigna_aiStore.getProfessionalOptionalFilters({filters:[{field:"provider_type",value:e.name}]})

                    // }}

                    getOptionLabel={(option) => `${option.name}`}
                    options={
                      zigna_aiStore.professionalOptionalFilter?.provider_type ||
                      []
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">
                  Region
                  {/* <span className='text-danger'>*</span> */}
                </label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    disabled={!selectedProvider}
                    className={`select-menu`}
                    value={
                      filterSet.find((i) => i.field == "region")?.value || null
                    }
                    onChange={(e, data) => {
                      setFilterSet(updateFilterSet("region", e));

                      refetchFilterData(updateFilterSet("region", e));
                    }}
                    getOptionLabel={(option) => `${option.id}`}
                    options={
                      zigna_aiStore.professionalOptionalFilter?.region || []
                    }
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">Tax ID</label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    disabled={!selectedProvider}
                    className={`select-menu`}
                    value={
                      filterSet.find((i) => i.field == "tin")?.value || null
                    }
                    onChange={(e, data) => {
                      setFilterSet(updateFilterSet("tin", e));

                      //refetchFilterData(updateFilterSet("tin",e))
                    }}
                    onMenuClose={() => refetchFilterData(filterSet)}
                    getOptionLabel={(option) => `${option.name}`}
                    options={
                      zigna_aiStore.professionalOptionalFilter?.tin || []
                    }
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                  {/* <button
				type="button"
				className="NPI-submit btn btn-primary btn-sm"
				disabled={!zigna_aiStore.drgCostBreakdownSelected.code_category?.length}
				onClick={()=>{
					zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
					zigna_aiStore.drgcostbreakdownData=[]
				
				    zigna_aiStore.getDDRGcostreportingEntity()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button> */}
                </div>
              </div>
            </Col>

            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">Billing Code</label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    disabled={!selectedProvider}
                    className={`select-menu`}
                    value={
                      filterSet.find((i) => i.field == "billing_code")?.value ||
                      null
                    }
                    onChange={(e, data) => {
                      setFilterSet(updateFilterSet("billing_code", e));
                      //refetchFilterData(updateFilterSet("billing_code",e))
                    }}
                    onMenuClose={() => refetchFilterData(filterSet)}
                    getOptionLabel={(option) => `${option.name}`}
                    options={
                      zigna_aiStore.professionalOptionalFilter?.billing_code ||
                      []
                    }
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                </div>
              </div>
            </Col>
            <Col className=" col-auto col-auto">
              <div className="mb-3">
                <label className="form-label">Payer</label>
                <div className="d-flex align-items-center h45 NPI-select">
                  <Select
                    disabled={!selectedProvider}
                    className={`select-menu`}
                    value={
                      filterSet.find((i) => i.field == "reporting_entity_name")
                        ?.value || null
                    }
                    onChange={(e, data) => {
                      setFilterSet(updateFilterSet("reporting_entity_name", e));
                      //refetchFilterData(updateFilterSet("billing_code",e))
                    }}
                    onMenuClose={() => refetchFilterData(filterSet)}
                    getOptionLabel={(option) => `${option.name}`}
                    options={
                      zigna_aiStore.professionalOptionalFilter?.reporting_entity_name?.filter(
                        (v) => v.name !== "CMS"
                      ) || []
                    }
                    isClearable={filterSet
                      .find((i) => i.field == "reporting_entity_name")
                      ?.value?.some((v) => !v.name == "CMS")}
                    isMulti
                    allowSelectAll={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                  />
                </div>
              </div>
            </Col>

            <Col className=" col-auto">
              <label className="form-label">{"   "}</label>
              <div className="d-flex align-item-center">
                <Button
                  //    disabled={

                  // 	!selectedProvider
                  //    }
                  variant="primary"
                  size="sm"
                  className="submitFilter"
                  onClick={(e) => {
                    onSubmitForm();
                  }}
                  type="button"
                >
                  Submit
                </Button>
              </div>
            </Col>
            {/* <Col className="col-12">
							<div >

						
						<span className='text-danger'>*</span> &nbsp;- &nbsp;<small>Mandatory Filter</small> 
						</div>
						</Col> */}
          </Row>
        </Card.Body>
      </Card>
    );
  })
);
