import React from 'react'
import {useMemo, useState,useEffect} from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'
import 'xlsx';
import { ResponsiveBar } from '@nivo/bar'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Select from '../../ui/Dropdown'
import * as d3 from 'd3'
import _, { forEach } from 'lodash'
import DataBarchart from '../../../components/ZignaAi/DataAuditReferenceTable/DataBarchart'
import {Chart} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';


const getTransformedData=(data)=>{
	
	Chart.register(ChartDataLabels);
let groupedBillingCodes=_.groupBy(data,"billing_code")
let groupedArray=Object.keys(groupedBillingCodes).map(billingCode=>{
	let groupedProvider=_.groupBy(groupedBillingCodes[billingCode],"provider_name")
    let reName=_.orderBy(_.unionBy(groupedBillingCodes[billingCode],i=>i.reporting_entity_name),["negotiated_type"],["asc"]).map(i=>i.reporting_entity_name)
	return(
		{
			code:billingCode,
			reporting_entity_names:reName,
			items:Object.keys(groupedProvider).map(item=>{
				let obj={
					provider_name:item,
                    

				}
				reName.map(i=>obj[i]=null)
				groupedProvider[item].map(i=>{
					obj["l4"]=i.level4_description
					obj[i.reporting_entity_name]=Number(i.negotiated_rate.toFixed(2))
				})
				return obj
			})
		}
	)
})

return(groupedArray)
}

export default inject('zigna_aiStore', 'authStore', 'providerPriceCompareStore')(
	observer(({ zigna_aiStore, authStore, providerPriceCompareStore, procCategory,filterSet }) => {

		//const rowList = providerPriceCompareStore.resultData || []
	const [providers,setProviders]=useState([])

	
	useEffect(()=>{
		setProviders(_.unionBy(zigna_aiStore?.providerExtractChartResult.map(i=>({...i,name:i.provider_name,id:i.provider_name,value:i.provider_name})),"name"))

	},[zigna_aiStore?.providerExtractChartResult])



let transformedArray=getTransformedData(zigna_aiStore?.providerExtractChartResult.filter(i=>providers.find(j=>j.value==i.provider_name)))

const getAllColors=(entity)=>{

const colors={
	aetna:"#5a2e6f",
	uhc :"#002677",
	cigna:"#00874d",
	umr:"#B54491",
	"anthem blue cross california":"#286CE2"
}

let finalColors=[]
let allColors=["#6750A4","#625B71",'#44a4b5','#FFD35E',"#77CEA3","#899EA9","#5DBBDA","#EBA06B"]
forEach(entity,i=>{
	if(colors[i.toLowerCase()]){
		finalColors.push(colors[i.toLowerCase()])
	}
	else{
		finalColors.push(allColors.shift())
	}

})
return finalColors
}




		return (
			<Card border="light" className="shadow-sm">
				
				<Card.Body>
				<Row >
					<Col xs={12} className='d-flex justify-content-end'>
					<Select
									className={`select-menu`}
									aria-label="Default select example"
									values={providers}
									onChange={(e,data) => {
										console.log(e)
										setProviders(e)
											
										}
									
                                  
										
										 
									 }
									
										isMulti
										getOptionLabel={(option) => `${option.name}`}
									value={providers}
										
										options={
											_.unionBy(zigna_aiStore?.providerExtractChartResult.map(i=>({...i,name:i.provider_name,id:i.provider_name,value:i.provider_name})),"name")
										}
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
							
						
								/>
					</Col>
						<Col xs={12} >
					{transformedArray.map(i=>{
let colors=getAllColors(i.reporting_entity_names)
						console.log(colors)
						return(			<div style={{width:"100%",paddingBottom:120,height:_.max([i.items?.length*(i.reporting_entity_names?.length)*(i.reporting_entity_names?.length>5?24:36),400])}}>
							<center><h2>{i.code}</h2></center>

							<center>{i?.items?.[0]?.l4}</center>

							<Bar 

									data={{
labels:i.items.map(j=>j.provider_name),
datasets:i.reporting_entity_names.map((j,index)=>({
	label:j,
	data:i.items.map(k=>k[j]),
	backgroundColor: [colors[index]],
}))

									}
										
										
									}
									options={{
										indexAxis:"y",
										responsive:true,
										maintainAspectRatio:false,
										skipNull:true,
										grouped:true,
									barThickness:24,
									
										
										plugins: {
										  legend: {
											display: true
										  },
										  datalabels:{
                                          color:"white"
										  },
										  tooltip: {
											mode: "point",
											backgroundColor: "white",
											bodyColor: "#8c8c8b",
											titleColor:"#000",
											bodyFont: {
											  weight: 500,
											  color:"black",
											},
											borderColor: "rgba(0, 0, 0, 0.08)",
											borderWidth: 2,
											cornerRadius: 0,
											padding: {
											  top: 4,
											  bottom: 4,
											  left: 8,
											  right: 8
											},
											
										  }
										},
										scales: {
										  x: {
											display: true,
											grid: {
											  display: false
											},
											ticks: {
											  color: "#8c8c8b",
											  font: {
												size: 12,
												weight: "500"
											  },
											  padding: 0
											}
										  },
										  y: {
											display: true,
											grid: {
											  color: "#ececec"
											},
											ticks: {
											  color: "#8c8c8b",
											  font: {
												size: 12,
												weight: "500"
											  },
											  padding: 6
											}
										  }
										}
									  }}
									/>
						{/* <ResponsiveBar
				data={i.items}
				keys={i.reporting_entity_names}
				indexBy="provider_name"
				margin={{ top: 50, right: 200, bottom: 150, left: 200 }}
				padding={0.2}
				innerPadding={0}

				layout="horizontal"
				forceSquare={true}
				//colors={["#B69DF8","#9A82DB",'#7F67BE','#6750A4']}
				colors={getAllColors(i.reporting_entity_names)}
				maxBarWidth={10}
			
				labelTextColor={"#fff"}
				
				defs={[
					{
						id: 'dots',
						type: 'patternDots',
						background: 'inherit',
						color: '#38bcb2',
						size: 4,
						padding: 1,
						stagger: true
					},
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: '#eed312',
						rotation: -45,
						lineWidth: 6,
						spacing: 10
					}
				]}
				fill={[
					{
						match: {
							id: 'fries'
						},
						id: 'dots'
					},
					{
						match: {
							id: 'sandwich'
						},
						id: 'lines'
					}
				]}
				borderColor={{
					from: 'color',
					modifiers: [
						[
							'darker',
							1.6
						]
					]
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={null}
				axisLeft={{
					tickSize: 5,
					tickPadding: 15,
					tickRotation: 0,
					
					format: ( formattedValue ) => (
				
						<CustomAxisTick value={formattedValue} />
					
					),
				  }}
				enableGridX={false}
				enableGridY={false}
				labelSkipWidth={12}
				labelSkipHeight={12}
				// labelTextColor={{
				// 	from: 'color',
				// 	modifiers: [
				// 		[
				// 			'darker',
				// 			1.6
				// 		]
				// 	]
				// }}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'top-right',
						direction: 'column',
						justify: false,
						translateX: 190,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 180,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
					
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1
								}
							}
						]
					}
				]}
				role="application"
				ariaLabel="Nivo bar chart demo"
				barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
			/> */}
		</div>)
					})}
					</Col>
				
					{/* <Col xs={12} xxl={6}>
					{getTransformedData(zigna_aiStore?.providerExtractResult).map(i=>{
						return(			<div style={{width:"100%",height:_.max([i.items?.length*i.reporting_entity_names?.length*60,300])}}>
							<center><h2>{i.code}</h2></center>
						<ResponsiveBar
				data={i.items}
				keys={i.reporting_entity_names}
				indexBy="provider_name"
				margin={{ top: 50, right: 200, bottom: 150, left: 200 }}
				padding={0.3}
				groupMode="grouped"
				layout="horizontal"
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={["#E5CFF7","#9D76C1",'#8B6CBC','#5B0888']}
				maxBarWidth={40}
				labelTextColor={"white"}
				defs={[
					{
						id: 'dots',
						type: 'patternDots',
						background: 'inherit',
						color: '#38bcb2',
						size: 4,
						padding: 1,
						stagger: true
					},
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: '#eed312',
						rotation: -45,
						lineWidth: 6,
						spacing: 10
					}
				]}
				fill={[
					{
						match: {
							id: 'fries'
						},
						id: 'dots'
					},
					{
						match: {
							id: 'sandwich'
						},
						id: 'lines'
					}
				]}
				borderColor={{
					from: 'color',
					modifiers: [
						[
							'darker',
							1.6
						]
					]
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
			
					legendPosition: 'middle',
					legendOffset: 32
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 15,
					tickRotation: 0,
					
					format: ( formattedValue ) => (
				
						<CustomAxisTick value={formattedValue} />
					
					),
				  }}
				enableGridX={true}
				enableGridY={true}
				labelSkipWidth={12}
				labelSkipHeight={12}
				// labelTextColor={{
				// 	from: 'color',
				// 	modifiers: [
				// 		[
				// 			'darker',
				// 			1.6
				// 		]
				// 	]
				// }}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'top-right',
						direction: 'column',
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
					
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1
								}
							}
						]
					}
				]}
				role="application"
				ariaLabel="Nivo bar chart demo"
				barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
			/>
		</div>)
					})}
					</Col> */}
					
</Row>
				</Card.Body>
			</Card>
		)
	})
)


const formatYAxisLabel = (label, maxWidth = 20) => {
	// Split the label into multiple lines based on a space
	const words = label.split(' ');
	let lines = [''];
	let currentLine = 0;
  
	words.forEach((word) => {
	  const line = lines[currentLine];
	  const testLine = `${line} ${word}`;
	  
	  if (testLine.length <= maxWidth) {
		lines[currentLine] = testLine.trim();
	  } else {
		currentLine += 1;
		lines[currentLine] = word;
	  }
	});
  
	return lines.map((line, index) => (
	  <tspan key={index} x={0} dy={index?20:0} style={{fontWeight:"bold"}}>
		{line}
	  </tspan>
	));
  };

  const CustomAxisTick = ({ value }) => (
	<
	>
	  {formatYAxisLabel(value)}
	</>
  );


  