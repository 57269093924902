import React from "react";

import { useFlexLayout, useResizeColumns, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
function useInstance(instance) {
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0, mainGroup: null },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

const CommonTable = ({ columns, data, className, sticky, stickyTop }) => {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = React.useMemo(
    () => ({
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    rowSpanHeaders,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    (hooks) => {
      hooks.useInstance.push(useInstance);
    },
    useResizeColumns,
    useSticky
  );

  const rowOnClick = (row) => {
    console.log(`clicked ${row.type} for Strike ${row.strike}`);
  };
  const headerProps = (props, { column }) => getStyles(props, "center");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        textAlign: align,
        border: "1px solid #D3D3D3",
      },
    },
  ];

  // Render the UI for your table
  return (
    <div className="parentTable" style={{ overflowY: "none" }}>
      <table
        style={{ position: "relative", borderCollapse: "separate" }}
        {...getTableProps()}
        className={`table table-borderless ${className} zignaAi_newTable`}
      >
        <thead
          style={
            sticky
              ? {
                  position: "sticky",
                  top: stickyTop > -1 ? stickyTop : 58,
                  zIndex: 10,
                }
              : {}
          }
        >
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(headerProps)}
                  className="th"
                  style={{
                    ...column.getHeaderProps(headerProps).style,
                    width: column.width,
                    minWidth: column.width,
                  }}
                >
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            for (let j = 0; j < row.allCells.length; j++) {
              let cell = row.allCells[j];
              let rowSpanHeader = rowSpanHeaders.find(
                (x) => x.id === cell.column.id
              );

              let mainGroup = row.allCells.find((i) => i.column.mainGroup);

              if (rowSpanHeader) {
                if (
                  rowSpanHeader.topCellValue === null ||
                  rowSpanHeader.topCellValue !== cell.value ||
                  rowSpanHeader.mainGroup === null ||
                  rowSpanHeader.mainGroup !== mainGroup.value
                ) {
                  cell.isRowSpanned = false;
                  rowSpanHeader.topCellValue = cell.value;
                  rowSpanHeader.mainGroup = mainGroup.value;
                  rowSpanHeader.topCellIndex = i;
                  cell.rowSpan = 1;
                } else {
                  rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                  cell.isRowSpanned = true;
                }
              }
            }
            return null;
          })}
          {rows.map((row) => {
            let mainGroup = row.cells.find((cell) => cell.column?.mainGroup);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.isRowSpanned) return null;
                  else {
                    // {cell.rowSpan?<>{console.log(cell,cell.rowSpan,"rowcells")}</>:""}
                    return (
                      <td
                        rowSpan={
                          cell.rowSpan > mainGroup.rowSpan
                            ? mainGroup.rowSpan
                            : cell.rowSpan
                        }
                        {...cell.getCellProps()}
                        style={{
                          ...cell.getCellProps(cellProps).style,
                          width: cell.column.width,
                          minWidth: cell.column.width,
                          verticalAlign: "middle",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CommonTable;
