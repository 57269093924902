import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import {
	Row,
	Col,
	Card,
	OverlayTrigger,
	Tooltip,
	Image,
	Button,
} from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import ZignaaiLogo from "../assets/img/zigna_ai/logo_zignaai.png";
import SutherlandLogo from "../assets/img/zigna_ai/logo_sutherland.png";
export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
	const currentYear = moment().get('year')
	const [logoImg, setlogoImg] = useState('')
	const clientName = () => {
		let hostname = window.location.hostname;
		const [one, two] = hostname.split('.') || [];
		return one === 'www' ? two : one;
	  };
	
	return (
		<div>
			<footer className="footer bg-white">
				<Row>
					<Col xs={12} lg={6} className="mb-4 mb-lg-0">
						<span className="mb-0 text-center text-xl-left">
							© Copyright {`${currentYear} `}
							<Card.Link
								href="https://www.zignaai.com/"
								target="_blank"
								className="text-blue text-decoration-none fw-normal"
							>
								ZignaAI
							</Card.Link>
						</span>
					</Col>
					<Col xs={12} lg={6}>
						<ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
							<a className="navbar-brand" href="#">
								 <Image
                  src={
                    clientName() == "ehp" ||
                    clientName() == "provider" ||
                    clientName() == "shs"
                      ? SutherlandLogo
                      : ZignaaiLogo
                  }
                  alt=""
                />
							</a>
							
						</ul>
					</Col>
				</Row>
			</footer>
		</div>
	)
}))
