export const  setLoading=(isLoading,timeout=1000)=>{
    let ele = document.getElementById('root')
	 
	//check if ele has showLoading class
	if(ele.classList.contains('showLoading')){
		return
	}
	
	   ele.classList.add('showLoading');
    
	
		setTimeout(() => {
			ele.classList.remove('showLoading')
		  }, timeout);
	  

}