import React from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'

const formatTableNumbers = (data = []) => {
	return data.map(row => ({
		...row,
		Facilities: convertPrice(row.Facilities, { skipCurrency: true }),
		//Physicians: convertPrice(row.Physicians, { skipCurrency: true }),
		Procedures: convertPrice(row.Procedures, { skipCurrency: true })
	}))
}

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let payerTableColumn = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Payers Name',
						accessor: 'PayerName',
					},
					{
						Header: 'Providers',
						accessor: 'Facilities',
						align: 'right',
						
					},
					// {
					// 	Header: 'Physicians',
					// 	accessor: 'Physicians',
					// 	align: 'right',
						
					// },
					{
						Header: 'Procedures',
						accessor: 'Procedures',
						align: 'right',
					},
				],
			},
		]
		return (
			<Card border="light" className="shadow-sm">
				{/* <Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>Page visits</h5>
						</Col>
					</Row>
				</Card.Header> */}
				<Card.Body>
					<CommonTable
						columns={payerTableColumn}
						data={formatTableNumbers(zigna_aiStore?.payerList)}
						className="zignaAi_table_1"
					/>
				</Card.Body>
			</Card>
		)
	})
)
