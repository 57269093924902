import React, { useEffect, useState } from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import DataTable from "./DataTable";
import { inject, observer } from "mobx-react";
import { orderBy } from "lodash";
import SummaryTable from "./SummaryTable";
export default inject("zigna_aiStore")(
  observer(
    ({ zigna_aiStore, children, npi, tin, payer, billing_code, filterSet }) => {
      const [showModal, setShowModal] = useState(false);
      const [showLoading, setShowLoading] = useState(false);
      const [data, setData] = useState([]);
      useEffect(() => {
        if (showModal) {
          setShowLoading(true);
          zigna_aiStore
            .getDetailsOfAllTin({
              npi,
              tin: filterSet.find((i) => i.field == "tin")?.value?.[0]?.value,
              payer,
              billing_code,
            })
            .then((res) => {
              setShowLoading(false);
              setData(res);
            });
        }
      }, [showModal]);

      const allDataSummary = [];

      data.map((i) => {
        if (i.group1 == "Allopathic & Osteopathic Physicians") {
          let exist = allDataSummary.findIndex(
            (it) => it.group1 == "Allopathic & Osteopathic Physicians"
          );
          if (exist > -1) {
            allDataSummary[exist] = {
              ...allDataSummary[exist],
              group2: "Multiple",
              group3: "",
              count: allDataSummary[exist].count + 1,
            };
          } else {
            allDataSummary.push({
              ...i,

              count: 1,
            });
          }
        } else {
          let exist = allDataSummary.findIndex(
            (it) => it.group1 == i.group1 && it.group2 == i.group2
          );
          if (exist > -1) {
            allDataSummary[exist] = {
              ...allDataSummary[exist],
              group3: "Multiple",

              count: allDataSummary[exist].count + 1,
            };
          } else {
            allDataSummary.push({
              ...i,

              count: 1,
            });
          }
        }
      });

      console.log(allDataSummary, "all detailed");

      let sortedData = orderBy(data, ["group1", "group2", "group3"]);

      return (
        <div>
          <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Individual NPIs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {showLoading ? (
                <div className={"my-8"}>
                  <center
                    style={{
                      fontSize: 24,
                    }}
                  >
                    loading...
                  </center>
                </div>
              ) : (
                <>
                  <SummaryTable
                    dataset={[
                      ...orderBy(
                        allDataSummary.filter((i) => i.group2 !== "-"),
                        ["group1", "group2", "group3"]
                      ),
                      ...allDataSummary.filter((i) => i.group2 == "-"),
                    ]}
                  />
                  <DataTable
                    dataset={[
                      ...sortedData.filter((i) => i.group2 !== "-"),
                      ...sortedData.filter((i) => i.group2 == "-"),
                    ]}
                  />
                </>
              )}
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(true)}>
              Close
            </Button>
          </Modal.Footer> */}
          </Modal>
          <b
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setShowModal(true)}
          >
            {children}
          </b>
        </div>
      );
    }
  )
);
