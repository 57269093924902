import React, { useState } from "react";
import StateMap from "./StateMap";
import RegionalMap from "./RegionalMap";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
export default function NpisMaps(props) {
  const [selectedState, setSelectedState] = useState(null);

  return (
    <Row style={{ minHeight: 300 }}>
      <Col xs={6}>
        <StateMap
          db={props?.db}
          type={props.type}
          setSelectedState={setSelectedState}
          selectedState={selectedState}
          mapData={props.mapData}
          payer={props.payer}
          month={props.month}
        />
      </Col>
      <Col xs={6}>
        <RegionalMap
          type={props.type}
          selectedState={selectedState}
          mapData={props.mapData}
          payer={props.payer}
          month={props.month}
        />
      </Col>
    </Row>
  );
}
