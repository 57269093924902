import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import FilterWidget from "../../components/ZignaAi/RateInsights/FilterUtilization";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { getYearFromString, getMonthFromString } from "../../Utilities";
import { useLocation } from "react-router-dom";

import DataTable from "../../components/ZignaAi/RateInsights/DataTable";

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore, filterSet1, selectedPayor }) => {
    const location = useLocation();
    const [includeCMS, setIncludeCMS] = useState(true);
    const [error, setError] = useState(false);
    const [isLargeData, setLargeData] = useState(false);
    const [noData, setNodata] = useState(false);
    const getRaheArr = (start, end) => {
      let arr = [];
      for (let i = start; i <= end; i++) {
        arr.push(i);
      }
      return arr;
    };

    useEffect(() => {
      setLargeData(false);
      let tin =  filterSet1.find(filter=>filter.field ==="tin")
    
      if(!tin){
        return
      } 
      setNodata(false);

      zigna_aiStore
        .getRateInsightData({
          fields: [
            ...filterSet1,
            {
              field: `reporting_entity_name`,
              value: [{ name: selectedPayor }],
            },
            {
              field: "billing_code",
              value: getRaheArr(99200, 99499).map((i) => {
                return { name: i };
              }),
            },
          ].map((i) => {
            return {
              field: i.field,
              value:
                ["most_frequent_rate","multiple_rates_reported","rate_type"].includes(i.field)
                ? i.value?.value
                  : i.value?.map((j) => j?.name),
            };
          }),
        })
        .then((res) => {
          if (res.isLargeData == true) {
            setLargeData(true);
          }
          if (res.data.length == 0) {
            setNodata(true);
          }
        });
    }, [selectedPayor]);
    const [filterSet, setFilterSet] = useState(filterSet1);

    useEffect(() => {
      zigna_aiStore.rateInsightData = [];
      zigna_aiStore.professionalRateChoiceCount = 0;
      zigna_aiStore.professionalType = [];
      //zigna_aiStore.professionalOptionalFilter = {};
      setFilterSet(filterSet1);
    }, [selectedPayor]);
    useEffect(() => {
      setError(false);
      zigna_aiStore.rateInsightData = [];
    }, [filterSet]);

    let currentDb = (authStore?.currentUser?.perms?.dblist || []).find(
      (i) => i.dbname == authStore?.currentUser?.db_name
    );

    if (
      getYearFromString(currentDb.label) < 23 ||
      (getYearFromString(currentDb.label) == 23 &&
        getMonthFromString(currentDb.label) < 10)
    )
      return (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
          <h3>Feature not available for current database</h3>

          <p style={{ fontSize: 18 }}>
            This feature is available for november 23 or later database.
          </p>
        </div>
      );
    else
      return (
        <>
          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <FilterWidget
                filterSet={filterSet}
                setLargeData={(val) => setLargeData(val)}
                filterSet1={filterSet1}
                emcodes={getRaheArr(99200, 99499)}
                setFilterSet={setFilterSet}
                setError={() => setError(true)}
                selectedPayor={selectedPayor}
                includeCMS={includeCMS}
                setIncludeCMS={setIncludeCMS}
              />
            </Col>
            <Row className={`m-4 p-0`}>
              <Col xs={12} xl={12} className="mb-4">
                {zigna_aiStore.rateInsightData.length == 0 ? (
                  <div>
                    <center>
                      <h5>
                        {" "}
                        {isLargeData ? (
                          <>
                            {" "}
                            Current application is unable to process this range
                            due to data limit. <br /> Please select individual
                            billing codes to get insights.
                          </>
                        ) : noData ? (
                          "No analysis available for selected combination"
                        ) : (
                          "Please Select Filter"
                        )}
                      </h5>
                    </center>
                  </div>
                ) : (
                  <DataTable
                    dataset={zigna_aiStore.rateInsightData}
                    uniqueNpis={zigna_aiStore.uniqueNpis}
                    filterSet={filterSet}
                    zigna_aiStore={zigna_aiStore}
                    includeCMS={includeCMS}
                  />
                )}
              </Col>
            </Row>
          </Row>
        </>
      );
  })
);
