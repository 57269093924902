import React, { useEffect } from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'



let selectAllOptionH = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionPt = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionbC = { id: 0, name: 'Select All', value: 'All'};
let selectAllOptions = { id: 0, name: 'Select All', value: 'All'};

const customStyles = {
	menu: base => ({ ...base, zIndex: 9999 }) ,
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}

const resetOptions=()=>{
    selectAllOptionH.value="All"
    selectAllOptionbC.value="All"
    selectAllOptionPt.value="All"
    selectAllOptions.value="All"
}
export default inject('zigna_aiStore')(
	observer(({zigna_aiStore, hideModifier,reporting_entity_name,dataType }) => {
	useEffect(()=>{
     
        zigna_aiStore.getProviderDrgByHospital(reporting_entity_name,dataType)
		
			zigna_aiStore.resetAll()
			
		
		

    },[reporting_entity_name,dataType])
		

	useEffect(()=>{

		if(	!zigna_aiStore.HospitalCountByDRGSelected2.state.length ||
			!zigna_aiStore.HospitalCountByDRGSelected2?.reporting_entity||
			!zigna_aiStore.HospitalCountByDRGSelected2?.billing_code_type.length||
			!zigna_aiStore.HospitalCountByDRGSelected2?.month.length||
			!zigna_aiStore.HospitalCountByDRGSelected2?.provider_type.length)
			return
			else{
				zigna_aiStore.getdDrgByHospitalCountWithNtype(dataType)
			}
	},
	[
dataType
	])

	
	

		const Option = (props) => {
			return props.data.id == '0' ? (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.data.value != 'All' ? true : false}
							onChange={() => null}
						/>{' '}
						<label>{props.label} </label>
						
					</components.Option>
				</div>
			) : (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>						
					</components.Option>
				</div>
			)
		}


	
	

		const MultiValue = ({ index, getValue, ...props }) => {
			const maxToShow = 0
			const length = getValue().length

			return index < maxToShow ? (
				<components.MultiValue {...props} />
			) : index === maxToShow ? (
				`${length} Values selected`
			) : null
		}

		const animatedComponents = makeAnimated()

		return (
			
					<Row className='px-4'>
					
					
				

                        <Col className=" col-auto col-auto">
								
		<label className="form-label">Provider Type <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">

		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.provider_type}
                onChange={(e, data) => {
			
						zigna_aiStore.setHospitalCountByDRG(
							'provider_type',
							e
                            
						)

						zigna_aiStore.getstateDrgByHospital(reporting_entity_name,dataType)
					
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.provider_type
                                        }
									
										
									
				getOptionLabel={(option) => `${option.name}`}
			
				
				
			
										
									/>
		
	
      
            </div>

     
      
							
						</Col>


                  



                        <Col className=" col-auto col-auto">
								
		<label className="form-label">State <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.state}
                onChange={(e, data) => {
					zigna_aiStore.setHospitalCountByDRG(
						'state',
						e
						
					)

					zigna_aiStore.getdDrgByHospitalCountWithNtype(reporting_entity_name,dataType)
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.state
                                        }
									
										
										
				getOptionLabel={(option) => `${option.name}`}
			
				
			
										
									/>
		
	
            </div>

     
      
							
						</Col>
                     
					</Row>
			
		)
	})
)
