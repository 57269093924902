import React, { useEffect, useState, useMemo } from "react";
import CommonTable from "../common/COmmonWIthSpan";

import _ from "lodash";

/** For future use - formatting only */
import "xlsx";

const Table = ({ dataset, onPayerSelect, filterSet, defaultPayer }) => {
  console.log("dataset",dataset)
  const transformData = (data) => {
    const allClients = data.allClients ?? [];
    const allNPICount = data["allNPIs"]?.npi_count;
    const headers = allClients.map((i) => i.reporting_entity_name);
    const hiddenHeaders = [...headers];
    const rowStats = {
      1: "providersCount",
      2: "notReportingAllCodes",
      3: "notReportingEMCodes",
    };
    const rowLabels = {
      1: "IND NPIs Reporting",
      2: "IND NPIs Not Reporting Any Rates",
      3: "IND NPIs Reporting E&M Rates",
    };
    const rows = [1, 2, 3];
    const dataset = rows.map((rowNum) => {
      let rowData = { _: rowLabels[rowNum] };
      headers.map((reporting_entity_name, index) => {
        rowData[reporting_entity_name] = data[rowStats[rowNum]]
          .filter((i) => i.reporting_entity_name === reporting_entity_name)
          .reduce(function (npi_count, record) {
            if (record.npi_count && record.npi_count > 0) {
              let indx = hiddenHeaders.indexOf(reporting_entity_name);
              if (indx > -1) hiddenHeaders.splice(indx, 1);
            }
            if (rowNum == 3) {
              if (record.notProvidingEM == "[]") return npi_count + 0;
            }
            return npi_count + (record.npi_count ?? 0);
          }, 0);

        rowData[reporting_entity_name + "_percent"] =
          allNPICount && allNPICount > 0
            ? Number(
                (rowData[reporting_entity_name] * 100) / allNPICount
              ).toFixed(2)
            : 0;
      });
      return rowData;
    });
    console.log({ hiddenHeaders });
    const allNPIsRow = Object.assign(
      { _: "All Individual (IND) NPIs" },
      ...headers.map((reporting_entity_name, index) => ({
        [reporting_entity_name]: data["allNPIs"]?.npi_count,
        [reporting_entity_name + "_percent"]: "100",
      }))
    );
    const finalHeaders = headers.filter(
      (item) => !hiddenHeaders.includes(item)
    );
    const include =
      filterSet?.find((f) => f.field == "rates_only")?.value?.value == "all";
    return {
      headers: include ? headers : finalHeaders,
      items: [allNPIsRow, ...dataset],
    };
  };

  const { items, headers } = useMemo(() => {
    return transformData(dataset);
  }, [dataset]);

  console.log(items, "allitem", headers);
  let columns = [
    {
      Header: "",
      accessor: "_",
      sticky: "left",
      width: "200px",
    },
    ...headers
      .filter((i) => i == defaultPayer)
      .sort()
      .map((col, index) => ({
        Header: String(col),
        align: "right",
        width: 160,
        columns: [
          {
            Header: "#",
            align: "right",
            id: `${col}`,
            width: 80,
            accessor: (d) => d[col],
            Cell: ({ value, row }) => (
              <div
                onClick={() =>
                  value && row.index == 1 ? onPayerSelect(col) : null
                }
                style={{ color: "#3d2260" }}
              >
                {/* Add your custom cell rendering logic here */}
                {value && row.index == 1 ? (
                  <b style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {value}
                  </b>
                ) : (
                  value
                )}
              </div>
            ),
          },
          {
            Header: "%",
            width: 80,
            id: `${col}_percent`,
            accessor: (d) => d[`${col}_percent`],
            align: "right",
            Cell: ({ value, row }) => (value ? value + "%" : ""),
          },
        ],
        // onClick: () => {
        //   // Add your logic here
        //   onPayerSelect(col);
        // },
        id: index,
        // accessor: (d) => d[col],

        //accessor: String(col),
        align: "right",
      })),

    ...headers
      .filter((i) => i !== defaultPayer && i != "CMS")
      .sort()
      .map((col, index) => ({
        Header: String(col),
        align: "right",
        width: 160,
        columns: [
          {
            Header: "#",
            align: "right",
            id: `${col}`,
            width: 80,
            accessor: (d) => d[col],
            Cell: ({ value, row }) => (
              <div
                onClick={() =>
                  value && row.index == 1 ? onPayerSelect(col) : null
                }
                style={{ color: "#3d2260" }}
              >
                {/* Add your custom cell rendering logic here */}
                {value && row.index == 1 ? (
                  <b style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {value}
                  </b>
                ) : (
                  value
                )}
              </div>
            ),
          },
          {
            Header: "%",
            width: 80,
            id: `${col}_percent`,
            accessor: (d) => d[`${col}_percent`],
            align: "right",
            Cell: ({ value, row }) => (value ? value + "%" : ""),
          },
        ],
        // onClick: () => {
        //   // Add your logic here
        //   onPayerSelect(col);
        // },
        id: index,
        // accessor: (d) => d[col],

        //accessor: String(col),
        align: "right",
      })),
  ];

  return (
    <>
      {dataset && dataset?.allClients && (
        <div
          style={{
            overflow: "scroll",
            width: "100%",
            marginBottom: 10,
            fontFamily: "arial",
            fontSize: 16,
            marginTop: 12,
          }}
        >
          <CommonTable
            sticky={true}
            stickyTop={0}
            columns={columns}
            data={items}
          />
        </div>
      )}
    </>
  );
};

export default Table;
