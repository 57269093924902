import React from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'

let selectAllOption = { id: 0, name: 'Select All', value: 'All' }

const customStyles = {
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}
export default inject('zigna_aiStore', 'authStore')(
	observer(({ zigna_aiStore, authStore, filterParent ,preferences = {} }) => {
		const {
			isPayersMultiselect = false
		} = preferences
		const npiSelectRef = useRef()
		const Option = (props) => {
			return props.data.id == '0' ? (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.data.value != 'All' ? true : false}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>
					</components.Option>
				</div>
			) : (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>
					</components.Option>
				</div>
			)
		}

		const MultiValue = ({ index, getValue, ...props }) => {
			const maxToShow = 0
			const length = getValue().length

			return index < maxToShow ? (
				<components.MultiValue {...props} />
			) : index === maxToShow ? (
				`${length} Payers selected`
			) : null
		}

		const animatedComponents = makeAnimated()

		return (
			<Card border="light" className="shadow-sm filterSection">
				<Card.Body>
					<Row>
						<Col className=" col-auto col-auto">
							<div className="mb-3">
								<label className="form-label">Payers</label>
								{isPayersMultiselect ? (<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={zigna_aiStore.selectedCommonFilterData.payers}
										onChange={(e, data) => {
											if (data.option?.value == 'All') {
												zigna_aiStore.setCommonFilterData(
													'payers',
													zigna_aiStore.allPayers
												)
												selectAllOption.value = 'D-All'
											} else if (data.option?.value == 'D-All') {
												selectAllOption.value = 'All'
												zigna_aiStore.setCommonFilterData('payers', [])
											} else {
												if (
													e
														.sort((a, b) =>
															a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
														)
														.toString() !=
													zigna_aiStore.allPayers
														.flat()
														.sort((a, b) =>
															a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
														)
														.toString()
												) {
													selectAllOption.value = 'All'
												} else {
													selectAllOption.value = 'D-All'
												}
												zigna_aiStore.setCommonFilterData('payers', e)
											}
											zigna_aiStore.resetTopOverviewList()
										}}
										onBlur={() => {
											if(zigna_aiStore.allPayers.payers?.length){
												//zigna_aiStore.getProceduresCatList()
											}
										}}
										isMulti
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.allPayers.length
												? [selectAllOption, ...zigna_aiStore.allPayers]
												: []
										}
										allowSelectAll={true}
										components={{
											Option,
											MultiValue,
											animatedComponents,
										}}
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
										ref={npiSelectRef}
									/>
									<button
										type="button"
										disabled={false}
										className="NPI-submit btn btn-primary btn-sm"
										onClick={async ()=>{
											zigna_aiStore.resetTopOverviewList()
											await zigna_aiStore.deleteLSData(`dashboardCategoryList_${zigna_aiStore.userType}`)
											await zigna_aiStore.deleteLSData(`regionMapList_${zigna_aiStore.userType}`)
											await zigna_aiStore.deleteLSData(`stateMapList_${zigna_aiStore.userType}`)
											await zigna_aiStore.deleteLSData(`topCPTs_${zigna_aiStore.userType}`)
											await zigna_aiStore.deleteLSData(`topDRGs_${zigna_aiStore.userType}`)
											zigna_aiStore.getCompetitiveLandscape()
											if (filterParent === "DRG")  zigna_aiStore.getOverviewDRGList()
											if (filterParent === "CPT") zigna_aiStore.getOverviewCPTList()
											await zigna_aiStore.setLSData(`selectedPayerFilterList_${zigna_aiStore.userType}`, zigna_aiStore.selectedCommonFilterData.payers)
										}}
									>
										<FontAwesomeIcon
											icon={faArrowRight}
											className="dropdown-arrow"
											size="lg"
										/>
									</button>
								</div>) : (
									<Select
									className={`select-menu ${
										zigna_aiStore.selectedCommonFilterData.payers != ''
											? ''
											: 'invalidFilter'
									}`}
									value={zigna_aiStore.selectedCommonFilterData.payers?.[0]}
									options={zigna_aiStore.allPayers}
									onChange={(e) => {
										zigna_aiStore.resetTopOverviewList()
										zigna_aiStore.setCommonFilterData('payers', [e])
									}}
									getOptionLabel={(option) => `${option.name}`}
									styles={customStyles}
								/>
								)}
							</div>
						</Col>
						<Col className=" col-auto">
							<label className="form-label">{'   '}</label>
							<div className="d-flex align-item-center">
								<Button
									variant="primary"
									size="sm"
									className="submitFilter"
									onClick={async (e) => {
										zigna_aiStore.resetTopOverviewList()
										await zigna_aiStore.deleteLSData(`dashboardCategoryList_${zigna_aiStore.userType}`)
										await zigna_aiStore.deleteLSData(`regionMapList_${zigna_aiStore.userType}`)
										await zigna_aiStore.deleteLSData(`stateMapList_${zigna_aiStore.userType}`)
										await zigna_aiStore.deleteLSData(`topCPTs_${zigna_aiStore.userType}`)
										await zigna_aiStore.deleteLSData(`topDRGs_${zigna_aiStore.userType}`)
										zigna_aiStore.getCompetitiveLandscape()
										if (filterParent === "DRG")  zigna_aiStore.getOverviewDRGList()
										if (filterParent === "CPT") zigna_aiStore.getOverviewCPTList()
										await zigna_aiStore.setLSData(`selectedPayerFilterList_${zigna_aiStore.userType}`, zigna_aiStore.selectedCommonFilterData.payers)
									}}
									type="button"
								>
									Reload Data
								</Button>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		)
	})
)
