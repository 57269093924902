import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faUserShield,
  faDatabase,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle, faFile } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  NavItem,
} from "@themesberg/react-bootstrap";

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { inject, observer } from "mobx-react";
import { faReact } from "@fortawesome/free-brands-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import { getPermissions } from "../PermisionObject";
import { getYearFromString, getMonthFromString } from "../Utilities";
import ZignaaiLogo from "../assets/img/zigna_ai/logo_zignaai.png";
import SutherlandLogo from "../assets/img/zigna_ai/logo_sutherland.png";
import _ from "lodash";
export default inject(
  "zigna_aiStore",
  "authStore",
  "providerPriceCompareStore"
)(
  observer(({ zigna_aiStore, authStore, providerPriceCompareStore }) => {
    const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
    let location = useLocation();
    const areNotificationsRead = notifications.reduce(
      (acc, notif) => acc && notif.read,
      true
    );
    const clientName = () => {
      let hostname = window.location.hostname;
      const [one, two] = hostname.split(".") || [];
      return one === "www" ? two : one;
    };
    const [logoImg, setlogoImg] = useState("");
    let history = useHistory();

    // useEffect(() => {
    //   const cname = clientName();
    //   if (cname === "demo") {
    //     loadImage(cname);
    //   } else if (cname === "axenehp") {
    //     loadImage("zignaai");
    //   } else {
    //     loadImage("sutherland");
    //   }
    // }, []);

    // let loadImage = (imageName) => {
    //   import(`../assets/img/zigna_ai/logo_${imageName}.png`)
    //     .then((image) => {
    //       setlogoImg(image.default);
    //     })
    //     .catch(() => {
    //       loadImage("sutherland");
    //     });
    // };

    const markNotificationsAsRead = () => {
      setTimeout(() => {
        setNotifications(notifications.map((n) => ({ ...n, read: true })));
      }, 300);
    };

    const Notification = (props) => {
      const { link, sender, image, time, message, read = false } = props;
      const readClassName = read ? "" : "text-danger";

      return (
        <ListGroup.Item
          action
          href={link}
          className="border-bottom border-light"
        >
          <Row className="align-items-center">
            <Col className="ms--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="h6 mb-0 text-small">{sender}</h4>
                </div>
                <div className="text-end">
                  <small className={readClassName}>{time}</small>
                </div>
              </div>
              <p className="font-small mt-1 mb-0">{message}</p>
            </Col>
          </Row>
        </ListGroup.Item>
      );
    };
    let permissions = getPermissions(authStore?.currentUser?.client_name);
    console.log(permissions, "all permissions");
    let ViewDropDownMenu = [
      {
        component: (
          <Dropdown.Item
            disabled={permissions.professional.length == 0}
            className="fw-bold"
            style={{ opacity: permissions.professional.length == 0 ? 0.2 : 1 }}
            eventKey="professional"
            onClick={() => {
              zigna_aiStore.userType = "professional";
              providerPriceCompareStore.userType = "professional";
              localStorage.setItem("userType", "professional");
              history.push("/");
            }}
          >
            Professional
          </Dropdown.Item>
        ),
      },

      {
        component: (
          <Dropdown.Item
            disabled={permissions.facility.length == 0}
            style={{ opacity: permissions.facility.length == 0 ? 0.2 : 1 }}
            className="fw-bold"
            eventKey="facility"
            onClick={() => {
              zigna_aiStore.userType = "facility";
              providerPriceCompareStore.userType = "facility";
              localStorage.setItem("userType", "facility");
              history.push("/");
            }}
          >
            {/* <FontAwesomeIcon icon={faUserCircle} className="me-2" />{' '} */}
            Facility
          </Dropdown.Item>
        ),
      },

      {
        component: (
          <Dropdown.Item
            disabled={permissions.ancillary.length == 0}
            style={{ opacity: permissions.ancillary.length == 0 ? 0.2 : 1 }}
            className="fw-bold"
            eventKey="ancillary"
            onClick={() => {
              zigna_aiStore.userType = "ancillary";
              providerPriceCompareStore.userType = "ancillary";
              localStorage.setItem("userType", "ancillary");
              history.push("/");
            }}
          >
            {/* <FontAwesomeIcon icon={faUserCircle} className="me-2" />{' '} */}
            Ancillary
          </Dropdown.Item>
        ),
      },

      {
        component: (
          <Dropdown.Item
            disabled={permissions.dataAuditReport.length == 0}
            style={{
              opacity: permissions.dataAuditReport.length == 0 ? 0.2 : 1,
            }}
            className="fw-bold"
            eventKey="dataAuditReport"
            onClick={() => {
              zigna_aiStore.userType = "dataAuditReport";
              providerPriceCompareStore.userType = "dataAuditReport";
              localStorage.setItem("userType", "dataAuditReport");
              history.push("/");
            }}
          >
            Data Audit Report
          </Dropdown.Item>
        ),
      },
    ];

    useEffect(() => {
      // authStore.isAuthenticate()
    }, [authStore.currentUser, zigna_aiStore.userType]);

    return (
      <Navbar
        variant="dark"
        expanded
        style={{ position: "fixed", top: 0, width: "100%" }}
        className="sticky-top ps-0 pt-0 pb-0 bg-white navbar-lg"
      >
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100 px-2">
            <div className="d-flex align-items-center">
              <a className="navbar-brand" href="#">
                <Image
                  src={
                    clientName() == "ehp" ||
                    clientName() == "provider" ||
                    clientName() == "shs"
                      ? SutherlandLogo
                      : ZignaaiLogo
                  }
                  alt=""
                />
              </a>
            </div>
            {authStore.isSessionActive && (
              <Nav className="align-items-center">
                {/* <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
									<li
										className="nav-item text-underline br-1-grey"
										onClick={() => {
											if (zigna_aiStore.userType == 'facility') {
												zigna_aiStore.userType = 'professional'
												providerPriceCompareStore.userType = 'professional'
											} else {
												zigna_aiStore.userType = 'facility'
												providerPriceCompareStore.userType = 'facility'
											}
											console.log(zigna_aiStore.userType)
										}}
									>
										<a
											className="nav-link text-primary"
											aria-current="page"
											href="#"
										> 
											<svg
												className="mx-2"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M18 20L17.2929 20.7071L18 21.4142L18.7071 20.7071L18 20ZM13 4C12.4477 4 12 4.44771 12 5C12 5.55229 12.4477 6 13 6L13 4ZM13.2929 16.7071L17.2929 20.7071L18.7071 19.2929L14.7071 15.2929L13.2929 16.7071ZM18.7071 20.7071L22.7071 16.7071L21.2929 15.2929L17.2929 19.2929L18.7071 20.7071ZM19 20L19 9L17 9L17 20L19 20ZM14 4L13 4L13 6L14 6L14 4ZM19 9C19 6.23858 16.7614 4 14 4L14 6C15.6569 6 17 7.34315 17 9L19 9Z"
													fill="#33363F"
												/>
												<path
													d="M6 4L6.70711 3.29289L6 2.58579L5.29289 3.29289L6 4ZM11 20C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18L11 20ZM10.7071 7.29289L6.70711 3.29289L5.29289 4.70711L9.29289 8.70711L10.7071 7.29289ZM5.29289 3.29289L1.29289 7.29289L2.70711 8.70711L6.70711 4.70711L5.29289 3.29289ZM5 4L5 15L7 15L7 4L5 4ZM10 20L11 20L11 18L10 18L10 20ZM5 15C5 17.7614 7.23858 20 10 20L10 18C8.34315 18 7 16.6569 7 15L5 15Z"
													fill="#33363F"
												/>
											</svg>
											Switch to Other View
										</a>
									</li>
								</ul> */}
                {permissions?.showDemoButton ? (
                  <a href="https://www.zignaai.com/contact-us" target="_blank">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Click here to schedule a demo</Tooltip>}
                    >
                      <Button size="sm" style={{ background: "#A02077" }}>
                        Contact Us
                      </Button>
                    </OverlayTrigger>
                  </a>
                ) : null}

                {permissions.disableDbDropDown ? null : (
                  <Dropdown
                    as={Nav.Item}
                    activekey={authStore?.currentUser?.db_name}
                  >
                    <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                      <div className="media d-flex align-items-center">
                        <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                          <span className="mb-0 font-small fw-bold">
                            <FontAwesomeIcon
                              icon={faDatabase}
                              className="me-2"
                            />
                            <b>
                              {(
                                authStore?.currentUser?.perms?.dblist || []
                              ).find(
                                (db) =>
                                  db.dbname === authStore?.currentUser?.db_name
                              )?.label || "NA"}
                            </b>{" "}
                            - Switch To Other DB
                          </span>
                        </div>
                      </div>
                      {/* <a
											className="nav-link text-primary"
											aria-current="page"
											href="#"
										> */}
                    </Dropdown.Toggle>
                    {
                      <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                        {_.orderBy(
                          authStore?.currentUser?.perms?.dblist || [],
                          [
                            (i) => getYearFromString(i.label),
                            (i) => getMonthFromString(i.label),
                          ],
                          ["asc", "asc"]
                        ).map((db) => {
                          return (
                            <>
                              <Dropdown.Item
                                className="fw-bold"
                                eventKey={db.dbname}
                                onClick={async () => {
                                  await authStore.exchangeToken(db.dbname);
                                  history.replace(location.pathname, {
                                    ...location.state,
                                  });
                                }}
                              >
                                {db.dbname ===
                                authStore?.currentUser?.db_name ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="me-2"
                                  />
                                ) : null}
                                {db.label}
                              </Dropdown.Item>
                              <Dropdown.Divider />
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    }
                  </Dropdown>
                )}

                <Dropdown as={Nav.Item} activekey={zigna_aiStore.userType}>
                  <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                    <div className="media d-flex align-items-center">
                      <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold">
                          <svg
                            className="mx-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 20L17.2929 20.7071L18 21.4142L18.7071 20.7071L18 20ZM13 4C12.4477 4 12 4.44771 12 5C12 5.55229 12.4477 6 13 6L13 4ZM13.2929 16.7071L17.2929 20.7071L18.7071 19.2929L14.7071 15.2929L13.2929 16.7071ZM18.7071 20.7071L22.7071 16.7071L21.2929 15.2929L17.2929 19.2929L18.7071 20.7071ZM19 20L19 9L17 9L17 20L19 20ZM14 4L13 4L13 6L14 6L14 4ZM19 9C19 6.23858 16.7614 4 14 4L14 6C15.6569 6 17 7.34315 17 9L19 9Z"
                              fill="#33363F"
                            />
                            <path
                              d="M6 4L6.70711 3.29289L6 2.58579L5.29289 3.29289L6 4ZM11 20C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18L11 20ZM10.7071 7.29289L6.70711 3.29289L5.29289 4.70711L9.29289 8.70711L10.7071 7.29289ZM5.29289 3.29289L1.29289 7.29289L2.70711 8.70711L6.70711 4.70711L5.29289 3.29289ZM5 4L5 15L7 15L7 4L5 4ZM10 20L11 20L11 18L10 18L10 20ZM5 15C5 17.7614 7.23858 20 10 20L10 18C8.34315 18 7 16.6569 7 15L5 15Z"
                              fill="#33363F"
                            />
                          </svg>
                          <b className="text-capitalize">
                            {zigna_aiStore.userType}
                          </b>{" "}
                          - Switch To Other Views
                        </span>
                      </div>
                    </div>
                    {/* <a
											className="nav-link text-primary"
											aria-current="page"
											href="#"
										> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    {ViewDropDownMenu?.map((i, index) => (
                      <>
                        {i.component}
                        {index !== ViewDropDownMenu?.length - 1 && (
                          <Dropdown.Divider />
                        )}
                      </>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
									<Dropdown.Toggle
										as={Nav.Link}
										className="text-dark icon-notifications me-lg-3"
									>
										<span className="icon icon-sm">
											<FontAwesomeIcon icon={faBell} className="bell-shake" />
											{areNotificationsRead ? null : (
												<span className="icon-badge rounded-circle unread-notifications" />
											)}
										</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
										<ListGroup className="list-group-flush">
											<Nav.Link
												href="#"
												className="text-center text-primary fw-bold border-bottom border-light py-3"
											>
												Notifications
											</Nav.Link>

											{notifications.map((n) => (
												<Notification key={`notification-${n.id}`} {...n} />
											))}

											<Dropdown.Item className="text-center text-primary fw-bold py-3">
												View all
											</Dropdown.Item>
										</ListGroup>
									</Dropdown.Menu>
								</Dropdown> */}

                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                    <div className="media d-flex align-items-center">
                      <Image
                        src={Profile3}
                        className="user-avatar md-avatar rounded-circle"
                      />
                      <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold">
                          {authStore.currentUser?.name || "User"}{" "}
                          {authStore.currentUser?.payer ? (
                            <>({authStore.currentUser?.payer})</>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    <Dropdown.Item className="fw-bold">
                      <FontAwesomeIcon icon={faUserCircle} className="me-2" />{" "}
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="fw-bold"
                      onClick={() =>
                        window.open(
                          "https://zignaai-webcontent.s3.amazonaws.com/product_manual/RightPx_User_Manual_v1.8.pdf"
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faFile} className="me-2" /> Product
                      manual
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="fw-bold"
                      onClick={() => {
                        authStore.logout();
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOutAlt} className=" me-2" />{" "}
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            )}
          </div>
        </Container>
      </Navbar>
    );
  })
);
