import axios from "axios";

const url = process.env.REACT_APP_API_ENDPOINT;
//export const url = 'http://localhost:3000/api/v1'
const Fetcher = axios.create({
    baseURL: url
})
Fetcher.interceptors.request.use(
  function (config) {
    // setLoading(true);
    if (!config.headers.Authorization) {
      const user = localStorage.getItem("currentUser");
      let token = "";
      if (user) {
        const usr = JSON.parse(user);
        token = usr?.token;
      }
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Fetcher.interceptors.response.use(
  function (response) {
    // setLoading(false);
    return response;
  },
  function (error) {
    // setLoading(false);
    console.log(error.response, "error.response");
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("currentUser");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
export default Fetcher;