import React from 'react'
import {useMemo} from 'react'
import { Button, Card, Col, Row, OverlayTrigger, Tooltip, Popover } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'
import * as XLSX from 'xlsx';
import Tag from '../common/Tag'
import ReRequestExtraData from '../common/ReRequestExtraData'

export default inject('zigna_aiStore', 'authStore')(
	observer(({ zigna_aiStore, authStore }) => {
		const downloadSignedUrl = async(path) => {
			try {
				const {signedUrl} = await zigna_aiStore.getSignedUrl(path)
				window.open(signedUrl, '_blank', 'noopener,noreferrer');
			}catch(error) {
				console.log(error, "error in fetching")
			}
		}
		let columns = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Id',
						accessor: 'id',	
					
						show: false				
					},
					{
						Header: 'Label',
						accessor: 'request_label',	
						width: 90						
					},
					{
						Header: 'Request Type',
						accessor: 'request_type',	
					
						show: false				
					},
					{
						Header: 'Query',
						accessor: 'query_string',
						width: 150,
						Cell: (props) => (
							<OverlayTrigger placement={"right"} overlay={
								<div style={{ backgroundColor: 'white', padding: 8, maxWidth: 700, border: '1px solid black' }}>
									{props.value}
								</div>
							}>
								<div style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									'-webkit-line-clamp': '3',
									'-webkit-box-orient': 'vertical'
								}}>
									{props.value}
								</div>
							</OverlayTrigger>
						),					
					},
					{
						Header: 'DB',
						id: "DB",
						accessor: 'query_string',
						width: 80,
						Cell: (props) => (
								<div style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									display: '-webkit-box',
									'-webkit-line-clamp': '3',
									'-webkit-box-orient': 'vertical'
								}}>
									{authStore?.currentUser?.perms?.dblist?.find(db=>db.dbname==(props.value?.match(/FROM?."(.*?.)"/)?.[1]))?.label}
								</div>
						),					
					},
					{
						Header: 'Status',
						accessor: 'status',
						width: 60,
						Cell: (props) => (
							<div>
								{(() => {
									switch(props.value) {
										case 'Pending': {
											return <Tag value={props.value} type={'error'} />
										}
										case 'Processed': {
											return <Tag value={props.value} type={'warning'} />
										}
										case 'Notifying': {
											return <Tag value={props.value} type={'info'} />
										}
										case 'Complete': {
											return <Tag value={props.value} type={'success'} />
										}
										default: {
											return <Tag value={props.value === 'Query Execution Failed' ? 'Query Execution Failed - Unable to execute the query due to mismatch in the data structure.' : props.value}/>
										}
									}
								})()}
							</div>
						),		
					},
					{
						Header: 'Results',
						accessor: 'extract_link',
						Cell: (props) => (
							<div>
								{(props.value || '').split(',').reduce((acc, elem, i) => {
									if (elem) acc.push(<a style={{ color: 'blue' }} onClick={() => downloadSignedUrl({path: elem})} >Download file {i+1}</a>)
									return acc
								}, [])}
							</div>
						),
						width: 50		
					},
					{
						Header: 'Requested By',
						accessor: 'requester_name',		
						width: 70					
					},
					{
						Header: 'Created On',
						accessor: 'created_on',
						width: 70,
						Cell: (props) => (
							<div>
								{ props.value ? `${new Date(props.value).toLocaleDateString()} - ${new Date(props.value).toLocaleTimeString()}` : 'NA' }
							</div>
						),				
					},
					{
						Header: 'Action',
						accessor: 'action',
						width: 50,
						Cell: (props) => {
							const id = props.rowsById[props.row.id]?.values?.id
							return (
								<ReRequestExtraData status={props.rowsById[props.row.id]?.values?.status} onConfirm={(fields) => {
									zigna_aiStore.placeReRequest(fields, id, props.rowsById[props.row.id]?.values?.request_type)
								}} 
								dbname={authStore?.currentUser?.db_name}
								/>
							)
						}
					},
					// {
					// 	Header: 'Count',
					// 	accessor: 'cnt',
					// 	width: 50
					// },
	
				],
			},
		];

		return (
			<Card border="light" className="shadow-sm">
				<Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>
							Historical Requests
							<span className="downloadBtn ">
							
						
							<span className="ml-5" onClick={() => { zigna_aiStore.getHistoricalRequests() }}>
								Refresh
							</span>
							
						</span>
						
							</h5>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body style={{ overflow: 'scroll',maxHeight:"90vh" }}>
					<CommonTable
						columns={columns}
						data={zigna_aiStore.historicalRequests || []}
						className="zignaAi_table_1"
						initialState={{ hiddenColumns: ['id', 'request_type'] }}
						stickyTop={-15}
								sticky
					/>
				</Card.Body>
			</Card>
		)
	})
)
