import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import React, { useEffect } from 'react'
import usStateJson from './../../../data/ZignaAi/us-states.json'


import * as d3 from 'd3'
import { inject, observer } from 'mobx-react'

export default inject('zigna_aiStore', 'authStore')(
	observer(({ zigna_aiStore, authStore }) => {
		let map
		useEffect(() => {
			drawMap()
		}, [zigna_aiStore.stateMapDataList])

		const drawMap = () => {
			d3.select('#usamap').select('svg').remove()
			let data = zigna_aiStore.stateMapDataList
			var margin_choropleth = {
					top: 10,
					left: 10,
					bottom: 10,
					right: 10,
				},
				width_choropleth = parseInt(d3.select('#usamap').style('width')),
				width_choropleth =
					width_choropleth - margin_choropleth.left - margin_choropleth.right,
				mapRatio = 0.5,
				height_choropleth = width_choropleth * mapRatio

			// D3 Projection
			var projection = d3
				.geoAlbersUsa()
				.scale(width_choropleth)
				.translate([width_choropleth / 2, height_choropleth / 2])

			// Define path generator
			var path = d3.geoPath().projection(projection)

			var viewboxwidth = width_choropleth * 1
			var viewboxheight = height_choropleth

			// d3.json(usStateJson, function (json) {

			var svg_choropleth = d3
				.select('#usamap')
				.append('svg')
				.attr('preserveAspectRatio', 'xMidYMid meet')
				.attr('viewBox', '0 0 ' + viewboxwidth + ' ' + viewboxheight + '')

			map = svg_choropleth.append('g').attr('id', 'usStates')
			let usMergedCustomData = [
				...usStateJson.features
					?.concat(data)
					.reduce(
						(m, o) =>
							m.set(
								o?.properties?.name,
								Object.assign(m.get(o.state) || {}, o)
							),
						new Map()
					)
					.values(),
			]
			map
				.selectAll('path')
				.data(usMergedCustomData)
				.enter()
				.append('path')
				.attr('d', path)
				.style('stroke', '#aaa')
				.style('stroke-width', '1')
				.style('fill', function (d) {
					return d.value == 0 ? '#5e3f93' : '#eaECF5'
				})
				.on('click', clicked)
			let zoomBtnPositionX = viewboxwidth - 30
			let zoomBtnPositionY = viewboxheight - 70
			svg_choropleth
				.append('g')
				.attr('class', 'D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomIn')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>+')
				.on('click', zoomIn)
			svg_choropleth
				.select('g.D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomOut')
				.attr('transform', 'translate(0,28)')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>─')
				.on('click', zoomOut)

			const zoom = d3.zoom().scaleExtent([1, 8]).on('zoom', zoomed)

			function zoomed() {
				map
					.selectAll('path') // To prevent stroke width from scaling
					.attr('transform', d3.event.transform)
			}

			svg_choropleth.call(zoom)
			let zoomfactor = 1
			function zoomOut() {
				if (zoomfactor > 1) {
					zoomfactor = zoomfactor - 0.5
					zoom.scaleTo(svg_choropleth.transition().duration(750), zoomfactor)
				}
			}
			function zoomIn() {
				zoomfactor = zoomfactor + 0.5
				zoom.scaleTo(svg_choropleth.transition().duration(750), zoomfactor)
			}
			function clicked(d) {
				zigna_aiStore.setSelectedState(d)
			}
		}
		return (
			<Card border="light" className="shadow-sm">
				<Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>State Map</h5>
						</Col>
						<Col className="text-end legendSection">
							<div className="">
								<div className="legendRow">
									<div className="legendColor legendC1"></div>
									<div className="legendValue">Selected payer</div>
								</div>
							</div>
							<div className="">
								<div className="legendRow">
									<div className="legendColor legendC2"></div>
									<div className="legendValue">Others</div>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<div id="usamap" className="d3Map"></div>
				</Card.Body>
			</Card>
		)
	})
)
