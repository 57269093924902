import { useState } from 'react';
import { Button, Alert, Modal, Form } from '@themesberg/react-bootstrap'
import _ from "lodash";
import { getYearFromString, getMonthFromString } from '../../../Utilities';
import { useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Select from 'react-select';

export default
  inject(
    "zigna_aiStore",
    "authStore"
  )(observer(({ zigna_aiStore, authStore, show, handleClose, setLabel, label, handleConfirm, db, setDb, isRe = false }) => {
    const history = useHistory()
    const location = useLocation()
    const setSubmitOnEnter = (e) => {
      if (e.key === 'Enter') {
        handleConfirm()
      }
    }
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Set a label for your request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => e.preventDefault()}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control value={label} onKeyDown={setSubmitOnEnter} onChange={(e) => setLabel(e.currentTarget.value)} type="text" placeholder="i.e. Request for extra data" />
            </Form.Group>

            {
              isRe &&
              (
                <Form.Group>
                  <Form.Label>Database</Form.Label>
                  <Select
                    value={db}
                    onChange={(value) => {setDb(value);console.log(value)}}
                    options={authStore?.currentUser?.perms?.dblist?.map(db => ({ id: db.dbname, label: db.label, value: db.dbname }))}
                  />
                </Form.Group>
              )
            }

            <span><i>Note: You can find this request under "Historical Requests" page.</i></span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={!label} size="sm" variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }))

// export default RequestMoreModal;