import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { Routes } from '../routes'

// components
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

import { inject, observer } from 'mobx-react'
import NotFoundPage from './notFound/NotFound'
import Signin from './signIn/Signin'
import { useLocation } from 'react-router-dom';
import { SplashScreen } from './splashScreen/SplashScreen'

import {getPermissions,getFilteredRoutes} from '../PermisionObject'
const RouteWithLoader = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					<Navbar />
					<Component {...props} />{' '}
				</>
			)}
		/>
	)
}

const RouteWithSidebar = inject('authStore')(
	observer(({ component: Component, authStore, ...rest }) => {
		useEffect(() => {
			authStore.isAuthenticate();
		}, [authStore.currentuser]);

		return (
			<Route
				{...rest}
				render={(props) => (
					<>
						<Preloader />
						<Navbar />
						<Sidebar />

						<main className="content pt-6" style={{height:"calc(100% - 52px)"}}>
							<Component {...props} />
						</main>
						<Footer />
						<SplashScreen />
					</>
				)}
			/>
		)
	})
)

export default inject('authStore','zigna_aiStore')(
	observer(({ component: Component, authStore,zigna_aiStore, ...rest }) =>{
		  const location = useLocation();
		  const history = useHistory();
		// useEffect(() => {
		//   return () => {
			
		//   }
		// }, [location])

		// useEffect(() => {
		// 	if (zigna_aiStore.userType && zigna_aiStore.userType!=='dataAuditReport'){
		// 		history.push(Routes.DataAuditReference.path)
		// 	}
		
		// }, [zigna_aiStore.userType])
		
		useEffect(() => {
			authStore.isAuthenticate()
		}, [location, authStore.currentUser])
		// console.log('testing home')

		const componentWithORWithoutModule = zigna_aiStore.userType ? (
			<>
				<RouteWithLoader path={Routes.NotFound.path} component={NotFoundPage} />
				<Redirect to={Routes.NotFound.path} />
			</>
		) : (
			<>
				<RouteWithLoader path={"/"} component={Signin} />
				<Redirect to={"/"} />
			</>
		)

		return (
			<>
			{authStore.isSessionActive ? 
			<Switch>

				{getFilteredRoutes(Routes,getPermissions(authStore?.currentUser?.client_name),zigna_aiStore.userType)?.map(i=><RouteWithSidebar
					exact={i.exact}
					path={i.path}
					component={i.component}
				/>)}

				   

					{getFilteredRoutes(Routes,getPermissions(authStore?.currentUser?.client_name),zigna_aiStore.userType)?.[0]?<RouteWithSidebar
					exact={true}
					path={"/"}
					component={getFilteredRoutes(Routes,getPermissions(authStore?.currentUser?.client_name),zigna_aiStore.userType)?.[0].component}
				/>:""}
				
				{/* ZignaAi Pages Start*/}
				
				{/* ZignaAi Pages EEnd*/}
				{componentWithORWithoutModule}
				
				</Switch>
				:
				<Switch>
	
				{/* ZignaAi Pages Start*/}
				<RouteWithLoader path={"/"} component={Signin} />
				<Redirect to={"/"} />
				</Switch>
		
		}
		</>
		)
	})
)
