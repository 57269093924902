import Select from "react-select";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import _, { set } from "lodash";
export default function Dropdown(props) {
  const [searchValue, setSearchValue] = useState("");
  const [lastValue, setLastValue] = useState(null);
  const [selectAllItems, setSelectAllItems] = useState({
    id: 0,
    name: "Select All",
    value: "All",
  });
  useEffect(() => {
    if (props?.value?.length == 0 || !props.value) {
      setSelectAllItems({ id: 0, name: "Select All", value: "All" });
    } else {
      if (props?.isMulti) {
        let e = props.value;
        if (
          e
            .sort((a, b) =>
              a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
            )
            .toString() !=
          props.options
            .flat()
            .sort((a, b) =>
              a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
            )
            .toString()
        ) {
          setSelectAllItems({ id: 0, name: "Select All", value: "All" });
        } else {
          setSelectAllItems({ id: 0, name: "Select All", value: "D-All" });
        }
      }
    }
  }, [props.value]);
  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 99 }),
    control: (base) => ({
      ...base,
      background: "#e7e3f9",
      height: 44,
      minHeight: 44,
      minWidth: 100,
      width: 240,
      //
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: "#c3b9f1",
        };
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#c3b9f1",
      };
    },
  };

  const handleClose = () => {
    console.log(JSON.stringify(lastValue), JSON.stringify(props.value));
    if (!_.isEqual(lastValue, props.value)) {
      if (props.onMenuClose) {
        setTimeout(props.onMenuClose, 300);
      }
    }
    setLastValue(props.value);
  };

  const Option = (props) => {
    return props.data.id == "0" ? (
      <div>
        <components.Option {...props}>
          <div className="d-flex">
            <input
              type="checkbox"
              checked={props.data.value != "All" ? true : false}
              onChange={() => null}
              className="mx-2"
            />{" "}
            <label>{props.label}</label>
          </div>
        </components.Option>
      </div>
    ) : (
      <div>
        <components.Option {...props}>
          <div className="d-flex">
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="mx-2"
            />{" "}
            <label>{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 0;
    const length = getValue().length;

    return index < maxToShow ? (
      <components.MultiValue {...props} />
    ) : index === maxToShow ? (
      `${length} selected`
    ) : null;
  };

  const animatedComponents = makeAnimated();
  return (
    <Select
      {...props}
      className={`select-menu`}
      menuPortalTarget={document.body}
      isClearable={props.isClearable}
      styles={customStyles}
      inputValue={searchValue}
      onMenuClose={handleClose}
      backspaceRemoves={false}
      onChange={(e, data) => {
        if (props?.isMulti) {
          if (data.option?.value == "All") {
            props.onChange(props.options, data);
            setSelectAllItems({ id: 0, name: "Select All", value: "D-All" });
          } else if (data.option?.value == "D-All") {
            setSelectAllItems({ id: 0, name: "Select All", value: "All" });
            props.onChange([], data);
          } else {
            if (
              e
                .sort((a, b) =>
                  a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
                )
                .toString() !=
              props.options
                .flat()
                .sort((a, b) =>
                  a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
                )
                .toString()
            ) {
              setSelectAllItems({ id: 0, name: "Select All", value: "All" });
            } else {
              setSelectAllItems({ id: 0, name: "Select All", value: "D-All" });
            }
            props.onChange(e, data);
          }
        } else {
          props.onChange(e, data);
        }
      }}
      options={
        props?.isMulti && !props?.disableSelectAll
          ? [selectAllItems, ..._.orderBy(props.options, "name")]
          : _.orderBy(props.options, "name")
      }
      onInputChange={(inputValue, { action }) => {
        if (
          (action === "input-blur" || action === "set-value") &&
          props.isMulti
        ) {
          return;
        } else setSearchValue(inputValue);
      }}
      components={
        props?.isMulti
          ? {
              Option: props?.components?.Option
                ? props?.components?.Option
                : Option,
              MultiValue,
              animatedComponents,
            }
          : {}
      }
    />
  );
}
