import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';


const StarRating = ({ rating }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<FontAwesomeIcon style={{fontSize:24}} key={i} icon={faStar} className="text-warning" />);
    } else {
      stars.push(<FontAwesomeIcon key={i} style={{fontSize:24}}icon={faStar} className="text" />);
    }
  }

  return (
    <div className="star-rating">
      {stars}
    </div>
  );
};

export default StarRating;