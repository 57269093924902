import React from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { toCurrency } from '../../../Utilities'
export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let payerTableColumn = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Hospital Name',
						accessor: 'hospitalName',
						Cell: (props) => {
							return props.value.split(',').map(val => `${val} \n`).join('')
						}
					},
					{
						Header: 'Excellus',
						accessor: 'excellus',
						Cell: props=><span className='numericVal'>{toCurrency(props?.value)}</span>
					},
					{
						Header: 'Other',
						accessor: 'other',
						Cell: props=><span className='numericVal'>{toCurrency(props?.value)}</span>
					},
				],
			},
		]
		return (
			<>
				<Col xs={12} sm={12} lg={12}>
					<Row className='categoryWidget2 m-0'>
					{zigna_aiStore?.topOverviewList?.categoryList?.map((item, i) => (
							<Col
								xs={4}
								sm={4}
								lg={4}
								className="catWidItem"
								key={`${item.id}_categoryPrice`}
							>
								<h5 className="fw400">{item.name}</h5>
								<div className="d-flex align-items-center gap-3 mb-3">
									<div>
										{iconSelector(i)}
									</div>
									<div className="numLg numColor">
										{toCurrency(item.value)}
									</div>
								</div>
							</Col>
						))}
					</Row>
				</Col>
				<Col xs={12} sm={12}>
					<CommonTable
						columns={payerTableColumn}
						data={zigna_aiStore?.topOverviewList?.overviewList}
						className="zignaAi_table_1"
					/>
				</Col>
			</>
		)
	})
)
const iconSelector = (value) => {
	let icon
	if (value == 0) {
		icon = (
			<svg
				width="26"
				height="28"
				viewBox="0 0 26 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.5 27.3201V6.83004C0.5 6.46775 0.631696 6.1203 0.866116 5.86412C1.10054 5.60795 1.41848 5.46403 1.75 5.46403H6.75V0H19.25V5.46403H24.25C24.5815 5.46403 24.8995 5.60795 25.1339 5.86412C25.3683 6.1203 25.5 6.46775 25.5 6.83004V27.3201H15.5V20.4901H10.5V27.3201H0.5ZM9.25 2.73201V10.9281H11.75V8.19604H14.25V10.9281H16.75V2.73201H14.25V5.46403H11.75V2.73201H9.25ZM3 24.5881H8V20.4901H3V24.5881ZM3 17.7581H8V13.6601H3V17.7581ZM18 24.5881H23V20.4901H18V24.5881ZM18 17.7581H23V13.6601H18V17.7581ZM10.5 17.7581H15.5V13.6601H10.5V17.7581Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else if (value == 1) {
		icon = (
			<svg
				width="26"
				height="28"
				viewBox="0 0 26 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.25 0V0.0273201V2.75933C19.7375 3.49698 23.625 8.94735 22.95 14.9441C22.375 19.9164 18.8 23.8641 14.25 24.4515V27.1835C21.125 26.4322 26.125 19.7661 25.4375 12.2531C24.875 5.76455 20.1625 0.642023 14.25 0ZM11.75 0.0409802C9.3125 0.300522 6.9875 1.32503 5.0875 3.0462L6.875 5.06789C8.275 3.83848 9.9625 3.0462 11.75 2.77299V0.0409802ZM3.325 4.97227C1.75 7.0486 0.8125 9.57571 0.5625 12.2531H3.0625C3.3 10.3134 4 8.46924 5.1125 6.92566L3.325 4.97227ZM17.375 8.83807L11.275 15.5042L8.625 12.6082L7.3 14.0562L11.275 18.4001L18.7 10.286L17.375 8.83807ZM0.575 14.9851C0.825 17.6625 1.7875 20.1896 3.3375 22.2659L5.1125 20.3125C4.0125 18.7689 3.3 16.9248 3.075 14.9851H0.575ZM6.875 22.3206L5.0875 24.192C6.975 25.9132 9.3 26.9923 11.75 27.2792V24.5471C9.975 24.3013 8.2875 23.5226 6.875 22.3206Z"
					fill="#8B6CBC"
				/>
			</svg>
			
		)
	} else if (value == 2) {
		icon = (
			<svg
				width="28"
				height="31"
				viewBox="0 0 28 31"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.1111 12.1707H25.6667L17.1111 2.82112V12.1707ZM3.11111 0.27124H18.6667L28 10.4708V27.47C28 28.3717 27.6722 29.2364 27.0888 29.874C26.5053 30.5116 25.714 30.8698 24.8889 30.8698H3.11111C1.38444 30.8698 0 29.3399 0 27.47V3.67108C0 2.76939 0.327777 1.90462 0.911223 1.26703C1.49467 0.629436 2.28599 0.27124 3.11111 0.27124Z"
					fill="#8B6CBC"
				/>
				<path
					d="M3 22.849H5C5 24.0293 6.37 25.0346 8 25.0346C9.63 25.0346 11 24.0293 11 22.849C11 21.6469 9.96 21.2098 7.76 20.6306C5.64 20.0514 3 19.3302 3 16.2922C3 14.3361 4.47 12.675 6.5 12.1177V9.73535H9.5V12.1177C11.53 12.675 13 14.3361 13 16.2922H11C11 15.112 9.63 14.1066 8 14.1066C6.37 14.1066 5 15.112 5 16.2922C5 17.4943 6.04 17.9314 8.24 18.5106C10.36 19.0898 13 19.811 13 22.849C13 24.8051 11.53 26.4662 9.5 27.0235V29.4059H6.5V27.0235C4.47 26.4662 3 24.8051 3 22.849Z"
					fill="white"
				/>
			</svg>
			
		)
	} else if (value == 3) {
		icon = (
			<svg
				width="25"
				height="32"
				viewBox="0 0 25 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.4999 0.916656C17.327 0.916656 21.2499 4.79582 21.2499 9.59374C21.2499 16.0979 12.4999 25.7083 12.4999 25.7083C12.4999 25.7083 3.74992 16.0979 3.74992 9.59374C3.74992 4.79582 7.67284 0.916656 12.4999 0.916656ZM12.4999 6.74999C11.7264 6.74999 10.9845 7.05728 10.4375 7.60426C9.89054 8.15124 9.58325 8.89311 9.58325 9.66666C9.58325 10.4402 9.89054 11.1821 10.4375 11.7291C10.9845 12.276 11.7264 12.5833 12.4999 12.5833C13.2735 12.5833 14.0153 12.276 14.5623 11.7291C15.1093 11.1821 15.4166 10.4402 15.4166 9.66666C15.4166 8.89311 15.1093 8.15124 14.5623 7.60426C14.0153 7.05728 13.2735 6.74999 12.4999 6.74999ZM24.1666 25.7083C24.1666 28.9312 18.9458 31.5417 12.4999 31.5417C6.05409 31.5417 0.833252 28.9312 0.833252 25.7083C0.833252 23.8271 2.61242 22.15 5.36867 21.0854L6.302 22.4125C4.727 23.0687 3.74992 23.9729 3.74992 24.9792C3.74992 26.9917 7.67284 28.625 12.4999 28.625C17.327 28.625 21.2499 26.9917 21.2499 24.9792C21.2499 23.9729 20.2728 23.0687 18.6978 22.4125L19.6312 21.0854C22.3874 22.15 24.1666 23.8271 24.1666 25.7083Z"
					fill="#8B6CBC"
				/>
			</svg>
		)
	} else {
		icon = (
			<svg
				width="31"
				height="27"
				viewBox="0 0 31 27"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M27.873 7.19825L15.873 15.3089L3.87305 7.19825V3.95398L15.873 12.0647L27.873 3.95398V7.19825ZM27.873 0.709717H3.87305C2.20805 0.709717 0.873047 2.15342 0.873047 3.95398V23.4196C0.873047 24.28 1.18912 25.1052 1.75173 25.7136C2.31434 26.322 3.0774 26.6639 3.87305 26.6639H27.873C28.6687 26.6639 29.4318 26.322 29.9944 25.7136C30.557 25.1052 30.873 24.28 30.873 23.4196V3.95398C30.873 2.15342 29.523 0.709717 27.873 0.709717Z"
					fill="#8B6CBC"
				/>
				<path
					d="M23.9998 16.9968C24.3452 16.9968 24.6764 17.1467 24.9205 17.4136C25.1647 17.6804 25.3019 18.0424 25.3019 18.4197C25.3019 18.6066 25.2682 18.7916 25.2028 18.9643C25.1374 19.1369 25.0415 19.2938 24.9205 19.4259C24.7996 19.558 24.6561 19.6628 24.4981 19.7343C24.3401 19.8059 24.1708 19.8427 23.9998 19.8427C23.6545 19.8427 23.3233 19.6927 23.0791 19.4259C22.8349 19.159 22.6978 18.7971 22.6978 18.4197C22.6978 18.0424 22.8349 17.6804 23.0791 17.4136C23.3233 17.1467 23.6545 16.9968 23.9998 16.9968ZM23.9998 14.4355C24.9668 14.4355 25.8941 14.8553 26.5778 15.6025C27.2616 16.3497 27.6457 17.3631 27.6457 18.4197C27.6457 21.4079 23.9998 25.8189 23.9998 25.8189C23.9998 25.8189 20.354 21.4079 20.354 18.4197C20.354 17.3631 20.7381 16.3497 21.4218 15.6025C22.1056 14.8553 23.0329 14.4355 23.9998 14.4355ZM23.9998 15.5739C23.3092 15.5739 22.6468 15.8737 22.1584 16.4074C21.67 16.9411 21.3957 17.665 21.3957 18.4197C21.3957 18.9889 21.3957 20.1272 23.9998 23.9464C26.604 20.1272 26.604 18.9889 26.604 18.4197C26.604 17.665 26.3296 16.9411 25.8413 16.4074C25.3529 15.8737 24.6905 15.5739 23.9998 15.5739Z"
					fill="white"
				/>
			</svg>
		)
	}
	return icon
}