import React, { useEffect, useState, useMemo } from "react";
import CommonTable from "./../common/CommonRowSpan";
import _ from "lodash";

/** For future use - formatting only */
import "xlsx";
import NpiModal from "./NpiModal";
import Select from "../../ui/Dropdown";
const Table = ({
  dataset,
  zigna_aiStore,
  filterSet,
  uniqueNpis,
  includeCMS,
}) => {
  const [selectedNpi, setSelectedNpi] = useState(null);
  const transformData = (dataset, withregion = false) => {
    let headers = [];

    let maxRates = 0;

    let items = [];

    dataset.map((i) => {
      let allRates = Object.values(i).filter((i) => typeof i == "object");
      let n = i.payor.replace(/\./g, "");

      allRates.map((rate) => {
        let item = {
          billing_code: i.billing_code,
          tin: i.tin,
          CMS: i.CMS,
          npi: rate.npi,
          payer: n,
          order: rate?.rates && rate.rates.length > 0 ? rate.count : -1,
        };

        if (!rate.rates) {
          return;
        }
        if (maxRates < rate?.rates?.length) {
          maxRates = rate?.rates?.length;
        }
        rate?.rates?.map((r, index) => {
          item[n + (" RATE " + (index + 1))] = "$" + r;
        });
        rate?.rates?.map((p, index) => {
          item[n + (" RATE % " + (index + 1))] = item.CMS
            ? ((p / item.CMS) * 100).toFixed(2) + "%"
            : null;
        });
        item["count"] = rate.count;
        items.push(item);
      });
    });
    let headersWithoutPeriods = dataset[0].payor.replace(/\./g, "");
    for (let i = 1; i <= maxRates; i++) {
      headers.push(headersWithoutPeriods + (" RATE " + i));
      headers.push(headersWithoutPeriods + " RATE % " + i);
    }

    // allGroups.map((gp) => {
    //   let item = {};
    //   if (gp.length == 2) {
    //     console.log(JSON.parse(JSON.stringify(gp)));
    //   }
    //   if (gp.length == 1 && gp[0].reporting_entity_name == "CMS") {
    //     return;
    //   }
    //   Object.values(_.groupBy(gp, "reporting_entity_name")).map(
    //     (gp2, index) => {
    //       _.orderBy(gp2, ["non_facility", "facility"], ["desc", "desc"]).map(
    //         (re, index) => {
    //           let n = re.reporting_entity_name;
    //           if (n !== "CMS") {
    //             headers.push(n + (" RATE " + (index + 1)));
    //             item[n + (" RATE " + (index + 1))] = { ...re };
    //           } else {
    //             item[n] = { ...re };
    //           }
    //           item = {
    //             ...item,
    //             tin: re.tin,
    //             billigCode: re.billing_code,
    //             npi: re.npi,
    //           };
    //           item["grouping"] = re.group1;
    //           item["classification"] = re.group2;
    //           item["specialization"] = re.group3;
    //           item["npp"] = re.npp_indicator;
    //           item["region"] = re.region;
    //           item["provider_type"] = re.provider_type;
    //           item["tax_id_name"] = re.tax_id_name;
    //           item["group_name"] = re.group_name;
    //           item["proc_count"] = re.proc_count;
    //         }
    //       );

    //       // let values={}

    //       // re.map(i=>{

    //       //   values[i.service_code]=i.negotiated_rate
    //       //   values[`diff_${i.service_code}`]=0

    //       // })

    //       // item["CMS"]={negotiated_rate_non_facility:95.6,negotiated_rate_facility:95.6}
    //     }
    //   );
    // });

    // let groupedData=groupArray(dataset,"tin","billing_code","group1","group2","npi","reporting_entity_name")

    // let items=[]
    // let headers=[]

    // Object.keys(groupedData).map(i=>{

    // let tin=i
    // let billigCode
    // let npi=""
    // let group1=""
    // let group2=""

    // Object.keys(groupedData[i]).map(j=>{
    //   billigCode=j

    //   Object.keys(groupedData[i][j]).map(k=>{
    //     group1=k
    //     Object.keys(groupedData[i][j][k]).map(l=>{
    //       group2=l
    //       Object.keys(groupedData[i][j][k][l]).map(m=>{
    //         npi=m
    //         let item={tin,billigCode,npi:m,group1,group2}
    //           Object.keys(groupedData[i][j][k][l][m]).map(n=>{

    //       Object.values(_.orderBy(groupedData[i][j][k][l][m][n],["non_facility","facility"],["desc","desc"])).map((re,index)=>{

    //         if(n!=="CMS")
    //         { headers.push(n+(" RATE "+(index+1)))
    //         item[n+(" RATE "+(index+1))]={...re}
    //        }
    //        else{
    //          item[n]={...re}
    //        }
    //         // item["CMS"]={negotiated_rate_non_facility:95.6,negotiated_rate_facility:95.6}
    //         item["grouping"]=re.group1
    //         item["classification"]=re.group2
    //         item["npp"]=re.npp_indicator
    //       })

    //     })
    //     items.push(item)
    //       })
    //     })

    //   })
    // })

    // })

    // selectedRates.map(i=>{
    //   headers=headers.filter(h=>h!==i)
    //   headers.push(i)
    // })

    // headers=selectedHeader?.length?headers.filter(i=>{
    //   if(selectedHeader.find(j=>i.includes(j.name))){
    //    return true
    //   }
    //   else{
    //    return false
    //   }
    // }):headers
    // })

    // if(showRelevant){
    //   items=items.filter(item=>item.CMS?.Facility||item.CMS?.["Non-Facility"])
    // }

    return { items, headers };
  };
  const { items, headers } = useMemo(() => {
    return transformData(dataset);
  }, [dataset, selectedNpi]);

  let columns = [
    // {
    //   Header: "TIN",
    //   accessor: "tin",
    //   width: 100,
    //   enableRowSpan: true,
    // },
    {
      Header: "Billing Code",
      accessor: "billing_code",
      width: 130,
      enableRowSpan: true,
      mainGroup: true,
      align: "center",
    },
    ...(includeCMS
      ? [
          {
            Header: "CMS",
            accessor: "CMS",
            width: 80,
            align: "right",
            enableRowSpan: true,
            Cell: ({ value }) => <>{value ? `$${value}` : ""}</>,
          },
        ]
      : []),
    ...headers
      .filter((i) => (includeCMS ? true : !i.match(/RATE %/)))
      .map((i) => {
        return {
          Header: i.match(/RATE %/) ? "% of CMS" : `${i}`,
          accessor: i,
          width: 100,
          align: "right",
        };
      }),

    {
      Header: "Individual NPI Count",
      accessor: "count",
      align: "right",
      width: 140,
      Cell: ({ value, row }) => {
        console.log(row, "row here");
        return (
          <NpiModal
            billing_code={row.original.billing_code}
            tin={row.original.tin}
            npi={row.original.npi}
            payer={row.original.payer}
            filterSet={filterSet}
          >
            {value}
          </NpiModal>
        );
      },
    },
    {
      Header: "Individual NPI Example 1",
      accessor: "npi",
      id: "npi1",
      align: "right",
      Cell: ({ value }) => {
        return (
          <div key={value}>
            {/* {value?.map((i) => ( */}
            <div>
              {selectedNpi && value.find((i) => i == selectedNpi?.value) ? (
                <div
                  style={{
                    background: "yellow",
                    width: "auto",
                    display: "inline-block",
                  }}
                >
                  {value.find((i) => i == selectedNpi?.value)}
                </div>
              ) : (
                value[0]
              )}
            </div>
            {/* ))} */}
          </div>
        );
      },
    },
    {
      Header: "Individual NPI Example 2",
      accessor: "npi",
      id: "npi2",
      align: "right",
      Cell: ({ value }) => {
        return (
          <div key={value}>
            {/* {value?.map((i) => ( */}
            <div>{value?.[1] || "-"}</div>
            {/* ))} */}
          </div>
        );
      },
    },
  ];
  // if(!includeCMS)
  //   columns=columns.filter(col=>col.Header==="CMS")

  // let columns = [
  //   {
  //     Header: "",
  //     sticky: "left",
  //     id: "_blank",
  //     columns: [
  //       {
  //         Header: (props) => (
  //           <div
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onClick={() => handleSort("tin")}
  //           >
  //             Tax ID
  //           </div>
  //         ),
  //         accessor: `tin`,
  //         width: 115,
  //         mainGroup: true,
  //       },
  //       {
  //         Header: (props) => (
  //           <div
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onClick={() => handleSort("billigCode")}
  //           >
  //             billing code
  //           </div>
  //         ),
  //         textHeader: "Allowed Per Unit",
  //         align: "left",
  //         accessor: `billing_code`,
  //         width: 100,
  //         Cell: (props) => <>{props.value}</>,
  //       },
  //     ],
  //   },
  //   {
  //     Header: "CMS",
  //     sticky: "left",
  //     columns: [
  //       {
  //         Header: "Non-Facility Global / Base Rate",
  //         accessor: `CMS.non_facility`,
  //         width: 100,
  //         align: "right",
  //       },

  //       // {
  //       //   Header: "Utilization #",
  //       //   accessor: `CMS.proc_count`,
  //       //   width: 110,
  //       //   align: "right",
  //       // },
  //       {
  //         Header: "Utilization $",
  //         accessor: `CMS.proc_count$`,
  //         width: 110,
  //         align: "right",
  //         Cell: (props) => {
  //           return props.row.original?.CMS?.proc_count
  //             ? (
  //                 props.row.original?.CMS?.proc_count *
  //                 props.row.original?.CMS?.["non_facility"]
  //               ).toFixed(1)
  //             : "";
  //         },
  //       },
  //     ],
  //   },
  //   ..._.uniq(headers).map((i) => ({
  //     Header: <div className="d-flex flex-row justify-content-center">{i}</div>,
  //     accessor: i,
  //     columns: [
  //       {
  //         Header: "Non-Facility Global / Base Rate",
  //         accessor: `${i}.non_facility`,
  //         width: 100,
  //         align: "right",
  //       },
  //       {
  //         Header: "% Medicare",
  //         accessor: `${i}.diff`,
  //         width: 100,
  //         align: "right",

  //         Cell: (props) => {
  //           return getFormatedData(
  //             getRateDiff(
  //               props.row.original?.CMS?.["non_facility"],
  //               props.row.original?.[i]?.["non_facility"]
  //             )
  //           );
  //         },
  //       },
  //       // {
  //       //   Header: "Utilization #",
  //       //   accessor: `${i}.proc_count`,
  //       //   width: 110,
  //       //   align: "right",
  //       // },
  //       {
  //         Header: "Utilization $",
  //         accessor: `${i}.proc_count$`,
  //         width: 110,
  //         align: "right",
  //         Cell: (props) => {
  //           return props.row.original?.[i]?.proc_count
  //             ? (
  //                 props.row.original?.[i]?.proc_count *
  //                 props.row.original?.[i]?.["non_facility"]
  //               ).toFixed(1)
  //             : "";
  //         },
  //       },
  //     ],
  //   })),
  // ];
  console.log(
    zigna_aiStore.uniqueNpis.map((i) => {
      return {
        name: i,
        value: i,
      };
    }),
    "options log"
  );
  return (
    <>
      <div className="d-flex justify-content-end">
        <div>
          <label className="form-label">Select Individual NPI</label>
          <Select
            className={`select-menu`}
            value={selectedNpi}
            onChange={(e, data) => {
              setSelectedNpi(e);
              //setFilterSet(updateFilterSet("billing_code", e));
              //refetchFilterData(updateFilterSet("billing_code",e))
            }}
            // onMenuClose={() => refetchFilterData(filterSet)}
            getOptionLabel={(option) => `${option.name}`}
            // isMulti
            isClearable
            options={
              uniqueNpis?.map((i) => {
                return {
                  name: i,
                  value: i,
                };
              }) || []
            }
            allowSelectAll={false}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
          />
        </div>
      </div>
      <div
        style={{
          overflow: "scroll",
          height: "93.5vh",
          width: "100%",
          marginBottom: 10,
          fontFamily: "arial",
          fontSize: 16,
          marginTop: 12,
        }}
      >
        <CommonTable
          sticky
          stickyTop={0}
          columns={columns}
          data={_.orderBy(items, [`billing_code`, "order"], ["asc", "desc"])}
          className="zignaAi_table_1 zignaAi_newTable"
        />
      </div>
    </>
  );
};

export default Table;
