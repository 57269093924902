import competitiveLandscape from "./pages/competitiveLandscape/CompetitiveLandscape";
import DataAuditReferenceTable from "./pages/DataAuditReference/DataAuditReferenceTable";
import DataAuditReport from "./pages/DataAuditReference/AuditReport";
import DRGcodesByHospital from "./pages/DataAuditReference/DRGcodesByHospital";
import DRGcodesByHospitalAndNegotiation from "./pages/DataAuditReference/DRGbyHospitalAndNegotiation";
import PayersPriceCompare from "./pages/payersPriceCompare/PayersPriceCompare";

import CostDiffReport from "./pages/costDiffReport/CostDiffReport";
import OverviewDrgCPT from "./pages/overviewDrgCPT/OverviewDrgCPT";
import { inject, observer } from "mobx-react";
import NotFoundPage from "./pages/notFound/NotFound";
import Signin from "./pages/signIn/Signin";
import { useLocation } from "react-router-dom";
import { SplashScreen } from "./pages/splashScreen/SplashScreen";
import ProvidersPriceCompare from "./pages/providersPriceCompare/ProvidersPriceCompare";
import FilteredDrgList from "./pages/FilteredDrgList/FilteredDrgList";
import DrgReferenceTable from "./pages/DrgReferenceTable/DrgReferenceTable";
import ProvidersCPTExtract from "./pages/providersCPTExtract/ProvidersCPTExtract";
import ProcedureCostByHospital from "./pages/ProcedureCostByHospital";
import DRGcostBreakdown from "./pages/DRGcostBreakdown";
import HistoricalRequests from "./pages/HistoricalRequests/HistoricalRequests";
import providerExtract from "./pages/providerExtract";
import providerExtractChart from "./pages/providerExtractChart";
import facilityScorecard from "./pages/facilityScorecard";
import Ratechoiceview from "./pages/ratechoiceview";
import ProfessionalRateUtilizationview from "./pages/rateUtilization";
import ProfessionalRateUtilizationviewByTIN from "./pages/rateUtilization/ByTIN";
import RatechoiceviewByTIN from "./pages/ratechoiceview/ByTIN";
import RateInsightsByTIN from "./pages/rateInsights/ByTIN";
import RateInsights from "./pages/rateInsights";
export const Routes = {
  dataAuditReport: [
    { path: "/", exact: true, component: DataAuditReferenceTable },
    { path: "/report", exact: true, component: DataAuditReport },
    {
      path: "/procedureCostByHospital",
      exact: true,
      component: ProcedureCostByHospital,
    },
    { path: "/drgRefRable", exact: true, component: DrgReferenceTable },
  ],
  ancillary: [
    { path: "/", exact: true, component: competitiveLandscape },
    {
      path: "/payersPriceCompareCPT-HCPCS",
      exact: true,
      component: PayersPriceCompare,
    },
    {
      path: "/payersPriceCompareAPC",
      exact: true,
      component: PayersPriceCompare,
    },
    {
      path: "/payersPriceCompareDRG",
      exact: true,
      component: PayersPriceCompare,
    },
    { path: "/filteredDrgList", exact: true, component: FilteredDrgList },
    { path: "/topCpt", exact: true, component: OverviewDrgCPT },
    { path: "/topDrg", exact: true, component: OverviewDrgCPT },
    { path: "/historicalrequests", exact: true, component: HistoricalRequests },
    { path: "/DRGcostBreakdown", exact: true, component: DRGcostBreakdown },
    {
      path: "/procedureCostByHospital",
      exact: true,
      component: ProcedureCostByHospital,
    },
    { path: "/providersExtractCPT", exact: true, component: providerExtract },
    { path: "/providersExtractHCPCS", exact: true, component: providerExtract },
    {
      path: "/providersExtractCPTChart",
      exact: true,
      component: providerExtractChart,
    },

    ,
  ],
  professional: [
    { path: "/", exact: true, component: competitiveLandscape },
    {
      path: "/providersCPTExtract",
      exact: true,
      component: ProvidersCPTExtract,
    },
    {
      path: "/providersHCPCSExtract",
      exact: true,
      component: ProvidersCPTExtract,
    },
    { path: "/topCpt", exact: true, component: OverviewDrgCPT },
    { path: "/historicalrequests", exact: true, component: HistoricalRequests },
    { path: "/Ratechoiceview", component: Ratechoiceview },
    {
      path: "/ProfessionalRateUtilizationview",
      component: ProfessionalRateUtilizationview,
    },
    {
      path: "/ProfessionalRateUtilizationviewByTIN",
      component: ProfessionalRateUtilizationviewByTIN,
    },
    { path: "/RatechoiceviewByTIN", component: RatechoiceviewByTIN },

    { path: "/RateInsightsByTIN", component: RateInsightsByTIN },

    { path: "/Rateinsightview", component: RateInsights },

  ],
  facility: [
    { path: "/", exact: true, component: competitiveLandscape },
    {
      path: "/payersPriceCompareCPT-HCPCS",
      exact: true,
      component: PayersPriceCompare,
    },
    {
      path: "/payersPriceCompareAPC",
      exact: true,
      component: PayersPriceCompare,
    },
    {
      path: "/payersPriceCompareDRG",
      exact: true,
      component: PayersPriceCompare,
    },
    {
      path: "/providersCPTExtract",
      exact: true,
      component: ProvidersCPTExtract,
    },
    { path: "/filteredDrgList", exact: true, component: FilteredDrgList },
    { path: "/topCpt", exact: true, component: OverviewDrgCPT },
    { path: "/topDrg", exact: true, component: OverviewDrgCPT },
    { path: "/historicalrequests", exact: true, component: HistoricalRequests },
    { path: "/DRGcostBreakdown", exact: true, component: DRGcostBreakdown },
    { path: "/providersExtractCPT", exact: true, component: providerExtract },
    { path: "/providersExtractHCPCS", exact: true, component: providerExtract },
    {
      path: "/providersExtractCPTChart",
      exact: true,
      component: providerExtractChart,
    },
    { path: "/facilityScoreCard", component: facilityScorecard },
  ],

  // ZignaAi Pages
  Signin: { path: "/" },
  CompetitiveLandscape: { path: "/" },
  DataAuditReference: { path: "/" },
  PayersPriceCompare: { path: "/payersPriceCompare" },
  CostDiffReport: { path: "/costDiffReport" },
  TopCpt: { path: "/topCpt" },
  TopDrg: { path: "/topDrg" },
  FilteredDrgList: { path: "/filteredDrgList" },
  DrgRefRable: { path: "/drgRefRable" },
  ProvidersCPTExtract: { path: "/providersCPTExtract" },
  ProvidersHCPCSExtract: { path: "/providersHCPCSExtract" },
  ProcedureCostByHospital: { path: "/procedureCostByHospital" },
  DRGcostBreakdown: { path: "/DRGcostBreakdown" },
  DataAuditReport: { path: "/report" },
  DatAuditDRGbyHospital: { path: "/drgcodesbyhospital" },
  DataAuditDRGbyHospitalAndNegotiation: {
    path: "/drgbyhospitalandnegotiation",
  },
  HistoricalRequests: { path: "/historicalrequests" },

  NotFound: { path: "/404" },
};
