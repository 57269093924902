import React, { useEffect } from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'



let selectAllOptionH = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionPt = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionbC = { id: 0, name: 'Select All', value: 'All'};
let selectAllOptions = { id: 0, name: 'Select All', value: 'All'};

const customStyles = {
	menu: base => ({ ...base, zIndex: 9999 }) ,
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}

const resetOptions=()=>{
    selectAllOptionH.value="All"
    selectAllOptionbC.value="All"
    selectAllOptionPt.value="All"
    selectAllOptions.value="All"
}
export default inject('zigna_aiStore')(
	observer(({zigna_aiStore, hideModifier,dataType }) => {
	useEffect(()=>{
        resetOptions();
        zigna_aiStore.getReportingEntityDrgByHospital()
		return ()=>{
			zigna_aiStore.resetAll()
		}
    },[])

	useEffect(()=>{

		if(	!zigna_aiStore.HospitalCountByDRGSelected?.reporting_entity||
			!zigna_aiStore.HospitalCountByDRGSelected?.month.length||
			!zigna_aiStore.HospitalCountByDRGSelected?.provider_type.length||
			!zigna_aiStore.HospitalCountByDRGSelected?.billing_code_type.length||
			!zigna_aiStore.HospitalCountByDRGSelected?.state.length
			){
				return
			}
			else{
				zigna_aiStore.getdDrgByHospitalCount(dataType)
			}

	},[dataType])
		
	

		const Option = (props) => {
			return props.data.id == '0' ? (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.data.value != 'All' ? true : false}
							onChange={() => null}
						/>{' '}
						<label>{props.label} </label>
						
					</components.Option>
				</div>
			) : (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>						
					</components.Option>
				</div>
			)
		}


	
	

		const MultiValue = ({ index, getValue, ...props }) => {
			const maxToShow = 0
			const length = getValue().length

			return index < maxToShow ? (
				<components.MultiValue {...props} />
			) : index === maxToShow ? (
				`${length} Values selected`
			) : null
		}

		const animatedComponents = makeAnimated()

		return (
			
					<Row className='px-4'>
					<Col className=" col-auto col-auto">
						<div >
								<label className="form-label">Reporting entity name <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">

									<Select
										className={`select-menu`}
										value={zigna_aiStore.HospitalCountByDRGSelected?.reporting_entity}
										onChange={(e, data) => {
											
												zigna_aiStore.setHospitalCountByDRG('reporting_entity', e)
                                                zigna_aiStore.setHospitalCountByDRG('month', [])
                                                zigna_aiStore.setHospitalCountByDRG('state', [])
                                                zigna_aiStore.setHospitalCountByDRG('billing_code_type', [])
                                                zigna_aiStore.setHospitalCountByDRG('provider_type', [])
												zigna_aiStore.setHospitalCountByDRG('data_type', null)

                                            resetOptions()
											

											// zigna_aiStore.resetPCHList()
										}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.HospitalCountByDRGFilter.reporting_entity?.length
												? zigna_aiStore.HospitalCountByDRGFilter.reporting_entity 
												: []
										}
									
										
										
										
									/>
								</div>
							</div>
						</Col>

						<Col className=" col-auto col-auto">
						<div >
								<label className="form-label">Data type<span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">

									<Select
										className={`select-menu`}
										value={zigna_aiStore.HospitalCountByDRGSelected?.data_type}
										onChange={(e, data) => {
											
												zigna_aiStore.setHospitalCountByDRG('data_type', e)
                                                zigna_aiStore.setHospitalCountByDRG('month', [])
                                                zigna_aiStore.setHospitalCountByDRG('state', [])
                                                zigna_aiStore.setHospitalCountByDRG('billing_code_type', [])
                                                zigna_aiStore.setHospitalCountByDRG('provider_type', [])

                                            resetOptions()
											 zigna_aiStore.getMonthDrgByHospital()

											// zigna_aiStore.resetPCHList()
										}}
										isDisabled={!zigna_aiStore.HospitalCountByDRGSelected?.reporting_entity}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											[
												{
													name:"raw",
													value:"raw"
												},
												{
													name:"relevant",
													value:"relevant"
												}
											]
										}
									
										
										
										
									/>
								</div>
							</div>
						</Col>
						
						<Col className=" col-auto col-auto">
								
		<label className="form-label">Months <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.month||[]}
                onChange={(e, data) => {
                    console.log(data.option?.value,e)
					if (data.option?.value == 'All') {
						zigna_aiStore.setHospitalCountByDRG(
							'month',
							zigna_aiStore.HospitalCountByDRGFilter.month
						)
						selectAllOptionH.value = 'D-All'
					} else if (data.option?.value == 'D-All') {
						selectAllOptionH.value = 'All'
						zigna_aiStore.setHospitalCountByDRG('month', [])
					} else {
						console.log(e?.length,zigna_aiStore.HospitalCountByDRGFilter?.month?.length,"selection console")
						if (
							e?.length!==
								zigna_aiStore.HospitalCountByDRGFilter?.month?.length
								
						) {
							selectAllOptionH.value = 'All'
						} else {
							selectAllOptionH.value = 'D-All'
						}
                       
             
                       
						zigna_aiStore.setHospitalCountByDRG('month', e)
					}
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.month?.length
                                                ? [selectAllOptionH, ...(zigna_aiStore.HospitalCountByDRGFilter.month || [])]
                                                : []
                                        }
									
										
										isMulti
				getOptionLabel={(option) => `${option.name}`}
			
				
				allowSelectAll={true}
				components={{
					Option,
					MultiValue,
					animatedComponents,
				}}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
										
									/>
		
	
        <button
				type="button"
				disabled={
					zigna_aiStore.HospitalCountByDRGSelected.month == '' ||
					zigna_aiStore.HospitalCountByDRGSelected.month == undefined ||
					zigna_aiStore.HospitalCountByDRGSelected.month == null ||
					!zigna_aiStore.HospitalCountByDRGSelected.month?.length
				}
				className="NPI-submit btn btn-primary btn-sm"
				onClick={()=>{
					
					// zigna_aiStore.setfdrgFilterData('payers', null)
					// zigna_aiStore.setfdrgFilterData('billingCodes', null)
					// zigna_aiStore.setfdrgFilterData('billingCodeType', null)
					// selectAllOptionP.value='All';
					// selectAllOptionType.value='All';
					// selectAllOptionC.value='All';
                    zigna_aiStore.setHospitalCountByDRG('state', [])
                    zigna_aiStore.setHospitalCountByDRG('billing_code_type', [])
                    zigna_aiStore.setHospitalCountByDRG('provider_type', [])
                    selectAllOptionbC.value="All"
                    selectAllOptionPt.value="All"
                    selectAllOptions.value="All"
					 zigna_aiStore.getProviderDrgByHospital()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
            </div>

     
      
							
						</Col>
						

                        <Col className=" col-auto col-auto">
								
		<label className="form-label">Provider type <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.provider_type}
                onChange={(e, data) => {
					if (data.option?.value == 'All') {
						zigna_aiStore.setHospitalCountByDRG(
							'provider_type',
							zigna_aiStore.HospitalCountByDRGFilter.provider_type
						)
						selectAllOptionPt.value = 'D-All'
					} else if (data.option?.value == 'D-All') {
						selectAllOptionPt.value = 'All'
						zigna_aiStore.setHospitalCountByDRG('provider_type', [])
					} else {
						if (
							e?.length !=
								zigna_aiStore.HospitalCountByDRGFilter.provider_type?.length
						) {
							selectAllOptionPt.value = 'All'
						} else {
							selectAllOptionPt.value = 'D-All'
						}
						zigna_aiStore.setHospitalCountByDRG('provider_type', e)
					}
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.provider_type?.length
                                                ? [selectAllOptionPt, ...(zigna_aiStore.HospitalCountByDRGFilter.provider_type || [])]
                                                : []
                                        }
									
										
										isMulti
				getOptionLabel={(option) => `${option.name}`}
			
				
				allowSelectAll={true}
				components={{
					Option,
					MultiValue,
					animatedComponents,
				}}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
										
									/>
		
	
        <button
				type="button"
				disabled={
					zigna_aiStore.HospitalCountByDRGSelected.provider_type == '' ||
					zigna_aiStore.HospitalCountByDRGSelected.provider_type == undefined ||
					zigna_aiStore.HospitalCountByDRGSelected.provider_type == null ||
					!zigna_aiStore.HospitalCountByDRGSelected.provider_type?.length
				}
				className="NPI-submit btn btn-primary btn-sm"
				onClick={()=>{
					
					// zigna_aiStore.setfdrgFilterData('payers', null)
					// zigna_aiStore.setfdrgFilterData('billingCodes', null)
					// zigna_aiStore.setfdrgFilterData('billingCodeType', null)
					// selectAllOptionP.value='All';
					// selectAllOptionType.value='All';
					// selectAllOptionC.value='All';
                    zigna_aiStore.setHospitalCountByDRG('state', [])
                    zigna_aiStore.setHospitalCountByDRG('billing_code_type',[])
                  
                    selectAllOptionbC.value="All"
                   
                    selectAllOptions.value="All"
				zigna_aiStore.getcodeDrgByHospital()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
            </div>

     
      
							
						</Col>


                        <Col className=" col-auto col-auto">
								
		<label className="form-label">Billing code type <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.billing_code_type}
                onChange={(e, data) => {
					if (data.option?.value == 'All') {
						zigna_aiStore.setHospitalCountByDRG(
							'billing_code_type',
							zigna_aiStore.HospitalCountByDRGFilter.billing_code_type
						)
						selectAllOptionbC.value = 'D-All'
					} else if (data.option?.value == 'D-All') {
						selectAllOptionbC.value = 'All'
						zigna_aiStore.setHospitalCountByDRG('billing_code_type', [])
					} else {
						if (
							e?.length !=
								zigna_aiStore.HospitalCountByDRGFilter.billing_code_type?.length
								
						) {
							selectAllOptionbC.value = 'All'
						} else {
							selectAllOptionbC.value = 'D-All'
						}
						zigna_aiStore.setHospitalCountByDRG('billing_code_type', e)
					}
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.billing_code_type?.length
                                                ? [selectAllOptionbC, ...(zigna_aiStore.HospitalCountByDRGFilter.billing_code_type || [])]
                                                : []
                                        }
									
										
										isMulti
				getOptionLabel={(option) => `${option.name}`}
			
				
				allowSelectAll={true}
				components={{
					Option,
					MultiValue,
					animatedComponents,
				}}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
										
									/>
		
	
        <button
				type="button"
				disabled={
					zigna_aiStore.HospitalCountByDRGSelected.billing_code_type == '' ||
					zigna_aiStore.HospitalCountByDRGSelected.billing_code_type == undefined ||
					zigna_aiStore.HospitalCountByDRGSelected.billing_code_type == null ||
					!zigna_aiStore.HospitalCountByDRGSelected.billing_code_type?.length
				}
				className="NPI-submit btn btn-primary btn-sm"
				onClick={()=>{
					
					// zigna_aiStore.setfdrgFilterData('payers', null)
					// zigna_aiStore.setfdrgFilterData('billingCodes', null)
					// zigna_aiStore.setfdrgFilterData('billingCodeType', null)
					// selectAllOptionP.value='All';
					// selectAllOptionType.value='All';
					// selectAllOptionC.value='All';
                    zigna_aiStore.setHospitalCountByDRG('state', [])
                    
                   
                    selectAllOptions.value="All"
				 zigna_aiStore.getstateDrgByHospital()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
            </div>

     
      
							
						</Col>




                        <Col className=" col-auto col-auto">
								
		<label className="form-label">State <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
										
										className={`select-menu`}
				value={zigna_aiStore.HospitalCountByDRGSelected.state}
                onChange={(e, data) => {
					if (data.option?.value == 'All') {
						zigna_aiStore.setHospitalCountByDRG(
							'state',
							zigna_aiStore.HospitalCountByDRGFilter.state
						)
						selectAllOptions.value = 'D-All'
					} else if (data.option?.value == 'D-All') {
						selectAllOptions.value = 'All'
						zigna_aiStore.setHospitalCountByDRG('state', [])
					} else {
						if (
							e?.length !=
								zigna_aiStore.HospitalCountByDRGFilter.state?.length
						) {
							selectAllOptions.value = 'All'
						} else {
							selectAllOptions.value = 'D-All'
						}
						zigna_aiStore.setHospitalCountByDRG('state', e)
					}
				
				}}
										
									
										styles={customStyles}
										options={
                                            zigna_aiStore.HospitalCountByDRGFilter.state?.length
                                                ? [selectAllOptions, ...(zigna_aiStore.HospitalCountByDRGFilter.state || [])]
                                                : []
                                        }
									
										
										isMulti
				getOptionLabel={(option) => `${option.name}`}
			
				
				allowSelectAll={true}
				components={{
					Option,
					MultiValue,
					animatedComponents,
				}}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
										
									/>
		
	
            </div>

     
      
							
						</Col>
                        <Col className=" col-auto">
							<label className="form-label">{'   '}</label>
							<div className="d-flex align-item-center">
								<Button
									variant="primary"
									size="sm"
									disabled={
										!zigna_aiStore.HospitalCountByDRGSelected?.reporting_entity||
										!zigna_aiStore.HospitalCountByDRGSelected?.month.length||
										!zigna_aiStore.HospitalCountByDRGSelected?.provider_type.length||
										!zigna_aiStore.HospitalCountByDRGSelected?.billing_code_type.length||
										!zigna_aiStore.HospitalCountByDRGSelected?.state.length
									}
									className="submitFilter"
									onClick={(e) => {
										zigna_aiStore.getdDrgByHospitalCount(dataType)
									}}
									type="button"
								>
									Submit
								</Button>
							</div>
						</Col>
					</Row>
			
		)
	})
)
