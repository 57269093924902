import React, { useState, useEffect } from 'react'
import _debounce from 'lodash.debounce'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import {
	addString,
	getAvg,
	getBgColorBasedNum,
	toCurrency,
} from './../../../Utilities'
import { CommonFixedColTable } from '../common/CommonFixedColTable'
export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		const validateAvg = (avg) => {
			if (avg) {
				return addString(Number(avg.toFixed()), '', '%')
			} else {
				return '-'
			}
		}
		const [windowWidth, setwindowWidth] = useState(window.innerWidth)
		const [windowHeight, setwindowHeight] = useState(window.innerHeight)

		useEffect(() => {
			const handleResize = _debounce(() => setwindowHeight(window.innerHeight), 100)

			window.addEventListener('resize', handleResize);

			return () => {
			window.removeEventListener('resize', handleResize);
			}
		}, [])
		let payerTableColumn = [
			{
				Header: 'Hospital',
				sticky: 'left',
				columns: [
					{
						Header: '',
						accessor: 'Hospital',
						// sticky: 'left',
						Footer: (
							<div>
								<div>
									<span>Central NY Total:</span>
								</div>
								<div>
									<strong>Central NY Reported</strong>{' '}
								</div>
							</div>
						),
					},
				],
			},
			{
				Header: 'YYYY Total Allowed',
				sticky: 'left',

				columns: [
					{
						Header: '',
						accessor: 'YYYY_Total_Allowed',
						Cell: (props) => {
							return(
							<div className={`${props.row.original.isReported == 0 ? 'fw700':'fw500'}`}>{toCurrency(props.value, '$')}</div>
						)},
						Footer: (props) => {
							if (!props?.data?.length) return false
							let total
							let totalCostReport
							let header = props?.column?.id
							total = props?.data
								?.map((d) => d[header])
								.reduce((a, b) => Number(a) + Number(b))
							totalCostReport = props?.data
								?.filter((d) => d['isReported'] == 1)
								.map((d) => d[header])
								.reduce((a, b) => Number(a) + Number(b))
							return (
								<div>
									<div>{toCurrency(total, '$')}</div>
									<div>
										<strong>{toCurrency(totalCostReport, '$')}</strong>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},
			{
				Header: '% of EHP Regional Spend',
				sticky: 'left',
				columns: [
					{
						Header: '',
						accessor: 'EHP_Regional_Spend',
						Cell: (props) => (
							<div className={'fw500'}> {addString(props.value, '', '%')} </div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let total
							let header = props?.column?.id
							total =
								props?.data &&
								props?.data
									?.map((d) => d[header])
									.reduce((a, b) => Number(a) + Number(b))
							return (
								<div>
									<div></div>
									<div>
										<strong>{addString(total, '', '%')}</strong>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},
			{
				Header: 'Reported Service Year',
sticky: 'left',
				columns: [
					{
						Header: '',
						accessor: 'Reported_service_year',
						Footer: (
							<div>
								<div></div>
								<div>
									<strong>Weighted Avg:</strong>{' '}
								</div>
							</div>
						),
					},
				],
			},
			{
				Header: 'MVP',
				id: 'MVP',
				columns: [
					{
						Header: 'Inpatient',
						accessor: 'MVP_Inpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Outpatient',
						accessor: 'MVP_Outpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Total',
						accessor: 'MVP_Total',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},
			{
				Header: 'United',
				id: 'United',
				columns: [
					{
						Header: 'Inpatient',
						accessor: 'United_Inpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Outpatient',
						accessor: 'United_Outpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Total',
						accessor: 'United_Total',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},

			{
				Header: 'Aetna',
				id: 'Aetna',
				columns: [
					{
						Header: 'Inpatient',
						accessor: 'Aetna_Inpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Outpatient',
						accessor: 'Aetna_Outpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Total',
						accessor: 'Aetna_Total',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},
			{
				Header: 'Pomco',
				id: 'Pomco',
				columns: [
					{
						Header: 'Inpatient',
						accessor: 'Pomco_Inpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Outpatient',
						accessor: 'Pomco_Outpatient',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
					{
						Header: 'Total',
						accessor: 'Pomco_Total',
						Cell: (props) => (
							<div
								className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
									props.value
								)}`}
							>
								{' '}
								{props.value != '' ? addString(props.value, '', '%') : '-'}{' '}
							</div>
						),
						Footer: (props) => {
							if (!props?.data?.length) return false
							let header = props?.column?.id
							let avg = getAvg(props?.data, header)
							let result = validateAvg(avg)
							return (
								<div>
									<div></div>
									<div>
										<span
											className={`tableCellCustom tableCellBg_${getBgColorBasedNum(
												avg
											)}`}
										>
											{result}
										</span>{' '}
									</div>
								</div>
							)
						},
					},
				],
			},
		]

		return (
			<Card border="light" className="shadow-sm">
				<Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>Hospital Cost Differential Summary Report - YYYY</h5>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<div className="table-responsive" style={{height: windowHeight - 240}}>
						<CommonFixedColTable
							columns={payerTableColumn}
							// data={zigna_aiStore?.hospitalCostDiffList}
							data={zigna_aiStore?.hospitalCostDiffList}
							className="zignaAi_table_2"
						/>
					</div>
				</Card.Body>
			</Card>
		)
	})
)
