import competitiveIcn from "../../assets/img/zigna_ai/competitive_landscape_icn.png";

export const navData = (client) => {
  return [
    {
      id: 1,
      pageName: client?.isDemo ? "Summary" : "Competitive landscape",
      image: competitiveIcn,
      path: "/",
      accessTo: ["professional", "facility", "ancillary"],
    },
    {
      id: 2,
      pageName: "Top DRGs	overview",
      icon: "competitive_landscape_icn.png",
      path: "/topDrg",
      image: competitiveIcn,
      accessTo: ["facility", "ancillary"],
    },
    {
      id: 3,
      pageName: "Top CPTs	overview",
      icon: "competitive_landscape_icn.png",
      path: "/topCpt",
      image: competitiveIcn,
      accessTo: ["professional", "facility", "ancillary"],
    },
    {
      id: 4,
      pageName: "Payer price comparison CPT, HCPCS",
      icon: "competitive_landscape_icn.png",
      path: "/payersPriceCompareCPT-HCPCS",
      image: competitiveIcn,
      filterType: ["CPT", "HCPCS"],
      accessTo: ["facility", "ancillary"],
    },
    // {
    // 	id: 5,
    // 	pageName: 'Payer Price Comparison HCPCS',
    // 	icon: 'competitive_landscape_icn.png',
    // 	path: '/payersPriceCompare',
    // 	image: competitiveIcn,
    // 	filterType: ['CPT','HCPCS']
    // },
    {
      id: 6,
      pageName: "Payer price comparison DRG",
      icon: "competitive_landscape_icn.png",
      path: "/payersPriceCompareDRG",
      image: competitiveIcn,
      filterType: ["DRG"],
      hideModifier: true,
      accessTo: ["facility", "ancillary"],
    },
    {
      id: 7,
      pageName: "Payer price comparison APC",
      icon: "competitive_landscape_icn.png",
      path: "/payersPriceCompareAPC",
      image: competitiveIcn,
      filterType: ["APC"],
      accessTo: ["facility", "ancillary"],
    },

    {
      id: 10,
      pageName: "Providers CPT extract",
      icon: "competitive_landscape_icn.png",
      path: "/providersCPTExtract",
      image: competitiveIcn,
      filterType: ["CPT"],
      accessTo: ["professional"],
    },
    {
      id: 11,
      pageName: "Providers HCPCS extract",
      icon: "competitive_landscape_icn.png",
      path: "/providersHCPCSExtract",
      image: competitiveIcn,
      filterType: ["HCPCS"],
      accessTo: ["professional"],
    },
    {
      id: 8,
      pageName: "Filtered DRG list",
      icon: "competitive_landscape_icn.png",
      path: "/filteredDrgList",
      image: competitiveIcn,
      accessTo: ["facility", "ancillary"],
    },

    {
      id: 13,
      pageName: "Payer Scorecard",
      image: competitiveIcn,
      path: "/",
      accessTo: ["dataAuditReport"],
    },
    {
      id: 14,
      pageName: "Report summary",

      path: "/report",
      accessTo: ["dataAuditReport"],
    },
    {
      id: 12,
      pageName: "Provider CPT/HCPCS data",
      icon: "competitive_landscape_icn.png",
      path: "/providersExtractCPT",
      image: competitiveIcn,
      filterType: ["CPT"],
      accessTo: ["ancillary", "facility"],
    },
    // {
    // 	id: 12,
    // 	pageName: 'Providers HCPCS extract',
    // 	icon: 'competitive_landscape_icn.png',
    // 	path: '/providersExtractHCPCS',
    // 	image: competitiveIcn,
    // 	filterType: ['HCPCS'],
    // 	accessTo: ["ancillary"]
    // },
    {
      id: 12,
      pageName: "Provider CPT/HCPCS charts",
      icon: "competitive_landscape_icn.png",
      path: "/providersExtractCPTChart",
      image: competitiveIcn,
      filterType: ["HCPCS"],
      accessTo: ["ancillary", "facility"],
    },
    {
      id: 12,
      pageName: "Procedure cost by hospital",
      icon: "competitive_landscape_icn.png",
      path: "/procedureCostByHospital",
      image: competitiveIcn,
      filterType: [],
      accessTo: ["dataAuditReport", "ancillary"],
    },
    {
      id: 9,
      pageName: "DRG counts by payer & hospital",
      icon: "competitive_landscape_icn.png",
      path: "/drgRefRable",
      image: competitiveIcn,
      accessTo: ["dataAuditReport"],
    },

    //   {
    // 	id: 15,
    // 	pageName: 'DRG codes by Hospital',

    // 	path: '/drgcodesbyhospital',
    // 	accessTo: ['dataAuditReport'],
    //   },
    //   {
    // 	id: 16,
    // 	pageName: 'DRG codes by Hosptial & Negotiated type',

    {
      id: 18,
      pageName: "DRG cost breakdown",
      image: competitiveIcn,
      path: "/DRGcostBreakdown",
      accessTo: ["facility", "ancillary"],
    },
    {
      id: 19,
      pageName: "Facility Scorecard",
      image: competitiveIcn,
      path: "/facilityScoreCard",
      accessTo: ["facility", "ancillary"],
    },
    {
      id: 20,
      pageName: "Professional Rate View",
      image: competitiveIcn,
      path: "/Ratechoiceview",
      accessTo: ["professional"],
    },

    {
      id: 21,
      pageName: "Professional Rate Utilization View",
      image: competitiveIcn,
      path: "/ProfessionalRateUtilizationview",
      accessTo: ["professional"],
    },
    {
      id: 21,
      pageName: "Professional Rate View By TIN",
      image: competitiveIcn,
      path: "/RatechoiceviewByTIN",
      accessTo: ["professional"],
    },
    {
      id: 21,
      pageName: "Professional Rate Utilization View By TIN",
      image: competitiveIcn,
      path: "/ProfessionalRateUtilizationviewByTIN",
      accessTo: ["professional"],
    },
    {
      id: 29,
      pageName: "Provider Tax ID Insights",
      image: competitiveIcn,
      path: "/RateInsightsByTIN",
    },

    {
      id: 17,
      pageName: "Historical Requests",
      image: competitiveIcn,
      path: "/historicalrequests",
      accessTo: ["professional", "facility", "ancillary"],
    },

    // 	path: '/drgbyhospitalandnegotiation',
    // 	accessTo: ['dataAuditReport'],
    //   },

    // {
    // 	id: 5,
    // 	pageName: 'Payer Hospital Comparison',
    // 	icon: 'competitive_landscape_icn.png',
    // 	path: '/payerHosComp',
    // 	image: competitiveIcn,
    // },
    // {
    // 	id: 5,
    // 	pageName: 'Cost Differential Report',
    // 	icon: 'competitive_landscape_icn.png',
    // 	path: '/costDiffReport',
    // 	image: competitiveIcn,
    // },
  ];
};
