import React from 'react';


import { useFlexLayout, useResizeColumns, useTable } from 'react-table';



export const CommonTable = ({ columns, data, className, initialState = {},sticky ,stickyTop}) => {
	// Use the state and functions returned from useTable to build your UI
	const defaultColumn = React.useMemo(
		() => ({
		  maxWidth: 200 // maxWidth is only used as a limit for resizing
		}),
		[]
	  );
	const { getTableProps, getTableBodyProps, headerGroups, rows, footerGroups,prepareRow } =
		useTable({
			columns,
			data,
			defaultColumn,
      initialState
		},
		useResizeColumns,
    	useFlexLayout,)

	const rowOnClick = (row) => {
		console.log(`clicked ${row.type} for Strike ${row.strike}`)
	}
	const headerProps = (props, { column }) => getStyles(props, "center");

	const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
	
	const getStyles = (props, align = "left") => [
	  props,
	  {
		style: {
      justifyContent:align=="left"?"flex-start":align=="center"?"center":"flex-end",
		  alignItems: "flex-start",
		  display: "flex",
		  border:"1px solid #D3D3D3",
      fontSize:props.fontSize||12
		}
	  }
	];
	
	// Render the UI for your table
	return (
		
		<div className='parentTable'>
		<div {...getTableProps()} className={`table table-borderless ${className} zignaAi_newTable`} >
      <div style={sticky?{position:"sticky",top:stickyTop,zIndex:10}:{}}>
        {headerGroups.map(headerGroup => (
          <div
            {...headerGroup.getHeaderGroupProps({
              // style: { paddingRight: '15px' },
            })}
            
            className="tr"
          >
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps(headerProps)} className="th">
                {column.render('Header')}
                {/* Use column.getResizerProps to hook up the events correctly */}
                {column.canResize && (
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody" {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <div {...row.getRowProps()} className="tr" >
              {row.cells.map(cell => {
				const [target, column] = cell.column.id.split('.')
                return (
                  <div {...cell.getCellProps(cellProps)} className="td" onClick={column === 'strike'? undefined: () => rowOnClick(cell.row.original[target])} >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
	</div>
	)
}
