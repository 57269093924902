import React, { useState } from 'react'
import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { toCurrency } from '../../../Utilities'
import { ResponsiveBar } from '@nivo/bar'
import { convertPrice } from './../../../Utilities'
export default inject('zigna_aiStore')(
	observer(({ dataField, zigna_aiStore ,color,disbaledClick}) => {
		const [selectedBar,setSelected]=useState("")
		const DataLabelOutside = ({ bars }) => {
			return bars.map((bar) => {
				const {
					key,
					width,
					height,
					x,
					y,
					data: { value },
				} = bar
				var canvas = document.createElement("canvas");
				var ctx = canvas.getContext("2d");
				var textWidth = ctx.measureText(convertPrice(value)).width;
				return (
					<g key={key} transform={`translate(${x}, ${y})`}>
						<text
							transform={`translate(${width + textWidth}, ${height / 2 + 5})`}
							textAnchor="middle"
							fontSize=".9rem"
						>
							{/* YOUR LABEL HERE */}
							{convertPrice(value)}
						</text>
					</g>
				)
			})
		}
		const getTspanGroups = (value, maxLineLength, maxLines = 2) => {
        const words = value.split(' ')

        //reduces the words into lines of maxLineLength
        const assembleLines = words.reduce( (acc, word) => {
            //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
            if ( (word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
                return {
                    lines: acc.lines.concat([acc.currLine]),
                    currLine: word
                }
            }
            //otherwise add the word to the current line
            return {
                ...acc,
                currLine: acc.currLine + ' ' + word 
            } 
            
        }, {lines: [], currLine: ''})

        //add the ending state of current line (the last line) to lines
        const allLines = assembleLines.lines.concat([assembleLines.currLine])

        //for now, only take first 2 lines due to tick spacing and possible overflow
        const lines = allLines.slice(0, maxLines)
        let children = []
        let dy = 0

        lines.forEach( (lineText, i) => {
            children.push(
                <tspan x={0} dy={dy} key={i}>
                    {
                        // if on the second line, and that line's length is within 3 of the max length, add ellipsis
                        (1 === i && allLines.length >2) ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
                    }
                </tspan> 
            )
            //increment dy to render next line text below
            dy += 15
        });

        return children
    }
	const getL4List=(l4description)=>{
if(disbaledClick){
	return
}

		setSelected(l4description)
		if(dataField=="topProcedures")
		zigna_aiStore.gettopProceduresL4(l4description)
	else
	if(dataField=="topProceduresNotPref")
	zigna_aiStore.gettopProceduresNonPrefL4(l4description)

	}

		const sortedData = ([...(zigna_aiStore?.topOverviewList[dataField] || [])]).sort((a, b) => a.price - b.price)
		return (
			<div style={{ height: '700px' }}>
				<ResponsiveBar
					theme={{
						fontSize: '.9rem',
						axis: {
							domain: {
								line: {
									stroke: '#777777',
									strokeWidth: 1,
								},
							},
						},
					}}
					layers={[
						'grid',
						'axes',
						'bars',
						'markers',
						'legends',
						'annotations',
						DataLabelOutside,
					]}
					enableLabel={false}
					data={sortedData}
					onClick={data=>getL4List(data.data.procedureName)}
					keys={['price']}
					indexBy="procedureName"
					borderRadius={5}
					margin={{ top: 5, right: 80, bottom: 50, left: 320 }}
					padding={0.6}
					layout="horizontal"
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					// colors={['#8B6CBC']}
					colors={(bar) => (bar.data.procedureName== selectedBar? '#9E2377' : (color||'#8B6CBC'))}
					enableGridX={false}
					enableGridY={false}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 1.6]],
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={null}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: -40,
						 renderTick: ({
										opacity,
										textAnchor,
										textBaseline,
										textX,
										textY,
										theme,
										value,
										x,
										y
									}) => {
										return (
										<g
											transform={`translate(${x},${y})`}
											style={{ opacity }}
										>
											<text
											alignmentBaseline={textBaseline}
											// style={theme.axis.ticks.text}
											textAnchor={textAnchor}
											transform={`translate(${textX},${textY})`}
											>
											{getTspanGroups(value, 32.5)}
											</text>
										</g>
										)
									}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{
						from: 'color',
						modifiers: [['darker', 1.6]],
					}}
					legends={[]}
					barAriaLabel={function (e) {
						return (
							e.id + ': ' + e.formattedValue + ' in payerName: ' + e.indexValue
						)
					}}
					tooltip={(bar, color, label) => {
						return <b style={{ background: 'white'}}>{bar.indexValue}: {convertPrice(bar.value)}</b>
					}}
				/>
			</div>
		)
	})
)
