import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'

import CategoryWidgets from '../../components/ZignaAi/competitiveLandscape/CategoryWidgets'

import { inject, observer } from 'mobx-react'
import StateMapWidget from '../../components/ZignaAi/competitiveLandscape/StateMapWidget'
import RegionalMapWidget from './../../components/ZignaAi/competitiveLandscape/RegionalMapWidget'
import PayerListWidget from './../../components/ZignaAi/competitiveLandscape/PayerListWidget'
import FilterWidget from '../../components/ZignaAi/competitiveLandscape/FilterWidget'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let location = useLocation();
		
		useEffect(() => {
			 zigna_aiStore.getListOfPayers()
			 zigna_aiStore.getCompetitiveLandscape()
		},[location, zigna_aiStore.userType])
		return (
			<>
				<Row className="justify-content-md-center">
						<Col xs={12} xl={12} className="mb-4">
							<FilterWidget filterParent={'competitiveLandscape'} preferences={{ isPayersMultiselect: true }} />
						</Col>
				</Row>
				<Row className="justify-content-md-center">
					<CategoryWidgets />
				</Row>

				<Row>
					<Col xs={12} xl={12} className="mb-5">
						<Row>
							<Col xs={12} xl={6} className="mb-4">
								<Row>
									<Col xs={12} className="mb-4">
										{/* <PageVisitsTable /> */}
										<StateMapWidget />
									</Col>
								</Row>
							</Col>

							<Col xs={12} xl={6}>
								<Row>
									<Col xs={12} className="mb-4">
										<RegionalMapWidget />
									</Col>
								</Row>
							</Col>
							<Col xs={12} xl={12}>
								<PayerListWidget />
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		)
	})
)
