import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import FilterWidget from "../../components/ZignaAi/DrgReferenceTable/FilterWidget";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PriceComparision from "../../components/ZignaAi/DrgReferenceTable/PriceComparision";

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    return (
      <>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <FilterWidget />
          </Col>
          <Row
            className={` ${
              zigna_aiStore.filteredDRGData.length == 0 && "blurDiv"
            } m-0 p-0`}
          >
            <Col xs={12} xl={12} className="mb-4">
              <PriceComparision db={authStore?.currentUser?.db_name} />
            </Col>
          </Row>
        </Row>
      </>
    );
  })
);
