import React from 'react'
import { Card, Col, Row } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { useEffect ,useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import PriceComparision from '../../components/ZignaAi/providerExtractCharts/PriceComparision'
import FilterWidget from '../../components/ZignaAi/providerExtractCharts/FilterWidget'
import "./custom.css"
export default inject(
	'zigna_aiStore',
	'providerPriceCompareStore',
	'authStore'
)(
	observer(({ zigna_aiStore, providerPriceCompareStore, authStore }) => {

		let location = useLocation();
		const history = useHistory();
		const [filterSet,setFilterSet]=useState([])
		const [billingCodeType,setBillingCodeType]=useState("CPT");
  		const { filterType = [], hideModifier = false } = location.state || {};
		useEffect(() => {
			if (!filterType.length) history.push("/");
		}, [])

		useEffect(() => {
			// providerPriceCompareStore.resetCategoryExtractResults()
			// providerPriceCompareStore.resetFilterData()
			
            zigna_aiStore.getProviderExtractFilters({billingCodeType:filterType[0],filters:[]})
			
			zigna_aiStore?.resetProviderExtractChartResult()
			//providerPriceCompareStore.getRegionList()
		}, [location])
		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4" style={{zIndex:2}}>
						<FilterWidget 
							actionFunc={providerPriceCompareStore.getCategoryExtractResults} 
						
							billingCodeType={billingCodeType}
							setBillingCodeType={setBillingCodeType}
							hideModifier={hideModifier}
							showExport={false}
							showAggMeasure={false}
							hideProcCategoty={true}
							filterSet={filterSet}
							setFilterSet={setFilterSet}
						/>
					</Col>
					
				</Row>
				<Row className={`m-0 p-0 ${(filterSet?.length ==0||zigna_aiStore?.providerExtractChartResult.length==0)&&'blurDiv'}`}>
							<Col xs={12} xl={12} className="mb-4">
							<PriceComparision 
							billingCodeType={billingCodeType}
							setBillingCodeType={setBillingCodeType} 	filterSet={filterSet} />
							</Col>
							{
								(filterSet?.length ==0||zigna_aiStore?.providerExtractChartResult.length==0)&&
								<div className='blurOverlay'><h5>Please select Filter</h5></div>
							}
						
						</Row>
	
			</>
		)
	})
)
