import React from 'react'
import { Card, Col, Row } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { useEffect ,useState} from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import PriceComparision from '../../components/ZignaAi/providerExtract/PriceComparision'
import PriceComparisionChart from '../../components/ZignaAi/providerExtractCharts/PriceComparision'
import FilterWidget from '../../components/ZignaAi/providerExtract/FilterWidget'

export default inject(
	'zigna_aiStore',
	'providerPriceCompareStore',
	'authStore'
)(
	observer(({ zigna_aiStore, providerPriceCompareStore, authStore }) => {

		let location = useLocation();
		const history = useHistory();
		const [filterSet,setFilterSet]=useState([])
		const [billingCodeType,setBillingCodeType]=useState("CPT");
		const [activeMode,setActiveMode]=useState("datasheet")
  		const { filterType = [], hideModifier = false } = location.state || {};
		useEffect(() => {
		
			if (!filterType.length) history.push("/");
		}, [])

		useEffect(() => {
		
            zigna_aiStore.getProviderExtractFilters({billingCodeType:billingCodeType,filters:[]})
			setFilterSet([])
			zigna_aiStore?.resetproviderExtractResult()
		}, [location])
		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4" style={{zIndex:2}}>
						<FilterWidget 
							actionFunc={providerPriceCompareStore.getCategoryExtractResults} 
							activeMode={activeMode}
							setActiveMode={setActiveMode}
							billingCodeType={billingCodeType}
							setBillingCodeType={setBillingCodeType}
							hideModifier={hideModifier}
							showExport={false}
							showAggMeasure={false}
							hideProcCategoty={true}
							filterSet={filterSet}
							setFilterSet={setFilterSet}
						/>
					</Col>
					<Row className={`m-0 p-0 ${zigna_aiStore?.providerExtractResult.length==0&&'blurDiv'}`}>
							<Col xs={12} xl={12} className="mb-4">
								{activeMode=="datasheet"?<PriceComparision billingCodeType={billingCodeType}
							setBillingCodeType={setBillingCodeType} 	filterSet={filterSet} />:
							<PriceComparisionChart
							billingCodeType={billingCodeType}
							setBillingCodeType={setBillingCodeType} 	filterSet={filterSet} />}
							</Col>
							{
								filterSet?.length ==0&&
								<div className='blurOverlay'><h5>Please select Filter</h5></div>
							}
						
						</Row>
				</Row>
			</>
		)
	})
)
