import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from '@themesberg/react-bootstrap';
import { inject, observer } from 'mobx-react';
import { PasswordReset } from './PasswordReset';
import { useLocation } from 'react-router-dom';
import { SEPARATOR } from '../../constants';

const useQuery = () => new URLSearchParams(window?.location?.search);

export default inject('authStore','zigna_aiStore')(
  observer(({ authStore ,zigna_aiStore}) => {
    const query = useQuery();

    useEffect(() => {
      zigna_aiStore.userType=null
      // Case where someone lands on login page with token as "?code="
      const token = query.get('code');
      const data = query.get('data')
      if (token) {
        authStore.validateMicrosoftToken(token);
      }
      if (data && atob(data)) {
        const [user = '', pass = ''] = (atob(data) || '').split(SEPARATOR)
        authStore.setUsername(user)
        authStore.setPassword(pass)
        authStore.login();
      }
    }, [])

    useEffect(() => {
      document.body.classList.add('login_page_bg');
      return () => document.body.classList.remove('login_page_bg');
    }, [authStore.username]);
    const clientName = () => {
      let hostname = window.location.hostname;
      const [one, two] = hostname.split('.') || [];
      return one === 'www' ? two : one;
    };
    const [logoImg, setlogoImg] = useState('');

    useEffect(() => {
      loadImage(clientName());
    }, []);

    let loadImage = (imageName) => {
      import(`../../assets/img/zigna_ai/logo_${imageName}.png`)
        .then((image) => {
          setlogoImg(image.default);
        })
        .catch(() => {
          setlogoImg('')
        });
    };
    return (
      <main>
        <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5 authPage'>
          <Container>
            {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
            <Row className='justify-content-center form-bg-image'>
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  {false ? (
                    <div className='d-flex align-items-center justify-content-center'>
                      <Image
                        height={70}
                        src={logoImg}
                        alt=''
                      />
                    </div>
                  ) :  <div className='d-flex align-items-center  justify-content-center flex-column pb-4' > <h3 style={{color:"#3D2260",marginBottom:0,letterSpacing:4}}> RightPx</h3><small style={{fontSize:10,marginLeft:32}}>Powered By ZignaAI</small></div>}
                  {
                    <div className='text-center text-md-center mb-4 mt-md-0'>
                      {authStore.currentUser?.isFirstTimeLogin ? (
                        <>
                          <h4 className='mb-0'>
                            Hi {authStore.currentUser?.name}
                          </h4>
                          <h6 className='mb-0'>Please reset your password</h6>
                          <br></br>
                          <h7 className='mb-0'>
                            Note: This screen will keep appearing untill the
                            password is changed after first time login.
                          </h7>
                        </>
                      ) : (
                        <h5 className='mb-0'>Sign In To Discover Price Transparency Insights.</h5>
                      )}
                    </div>
                  }
                  {authStore.currentUser?.isFirstTimeLogin ? (
                    <PasswordReset />
                  ) : (
                    <Form className='mt-4'>
                      <Form.Group
                        id='username'
                        className='mb-4'
                      >
                        <Form.Label>User Name</Form.Label>
                        <InputGroup
                          className={`${
                            authStore.username == '' && 'invalid_input'
                          }`}
                        >
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUser} />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            required
                            type='username'
                            placeholder='User Name'
                            value={authStore.username==null? '':authStore.username}
                            isInvalid={authStore.username==null?false:authStore.username == ''}
                            onChange={(e) =>
                              authStore.setUsername(e.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Group
                          id='password'
                          className='mb-4'
                        >
                          <Form.Label>Password</Form.Label>
                          <InputGroup
                            className={`${
                              authStore.password == '' && 'invalid_input'
                            }`}
                          >
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type='password'
                              placeholder='Password'
                              value={authStore.password==null? '':authStore.password}
                              isInvalid={authStore.password==null?false:authStore.password == ''}
                              onChange={(e) =>
                                authStore.setPassword(e.target.value)
                              }
                            />
                          </InputGroup>
                        </Form.Group>
                      </Form.Group>
                      <span className='text-danger'>
                        {authStore.loginError}
                      </span>
                      <Button
                        variant='primary'
                        type='button'
                        className='w-100'
                        onClick={() => {
                          authStore.login();
                        }}
                      >
                        {authStore.isLoginLoaderEnable ? (
                          <>
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            ></span>{' '}
                            Loading...
                          </>
                        ) : (
                          'Sign in'
                        )}
                      </Button>

                     {false && ( <Button
                        variant='secondary'
                        type='button'
                        className='w-100 mt-1'
                        onClick={() => {
                          window.location.href = process.env.REACT_APP_AUTH_URL;
                        }}
                      >
                          Sign in with Microsoft
                      </Button>)}
                    </Form>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  })
);
