import React, { useEffect } from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from '../../ui/Dropdown'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'




let selectAllOptionC = { id: 0, name: 'Select All', value: 'All'};
let selectAllOptionCode = { id: 0, name: 'Select All', value:'All'}
const customStyles = {
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	menuPortal: base => ({ ...base, zIndex: 999999 }),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}
export default inject('zigna_aiStore')(
	observer(({zigna_aiStore, hideModifier }) => {
	
		
		let location = useLocation();
		useEffect(() => {
		     zigna_aiStore.getDRGcostProviderName()
			 zigna_aiStore.getDRGcostallentity()
			zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
			zigna_aiStore.setDrgBreakdownSelected('provider_name', null)
			zigna_aiStore.setDrgBreakdownSelected('code_category',null)
			zigna_aiStore.drgcostbreakdownData=[]
			zigna_aiStore.resetCostBreakdownFiterList()
		
	
		
			
		}, [location])

		const Option = (props) => {
			return props.data.id == '0' ? (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.data.value != 'All' ? true : false}
							onChange={() => null}
						/>{' '}
						<label>{props.label} </label>
						
					</components.Option>
				</div>
			) : (
				<div>
					<components.Option {...props}>
						<input
							type="checkbox"
							checked={props.isSelected}
							onChange={() => null}
						/>{' '}
						<label>{props.label}</label>						
					</components.Option>
				</div>
			)
		}


	
	

		const MultiValue = ({ index, getValue, ...props }) => {
			const maxToShow = 0
			const length = getValue().length

			return index < maxToShow ? (
				<components.MultiValue {...props} />
			) : index === maxToShow ? (
				`${length} Values selected`
			) : null
		}

		const animatedComponents = makeAnimated()

		return (
			<Card border="light" className="shadow-sm filterSection">
				<Card.Body>
					<h5>
						Filter{' '}
					</h5>
					<Row>
					<Col className=" col-auto col-auto">
						<div className="mb-3">
								<label className="form-label">Comparing entity <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
									
										className={`select-menu`}
										value={zigna_aiStore.drgCostBreakdownSelected.comparingEntity}
										onChange={(e, data) => {
											
												zigna_aiStore.setDrgBreakdownSelected('comparingEntity', e)
											
												zigna_aiStore.setDrgBreakdownSelected('provider_name', null)
												zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
												zigna_aiStore.drgcostbreakdownData=[]
												zigna_aiStore.getDRGcostProviderName()
										
										}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.drgCostBreakdownFilters.allEntities?.length
												? zigna_aiStore.drgCostBreakdownFilters.allEntities 
												: []
										}
									
										
										
										
									/>
								</div>
							</div>
						</Col>
					<Col className=" col-auto col-auto">
						<div className="mb-3">
								<label className="form-label">Provider name <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
									 menuPortalTarget={document.body}
										className={`select-menu`}
										value={zigna_aiStore.drgCostBreakdownSelected.provider_name}
										onChange={(e, data) => {
											
												zigna_aiStore.setDrgBreakdownSelected('provider_name', e)
												zigna_aiStore.setDrgBreakdownSelected('code_category', null)
												zigna_aiStore.drgcostbreakdownData=[]
											
											zigna_aiStore.getDRGcodeCategory()
											
										}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.drgCostBreakdownFilters.provider_name?.length
												? zigna_aiStore.drgCostBreakdownFilters.provider_name 
												: []
										}
									
										
										
										
									/>
								</div>
							</div>
						</Col>

						<Col className=" col-auto col-auto">
						<div className="mb-3">
								<label className="form-label">Code category <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
									
										className={`select-menu`}
										value={zigna_aiStore.drgCostBreakdownSelected.code_category}
										onChange={(e, data) => {
                                            
						zigna_aiStore.setDrgBreakdownSelected('code_category', e)
					
					zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
					zigna_aiStore.drgcostbreakdownData=[]
					
											
												
												
											
										}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											zigna_aiStore.drgCostBreakdownFilters.code_category?.length
												?zigna_aiStore.drgCostBreakdownFilters.code_category  
												: []
										}
									
										isMulti
									
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
												
										
										
									/>
			<button
				type="button"
				className="NPI-submit btn btn-primary btn-sm"
				disabled={!zigna_aiStore.drgCostBreakdownSelected.code_category?.length}
				onClick={()=>{
					zigna_aiStore.setDrgBreakdownSelected('reporting_entity', null)
					zigna_aiStore.drgcostbreakdownData=[]
				
				    zigna_aiStore.getDDRGcostreportingEntity()
				}}
			>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="dropdown-arrow"
					size="lg"
				/>
			</button>
								</div>
							</div>
						</Col>
						
						<Col className=" col-auto col-auto">
		 <div className="mb-3">								
		<label className="form-label">Reporting entity name <span className='text-danger'>*</span></label>
		<div className="d-flex align-items-center h45 NPI-select">
		<Select
						 menuPortalTarget={document.body} 				
										className={`select-menu`}
				value={zigna_aiStore.drgCostBreakdownSelected.reporting_entity}
				onChange={(e, data) => {
					
					
						zigna_aiStore.setDrgBreakdownSelected('reporting_entity', e)
					
				
				}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										
										options={
											zigna_aiStore.drgCostBreakdownFilters.reporting_entity?.length
												?zigna_aiStore.drgCostBreakdownFilters.reporting_entity 
												: []
										}
									
										
										isMulti
									
										allowSelectAll={true}
										
										closeMenuOnSelect={false}
										hideSelectedOptions={false}
																
										
									/>
		
		</div>
	
	</div>
     
      
							
						</Col>
					
						
						
						<Col className=" col-auto">
							<label className="form-label">{'   '}</label>
							<div className="d-flex align-item-center">
								<Button
								   disabled={
									!zigna_aiStore.drgCostBreakdownSelected.provider_name||
									!zigna_aiStore.drgCostBreakdownSelected.reporting_entity?.length||
									!zigna_aiStore.drgCostBreakdownSelected.code_category?.length
								   }
									variant="primary"
									size="sm"
									className="submitFilter"
									onClick={(e) => {
										zigna_aiStore.getDRGcostbreakdown()
									}}
									type="button"
								>
									Submit
								</Button>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		)
	})
)
