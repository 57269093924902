import React, { useCallback } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import FilterWidget from "../../components/ZignaAi/RateInsightsByTin/FilterWidget";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DataTable from "../../components/ZignaAi/RateInsightsByTin/DataTable";
import RateInsight from "./index";
import { getYearFromString, getMonthFromString } from "../../Utilities";
export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    const [selectedPayor, setSelectedPayor] = useState(null);
    let location = useLocation();
    const [error, setError] = useState(false);
    const [filterSet, setFilterSet] = useState([]);
    const [payerFilter, setPayerFilter] = useState([]);
    const [includeCMS, setIncludeCMS] = useState(true);
    const [insightsData, setInsightsData] = useState(null)

    useEffect(() => {
      zigna_aiStore.rateInsightsData = {};
      zigna_aiStore.professionalType = [];
      zigna_aiStore.professionalOptionalFilter = {};
      setFilterSet([]);
      setPayerFilter([
        {
          field: "rate_type",
          value: {
            // id:2,
            name: "Non Facility",
            value: "Non_Facility",
          },
        },
        {
          field: "most_frequent_rate",
          value: {
            name: "All NPI's",
            value: "all",
          },
        },
        {
          field: "multiple_rates_reported",
          value: {
            name: "All Rates",
            value: "all",
          },
        },
      ]);
    }, [location]);
    useEffect(() => {
      zigna_aiStore.rateInsightsData = {};
      setSelectedPayor(null);
      setError(false);
    }, [filterSet]);

    useEffect(()=>{
      zigna_aiStore.rateInsightsData = {}
      setInsightsData(null)
      zigna_aiStore.fetchUserFilters()
      .then(userFilters=>{
        let dbname = authStore?.currentUser?.db_name
        userFilters = userFilters?.find(f=>f.page_name === 'rateInsights' && dbname===f.dbname)
        console.log('userFilters', userFilters)
        if(userFilters && JSON.parse(userFilters?.saved_filters)?.filterSet){
          setFilterSet(JSON.parse(userFilters.saved_filters)?.filterSet)
        }
        if(userFilters && JSON.parse(userFilters?.saved_filters)?.data)
          setInsightsData(JSON.parse(userFilters.saved_filters).data)
      })
    },[authStore?.currentUser?.db_name])

    useEffect(()=>{},[zigna_aiStore.rateInsightsData.allClients])
    const handleSaveFilters = useCallback(()=>{
      zigna_aiStore.saveUserFilters({ page_name:"rateInsights", saved_filters:{filterSet, data: zigna_aiStore.rateInsightsData} })
    },[zigna_aiStore.rateInsightsData])

    let currentDb = (authStore?.currentUser?.perms?.dblist || []).find(
      (i) => i.dbname == authStore?.currentUser?.db_name
    );

    if (
      getYearFromString(currentDb.label) < 23 ||
      (getYearFromString(currentDb.label) == 23 &&
        getMonthFromString(currentDb.label) < 10)
    )
      return (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
          <h3>Feature not available for current database</h3>

          <p style={{ fontSize: 18 }}>
            This feature is available for november 23 or later database.
          </p>
        </div>
      );
    else
      return (
        <>
          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <FilterWidget
                filterSet={filterSet}
                setFilterSet={setFilterSet}
                setError={() => setError(true)}
                includeCMS={includeCMS}
                setIncludeCMS={setIncludeCMS}
                onSubmit={() => setSelectedPayor(null)}
                handleSaveFilters={handleSaveFilters}
                setInsightsData={setInsightsData}
                setPayerFilter={setPayerFilter}
                payerFilter={payerFilter}
              />
            </Col>
            <Row className={`m-0 p-0`}>
              <Col xs={12} xl={12} className="mb-2">
               { insightsData &&
                  <DataTable
                    dataset={insightsData}
                    filterSet={filterSet}
                    zigna_aiStore={zigna_aiStore}
                    onPayerSelect={(payor) => setSelectedPayor(payor)}
                    defaultPayer={authStore?.currentUser?.default_payer}
                    includeCMS={includeCMS}
                  />
               }
                
              </Col>
              {selectedPayor && (
                <Col style={{ minHeight: 500 }}>
                  {/* <h6>Selected Payor: {selectedPayor}</h6> */}
                  <RateInsight
                    selectedPayor={selectedPayor}
                    filterSet1={[
                      {
                        field: "tin",
                        value: [filterSet.find((i) => i.field == "tin")?.value],
                      },
                      {
                        field: "rate_type",
                        value: payerFilter.find((i) => i.field === "rate_type")
                          ?.value,
                      },
                      {
                        field: "most_frequent_rate",
                        value: payerFilter.find((i) => i.field === "most_frequent_rate")
                          ?.value,
                      },
                      {
                        field: "multiple_rates_reported",
                        value: payerFilter.find((i) => i.field === "multiple_rates_reported")
                          ?.value,
                      },
                    ]}
                    includeCMS={includeCMS}
                    setIncludeCMS={setIncludeCMS}
                  />
                </Col>
              )}
            </Row>
          </Row>
        </>
      );
  })
);
