import React from 'react';


import { useFlexLayout, useResizeColumns, useTable } from 'react-table';
import { useSticky } from "react-table-sticky";
function useInstance(instance) {
    const { allColumns } = instance;
  
    let rowSpanHeaders = [];
  
    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column;
  
      if (enableRowSpan !== undefined) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 }
        ];
      }
    });
  
    Object.assign(instance, { rowSpanHeaders });
  }

export const CommonTable = ({ columns, data, className,sticky ,stickyTop}) => {
	// Use the state and functions returned from useTable to build your UI
	const defaultColumn = React.useMemo(
		() => ({
		  maxWidth: 200 // maxWidth is only used as a limit for resizing
		}),
		[]
	  );
	const { getTableProps, getTableBodyProps, headerGroups, rows, footerGroups,prepareRow, rowSpanHeaders } =
		useTable({
			columns,
			data,
			defaultColumn
		}
        , hooks => {
            hooks.useInstance.push(useInstance);
          },
		useResizeColumns,
    useSticky,
    	useFlexLayout,)

	const rowOnClick = (row) => {
		console.log(`clicked ${row.type} for Strike ${row.strike}`)
	}
	const headerProps = (props, { column }) => getStyles(props, "center");

	const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
	
	const getStyles = (props, align = "left") => [
	  props,
	  {
		style: {
		  justifyContent:align=="left"?"flex-start":align=="center"?"center":"flex-end",
		  alignItems: "flex-start",
		  display: "flex",
		  border:"1px solid #D3D3D3"
		}
	  }
	];
	
	// Render the UI for your table
	return (
		
		<div className='parentTable' style={{overflow:"none"}}>
		<table style={{position:"relative"}}  {...getTableProps()} className={`table table-borderless ${className} zignaAi_newTable`}>
      <thead style={sticky?{position:"sticky",top:stickyTop>-1?stickyTop:58,zIndex:20}:{}}>
        {headerGroups.map(headerGroup => (
          <div
            {...headerGroup.getHeaderGroupProps({
              // style: { paddingRight: '15px' },
            })}
            className="tr"
          >
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps(headerProps)} className="th" >
                {column.render('Header')}
                {/* Use column.getResizerProps to hook up the events correctly */}
                {column.canResize && (
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </thead>
     
       
  
      <tbody className="tbody" {...getTableBodyProps()} style={{marginTop:180}}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <div {...row.getRowProps()} className="tr" >
              {row.cells.map(cell => {
				const [target, column] = cell.column.id.split('.')
                return (
                  <div {...cell.getCellProps(cellProps)} className="td" onClick={column === 'strike'? undefined: () => rowOnClick(cell.row.original[target])} >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        })}
        
      </tbody>
    </table>
	</div>
	)
}


