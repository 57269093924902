// import axiosConfig from 'axiosConfig'
import React, { Component } from 'react'
import axiosConfig, { url } from '../../../../store/Config'

export class ProvidersCPTExtractService extends Component {
	getCategoryFilteredRegionList = (state) => {
		return axiosConfig.post(`${url}/getCategoryFilteredRegionList`, state)
	}
	getLevel1DescList = (state) => {
		return axiosConfig.post(`${url}/getCategoryFilteredLevel1DescList`, state)
	}
	extractResults = (body) => {
		console.log('service call')
		//return axiosConfig.get(`./data/priceDiffAvgList.json`)
		return axiosConfig.post(`${url}/getCategoryFilterResults`, body)
	}
	getBcodeList = (body) => {
		return axiosConfig.post(`${url}/getBcodeList`, body)
	}
	getCategoryFilteredPayerList = (body) => {
		return axiosConfig.post(`${url}/getCategoryFilteredPayerList`, body)
	}
}
