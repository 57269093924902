import React, { useEffect, useState } from 'react'
import { Col, Row,Card } from '@themesberg/react-bootstrap'

import { inject, observer } from 'mobx-react'

import DrgCodesByHospitalWithNtype from '../../components/ZignaAi/DataAuditReferenceTable/Tables/DrgCodesByHospitalWithNtype'


import _ from 'lodash'


export default inject('zigna_aiStore','authStore')(
	observer(({ zigna_aiStore ,authStore}) => {




		
		return (
		
							<DrgCodesByHospitalWithNtype dataset={zigna_aiStore.DrgByHospitalCountWithNtype}/>
          
		)
		
	})
)




