import React from "react";
import { CommonTable } from "../../common/CommonTable";
import _ from "lodash";

/** For future use - formatting only */
import "xlsx";

const Table = ({ dataset }) => {
  let columns = [
    {
      Header: "NUCC Grouping",
      accessor: "group1",

      align: "left",
    },
    {
      Header: "NUCC Classification",
      accessor: "group2",

      align: "center",
    },
    {
      Header: "NUCC Specialization",
      accessor: "group3",

      align: "center",
    },
    {
      Header: "Individual NPI Count",
      accessor: "count",
      align: "right",
    },
  ];

  return (
    <>
      <h5 className="mx-3">Summary</h5>
      <div
        style={{
          overflow: "scroll",
          maxHeight: "80.5vh",
          minHeight: "30vh",
          width: "100%",
          marginBottom: 10,
          fontFamily: "arial",
          fontSize: 16,
        }}
      >
        <CommonTable
          sticky
          stickyTop={0}
          columns={columns}
          data={dataset}
          className="zignaAi_table_1 zignaAi_newTable"
        />
      </div>
    </>
  );
};

export default Table;
