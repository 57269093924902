import { tickStep } from "d3";
import { makeAutoObservable } from "mobx";

export class AuthStore {
  service;
  constructor(service) {
    makeAutoObservable(this);
    this.service = service;
  }
  username = null;
  password = null;
  isSessionActive = localStorage.getItem("currentUser") ? true : false;
  newPassword = null;
  btnLoading = false;
  setUsername = (value) => {
    this.username = value;
  };
  setPassword = (value) => {
    this.password = value;
  };
  setNewPassword = (value) => {
    this.newPassword = value;
  };
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  loginError = "";
  isLoginLoaderEnable = false;

  login = async () => {
    if (
      this.username &&
      this.password &&
      this.username != "" &&
      this.password != ""
    ) {
      this.isLoginLoaderEnable = true;
      // let response = await this.service.login(this.username, this.password);
      // if(response.data.token){
      // 	this.currentUser = response?.data;
      // 	localStorage.setItem('currentUser', JSON.stringify(response?.data));
      // 	this.loginError = ''
      // }
      // else {
      // 	this.loginError = response.data?.error
      // }

      try {
        let response = await this.service.login(this.username, this.password);
        if (response.data.token) {
          // response.data.isFirstTimeLogin = true
          this.currentUser = response?.data;
          localStorage.setItem("currentUser", JSON.stringify(response?.data));
          this.loginError = "";
        }
      } catch (e) {
        this.loginError = e.response.data?.error;
      }
      this.isLoginLoaderEnable = false;
    } else {
      this.setUsername("");
      this.setPassword("");
    }
  };
  logout = async () => {
    // remove user from local storage to log user out

    try {
      this.isLoginLoaderEnable = true;
      const token = this.getcurrentUser()?.token || "";
      if (!token) {
        this.isLoginLoaderEnable = false;
        return;
      }
      let response = await this.service.invalidateToken(token);
      if (response.data) {
        this.isSessionActive = false;
        // this.currentUser = null
        localStorage.removeItem("currentUser");
        localStorage.clear();

        // window.location="/"
      }
    } catch (e) {
      console.log(e);
      this.isSessionActive = false;
      localStorage.removeItem("currentUser");
      localStorage.clear();
    }
    this.isLoginLoaderEnable = false;
  };
  getcurrentUser() {
    return this.currentUser;
  }
  exchangeToken = async (dbname) => {
    try {
      this.isLoginLoaderEnable = true;
      const token = this.getcurrentUser()?.token || "";
      if (!token) {
        this.logout();
        return;
      }
      let response = await this.service.exchangeToken(token, dbname);
      if (response.data.token) {
        // response.data.isFirstTimeLogin = true
        this.currentUser = response?.data;
        localStorage.setItem("currentUser", JSON.stringify(response?.data));
        this.loginError = "";
      }
    } catch (e) {
      this.logout();
      this.loginError = e.response.data?.error;
      this.isSessionActive = false;
    }
    this.isLoginLoaderEnable = false;
  };
  validateMicrosoftToken = async (token) => {
    this.isLoginLoaderEnable = true;
    try {
      let response = await this.service.validateToken(token);
      if (response.data.status === "valid") {
        const userData = { ...(response?.data || {}), token };
        this.currentUser = userData;
        localStorage.setItem("currentUser", JSON.stringify(userData));
        this.loginError = "";
        this.isSessionActive = true;
        window.location.href = window.location.origin;
      } else {
        this.isSessionActive = false;
        // this.loginError= 'Invalid credentials/token. Please signin again';
      }
    } catch (e) {
      this.loginError = e.response.data?.error;
    }
    this.isLoginLoaderEnable = false;
  };
  isAuthenticate = async () => {
    try {
      const token = this.getcurrentUser()?.token || "";
      if (!token) {
        this.logout();
        return;
      }
      let isFirstTimeLogin = this.getcurrentUser()?.isFirstTimeLogin;
      console.log(isFirstTimeLogin);
      if (!isFirstTimeLogin) {
        let response = await this.service.validateToken(token);
        if (response.data.status === "valid") {
          this.isSessionActive = true;
        } else {
          this.isSessionActive = false;
          // this.loginError= 'Session expired. Please signin again';
        }
      }
    } catch (e) {
      this.logout();
      // this.loginError = 'Invalid credentials/token. Please signin again';
      // this.isSessionActive = false
    }
  };
  resetPassword = async () => {
    this.btnLoading = true;
    if (this.newPassword != "" && this.newPassword) {
      try {
        let response = await this.service.resetPassword(
          this.newPassword,
          this.currentUser?.token
        );
        this.logout();
        alert("Password change success, please login with new password");
        this.btnLoading = false;
      } catch (e) {
        this.btnLoading = false;
      }
    }
  };
}
