import React from "react";
import { useEffect } from "react";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { convertPrice } from "../../../Utilities";
import millify from "millify";

import { orderBy } from "lodash";

export default function DataBarchart({
  title,
  data,
  isGrouped,
  horizontal,
  autoBarThickness,
  rotation,
  responsive,
  barThickness,
  padding,
}) {
  Chart.register(ChartDataLabels);

  console.log(data);
  let labels = [];
  let dataset = [];
  let colors = ["#3d2260", "#8294C4", "#7CB9E8", "#D4ADFC", "#0071c5", "#7d5bec", "#3b65ce", "#22105d"];
  if (!isGrouped) {
    data
      .filter((i) => i.value > 0)
      .map((i) => {
        labels.push(i.label);
        dataset.push(i.value);
      });
  } else {
    let firstDataSet = data[0]?.data;

    firstDataSet
      .filter((i) => i.value > 0)
      .map((i) => {
        labels.push(i.label);
      });
    data.map((i, index) => {
      let localDataSet = new Array(labels.length).fill('');
      i.data
        .filter((i) => i.value > 0)
        .map((j) => {
          let labelIndex = labels.findIndex((li) => li == j.label);
          if (labelIndex < 0) {
            labels.push(j.label);
            localDataSet.push(j.value);
          } else {
            localDataSet[labelIndex] = j.value;
          }
        });
      dataset.push({
        label: i.month,
        maxBarThickness: 32,
        barThickness: barThickness,
        skipNull: true,
        backgroundColor: colors[index],
        data: localDataSet,

        legend: {
          position: "left",
        },

        datalabels: {
          anchor: "end",
          clamp: true,
          align: "end",
          offset: -4,
        },
      });
    });
  }

  let filteredData = {
    labels: labels,
    datasets: isGrouped
      ? dataset
      : [
          {
            label: title,
            barThickness: 43,
            backgroundColor: "#3d2260",

            data: dataset,
            datalabels: {
              anchor: "end",
              clamp: true,
              align: "end",
              offset: -4,
            },
          },
        ],
  };

  let options = {
    indexAxis: horizontal ? "y" : "x",
    tooltips: {
      enabled: false,
    },

    animation: {
      duration: 2000,
    },
    layout: {
      padding: padding || 12,
    },
    legend: {
      display: false,
    },
    scales: {
      y: {
        display: true,
        beginAtZero: true,
        ticks: !horizontal
          ? {
              callback: (label) =>
                millify(label, {
                  precision: 1,
                }),
            }
          : {},
      },

      x: {
        ticks: horizontal
          ? {
              callback: (label) =>
                millify(label, {
                  precision: 1,
                }),
            }
          : {},
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      datalabels: {
        rotation: rotation || 0,
        overlap: "auto",
        color: "black",
        offset: 4,

        font: {
          weight: "bold",
          size: 10,
        },
        formatter: function (value, context) {
          return (
            " " +
            millify(value, {
              precision: 1,
            })
          );
        },
      },
    },
  };

  if (responsive) {
    options.responsive = true;
    options.maintainAspectRatio = false;
  }
  if (horizontal) {
    return <Bar data={filteredData} options={options} />;
  } else return <Bar data={filteredData} options={options} />;
}
