import {
  makeAutoObservable,
  runInAction,
  reaction,
  observable,
  computed,
} from "mobx";
import { getAvg, getMax } from "../../../../Utilities";
// import { getAvg, getMax, getMin, sortJsonArr } from '../Utilities'
import { sortJsonArr, getMin } from "./../../../../Utilities";

export class ProvidersPriceCompareStore {
  //Common variables start
  userType = localStorage.getItem("userType") || null;
  //Common variables end
  service;
  constructor(service) {
    makeAutoObservable(this);
    this.service = service;
  }
  // filter
  regionList = [];
  specialtyList = [];
  feeScheduleTypeList = [];
  proceduresCatList = [];
  level1DescList = [];
  modifierList = [];
  groupList = [];
  placeOfServiceList = [];
  billingCodeList = [];
  reportingEntityNameList = [];
  aggregationMeasureList = [
    {
      id: 1,
      name: "Minimum",
      value: "Minimum",
    },
    {
      id: 2,
      name: "Median",
      value: "Median",
    },
    {
      id: 3,
      name: "Average",
      value: "Average",
    },
    {
      id: 4,
      name: "Maximum",
      value: "Maximum",
    },
  ];
  procedureNameList = [];
  priceDiffAvgList = {
    negotiatedTypes: [],
    data: [],
  };
  providerPriceListData = [];

  providerPriceListColumnData = [
    {
      Header: " ",
      columns: [
        {
          Header: "Region",
          accessor: "region",
          width: 50,
        },
        {
          Header: "Level 1 Description",
          accessor: "level1_description",
          width: 50,
        },
        {
          Header: "Fee Schedule Type",
          accessor: "provider_type",
          width: 50,
        },
        {
          Header: "Specialty",
          accessor: "classification",
          width: 50,
        },
        {
          Header: "Group",
          accessor: "grouping",
          width: 50,
        },
        {
          Header: "Place Of Service",
          accessor: "service_code",
          width: 50,
        },
        {
          Header: "Reporting Entity Name",
          accessor: "reporting_entity_name",
          width: 50,
        },
        {
          Header: "Billing Code",
          accessor: "billing_code",
          width: 50,
        },
        {
          Header: "Procedures Category",
          accessor: "billing_code_type",
          width: 50,
        },
        {
          Header: "Modifiers",
          accessor: "billing_code_modifier",
          width: 50,
        },
        {
          Header: "NPI",
          accessor: "npi",
          width: 50,
        },
        {
          Header: "Provider Name",
          accessor: "provider_name",
          width: 50,
        },
        {
          Header: "Negotiated Type",
          accessor: "negotiated_type",
          width: 50,
        },
        {
          Header: "Rate",
          accessor: "rate",
          width: 50,
        },
      ],
    },
  ];

  categoryExtractResults = [];

  priceCategoryList = [];
  getRegionList = async () => {
    let response = await this.service.getRegionList({
      billingCodeType: this.selectedFilterData.procedureCat,
    });

    this.regionList = response.data;
  };
  getUserType = () => {
    const mapping = {
      professional: "professional",
      facility: "institutional",
      ancillary: "institutional",
    };
    return mapping[this.userType];
  };

  getFeeScheduleTypeList = async () => {
    this.feeScheduleTypeList = [];
    let response = await this.service.getFeeScheduleTypeList({
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      userType: this.getUserType(),
    });
    this.feeScheduleTypeList = response.data;
  };
  getSpecialtyList = async () => {
    this.specialtyList = [];
    let response = await this.service.getSpecialtyList({
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      userType: this.getUserType(),
    });
    this.specialtyList = response.data;
  };
  getGroupList = async () => {
    this.groupList = [];
    let response = await this.service.getGroupList({
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      userType: this.getUserType(),
    });
    this.groupList = response.data;
  };
  getPlaceOfServiceList = async () => {
    this.placeOfServiceList = [];
    let response = await this.service.getPlaceOfServiceList({
      billingCodeType: this.selectedFilterData.procedureCat,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      userType: this.getUserType(),
    });
    this.placeOfServiceList = [];
    this.placeOfServiceList = (response.data || []).reduce((acc, d) => {
      const obj = {
        name: (d.name || "").trim(),
        ...d,
      };
      if (d.name !== "Catch all" && d.name !== "Catch All") acc.push(obj);
      return acc;
    }, []);

    // console.log(JSON.stringify(this.placeOfServiceList))
  };
  getReportingEntityNameList = async () => {
    this.reportingEntityNameList = [];
    let response = await this.service.getReportingEntityNameList({
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      billingCode:
        this.selectedFilterData.billingCode.map((el) => el.value) || [],
      placeOfService:
        this.selectedFilterData.placeOfService.value == "Urgent Care"
          ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
          : [
              this.selectedFilterData.placeOfService.value,
              "Catch All",
              "Catch all",
            ],
      userType: this.getUserType(),
      billingCodeType: this.selectedFilterData.procedureCat,
    });
    this.reportingEntityNameList = response.data;
  };
  getBillingCodeList = async () => {
    this.billingCodeList = [];
    let response = await this.service.getBillingCodeList({
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      placeOfService:
        this.selectedFilterData.placeOfService.value == "Urgent Care"
          ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
          : [
              this.selectedFilterData.placeOfService.value,
              "Catch All",
              "Catch all",
            ],
      // payers: (this.selectedFilterData.reportingEntityName || []).map(el => el.value) || [],
      billingCodeType: this.selectedFilterData.procedureCat,
      userType: this.getUserType(),
    });
    this.billingCodeList = response.data;
  };

  getProceduresCatList = async () => {
    this.proceduresCatList = [];
    let response = await this.service.getProceduresCatList({
      specialty: this.selectedFilterData.specialty.value,
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      userType: this.getUserType(),
    });
    this.proceduresCatList = response.data;
  };
  getLevel1DescList = async () => {
    this.level1DescList = [];
    let response = await this.service.getLevel1DescList({
      billingCodeType: this.selectedFilterData.procedureCat,
      //specialty: this.selectedFilterData.specialty,
      region: this.selectedFilterData.region.value,
      userType: this.getUserType(),
    });
    this.level1DescList = response.data;
  };
  getProcedureNameList = async () => {
    this.procedureNameList = [];
    let response = await this.service.getProcedureNameList({
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      placeOfService:
        this.selectedFilterData.placeOfService.value == "Urgent Care"
          ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
          : [
              this.selectedFilterData.placeOfService.value,
              "Catch All",
              "Catch all",
            ],
      billingCode:
        this.selectedFilterData.billingCode.map((el) => el.value) || [],
      payers:
        (this.selectedFilterData.reportingEntityName || []).map(
          (el) => el.value
        ) || [],
      billingCodeType: this.selectedFilterData.procedureCat,
      userType: this.getUserType(),
    });
    this.procedureNameList = response.data;
  };
  getmodifierList = async () => {
    this.modifierList = [];
    let response = await this.service.getmodifierList({
      billingCodeType: this.selectedFilterData.procedureCat,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      placeOfService:
        this.selectedFilterData.placeOfService.value == "Urgent Care"
          ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
          : [
              this.selectedFilterData.placeOfService.value,
              "Catch All",
              "Catch all",
            ],
      billingCode:
        this.selectedFilterData.billingCode.map((el) => el.value) || [],
      payers:
        (this.selectedFilterData.reportingEntityName || []).map(
          (el) => el.value
        ) || [],
      userType: this.getUserType(),
    });
    this.modifierList = [];
    response.data.forEach((d) => {
      if (d.name !== "base" || d.name !== null) {
        this.modifierList.push(d);
      }
    });
  };

  selectedFilterData = {
    region: null,
    specialty: null,
    procedureCat: null,
    level1Desc: null,
    aggregationMeasure: null,
    modifier: null,
    feeScheduleType: null,
    group: null,
    placeOfService: null,
    reportingEntityName: null,
    billingCode: null,
  };
  setFilterData = (key, value) => {
    this.selectedFilterData[key] = value;
  };
  resetFilterData = () => {
    this.selectedFilterData = {
      region: null,
      specialty: null,
      //procedureCat: null,
      level1Desc: null,
      aggregationMeasure: null,
      modifier: null,
      feeScheduleType: null,
      group: null,
      placeOfService: null,
      reportingEntityName: null,
      billingCode: null,
    };
  };
  // filter

  isValidFilter = () => {
    let self = this;
    let isValid = true;
    Object.keys(this.selectedFilterData).map((key) => {
      if (self.selectedFilterData[key] == null) {
        self.selectedFilterData[key] = "";
      }
      if (
        key != "specialty" &&
        key != "modifier" &&
        key != "aggregationMeasure" &&
        self.selectedFilterData[key] == ""
      ) {
        isValid = false;
      }
    });

    return isValid;
  };
  getPayerPriceCompList = async () => {
    console.log(this.isValidFilter());
    let providerType = [];
    // if (this.selectedFilterData.enhanced) providerType.push('Enhanced')
    // if (this.selectedFilterData.community) providerType.push('Community')
    if (this.isValidFilter()) {
      let response = await this.service.getPayerPriceCompList({
        billingCodeType: this.selectedFilterData.procedureCat,
        specialty:
          this.selectedFilterData.specialty.map((el) => el.value) || [],
        region: this.selectedFilterData.region.value,
        description: this.selectedFilterData.level1Desc.value,
        aggregationMeasure: this.selectedFilterData.aggregationMeasure?.value,
        modifier: this.selectedFilterData.modifier.value,
        feeScheduleType:
          this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
        grouping: this.selectedFilterData.group.value,
        placeOfService:
          this.selectedFilterData.placeOfService.value == "Urgent Care"
            ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
            : [
                this.selectedFilterData.placeOfService.value,
                "Catch All",
                "Catch all",
              ],
        billingCode:
          this.selectedFilterData.billingCode.map((el) => el.value) || [],
        payers:
          (this.selectedFilterData.reportingEntityName || []).map(
            (el) => el.value
          ) || [],
        userType: this.getUserType(),
      });
      // console.log(response.data);
      this.providerPriceListData = response.data;

      // this.providerPriceListColumnData=Object.keys(response.data[0]);
      // console.log(this.providerPriceListColumnData,'providerPriceListColumnData')
      // this.priceDiffAvgList = response?.data
      // this.setPriceDiffAvgChartList(this.priceDiffAvgList)
      // this.setPriceCategoryList(this.priceDiffAvgList)
    }
  };
  resetCategoryExtractResults = () => {
    this.categoryExtractResults = [];
  };
  createCategoryExtractRequest = async (requestAll, fields = {}) => {
    return this.service.getCategoryExtractResults({
      billingCodeType: this.selectedFilterData.procedureCat,
      specialty: this.selectedFilterData.specialty.map((el) => el.value) || [],
      region: this.selectedFilterData.region.value,
      description: this.selectedFilterData.level1Desc.value,
      aggregationMeasure: this.selectedFilterData.aggregationMeasure.value,
      modifier: this.selectedFilterData.modifier.value,
      feeScheduleType:
        this.selectedFilterData.feeScheduleType.map((el) => el.value) || [],
      grouping: this.selectedFilterData.group.value,
      placeOfService:
        this.selectedFilterData.placeOfService.value == "Urgent Care"
          ? ["Urgent Care", " Urgent", "Catch All", "Catch all"]
          : [
              this.selectedFilterData.placeOfService.value,
              "Catch All",
              "Catch all",
            ],
      billingCode:
        this.selectedFilterData.billingCode.map((el) => el.value) || [],
      payers:
        (this.selectedFilterData.reportingEntityName || []).map(
          (el) => el.value
        ) || [],
      userType: this.getUserType(),
      //allModifiers:this.modifierList?.map(i=>i.value),
      requestAll: requestAll,
      ...fields,
    });
  };
  getCategoryExtractResults = async () => {
    console.log(this.isValidFilter());
    if (this.isValidFilter()) {
      let response = await this.createCategoryExtractRequest(false);
      this.categoryExtractResults = response.data;
    }
  };
  placeCategoryExtractRequest = async (fields, notify) => {
    try {
      let response = await this.createCategoryExtractRequest(true, fields);
      notify("success", "Your request has been placed successfully");
    } catch (e) {
      notify(
        "error",
        "There was an error creating the request, please try saving it with unique label name using which you can identify the request uniquely"
      );
    }
  };
  getFilterPriceDiffAvgList(providerType, typeName) {
    if (typeName) {
      return this.priceDiffAvgList?.data?.filter(
        (obj) => obj.providerType === providerType && obj.typeName == typeName
      );
    } else {
      return this.priceDiffAvgList?.data?.filter(
        (obj) =>
          obj.providerType === providerType &&
          obj.typeName != "percentage" &&
          obj.typeName != "per diem"
      );
    }
  }
  resetPayerPriceCompList = () => {
    this.priceDiffAvgList = {
      negotiatedTypes: [],
      data: [],
    };
    this.priceCategoryList = [];
    this.categoryExtractResults = [];
  };
  setPriceCategoryList(data) {
    let dataList = data?.data;
    let maxPrice = [];
    let minPrice = [];
    let average = 0;
    maxPrice = getMax(dataList, "price");
    // this.priceDiffAvgList?.data?.filter( x => x["price"] == Math.max(...self.priceDiffAvgList.data.map(x => x["price"])) )
    minPrice = getMin(dataList, "price");
    average = getAvg(dataList, "price");
    let result = [];
    result.push(
      {
        id: 3,
        priceCat: "Minimum Price",
        value: minPrice && minPrice[0]?.price,
      },
      {
        id: 1,
        priceCat: "Maximum Price",
        value: maxPrice && maxPrice[0]?.price,
      },
      {
        id: 2,
        priceCat: "Average Price",
        value: average && average?.toFixed(0),
      }
    );
    this.priceCategoryList = result;
  }
  setPriceDiffAvgChartList(data) {
    let dataList = data?.data;
    let average = getAvg(dataList, "price").toFixed(2);

    let result = [
      {
        id: "chart",
        data: [],
      },
    ];
    let resultDataArr = dataList?.map((item) => {
      return {
        x: item.payerName,
        // y: item.price,
        y: (average - item.price)?.toFixed() * -1,
        priceDiff: (average - item.price)?.toFixed(2),
        priceDiffPercentage: (
          ((average - item.price) * 100) /
          parseFloat(average)
        )?.toFixed(),
      };
    });
    // console.log(resultDataArr)
    result[0].data = sortJsonArr(resultDataArr, "y", "asc");
    this.priceDiffAvgChartList = result;
  }
}
