import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'
import FilterWidget from '../../components/ZignaAi/FilteredDrgList/FilterWidget'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import PriceComparision from '../../components/ZignaAi/HistoricalRequests/PriceComparision'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		const location=useLocation()
		useEffect(() => {
			zigna_aiStore.getHistoricalRequests()
		},[location])
		return (
			<>
				<Row>
					<Row className={` ${zigna_aiStore.filteredDRGData.length ==0&&'blurDiv'} m-0 p-0`}>
						<Col xs={12} xl={12} className="mb-4">
							<PriceComparision />
						</Col>

					</Row>
				</Row>
			</>
		)
	})
)
