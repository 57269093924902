import React, { useState } from 'react'
import { Button, Card, Col, Row ,ButtonGroup} from '@themesberg/react-bootstrap'
import { CommonTable } from '../common/CommonTable'
import { inject, observer } from 'mobx-react'
import { convertPrice } from '../../../Utilities'
import data from "./data/data.json"
import _ from "lodash"
import {getValueLegend} from "../../../utils/facilityscorecard"
const formatTableNumbers = (data = []) => {
	return data.map(row => ({
		...row,
		Facilities: convertPrice(row.Facilities, { skipCurrency: true }),
		//Physicians: convertPrice(row.Physicians, { skipCurrency: true }),
		Procedures: convertPrice(row.Procedures, { skipCurrency: true })
	}))
}


export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore ,selectedRegion}) => {

		const [selectedTab,setSelectedTab]=useState("CPT")
		let payerTableColumn = [
			{
				Header: ' ',
				columns: [
					{
						Header: 'Region Name',
						accessor: 'region',
						width:140,
						Cell:({value})=>selectedRegion?.name==value?<div className='p-2' style={{backgroundColor:"yellow",border:"1px dashed grey"}}>{value}</div>:value
					},
					{
						Header: 'Provider Count',
						accessor: 'provider_count',
						align: 'right',
						width:60
						
					},
					
					{
						Header: 'Total Codes',
						accessor: 'total_codes',
						align: 'right',
						width:100
						
					},
				
					{
						Header: 'Code Coverage Percent',
						accessor: 'code_coverage_percent',
						align: 'right',
						width:100
						
					},
					{
						Header: 'DRG Coverage Percent',
						accessor: 'preferred_drg_code_coverage_percentage',
						align: 'right',
						width:100
						
					},
					{
						Header: 'Total Payers',
						accessor: 'total_payers',
						align: 'right',
						width:100
						
					},

				],
			},
		]
		console.log(data.find(i=>i.provider_type=="Hospital"&&i.region==selectedRegion?.region))
		return (
			<Card border="light" className="shadow-sm h-100">
				{/* <Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>Page visits</h5>
						</Col>
					</Row>
				</Card.Header> */}
				<Card.Body style={{overflow:"auto"}}>
					<div className='d-flex justify-content-between'>
					<ButtonGroup size="sm" className="mx-3 ">
					<Button variant={selectedTab=="CPT"?'primary':"outline-primary"} onClick={()=>setSelectedTab("CPT")} active={selectedTab=="CPT"}>CPT</Button>
					<Button variant={selectedTab=="DRG"?'primary':"outline-primary"} onClick={()=>setSelectedTab("DRG")} active={selectedTab=="DRG"}>DRG</Button>
					<Button variant={selectedTab=="HCPCS"?'primary':"outline-primary"} onClick={()=>setSelectedTab("HCPCS")} active={selectedTab=="HCPCS"}>HCPCS</Button>
					<Button variant={selectedTab=="APC"?'primary':"outline-primary"} onClick={()=>setSelectedTab("APC")} active={selectedTab=="APC"}>APC</Button>
					</ButtonGroup>

					<Button size={"sm"} className={data.find(i=>i.billing_code_type==selectedTab&&i.provider_type=="Hospital"&&i.region==selectedRegion?.region)?.is_excellus_present>0?'bg-success':'bg-danger'}>Excellus</Button>
					</div>
					
					<CommonTable
						columns={payerTableColumn}
						data={data.filter(i=>i.billing_code_type==selectedTab&&i.provider_type=="Hospital")}
						className="zignaAi_table_1"
					/>
				</Card.Body>
			</Card>
		)
	})
)
