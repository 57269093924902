import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";

import { inject, observer } from "mobx-react";
import PriceComparision from "../../components/ZignaAi/DataAuditReferenceTable/PriceComparision";
import BctvTable from "../../components/ZignaAi/DataAuditReferenceTable/Tables/BctvTable";
import HospitalDRGTable from "../../components/ZignaAi/DataAuditReferenceTable/Tables/HospitalCountDRG";
import DrgCodesByHospital from "../../components/ZignaAi/DataAuditReferenceTable/Tables/DrgCodesByHospital";
import DrgCodesByHospitalWithNtype from "../../components/ZignaAi/DataAuditReferenceTable/Tables/DrgCodesByHospitalWithNtype";
import DistictDrgtable from "../../components/ZignaAi/DataAuditReferenceTable/Tables/DistictDrgByNegotiationType";
import DataBarchart from "../../components/ZignaAi/DataAuditReferenceTable/DataBarchart";
import DistinctBillingCodesGraph from "../../components/ZignaAi/DataAuditReferenceTable/DistinctBillingCodesGraph";
import MapChart from "../../components/ZignaAi/DataAuditReferenceTable/NpisMaps";

import ExellusData from "../../data/DataAudit/exculles.json";
import WellmarkData from "../../data/DataAudit/main.json";
import Select from "react-select";
import millify from "millify";
import { convertToMonth, convertToQuater } from "../../../src/Utilities";
import _ from "lodash";
const customStyles = {
  control: (base) => ({
    ...base,
    background: "#e7e3f9",
    height: 44,
    minHeight: 44,
    minWidth: 100,
    width: "100%",
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#c3b9f1",
      };
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#c3b9f1",
    };
  },
};

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    let dataType = zigna_aiStore.DataAuditDataType;

    const setDataType = (type) => {
      zigna_aiStore.setDataAuditDataType(type);
    };
    const [AuditData, setAuditData] = useState([]);
    const [selectedDataSet, setSelectedDataset] = useState([]);
    const [selectedProviderType, setSelectedProviderType] = useState("");
    useEffect(() => {
      if (zigna_aiStore.staticChartData?.length == 0) {
        return;
      }

      let data = zigna_aiStore.staticChartData.map((i) => {
        if (dataType == "raw") {
          return {
            MONTH: i["month"],
            REPORTING_ENTITY_NAME: i["reporting_entity_name"],
            POSTED_FILES: i["posted_files"],
            AVERAGE_FILE_SIZE: i["avg_file_size"],
            TOTAL_RECORDS: i["total_records"],
            TOTAL_NPIS: i["total_npis"],
            RELEVANT_NPIS: i["relevant_npis"],
            ZIP_CODES: i["total_zip_codes"],
            REGIONS: i["total_regions"],
            DERIVED: i["total_nt_d"],
            "FEE SCHEDULE": i["total_nt_fs"],
            NEGOTIATED: i["total_nt_neg"],
            "PER DIEM": i["total_nt_pd"],
            PERCENTAGE: i["total_nt_per"],
            INSTITUTIONAL: i["total_bc_inst"],
            PROFESSIONAL: i["total_bc_prof"],
            APC: i["total_records_bc_apc"],
            "APR-DRG": i["total_records_bc_apr-drg"],
            "AP-DRG": i["total_records_bc_ap-drg"],
            CDT: i["total_records_bc_cdt"],
            CPT: i["total_records_bc_cpt"],
            "CSTM-ALL": i["total_records_bc_cstm-all"],
            EAPG: i["total_records_bc_eapg"],
            HCPCS: i["total_records_bc_hcpcs"],
            HIPPS: i["total_records_bc_hipps"],
            ICD: i["total_records_bc_icd"],
            LOCAL: i["total_records_bc_local"],
            "MS-DRG": i["total_records_bc_msdrg"],
            NDC: i["total_records_bc_ndc"],
            RC: i["total_records_bc_rc"],
            PROC: i["total_records_bc_proc"],

            APC_DC: i["total_distinct_bc_apc"],
            "AP-DRG_DC": i["total_distinct_bc_ap-drg"],
            "APR-DRG": i["total_distinct_bc_apr-drg"],
            CDT_DC: i["total_distinct_bc_cdt"],
            CPT_DC: i["total_distinct_bc_cpt"],
            "CSTM-ALL_DC": i["total_distinct_bc_cstm-all"],
            EAPG_DC: i["total_distinct_bc_eapg"],
            HCPCS_DC: i["total_distinct_bc_hcpcs"],
            HIPPS_DC: i["total_distinct_bc_hipps"],
            ICD_DC: i["total_distinct_bc_icd"],
            LOCAL_DC: i["total_distinct_bc_local"],
            "MS-DRG_DC": i["total_distinct_bc_msdrg"],
            NDC_DC: i["total_distinct_bc_ndc"],
            RC_DC: i["total_distinct_bc_rc"],
            PROC_DC: i["total_distinct_bc_proc"],

            PT_H: i["total_npi_pt_h"],
            PT_A: i["total_npi_pt_a"],
            PT_C: i["total_npi_pt_c"],
          };
        } else {
          return {
            MONTH: i["month"],
            REPORTING_ENTITY_NAME: i["reporting_entity_name"],
            POSTED_FILES: i["posted_files"],
            AVERAGE_FILE_SIZE: i["avg_file_size"],
            TOTAL_RECORDS: i["relevant_records"],
            TOTAL_NPIS: i["relevant_npis"],
            RELEVANT_NPIS: i["relevant_npis"],
            ZIP_CODES: i["relevant_zip_codes"],
            REGIONS: i["relevant_regions"],
            DERIVED: i["relevant_nt_d"],
            "FEE SCHEDULE": i["relevant_nt_fs"],
            NEGOTIATED: i["relevant_nt_neg"],
            "PER DIEM": i["relevant_nt_pd"],
            PERCENTAGE: i["relevant_nt_per"],
            INSTITUTIONAL: i["relevant_bc_inst"],
            PROFESSIONAL: i["relevant_bc_prof"],
            APC: i["relevant_records_bc_apc"],
            "AP-DRG": i["relevant_records_bc_ap-drg"],
            "APR-DRG": i["relevant_records_bc_apr-drg"],
            CDT: i["relevant_records_bc_cdt"],
            CPT: i["relevant_records_bc_cpt"],
            "CSTM-ALL": i["relevant_records_bc_cstm-all"],
            EAPG: i["relevant_records_bc_eapg"],
            HCPCS: i["relevant_records_bc_hcpcs"],
            HIPPS: i["relevant_records_bc_hipps"],
            ICD: i["relevant_records_bc_icd"],
            LOCAL: i["relevant_records_bc_local"],
            "MS-DRG": i["relevant_records_bc_msdrg"],
            NDC: i["relevant_records_bc_ndc"],
            RC: i["relevant_records_bc_rc"],
            PROC: i["relevant_records_bc_proc"],

            APC_DC: i["relevant_distinct_bc_apc"],
            "AP-DRG_DC": i["relevant_distinct_bc_ap-drg"],
            "APR-DRG_DC": i["relevant_distinct_bc_apr-drg"],
            CDT_DC: i["relevant_distinct_bc_cdt"],
            CPT_DC: i["relevant_distinct_bc_cpt"],
            "CSTM-ALL_DC": i["relevant_distinct_bc_cstm-all"],
            EAPG_DC: i["relevant_distinct_bc_eapg"],
            HCPCS_DC: i["relevant_distinct_bc_hcpcs"],
            HIPPS_DC: i["relevant_distinct_bc_hipps"],
            ICD_DC: i["relevant_distinct_bc_icd"],
            LOCAL_DC: i["relevant_distinct_bc_local"],
            "MS-DRG_DC": i["relevant_distinct_bc_msdrg"],
            NDC_DC: i["relevant_distinct_bc_ndc"],
            RC_DC: i["relevant_distinct_bc_rc"],
            PROC_DC: i["relevant_distinct_bc_proc"],

            PT_H: i["relevant_npi_pt_h"],
            PT_A: i["relevant_npi_pt_a"],
            PT_C: i["relevant_npi_pt_c"],
          };
        }
      });

      setAuditData(_.orderBy(data, ["REPORTING_ENTITY_NAME"], ["asc"]));

      setSelectedDataset(
        data.filter(
          (i) =>
            i.MONTH ==
            _.orderBy(data, (i) => new Date(i.MONTH), ["desc"])[0].MONTH
        )
      );
    }, [zigna_aiStore.staticChartData?.length, dataType]);

    useEffect(() => {
      zigna_aiStore.getHospitalCountDrg(dataType);
      zigna_aiStore.getDistingDRGByNegotiations(dataType);
    }, [dataType]);

    useEffect(() => {
      if (zigna_aiStore.HospitalCountByDRG.length) {
        setSelectedProviderType(
          zigna_aiStore.HospitalCountByDRG[0].provider_type
        );
      }
    }, [zigna_aiStore.HospitalCountByDRG]);
    useEffect(() => {
      zigna_aiStore.getStaticChartData();
    }, []);
    return (
      <Row>
        <Col xs={12} className="d-flex justify-content-end py-2">
          <div className="btn-group" role="group" aria-label="Data-type">
            <button
              type="button"
              onClick={() => setDataType("raw")}
              className={`btn ${
                dataType == "raw" ? "btn-primary" : "btn-outline-primary"
              }`}
            >
              Raw Data
            </button>
            <button
              type="button"
              onClick={() => setDataType("relevant")}
              className={`btn ${
                dataType == "relevant" ? "btn-primary" : "btn-outline-primary"
              }`}
            >
              Relevant Data
            </button>
          </div>
        </Col>

        <Col xs={12} xl={12} className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="pb-0">
              <Row className="align-items-center">
                <Col col={12} className="d-flex justify-content-between">
                  <h6>Unique Billing Codes Across Payers</h6>
                  <nav class="nav flex-row">
                    {_.orderBy(
                      _.uniqBy(AuditData, (i) => i.MONTH),
                      (i) => new Date(i.MONTH)
                    )?.map((i) => (
                      <a
                        class={`nav-link ${
                          selectedDataSet[0]?.MONTH == i?.MONTH
                            ? "active border border-primary bg-primary text-white"
                            : ""
                        }`}
                        style={{ textTransform: "capitalize" }}
                        aria-current="page"
                        onClick={() =>
                          setSelectedDataset(
                            AuditData.filter((j) => j?.MONTH == i?.MONTH)
                          )
                        }
                      >
                        {convertToMonth(i?.MONTH)}
                      </a>
                    ))}
                  </nav>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body
              style={{ overflow: "scroll", paddingTop: 0 }}
              className="p-0"
            >
              <PriceComparision
                db={authStore?.currentUser?.db_name}
                months={_.orderBy(
                  _.uniqBy(AuditData, (i) => i.MONTH),
                  (i) => new Date(i.MONTH)
                ).map((i) => i.MONTH)}
                dataset={selectedDataSet}
                allDataset={AuditData}
              />
            </Card.Body>
          </Card>
        </Col>

        {/* <Col xs={12} xl={12} className="mb-4">
                <Card
        border='light'
        className='shadow-sm'
      >
        <Card.Header className='pb-0'>
          <Row className='align-items-center'>
            <Col col={12} className='d-flex justify-content-between'>
              <h6>
              Hospital count posting DRG
              
              </h6>
              <nav class="nav flex-row">
{_.uniqBy(zigna_aiStore.HospitalCountByDRG,i=>i.provider_type)?.map(i=>(
	<a class={`nav-link ${selectedProviderType==i.provider_type?"active border border-primary bg-primary text-white":""}`} style={{textTransform:"capitalize"}} aria-current="page" onClick={()=>setSelectedProviderType(i.provider_type)}>{i.provider_type}</a>
))  }

 
</nav>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body style={{ overflow: 'scroll',paddingTop:0 }} className='p-0'>
							<HospitalDRGTable dataset={zigna_aiStore.HospitalCountByDRG.filter(i=>i.provider_type==selectedProviderType)}/>
                            </Card.Body>
      </Card>
						</Col> */}

        <Col xs={12} xl={12} className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Header className="pb-0">
              <Row className="align-items-center">
                <Col col={12} className="d-flex justify-content-between">
                  <h6>Unique DRG Codes Count By Negotiation Type</h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body
              style={{ overflow: "scroll", paddingTop: 0 }}
              className="p-0"
            >
              <DistictDrgtable
                dataset={zigna_aiStore.DistingDRGByNegotiations}
                db={authStore?.currentUser?.db_name}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  })
);
