import React from "react";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PriceComparision from "../../components/ZignaAi/providersCPTExtract/PriceComparision";
import FilterWidget from "../../components/ZignaAi/providersPriceCompare/FilterWidget";

export default inject(
  "zigna_aiStore",
  "providerPriceCompareStore",
  "authStore"
)(
  observer(({ zigna_aiStore, providerPriceCompareStore, authStore }) => {
    let location = useLocation();
    const history = useHistory();
    const { filterType = [], hideModifier = false } = location.state || {};
    useEffect(() => {
      if (!filterType.length) history.push("/");
    }, []);

    useEffect(() => {
      providerPriceCompareStore.resetCategoryExtractResults();
      providerPriceCompareStore.resetFilterData();

      filterType.length &&
        providerPriceCompareStore.setFilterData("procedureCat", filterType[0]);

      providerPriceCompareStore.getRegionList();
    }, [location, filterType]);
    return (
      <>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <FilterWidget
              actionFunc={providerPriceCompareStore.getCategoryExtractResults}
              procCategory={filterType}
              hideModifier={hideModifier}
              showExport={false}
              showAggMeasure={false}
              hideProcCategoty={true}
            />
          </Col>
          <Row
            className={` ${
              providerPriceCompareStore?.categoryExtractResults?.length == 0 &&
              "blurDiv"
            } m-0 p-0`}
          >
            <Col xs={12} xl={12} className="mb-4">
              <PriceComparision procCategory={filterType} />
            </Col>

            {providerPriceCompareStore?.categoryExtractResults?.length == 0 && (
              <div className="blurOverlay">
                <h5>Please select Filter</h5>
              </div>
            )}
          </Row>
        </Row>
      </>
    );
  })
);
