import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'

import CategoryWidgets from '../../components/ZignaAi/competitiveLandscape/CategoryWidgets'

import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import FilterWidget from '../../components/ZignaAi/payersPriceCompare/FilterWidget'
import PriceDiffAvgWidget from '../../components/ZignaAi/payersPriceCompare/PriceDiffAvgWidget'
import PriceComparision from '../../components/ZignaAi/payersPriceCompare/PriceComparision'
import { useLocation, useHistory } from 'react-router-dom';

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {

		let location = useLocation();
		const history = useHistory();
  		const { filterType = [], hideModifier = false } = location.state || {};
		  useEffect(() => {
			if (!filterType.length) history.push("/");
		})

		useEffect(() => {
			console.log('service ref',filterType)
			// zigna_aiStore.getHospitalList()
			zigna_aiStore.resetPayerPriceCompList()
			zigna_aiStore.resetFilterData()
			if(zigna_aiStore.userType == 'facility'){
				filterType.length && zigna_aiStore.getHospitalList(filterType[0])
			}else{
				filterType.length && zigna_aiStore.getAncillaryList(filterType[0])
			}
		},[location, zigna_aiStore.userType])

	
		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4">
						<FilterWidget procCategory={filterType} hideModifier={hideModifier} location={location}/>
					</Col>
					<Row className={` ${zigna_aiStore.priceDiffAvgList.data.length ==0&&'blurDiv'} m-0 p-0`}>
						{
							zigna_aiStore.combinedPriceDiffList.map((calcData,i) => {
								return (<Col key={i} xs={12} lg={12} className="mb-4 w-100-1024 d-none-b768">
									<PriceDiffAvgWidget calcData={calcData} />
								</Col>)
							})
						}
						{(zigna_aiStore.userType !== 'professional' && zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('').length)?
						<Col xs={12} xl={12} className="mb-4">
							<PriceComparision procCategory={filterType} hideModifier={hideModifier} data={zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('') } pType=""  />
						</Col>
						:''}
						{(zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('percentage').length)?
						<Col xs={12} xl={12} className="mb-4">
							<PriceComparision procCategory={filterType} hideModifier={hideModifier} data={zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('percentage')} pType="percentage" />
						</Col>
						:''}
						
						{(zigna_aiStore.userType !== 'professional' && zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('per diem')?.length )?
						<Col xs={12} xl={12} className="mb-4">
							<PriceComparision procCategory={filterType} hideModifier={hideModifier} data={zigna_aiStore.getFilterPriceDiffAvgListBaseOnTypeName('per diem')} pType="per diem"   />
						</Col>
						:''}
						

						{
							zigna_aiStore.priceDiffAvgList.data.length ==0&&
							<div className='blurOverlay'><h5>Please select Filter</h5></div>
						}
					</Row>
				</Row>
			</>
		)
	})
)
