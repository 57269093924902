import React from 'react'

export const DirectionArrow = (props) => {
	return (
		<div className="d-flex gap-1 align-items-center">
			{props.value < 0 ? (
				<>
					{icnRed}
					<span className={`${props.textColor !== 'neutral' ? 'textRed' : ''}`}>
						{props?.children ? props.children : Math.abs(props.value) + '%'}
					</span>
				</>
			) : (
				<>
					<div>{icnGreen}</div>
					<span
						className={`${props.textColor !== 'neutral' ? 'textGreen' : ''}`}
					>
						{props?.children ? props.children : Math.abs(props.value) + '%'}
					</span>
				</>
			)}
		</div>
	)
}
const icnRed = (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle r="7.5" transform="matrix(1 0 0 -1 8 8)" stroke="#CF5353" />
		<path
			d="M8.5 12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12L8.5 12ZM7.64645 4.64645C7.84171 4.45118 8.15829 4.45118 8.35355 4.64645L11.5355 7.82843C11.7308 8.02369 11.7308 8.34027 11.5355 8.53553C11.3403 8.7308 11.0237 8.7308 10.8284 8.53553L8 5.70711L5.17157 8.53553C4.97631 8.7308 4.65973 8.7308 4.46447 8.53553C4.2692 8.34027 4.2692 8.02369 4.46447 7.82843L7.64645 4.64645ZM7.5 12L7.5 5L8.5 5L8.5 12L7.5 12Z"
			fill="#CF5353"
		/>
	</svg>
)
const icnGreen = (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="8" cy="8" r="7.5" stroke="#0D7318" />
		<path
			d="M8.5 4C8.5 3.72386 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.72386 7.5 4L8.5 4ZM7.64645 11.3536C7.84171 11.5488 8.15829 11.5488 8.35355 11.3536L11.5355 8.17157C11.7308 7.97631 11.7308 7.65973 11.5355 7.46447C11.3403 7.2692 11.0237 7.2692 10.8284 7.46447L8 10.2929L5.17157 7.46447C4.97631 7.2692 4.65973 7.2692 4.46447 7.46447C4.2692 7.65973 4.2692 7.97631 4.46447 8.17157L7.64645 11.3536ZM7.5 4L7.5 11L8.5 11L8.5 4L7.5 4Z"
			fill="#0D7318"
		/>
	</svg>
)
