import { Button, Card, Col, Modal, Row } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import payerIcn from '../../assets/img/zigna_ai/icn_payer_splash.png'
import providerIcn from '../../assets/img/zigna_ai/icn_provider_splash.png'
import { getPermissions } from '../../PermisionObject'
export const SplashScreen = inject('zigna_aiStore','authStore', 'providerPriceCompareStore')(
	observer(({ zigna_aiStore, authStore, providerPriceCompareStore }) => {
	let [isSplashOpen, setisSplashOpen] = useState(false)
	const handleSplashBtn = (type) => {
		localStorage.setItem('userType', type);
		zigna_aiStore.userType = type;
		providerPriceCompareStore.userType = type;
		setisSplashOpen(false);
	}

	useEffect(()=>{
		if(!zigna_aiStore.userType){
			setisSplashOpen(true);
		}
	},[zigna_aiStore?.userType])

	const permissions=getPermissions(authStore?.currentUser?.client_name)
	return (
		<>
			<Modal
				as={Modal.Dialog}
				centered
				show={isSplashOpen}
				onHide={handleSplashBtn}
				size="xl"
				className="splashPage"
			>
				{/* <Modal.Header>
					<Modal.Title className="h6">Terms of Service</Modal.Title>
					<Button variant="close" aria-label="Close" onClick={handleSplashBtn} />
				</Modal.Header> */}
				<Modal.Body>
					<div className="p-3 p10 primary splashHeader">
						{permissions?.showDemoButton?<center><h5 className="text-primary">
						Welcome to RightPx - Your Competitive Intelligence Tool for Informed Decision Making
						</h5></center>:<h5 className="text-primary">
							Please select "Facility" or "Ancillary" or "Professional" to access relevant rate information.
						</h5>}
						{permissions?.shownoteInHomeModal?<small style={{fontSize:14,color:"#000"}}>
						<i>Our exceptional AI-powered tool, RightPx, delivers pivotal insights into your competitors' strategies. It reveals targeted information tailored to your market, competitors, providers, code sets and billing codes.		
</i>
						</small>:null}






						{/* With less than a month to go before the European Union enacts new
						consumer privacy laws for its citizens, companies around the world
						are updating their terms of service agreements to comply. */}
					</div>
					{
	permissions?.shownoteInHomeModal?<Row>
		<Col xs={12} className='d-flex justify-content-center'>
<span className='text-primary font-bold my-4' style={{fontWeight:500}}>Kindly note that this is sample demo data and please <a href="https://www.zignaai.com/contact-us" target="_blank" style={{color:"red"}}>contact us</a> for a complete product overview.</span>
</Col>
<Col xs={12} md={4}>
<div className='splashHeader rounded p-4' style={{height:"100%"}}>
	<img src="/assets/img/zigna_ai/splashdemo.png" style={{height:"auto",width:"100%"}}/>
	</div>
</Col>
<Col xs={12} md={8}>
	<div className='splashHeader rounded p-4' style={{color:"#000" ,height:"100%"}}>
<b>Here's what you can expect from RightPx</b>
<br/>
<br/>

 <ul>
	<li>Tailored competitive insights by market, payer, provider, billing codes, etc.</li>
	<li>Analyze the data to generate insights and recommendations to further your network strategy</li>
	<li>Win new or retain existing business</li>
 </ul>





 

<center className='text-primary' style={{fontWeight:500}}>You are just a click away to discover how your organization can transform your decision-making and contracting capabilities.</center>
<Row>
<Col>
<div className='d-flex justify-content-center'>
<Button className='mt-4' 
										
											variant="primary" size="sm" onClick={()=>handleSplashBtn(permissions?.facility?.length?'facility':'ancillary')}>
												Access Now
												
											</Button>
											</div>
</Col>
				</Row>
</div>
</Col>
</Row>:null
}




				{	permissions?.shownoteInHomeModal?null:<Row>
						<Col xl={12} sm={12} md={10} className="m-auto">
							<Row className="justify-content-between splashContent">
								<Col  className="mt-5">
									<Card border="light" >
										<div className="icn_splash">
											<img src={payerIcn} />
										</div>
										<Card.Body>
											<h5 className="text-primary">Facility</h5>
											<p>
												This includes all facilities such as General Acute Care Hospitals. 
											</p>
										</Card.Body>
										<Card.Footer className="text-right border-0">
											<Button 
											disabled={permissions?.facility?.length==0}
											style={{opacity:permissions?.facility?.length==0?0.2:1}}
											variant="primary" size="sm" onClick={()=>handleSplashBtn('facility')}>
												Click here{' '}
												<svg
													width="8"
													height="7"
													viewBox="0 0 8 7"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8 3.5L5 6.53109L5 0.468911L8 3.5Z"
														fill="white"
													/>
													<path
														d="M3 3.5L0 6.53109L0 0.468911L3 3.5Z"
														fill="white"
													/>
												</svg>
											</Button>
										</Card.Footer>
									</Card>
								</Col>
								<Col  className="mt-5">
									<Card border="light" >
										<div className="icn_splash">
											<img src={payerIcn} />
										</div>
										<Card.Body>
											<h5 className="text-primary">Ancillary</h5>
											<p>
											This includes all facilities such as Skilled Nursing Facilities, Psychiatric Units, Rehabilitation Units etc.
											</p>
										</Card.Body>
										<Card.Footer className="text-right border-0">
											<Button 
											disabled={permissions?.ancillary?.length==0}
											style={{opacity:permissions?.ancillary?.length==0?0.2:1}}
											variant="primary" size="sm" onClick={()=>handleSplashBtn('ancillary')}>
												Click here{' '}
												<svg
													width="8"
													height="7"
													viewBox="0 0 8 7"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8 3.5L5 6.53109L5 0.468911L8 3.5Z"
														fill="white"
													/>
													<path
														d="M3 3.5L0 6.53109L0 0.468911L3 3.5Z"
														fill="white"
													/>
												</svg>
											</Button>
										</Card.Footer>
									</Card>
								</Col>
								<Col  className="mt-5">
									<Card border="light" >
										<div className="icn_splash">
											<img src={providerIcn} />
										</div>
										<Card.Body>
											<h5 className="text-primary">Professional</h5>
											<p>
											This includes all physicians - primary care as well as specialists.
											<br></br>
											<br></br>
											<br></br>
											</p>
										</Card.Body>
										<Card.Footer className="text-right border-0">
											<Button 
											disabled={permissions?.professional?.length==0}
											style={{opacity:permissions?.professional?.length==0?0.2:1}}
											variant="primary" size="sm" onClick={()=>handleSplashBtn('professional')}>
												Click here{'  '}
												<svg
													width="8"
													height="7"
													viewBox="0 0 8 7"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8 3.5L5 6.53109L5 0.468911L8 3.5Z"
														fill="white"
													/>
													<path
														d="M3 3.5L0 6.53109L0 0.468911L3 3.5Z"
														fill="white"
													/>
												</svg>
											</Button>
										</Card.Footer>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>}
				</Modal.Body>
			</Modal>
		</>
	)
}))
