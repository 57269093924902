// import axiosConfig from 'axiosConfig'
import React, { Component } from 'react'
import axiosConfig, { url } from '../../../../store/Config'

export class ProvidersPriceCompareService extends Component {
	getRegionList = (state) => {
		return axiosConfig.post(`${url}/getProvRegionList`,state)
	}
	getSpecialtyList = (state) => {
		return axiosConfig.post(`${url}/getProvSpecialtyList`, state)
	}
	getGroupList = (state) => {
		return axiosConfig.post(`${url}/getProvGroupList`, state)
	}
	getPlaceOfServiceList = (state) => {
		return axiosConfig.post(`${url}/getProvPlaceOfServiceList`, state)
	}
	getFeeScheduleTypeList = (state) => {
		return axiosConfig.post(`${url}/getProvFeeScheduleTypeList`, state)
	}
	getReportingEntityNameList= (state) => {
		return axiosConfig.post(`${url}/getProvReportingEntityNameList`, state)
	} 
	getBillingCodeList= (state) => {
		return axiosConfig.post(`${url}/getProvBillingCodeList`, state)
	}
	geBillingCodeModifierList= (state) => {
		return axiosConfig.post(`${url}/getProvBillingCodeModifierList`, state)
	}
	getProceduresCatList = (state) => {
		return axiosConfig.post(`${url}/getProvProceduresCatList`, state)
	}
	getLevel1DescList = (state) => {
		return axiosConfig.post(`${url}/getProvLevel1DescList`, state)
	}
	getProcedureNameList = (state) => {
		return axiosConfig.post(`${url}/getProvProcedureNameList`, state)
	}
	getmodifierList = (body) => {
		return axiosConfig.post(`${url}/getProvModifierList`, body)
	}
	getPayerPriceCompList = (body) => {
		console.log('service call')
		//return axiosConfig.get(`./data/priceDiffAvgList.json`)
		return axiosConfig.post(`${url}/getProvPayerPriceCompList`, body)
	}
	getCategoryExtractResults = (body) => {
		console.log('service call')
		//return axiosConfig.get(`./data/priceDiffAvgList.json`)
		return axiosConfig.post(`${url}/getCategoryFilterResults`, body)
	}
}
