import React from 'react'
import { Card, Col, Row } from '@themesberg/react-bootstrap'

import CategoryWidgets from '../../components/ZignaAi/competitiveLandscape/CategoryWidgets'

import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import FilterWidget from '../../components/ZignaAi/providersPriceCompare/FilterWidget'
import PriceComparision from '../../components/ZignaAi/providersPriceCompare/PriceComparision'
import PriceDiffAvgWidget from '../../components/ZignaAi/providersPriceCompare/PriceDiffAvgWidget'
import PriceWidget from '../../components/ZignaAi/providersPriceCompare/PriceWidget'
import CommonsLabelDesc from '../../components/ZignaAi/common/CommonsLabelDesc'
import { useLocation, useHistory } from 'react-router-dom';
import { CommonTable } from '../../components/ZignaAi/common/CommonTable'

export default inject(
	'zigna_aiStore',
	'providerPriceCompareStore',
	'authStore'
)(
	observer(({ zigna_aiStore, providerPriceCompareStore, authStore }) => {

		let location = useLocation();
		const history = useHistory();
  		const { filterType = [], hideModifier = false } = location.state || {};

		useEffect(() => {
			if (!filterType.length) history.push("/");
		},[])

		useEffect(() => {
			
			providerPriceCompareStore.resetPayerPriceCompList()
			providerPriceCompareStore.resetFilterData()
			providerPriceCompareStore.getRegionList()
			filterType.length && providerPriceCompareStore.setFilterData(
				'procedureCat',
				filterType[0]
			)
		}, [location])
		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4">
						<FilterWidget 
							procCategory={filterType} 
							hideModifier={hideModifier}
							showExport={true}
							showAggMeasure={true}
							actionFunc={providerPriceCompareStore.getPayerPriceCompList}
							hideProcCategoty={false}
						/>
					</Col>
					<Row className="position-relative">
						{/* <Col xs={12} lg={8} className="mb-4 w-100-1024 d-none-b768">
							<PriceWidget />
						</Col> */}
						{
							 providerPriceCompareStore.providerPriceListData?.length  !=0 &&
							<Card
								border="light"
								className="shadow-sm filterSection pricCompSection"
							>
								<Card.Header className="pb-0">
									<Row className="align-items-center">
										<Col>
											<h5>Price Comparison with {authStore?.currentUser?.payer} ({filterType})</h5>
										</Col>
									</Row>
								</Card.Header>
								<Card.Body>
								<CommonTable
									columns={providerPriceCompareStore?.providerPriceListColumnData}
									data={providerPriceCompareStore?.providerPriceListData}
									className="zignaAi_table_1"
								/>
								</Card.Body>
								{/* <Card.Body>
									<Row>
									{providerPriceCompareStore.selectedFilterData.enhanced && (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
											style={{borderRight: providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community ? '1px solid #ccc':0}}
										>
											<PriceComparision type={'Enhanced'} procCategory={filterType} hideModifier={hideModifier}  data={providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','')} />
										</Col>
									)}
									{providerPriceCompareStore.selectedFilterData.community && (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
										>
											<PriceComparision type={'Community'} procCategory={filterType} hideModifier={hideModifier} data={providerPriceCompareStore.getFilterPriceDiffAvgList('Community','')} />
										</Col>
									)}

									{providerPriceCompareStore.selectedFilterData.enhanced && (providerPriceCompareStore.getFilterPriceDiffAvgList('Community','percentage').length || providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','percentage').length) &&  (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
										>
											<PriceComparision  type={'Enhanced(percentage)'} procCategory={filterType} hideModifier={hideModifier} data={providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','percentage')}  />
										</Col>
									)}
									
									{providerPriceCompareStore.selectedFilterData.community && (providerPriceCompareStore.getFilterPriceDiffAvgList('Community','percentage').length || providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','percentage').length) && (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
										>
											<PriceComparision  type={'Community(percentage)'} procCategory={filterType} hideModifier={hideModifier} data={providerPriceCompareStore.getFilterPriceDiffAvgList('Community','percentage')}  />
										</Col>
									)}

									{providerPriceCompareStore.selectedFilterData.enhanced && (providerPriceCompareStore.getFilterPriceDiffAvgList('Community','per diem').length || providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','per diem').length) &&  (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
										>
											<PriceComparision  type={'Enhanced(per diem)'} procCategory={filterType} hideModifier={hideModifier} data={providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','per diem')}  />
										</Col>
									)}
									
									{providerPriceCompareStore.selectedFilterData.community && (providerPriceCompareStore.getFilterPriceDiffAvgList('Community','per diem').length || providerPriceCompareStore.getFilterPriceDiffAvgList('Enhanced','per diem').length) && (
										<Col
											xs={12}
											xl={
												providerPriceCompareStore.selectedFilterData.enhanced &&
												providerPriceCompareStore.selectedFilterData.community
													? 6
													: 12
											}
											className="mb-4"
										>
											<PriceComparision  type={'Community(per diem)'} procCategory={filterType} hideModifier={hideModifier} data={providerPriceCompareStore.getFilterPriceDiffAvgList('Community','per diem')}  />
										</Col>
									)}
									
									</Row>
									
								</Card.Body> */}
							</Card>
						}
						{providerPriceCompareStore.providerPriceListData?.length == 0 && (
							<div className="blurOverlay">
								<h5>Please select Filter</h5>
							</div>
						)}
					</Row>
				</Row>
			</>
		)
	})
)
