import React from 'react'
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { ResponsiveBar } from '@nivo/bar'
import { addString, convertPrice } from '../../../Utilities'
import { DirectionArrow } from '../common/DirectionArrow'
import CommonsLabelDesc from '../common/CommonsLabelDesc'
import { BasicTooltip, useTooltip } from '@nivo/tooltip'

let chartMargin = { top: 50, right: 80, bottom: 50, left: 250 }
const BAR_MAX_HEIGHT = 25
const CustomBarComponent = ({
	bar: { x, y, width, height, color, data },
	...rest
}) => {
	const h = height > BAR_MAX_HEIGHT ? BAR_MAX_HEIGHT : height
	const { showTooltipFromEvent, hideTooltip } = useTooltip()
	// console.log(data)
	return (
		<rect
			x={x}
			y={y + height / 2 - h / 2}
			width={width}
			height={h}
			rx={3}
			fill={color}
			onMouseEnter={(event) =>
				showTooltipFromEvent(
					<BasicTooltip id={data.indexValue} value={convertPrice(data?.value)} />,
					event
				)
			}
			onMouseLeave={() => hideTooltip()}
			onMouseMove={(event) =>
				showTooltipFromEvent(
					<BasicTooltip id={data?.indexValue} value={convertPrice(data?.value)}/>,
					event
				)
			}
		/>
	)
}
const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
	const MAX_LINE_LENGTH = 25
	const MAX_LINES = 2
	const LENGTH_OF_ELLIPSIS = 3
	const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS
	const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`)
	const groupWordsByLength = new RegExp(
		`([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
		'gm'
	)
	const splitValues = value
		.replace(trimWordsOverLength, '$1...')
		.match(groupWordsByLength)
		.slice(0, 2)
		.map((val, i) => (
			<tspan
				key={val}
				dy={14 * i}
				x={-10}
				// style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
			>
				{val}
			</tspan>
		))
	return (
		<g transform={`translate(${x},${y})`}>
			<text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
				{splitValues}
			</text>
		</g>
	)
}
export default inject('providerPriceCompareStore')(
	observer(({ providerPriceCompareStore ,...props}) => {
		const DataLabelOutside = ({ bars }) => {
			return bars.map((bar) => {
				const {
					key,
					width,
					height,
					x,
					y,
					data: { value },
				} = bar
				var canvas = document.createElement('canvas')
				var ctx = canvas.getContext('2d')
				var textWidth = 5 + ctx.measureText(parseInt(value)).width
				return (
					<g key={key} transform={`translate(${x}, ${y})`}>
						<text
							transform={`translate(${width + textWidth}, ${height / 2 + 5})`}
							textAnchor="middle"
							// fontSize="11px"
						>
							{/* YOUR LABEL HERE */}
							{convertPrice(value)}
						</text>
					</g>
				)
			})
		}

		const compareData =props.data //providerPriceCompareStore.priceDiffAvgList?.data?.filter( obj => obj.providerType === props.type && obj.typeName != 'percentage' && obj.typeName != 'per diem')
		// console.log(JSON.parse(JSON.stringify(compareData)),'compareData')
		return (
			// <Card border="light" className="shadow-sm filterSection pricCompSection">
			// 	<Card.Header>
			// 		<Row className="align-items-center">
			// 			<Col>
			// 				<h5>Price Comparison with Excellus</h5>
			// 			</Col>
			// 		</Row>
			// 	</Card.Header>
			// 	<Card.Body>
			<>
				<h5 className='widget_subheader'>{props.type}</h5>
				<Row>
					<Col
						xl={12}
						md={12}
						sm={12}
						xs={12}
						className="mb-4 mt-12-b768"
						style={{
							height:
								100 +
								compareData?.length * 50,
						}}
					>
						<ResponsiveBar
							barComponent={CustomBarComponent}
							theme={{
								fontSize: '.9rem',
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1,
										},
									},
								},
							}}
							layers={[
								'grid',
								'axes',
								'bars',
								'markers',
								'legends',
								'annotations',
								DataLabelOutside,
							]}
							enableLabel={false}
							data={compareData}
							keys={['price']}
							indexBy="payerName"
							borderRadius={3}
							margin={chartMargin}
							padding={0.3}
							layout="horizontal"
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							colors={['#8B6CBC']}
							enableGridX={false}
							enableGridY={false}
							borderColor={{
								from: 'color',
								modifiers: [['darker', 1.6]],
							}}
							axisTop={null}
							axisRight={null}
							axisBottom={{
								// tickValues: [1000, 2000, 3000, 4000, 5000],
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: '',
								legendPosition: 'middle',
								legendOffset: 32,
								format: function (value) {
									return convertPrice(value)
								},
							}}
							axisLeft={{
								legend: '',
								legendPosition: 'middle',
								legendOffset: -40,
								renderTick: HorizontalTick,
								tickPadding: 4,
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{
								from: 'color',
								modifiers: [['darker', 1.6]],
							}}
							legends={[]}
							barAriaLabel={function (e) {
								return (
									e.id +
									': ' +
									e.formattedValue +
									' in payerName: ' +
									e.indexValue
								)
							}}
							tooltip={(bar, color, label) => {
								return <b style={{ background: 'white'}}>{bar.indexValue}: {convertPrice(bar.value)}</b>
							}}
						/>
					</Col>
					{/* <Col sm={10} className="m0-auto col-10">
							<CommonsLabelDesc>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
								rutrum mattis erat, vitae tristique mauris maximus pretium. Cras
								venenatis pulvinar malesuada.
							</CommonsLabelDesc>
						</Col> */}
				</Row>
			</>
			// 	</Card.Body>
			// </Card>
		)
	})
)
