import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { navData } from "../data/ZignaAi/NavData";
import { inject, observer } from "mobx-react";
import { getPermissions, getFilteredSideList } from "../PermisionObject";
export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    const location = useLocation();
    const { pathname, id } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const CollapsableNavItem = (props) => {
      const { eventKey, title, icon, children = null } = props;
      const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

      return (
        <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
          <Accordion.Item eventKey={eventKey}>
            <Accordion.Button
              as={Nav.Link}
              className="d-flex justify-content-between align-items-center"
            >
              <span>
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
                <span className="sidebar-text">{title}</span>
              </span>
            </Accordion.Button>
            <Accordion.Body className="multi-level">
              <Nav className="flex-column">{children}</Nav>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    };

    const NavItem = (props) => {
      const {
        title,
        link,
        external,
        target,
        icon,
        image,
        badgeText,
        badgeBg = "secondary",
        badgeColor = "primary",
        disabled,
      } = props;
      const classNames = badgeText
        ? "d-flex justify-content-start align-items-center justify-content-between text-capitalize"
        : "text-capitalize";
      const navItemClassName =
        link?.pathname === pathname && link?.id === id ? "active" : "";
      const linkProps = external ? { href: link } : { as: Link, to: link };
      return (
        <Nav.Item
          className={navItemClassName}
          onClick={() => setShow(false)}
          disabled={disabled}
        >
          <Nav.Link {...linkProps} target={target} className={classNames}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}
            >
              <div style={{ minWidth: 30, maxWidth: 50 }}>
                {icon ? icon : null}
              </div>
              <div style={{ marginLeft: 4, whiteSpace: "normal" }}>{title}</div>
            </div>
            {badgeText ? (
              <Badge
                pill
                bg={badgeBg}
                text={badgeColor}
                className="badge-md notification-count ms-2"
              >
                {badgeText}
              </Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      );
    };
    const client_name = authStore?.currentUser?.client_name;
    const userType = zigna_aiStore.userType;
    return (
      <>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          className="customNavToggler d-block-1024"
        >
          <div className="svgMenu cross svgMenu--2">
            <label>
              <input type="checkbox" />
              <svg
                onClick={onCollapse}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path className="line--1" d="M0 70l28-28c2-2 2-2 7-2h64" />
                <path className="line--2" d="M0 50h99" />
                <path className="line--3" d="M0 30l28 28c2 2 2 2 7 2h64" />
              </svg>
            </label>
          </div>
        </Navbar.Toggle>
        <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
          <SimpleBar
            className={`collapse ${showClass} sidebar d-none-1024 text-white zignaAISidebar`}
          >
            <div className="sidebar-inner">
              <Nav className="flex-column pt-3 pt-md-0">
                {getFilteredSideList(
                  getPermissions(client_name)[userType],
                  navData(getPermissions(client_name))
                ).map((nav, i) => {
                  //const isDisabled = (zigna_aiStore.userType == 'professional' && nav.pageName === 'Top DRGs	Overview')
                  //if (isDisabled) return null
                  //if(zigna_aiStore.userType == 'professional' &&  nav.id>=6) return null

                  if (
                    nav.accessTo &&
                    !nav.accessTo.includes(zigna_aiStore.userType)
                  )
                    return null;
                  return (
                    <NavItem
                      key={`navbar_${i}`}
                      title={nav.pageName}
                      link={{
                        id: nav.id,
                        pathname: nav.path,
                        state: {
                          pageId: navData?.pageId,
                          filterType: nav.filterType,
                          hideModifier: nav.hideModifier,
                        },
                      }}
                      // link={nav.path}
                      icon={iconSelector(i)}
                      disabled={true}
                      //image={iconSelector(i)}
                    />
                  );
                })}
              </Nav>
              <Nav className="flex-column pt-3 pt-md-0"></Nav>
            </div>
          </SimpleBar>
        </CSSTransition>
      </>
    );
  })
);
const iconSelector = (value, { height = 18, width = 18 } = {}) => {
  let icon = [
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.79518 1.25368C0.916504 2.13236 0.916504 3.54657 0.916504 6.375V25C0.916504 27.8284 0.916504 29.2426 1.79518 30.1213C2.67386 31 4.08808 31 6.9165 31H25.5415C28.3699 31 29.7841 31 30.6628 30.1213C31.5415 29.2426 31.5415 27.8284 31.5415 25V6.375C31.5415 3.54657 31.5415 2.13236 30.6628 1.25368C29.7841 0.375 28.3699 0.375 25.5415 0.375H6.9165C4.08808 0.375 2.67386 0.375 1.79518 1.25368ZM22.354 9.58333C22.9063 9.58333 23.354 10.031 23.354 10.5833V24.1944C23.354 24.7467 22.9063 25.1944 22.354 25.1944C21.8017 25.1944 21.354 24.7467 21.354 24.1944V10.5833C21.354 10.031 21.8017 9.58333 22.354 9.58333ZM11.104 13.9861C11.104 13.4338 10.6563 12.9861 10.104 12.9861C9.55172 12.9861 9.104 13.4338 9.104 13.9861L9.104 24.1945C9.104 24.7467 9.55172 25.1945 10.104 25.1945C10.6563 25.1945 11.104 24.7467 11.104 24.1945V13.9861ZM17.229 17.3889C17.229 16.8366 16.7813 16.3889 16.229 16.3889C15.6767 16.3889 15.229 16.8366 15.229 17.3889V24.1944C15.229 24.7467 15.6767 25.1944 16.229 25.1944C16.7813 25.1944 17.229 24.7467 17.229 24.1944V17.3889Z"
        fill="white"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11H27V9H8V11ZM23 16H27V14H8V16H23ZM16.0709 24C16.1719 24.7061 16.3783 25.3783 16.6736 26H8V24H16.0709ZM17.2547 19C16.8334 19.604 16.5049 20.2776 16.2899 21H8V19H17.2547Z"
        fill="white"
      />
      <rect
        x="1.5"
        y="1.5"
        width="32"
        height="32"
        rx="3.5"
        stroke="white"
        strokeWidth="3"
      />
      <circle cx="24" cy="23" r="6" fill="white" />
      <path d="M24 20L26.5981 24.5H21.4019L24 20Z" fill="#3D2260" />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.07661 25.25L17.5 2L30.9234 25.25H4.07661Z"
        fill="#3C265F"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.0048 21.25L17.5 10L23.9952 21.25H11.0048Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <circle
        cx="32"
        cy="24"
        r="9"
        fill="white"
        stroke="#3C265F"
        strokeWidth="2"
      />
      <path
        d="M29.5508 25.8789V21.9297C29.5508 21.4766 29.7188 21.0352 30.0547 20.6055C30.5 20.0352 31.1016 19.7461 31.8594 19.7383V21.3906C31.5312 21.3984 31.3203 21.5508 31.2266 21.8477C31.2109 21.8789 31.2031 21.9062 31.2031 21.9297V25.8789C31.2031 26.0039 31.2695 26.125 31.4023 26.2422C31.5273 26.3516 31.6797 26.4062 31.8594 26.4062V28.0586C30.9844 28.0586 30.3242 27.6914 29.8789 26.957C29.6602 26.582 29.5508 26.2227 29.5508 25.8789ZM32.4922 28.0586V26.4062C32.4922 26.4062 32.582 26.3711 32.7617 26.3008C32.8711 26.2227 32.9453 26.1406 32.9844 26.0547C33.0234 25.9531 33.043 25.8086 33.043 25.6211V25.4805L34.6602 25.8672C34.6602 26.5469 34.3867 27.1094 33.8398 27.5547C33.4258 27.8906 32.9766 28.0586 32.4922 28.0586ZM32.4922 21.3906V19.7383C33.0625 19.7383 33.5781 19.9883 34.0391 20.4883C34.4531 20.9414 34.6602 21.4297 34.6602 21.9531L33.043 22.375V22.1992C33.043 22.0039 33.0234 21.8594 32.9844 21.7656C32.9531 21.6875 32.8945 21.6172 32.8086 21.5547C32.7305 21.4922 32.6602 21.4492 32.5977 21.4258L32.4922 21.3906Z"
        fill="#3C265F"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="3" fill="white" />
      <path
        d="M13.297 14.98C13.297 15.6733 13.117 16.3267 12.757 16.94C12.4104 17.54 11.8904 18.04 11.197 18.44C10.517 18.8267 9.71036 19.0533 8.77703 19.12V20.74H7.49703V19.1C6.1637 18.98 5.09036 18.58 4.27703 17.9C3.4637 17.2067 3.0437 16.2733 3.01703 15.1H6.01703C6.09703 16.06 6.59036 16.64 7.49703 16.84V13.02C6.53703 12.78 5.7637 12.54 5.17703 12.3C4.59036 12.06 4.0837 11.6733 3.65703 11.14C3.23036 10.6067 3.01703 9.88 3.01703 8.96C3.01703 7.8 3.43036 6.85333 4.25703 6.12C5.09703 5.38667 6.17703 4.96667 7.49703 4.86V3.24H8.77703V4.86C10.057 4.96667 11.077 5.35333 11.837 6.02C12.6104 6.68667 13.0437 7.60667 13.137 8.78H10.117C10.077 8.39333 9.93703 8.06 9.69703 7.78C9.47036 7.48667 9.1637 7.28 8.77703 7.16V10.94C9.77703 11.1933 10.5637 11.44 11.137 11.68C11.7237 11.9067 12.2304 12.2867 12.657 12.82C13.0837 13.34 13.297 14.06 13.297 14.98ZM5.93703 8.82C5.93703 9.26 6.07036 9.62 6.33703 9.9C6.6037 10.1667 6.99036 10.3867 7.49703 10.56V7.1C7.01703 7.16667 6.63703 7.34667 6.35703 7.64C6.07703 7.93333 5.93703 8.32667 5.93703 8.82ZM8.77703 16.88C9.2837 16.7867 9.67703 16.58 9.95703 16.26C10.2504 15.94 10.397 15.5533 10.397 15.1C10.397 14.66 10.257 14.3067 9.97703 14.04C9.69703 13.7733 9.29703 13.5533 8.77703 13.38V16.88Z"
        fill="#3C265F"
      />
      <path
        d="M29.297 26.98C29.297 27.6733 29.117 28.3267 28.757 28.94C28.4104 29.54 27.8904 30.04 27.197 30.44C26.517 30.8267 25.7104 31.0533 24.777 31.12V32.74H23.497V31.1C22.1637 30.98 21.0904 30.58 20.277 29.9C19.4637 29.2067 19.0437 28.2733 19.017 27.1H22.017C22.097 28.06 22.5904 28.64 23.497 28.84V25.02C22.537 24.78 21.7637 24.54 21.177 24.3C20.5904 24.06 20.0837 23.6733 19.657 23.14C19.2304 22.6067 19.017 21.88 19.017 20.96C19.017 19.8 19.4304 18.8533 20.257 18.12C21.097 17.3867 22.177 16.9667 23.497 16.86V15.24H24.777V16.86C26.057 16.9667 27.077 17.3533 27.837 18.02C28.6104 18.6867 29.0437 19.6067 29.137 20.78H26.117C26.077 20.3933 25.937 20.06 25.697 19.78C25.4704 19.4867 25.1637 19.28 24.777 19.16V22.94C25.777 23.1933 26.5637 23.44 27.137 23.68C27.7237 23.9067 28.2304 24.2867 28.657 24.82C29.0837 25.34 29.297 26.06 29.297 26.98ZM21.937 20.82C21.937 21.26 22.0704 21.62 22.337 21.9C22.6037 22.1667 22.9904 22.3867 23.497 22.56V19.1C23.017 19.1667 22.637 19.3467 22.357 19.64C22.077 19.9333 21.937 20.3267 21.937 20.82ZM24.777 28.88C25.2837 28.7867 25.677 28.58 25.957 28.26C26.2504 27.94 26.397 27.5533 26.397 27.1C26.397 26.66 26.257 26.3067 25.977 26.04C25.697 25.7733 25.297 25.5533 24.777 25.38V28.88Z"
        fill="#3C265F"
      />
      <line
        x1="22.1547"
        y1="9.36969"
        x2="10.5411"
        y2="25.131"
        stroke="#3C265F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="3" fill="white" />
      <path
        d="M13.297 14.98C13.297 15.6733 13.117 16.3267 12.757 16.94C12.4104 17.54 11.8904 18.04 11.197 18.44C10.517 18.8267 9.71036 19.0533 8.77703 19.12V20.74H7.49703V19.1C6.1637 18.98 5.09036 18.58 4.27703 17.9C3.4637 17.2067 3.0437 16.2733 3.01703 15.1H6.01703C6.09703 16.06 6.59036 16.64 7.49703 16.84V13.02C6.53703 12.78 5.7637 12.54 5.17703 12.3C4.59036 12.06 4.0837 11.6733 3.65703 11.14C3.23036 10.6067 3.01703 9.88 3.01703 8.96C3.01703 7.8 3.43036 6.85333 4.25703 6.12C5.09703 5.38667 6.17703 4.96667 7.49703 4.86V3.24H8.77703V4.86C10.057 4.96667 11.077 5.35333 11.837 6.02C12.6104 6.68667 13.0437 7.60667 13.137 8.78H10.117C10.077 8.39333 9.93703 8.06 9.69703 7.78C9.47036 7.48667 9.1637 7.28 8.77703 7.16V10.94C9.77703 11.1933 10.5637 11.44 11.137 11.68C11.7237 11.9067 12.2304 12.2867 12.657 12.82C13.0837 13.34 13.297 14.06 13.297 14.98ZM5.93703 8.82C5.93703 9.26 6.07036 9.62 6.33703 9.9C6.6037 10.1667 6.99036 10.3867 7.49703 10.56V7.1C7.01703 7.16667 6.63703 7.34667 6.35703 7.64C6.07703 7.93333 5.93703 8.32667 5.93703 8.82ZM8.77703 16.88C9.2837 16.7867 9.67703 16.58 9.95703 16.26C10.2504 15.94 10.397 15.5533 10.397 15.1C10.397 14.66 10.257 14.3067 9.97703 14.04C9.69703 13.7733 9.29703 13.5533 8.77703 13.38V16.88Z"
        fill="#3C265F"
      />
      <path
        d="M29.297 26.98C29.297 27.6733 29.117 28.3267 28.757 28.94C28.4104 29.54 27.8904 30.04 27.197 30.44C26.517 30.8267 25.7104 31.0533 24.777 31.12V32.74H23.497V31.1C22.1637 30.98 21.0904 30.58 20.277 29.9C19.4637 29.2067 19.0437 28.2733 19.017 27.1H22.017C22.097 28.06 22.5904 28.64 23.497 28.84V25.02C22.537 24.78 21.7637 24.54 21.177 24.3C20.5904 24.06 20.0837 23.6733 19.657 23.14C19.2304 22.6067 19.017 21.88 19.017 20.96C19.017 19.8 19.4304 18.8533 20.257 18.12C21.097 17.3867 22.177 16.9667 23.497 16.86V15.24H24.777V16.86C26.057 16.9667 27.077 17.3533 27.837 18.02C28.6104 18.6867 29.0437 19.6067 29.137 20.78H26.117C26.077 20.3933 25.937 20.06 25.697 19.78C25.4704 19.4867 25.1637 19.28 24.777 19.16V22.94C25.777 23.1933 26.5637 23.44 27.137 23.68C27.7237 23.9067 28.2304 24.2867 28.657 24.82C29.0837 25.34 29.297 26.06 29.297 26.98ZM21.937 20.82C21.937 21.26 22.0704 21.62 22.337 21.9C22.6037 22.1667 22.9904 22.3867 23.497 22.56V19.1C23.017 19.1667 22.637 19.3467 22.357 19.64C22.077 19.9333 21.937 20.3267 21.937 20.82ZM24.777 28.88C25.2837 28.7867 25.677 28.58 25.957 28.26C26.2504 27.94 26.397 27.5533 26.397 27.1C26.397 26.66 26.257 26.3067 25.977 26.04C25.697 25.7733 25.297 25.5533 24.777 25.38V28.88Z"
        fill="#3C265F"
      />
      <line
        x1="22.1547"
        y1="9.36969"
        x2="10.5411"
        y2="25.131"
        stroke="#3C265F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="3" fill="white" />
      <path
        d="M13.297 14.98C13.297 15.6733 13.117 16.3267 12.757 16.94C12.4104 17.54 11.8904 18.04 11.197 18.44C10.517 18.8267 9.71036 19.0533 8.77703 19.12V20.74H7.49703V19.1C6.1637 18.98 5.09036 18.58 4.27703 17.9C3.4637 17.2067 3.0437 16.2733 3.01703 15.1H6.01703C6.09703 16.06 6.59036 16.64 7.49703 16.84V13.02C6.53703 12.78 5.7637 12.54 5.17703 12.3C4.59036 12.06 4.0837 11.6733 3.65703 11.14C3.23036 10.6067 3.01703 9.88 3.01703 8.96C3.01703 7.8 3.43036 6.85333 4.25703 6.12C5.09703 5.38667 6.17703 4.96667 7.49703 4.86V3.24H8.77703V4.86C10.057 4.96667 11.077 5.35333 11.837 6.02C12.6104 6.68667 13.0437 7.60667 13.137 8.78H10.117C10.077 8.39333 9.93703 8.06 9.69703 7.78C9.47036 7.48667 9.1637 7.28 8.77703 7.16V10.94C9.77703 11.1933 10.5637 11.44 11.137 11.68C11.7237 11.9067 12.2304 12.2867 12.657 12.82C13.0837 13.34 13.297 14.06 13.297 14.98ZM5.93703 8.82C5.93703 9.26 6.07036 9.62 6.33703 9.9C6.6037 10.1667 6.99036 10.3867 7.49703 10.56V7.1C7.01703 7.16667 6.63703 7.34667 6.35703 7.64C6.07703 7.93333 5.93703 8.32667 5.93703 8.82ZM8.77703 16.88C9.2837 16.7867 9.67703 16.58 9.95703 16.26C10.2504 15.94 10.397 15.5533 10.397 15.1C10.397 14.66 10.257 14.3067 9.97703 14.04C9.69703 13.7733 9.29703 13.5533 8.77703 13.38V16.88Z"
        fill="#3C265F"
      />
      <path
        d="M29.297 26.98C29.297 27.6733 29.117 28.3267 28.757 28.94C28.4104 29.54 27.8904 30.04 27.197 30.44C26.517 30.8267 25.7104 31.0533 24.777 31.12V32.74H23.497V31.1C22.1637 30.98 21.0904 30.58 20.277 29.9C19.4637 29.2067 19.0437 28.2733 19.017 27.1H22.017C22.097 28.06 22.5904 28.64 23.497 28.84V25.02C22.537 24.78 21.7637 24.54 21.177 24.3C20.5904 24.06 20.0837 23.6733 19.657 23.14C19.2304 22.6067 19.017 21.88 19.017 20.96C19.017 19.8 19.4304 18.8533 20.257 18.12C21.097 17.3867 22.177 16.9667 23.497 16.86V15.24H24.777V16.86C26.057 16.9667 27.077 17.3533 27.837 18.02C28.6104 18.6867 29.0437 19.6067 29.137 20.78H26.117C26.077 20.3933 25.937 20.06 25.697 19.78C25.4704 19.4867 25.1637 19.28 24.777 19.16V22.94C25.777 23.1933 26.5637 23.44 27.137 23.68C27.7237 23.9067 28.2304 24.2867 28.657 24.82C29.0837 25.34 29.297 26.06 29.297 26.98ZM21.937 20.82C21.937 21.26 22.0704 21.62 22.337 21.9C22.6037 22.1667 22.9904 22.3867 23.497 22.56V19.1C23.017 19.1667 22.637 19.3467 22.357 19.64C22.077 19.9333 21.937 20.3267 21.937 20.82ZM24.777 28.88C25.2837 28.7867 25.677 28.58 25.957 28.26C26.2504 27.94 26.397 27.5533 26.397 27.1C26.397 26.66 26.257 26.3067 25.977 26.04C25.697 25.7733 25.297 25.5533 24.777 25.38V28.88Z"
        fill="#3C265F"
      />
      <line
        x1="22.1547"
        y1="9.36969"
        x2="10.5411"
        y2="25.131"
        stroke="#3C265F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="3" fill="white" />
      <path
        d="M13.297 14.98C13.297 15.6733 13.117 16.3267 12.757 16.94C12.4104 17.54 11.8904 18.04 11.197 18.44C10.517 18.8267 9.71036 19.0533 8.77703 19.12V20.74H7.49703V19.1C6.1637 18.98 5.09036 18.58 4.27703 17.9C3.4637 17.2067 3.0437 16.2733 3.01703 15.1H6.01703C6.09703 16.06 6.59036 16.64 7.49703 16.84V13.02C6.53703 12.78 5.7637 12.54 5.17703 12.3C4.59036 12.06 4.0837 11.6733 3.65703 11.14C3.23036 10.6067 3.01703 9.88 3.01703 8.96C3.01703 7.8 3.43036 6.85333 4.25703 6.12C5.09703 5.38667 6.17703 4.96667 7.49703 4.86V3.24H8.77703V4.86C10.057 4.96667 11.077 5.35333 11.837 6.02C12.6104 6.68667 13.0437 7.60667 13.137 8.78H10.117C10.077 8.39333 9.93703 8.06 9.69703 7.78C9.47036 7.48667 9.1637 7.28 8.77703 7.16V10.94C9.77703 11.1933 10.5637 11.44 11.137 11.68C11.7237 11.9067 12.2304 12.2867 12.657 12.82C13.0837 13.34 13.297 14.06 13.297 14.98ZM5.93703 8.82C5.93703 9.26 6.07036 9.62 6.33703 9.9C6.6037 10.1667 6.99036 10.3867 7.49703 10.56V7.1C7.01703 7.16667 6.63703 7.34667 6.35703 7.64C6.07703 7.93333 5.93703 8.32667 5.93703 8.82ZM8.77703 16.88C9.2837 16.7867 9.67703 16.58 9.95703 16.26C10.2504 15.94 10.397 15.5533 10.397 15.1C10.397 14.66 10.257 14.3067 9.97703 14.04C9.69703 13.7733 9.29703 13.5533 8.77703 13.38V16.88Z"
        fill="#3C265F"
      />
      <path
        d="M29.297 26.98C29.297 27.6733 29.117 28.3267 28.757 28.94C28.4104 29.54 27.8904 30.04 27.197 30.44C26.517 30.8267 25.7104 31.0533 24.777 31.12V32.74H23.497V31.1C22.1637 30.98 21.0904 30.58 20.277 29.9C19.4637 29.2067 19.0437 28.2733 19.017 27.1H22.017C22.097 28.06 22.5904 28.64 23.497 28.84V25.02C22.537 24.78 21.7637 24.54 21.177 24.3C20.5904 24.06 20.0837 23.6733 19.657 23.14C19.2304 22.6067 19.017 21.88 19.017 20.96C19.017 19.8 19.4304 18.8533 20.257 18.12C21.097 17.3867 22.177 16.9667 23.497 16.86V15.24H24.777V16.86C26.057 16.9667 27.077 17.3533 27.837 18.02C28.6104 18.6867 29.0437 19.6067 29.137 20.78H26.117C26.077 20.3933 25.937 20.06 25.697 19.78C25.4704 19.4867 25.1637 19.28 24.777 19.16V22.94C25.777 23.1933 26.5637 23.44 27.137 23.68C27.7237 23.9067 28.2304 24.2867 28.657 24.82C29.0837 25.34 29.297 26.06 29.297 26.98ZM21.937 20.82C21.937 21.26 22.0704 21.62 22.337 21.9C22.6037 22.1667 22.9904 22.3867 23.497 22.56V19.1C23.017 19.1667 22.637 19.3467 22.357 19.64C22.077 19.9333 21.937 20.3267 21.937 20.82ZM24.777 28.88C25.2837 28.7867 25.677 28.58 25.957 28.26C26.2504 27.94 26.397 27.5533 26.397 27.1C26.397 26.66 26.257 26.3067 25.977 26.04C25.697 25.7733 25.297 25.5533 24.777 25.38V28.88Z"
        fill="#3C265F"
      />
      <line
        x1="22.1547"
        y1="9.36969"
        x2="10.5411"
        y2="25.131"
        stroke="#3C265F"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="35" rx="3" fill="white" />
      <rect x="9" y="9" width="13" height="26" fill="#3C265F" />
      <rect x="15" y="12" width="1" height="5" fill="white" />
      <rect x="13" y="14" width="5" height="1" fill="white" />
      <mask id="path-5-inside-1_487_384" fill="white">
        <path d="M17 20H26V35H17V20Z" />
      </mask>
      <path d="M17 20H26V35H17V20Z" fill="#3C265F" />
      <path
        d="M17 20V19.8H16.8V20H17ZM17 20.2H26V19.8H17V20.2ZM17.2 35V20H16.8V35H17.2Z"
        fill="white"
        mask="url(#path-5-inside-1_487_384)"
      />
      <rect x="19" y="22" width="5" height="3" fill="white" />
      <rect x="19" y="27" width="5" height="3" fill="white" />
      <rect x="19" y="32" width="5" height="3" fill="white" />
      <rect x="13" y="22" width="4" height="3" fill="white" />
      <rect x="13" y="27" width="4" height="3" fill="white" />
      <rect x="13" y="32" width="4" height="3" fill="white" />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="32"
        height="32"
        rx="3.5"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H27V8H8V10ZM22.5 14H27V12H8V14H22.5ZM14.1319 24C14.2566 24.7001 14.4669 25.3705 14.7517 26H8V24H14.1319ZM14.3736 20C14.1776 20.6379 14.0546 21.3079 14.0145 22H8V20H14.3736ZM17.0225 16C16.3456 16.571 15.7591 17.2459 15.2876 18H8V16H17.0225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 29C26.866 29 30 25.866 30 22C30 18.134 26.866 15 23 15C19.134 15 16 18.134 16 22C16 25.866 19.134 29 23 29ZM25.1768 17.8232C25.0791 17.7256 24.9209 17.7256 24.8232 17.8232L23.2322 19.4142C23.1346 19.5118 23.1346 19.6701 23.2322 19.7678C23.3299 19.8654 23.4882 19.8654 23.5858 19.7678L24.75 18.6036V22H25.25V18.6036L26.4142 19.7678C26.5118 19.8654 26.6701 19.8654 26.7678 19.7678C26.8654 19.6701 26.8654 19.5118 26.7678 19.4142L25.1768 17.8232ZM22.7678 20.5858L21.1768 22.1768C21.0791 22.2744 20.9209 22.2744 20.8232 22.1768L19.2322 20.5858C19.1346 20.4882 19.1346 20.3299 19.2322 20.2322C19.3299 20.1346 19.4882 20.1346 19.5858 20.2322L20.75 21.3964L20.75 18H21.25L21.25 21.3964L22.4142 20.2322C22.5118 20.1346 22.6701 20.1346 22.7678 20.2322C22.8654 20.3299 22.8654 20.4882 22.7678 20.5858ZM23.7775 26.58C24.0975 26.525 24.3225 26.4175 24.4525 26.2575C24.5825 26.0925 24.6475 25.9175 24.6475 25.7325C24.6475 25.5475 24.595 25.395 24.49 25.275C24.39 25.155 24.2675 25.0625 24.1225 24.9975C23.9825 24.9325 23.8025 24.8675 23.5825 24.8025C23.3825 24.7425 23.235 24.6875 23.14 24.6375C23.05 24.5875 23.005 24.5225 23.005 24.4425C23.005 24.3675 23.0425 24.31 23.1175 24.27C23.1925 24.23 23.34 24.21 23.56 24.21C23.76 24.21 24.015 24.255 24.325 24.345L24.505 23.79C24.25 23.715 24.0075 23.665 23.7775 23.64V23.025H23.1775V23.6475C22.8425 23.6925 22.6175 23.79 22.5025 23.94C22.3875 24.085 22.33 24.2425 22.33 24.4125C22.33 24.6175 22.385 24.7875 22.495 24.9225C22.605 25.0525 22.735 25.1525 22.885 25.2225C23.035 25.2875 23.22 25.3525 23.44 25.4175C23.625 25.4775 23.7575 25.5275 23.8375 25.5675C23.9175 25.6075 23.9575 25.66 23.9575 25.725C23.9575 25.82 23.915 25.89 23.83 25.935C23.745 25.98 23.5975 26.0025 23.3875 26.0025C23.2725 26.0025 23.1275 25.9825 22.9525 25.9425C22.7775 25.9025 22.605 25.8525 22.435 25.7925L22.2325 26.355C22.5425 26.495 22.8575 26.58 23.1775 26.61V27.3525H23.7775V26.58Z"
        fill="white"
      />
    </svg>,
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="32"
        height="32"
        rx="3.5"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H27V8H8V10ZM22.5 14H27V12H8V14H22.5ZM14.1319 24C14.2566 24.7001 14.4669 25.3705 14.7517 26H8V24H14.1319ZM14.3736 20C14.1776 20.6379 14.0546 21.3079 14.0145 22H8V20H14.3736ZM17.0225 16C16.3456 16.571 15.7591 17.2459 15.2876 18H8V16H17.0225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 29C26.866 29 30 25.866 30 22C30 18.134 26.866 15 23 15C19.134 15 16 18.134 16 22C16 25.866 19.134 29 23 29ZM25.1768 17.8232C25.0791 17.7256 24.9209 17.7256 24.8232 17.8232L23.2322 19.4142C23.1346 19.5118 23.1346 19.6701 23.2322 19.7678C23.3299 19.8654 23.4882 19.8654 23.5858 19.7678L24.75 18.6036V22H25.25V18.6036L26.4142 19.7678C26.5118 19.8654 26.6701 19.8654 26.7678 19.7678C26.8654 19.6701 26.8654 19.5118 26.7678 19.4142L25.1768 17.8232ZM22.7678 20.5858L21.1768 22.1768C21.0791 22.2744 20.9209 22.2744 20.8232 22.1768L19.2322 20.5858C19.1346 20.4882 19.1346 20.3299 19.2322 20.2322C19.3299 20.1346 19.4882 20.1346 19.5858 20.2322L20.75 21.3964L20.75 18H21.25L21.25 21.3964L22.4142 20.2322C22.5118 20.1346 22.6701 20.1346 22.7678 20.2322C22.8654 20.3299 22.8654 20.4882 22.7678 20.5858ZM23.7775 26.58C24.0975 26.525 24.3225 26.4175 24.4525 26.2575C24.5825 26.0925 24.6475 25.9175 24.6475 25.7325C24.6475 25.5475 24.595 25.395 24.49 25.275C24.39 25.155 24.2675 25.0625 24.1225 24.9975C23.9825 24.9325 23.8025 24.8675 23.5825 24.8025C23.3825 24.7425 23.235 24.6875 23.14 24.6375C23.05 24.5875 23.005 24.5225 23.005 24.4425C23.005 24.3675 23.0425 24.31 23.1175 24.27C23.1925 24.23 23.34 24.21 23.56 24.21C23.76 24.21 24.015 24.255 24.325 24.345L24.505 23.79C24.25 23.715 24.0075 23.665 23.7775 23.64V23.025H23.1775V23.6475C22.8425 23.6925 22.6175 23.79 22.5025 23.94C22.3875 24.085 22.33 24.2425 22.33 24.4125C22.33 24.6175 22.385 24.7875 22.495 24.9225C22.605 25.0525 22.735 25.1525 22.885 25.2225C23.035 25.2875 23.22 25.3525 23.44 25.4175C23.625 25.4775 23.7575 25.5275 23.8375 25.5675C23.9175 25.6075 23.9575 25.66 23.9575 25.725C23.9575 25.82 23.915 25.89 23.83 25.935C23.745 25.98 23.5975 26.0025 23.3875 26.0025C23.2725 26.0025 23.1275 25.9825 22.9525 25.9425C22.7775 25.9025 22.605 25.8525 22.435 25.7925L22.2325 26.355C22.5425 26.495 22.8575 26.58 23.1775 26.61V27.3525H23.7775V26.58Z"
        fill="white"
      />
    </svg>,

    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width="32"
        height="32"
        rx="3.5"
        stroke="white"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10H27V8H8V10ZM22.5 14H27V12H8V14H22.5ZM14.1319 24C14.2566 24.7001 14.4669 25.3705 14.7517 26H8V24H14.1319ZM14.3736 20C14.1776 20.6379 14.0546 21.3079 14.0145 22H8V20H14.3736ZM17.0225 16C16.3456 16.571 15.7591 17.2459 15.2876 18H8V16H17.0225Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 29C26.866 29 30 25.866 30 22C30 18.134 26.866 15 23 15C19.134 15 16 18.134 16 22C16 25.866 19.134 29 23 29ZM25.1768 17.8232C25.0791 17.7256 24.9209 17.7256 24.8232 17.8232L23.2322 19.4142C23.1346 19.5118 23.1346 19.6701 23.2322 19.7678C23.3299 19.8654 23.4882 19.8654 23.5858 19.7678L24.75 18.6036V22H25.25V18.6036L26.4142 19.7678C26.5118 19.8654 26.6701 19.8654 26.7678 19.7678C26.8654 19.6701 26.8654 19.5118 26.7678 19.4142L25.1768 17.8232ZM22.7678 20.5858L21.1768 22.1768C21.0791 22.2744 20.9209 22.2744 20.8232 22.1768L19.2322 20.5858C19.1346 20.4882 19.1346 20.3299 19.2322 20.2322C19.3299 20.1346 19.4882 20.1346 19.5858 20.2322L20.75 21.3964L20.75 18H21.25L21.25 21.3964L22.4142 20.2322C22.5118 20.1346 22.6701 20.1346 22.7678 20.2322C22.8654 20.3299 22.8654 20.4882 22.7678 20.5858ZM23.7775 26.58C24.0975 26.525 24.3225 26.4175 24.4525 26.2575C24.5825 26.0925 24.6475 25.9175 24.6475 25.7325C24.6475 25.5475 24.595 25.395 24.49 25.275C24.39 25.155 24.2675 25.0625 24.1225 24.9975C23.9825 24.9325 23.8025 24.8675 23.5825 24.8025C23.3825 24.7425 23.235 24.6875 23.14 24.6375C23.05 24.5875 23.005 24.5225 23.005 24.4425C23.005 24.3675 23.0425 24.31 23.1175 24.27C23.1925 24.23 23.34 24.21 23.56 24.21C23.76 24.21 24.015 24.255 24.325 24.345L24.505 23.79C24.25 23.715 24.0075 23.665 23.7775 23.64V23.025H23.1775V23.6475C22.8425 23.6925 22.6175 23.79 22.5025 23.94C22.3875 24.085 22.33 24.2425 22.33 24.4125C22.33 24.6175 22.385 24.7875 22.495 24.9225C22.605 25.0525 22.735 25.1525 22.885 25.2225C23.035 25.2875 23.22 25.3525 23.44 25.4175C23.625 25.4775 23.7575 25.5275 23.8375 25.5675C23.9175 25.6075 23.9575 25.66 23.9575 25.725C23.9575 25.82 23.915 25.89 23.83 25.935C23.745 25.98 23.5975 26.0025 23.3875 26.0025C23.2725 26.0025 23.1275 25.9825 22.9525 25.9425C22.7775 25.9025 22.605 25.8525 22.435 25.7925L22.2325 26.355C22.5425 26.495 22.8575 26.58 23.1775 26.61V27.3525H23.7775V26.58Z"
        fill="white"
      />
    </svg>,

    <svg
      width={width}
      height={height}
      fill="white"
      style={{ color: "white" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
    </svg>,

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill="white"
      style={{ color: "white" }}
    >
      <path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
    </svg>,

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width={width}
      height={height}
      fill="white"
      style={{ color: "white" }}
    >
      <path
        d="M232 0c-39.8 0-72 32.2-72 72v8H72C32.2 80 0 112.2 0 
152V440c0 39.8 32.2 72 72 72h.2 .2 .2 .2 .2H73h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2H75h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2H77h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2H79h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2H82h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2H85h.2 .2 .2 .2H86h.2 .2
 .2 .2H87h.2 .2 .2 .2H88h.2 .2 .2 .2 .2 .2 .2
  .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2H98h.2 .2 .2 .2H99h.2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2
   .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2
    .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2
	 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2 .2v0H456h8v0H568c39.8 0 72-32.2 72-72V152c0-39.8-32.2-72-72-72H480V72c0-39.8-32.2-72-72-72H232zM480 
	 128h88c13.3 0 24 10.7 24 24v40H536c-13.3 0-24 10.7-24 24s10.7 24 24 24h56v48H536c-13.3 0-24 10.7-24 24s10.7 24 24 24h56V440c0 13.3-10.7 24-24 24H480V336 128zM72 128h88V464h-.1-.2-.2-.2H159h-.2-.2-.2H158h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H154h-.2-.2-.2H153h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H150h-.2-.2-.2H149h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H146h-.2-.2-.2H145h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H142h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H139h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H136h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H133h-.2-.2-.2-.2-.2-.2-.2-.2H131h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H128h-.2-.2-.2-.2-.2-.2-.2-.2H126h-.2-.2-.2-.2-.2-.2-.2-.2H124h-.2-.2-.2-.2-.2-.2-.2-.2H122h-.2-.2-.2-.2-.2-.2-.2-.2H120h-.2-.2-.2-.2-.2-.2-.2-.2H118h-.2-.2-.2-.2-.2-.2-.2-.2H116h-.2-.2-.2-.2-.2-.2-.2-.2H114h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H111h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H108h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H105h-.2-.2-.2-.2H104h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H100h-.2-.2-.2-.2H99h-.2-.2-.2-.2H98h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H88h-.2-.2-.2-.2H87h-.2-.2-.2-.2H86h-.2-.2-.2-.2H85h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H82h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H79h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H77h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H75h-.2-.2-.2-.2-.2-.2-.2-.2-.2-.2H73h-.2-.2-.2-.2-.2H72c-13.2 0-24-10.7-24-24V336h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H48V240h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H48V152c0-13.3 10.7-24 24-24zM208 72c0-13.3 10.7-24 24-24H408c13.3 0 24 10.7 24 24V336 464H368V400c0-26.5-21.5-48-48-48s-48 21.5-48 48v64H208V72zm88 24v24H272c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h24v24c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V168h24c8.8 0 16-7.2 16-16V136c0-8.8-7.2-16-16-16H344V96c0-8.8-7.2-16-16-16H312c-8.8 0-16 7.2-16 16z"
      />
    </svg>,

    <svg
      width={width}
      height={height}
      fill="white"
      style={{ color: "white" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path d="M272.2 64.6l-51.1 51.1c-15.3 4.2-29.5 11.9-41.5 22.5L153 161.9C142.8 171 129.5 176 115.8 176H96V304c20.4 .6 39.8 8.9 54.3 23.4l35.6 35.6 7 7 0 0L219.9 397c6.2 6.2 16.4 6.2 22.6 0c1.7-1.7 3-3.7 3.7-5.8c2.8-7.7 9.3-13.5 17.3-15.3s16.4 .6 22.2 6.5L296.5 393c11.6 11.6 30.4 11.6 41.9 0c5.4-5.4 8.3-12.3 8.6-19.4c.4-8.8 5.6-16.6 13.6-20.4s17.3-3 24.4 2.1c9.4 6.7 22.5 5.8 30.9-2.6c9.4-9.4 9.4-24.6 0-33.9L340.1 243l-35.8 33c-27.3 25.2-69.2 25.6-97 .9c-31.7-28.2-32.4-77.4-1.6-106.5l70.1-66.2C303.2 78.4 339.4 64 377.1 64c36.1 0 71 13.3 97.9 37.2L505.1 128H544h40 40c8.8 0 16 7.2 16 16V352c0 17.7-14.3 32-32 32H576c-11.8 0-22.2-6.4-27.7-16H463.4c-3.4 6.7-7.9 13.1-13.5 18.7c-17.1 17.1-40.8 23.8-63 20.1c-3.6 7.3-8.5 14.1-14.6 20.2c-27.3 27.3-70 30-100.4 8.1c-25.1 20.8-62.5 19.5-86-4.1L159 404l-7-7-35.6-35.6c-5.5-5.5-12.7-8.7-20.4-9.3C96 369.7 81.6 384 64 384H32c-17.7 0-32-14.3-32-32V144c0-8.8 7.2-16 16-16H56 96h19.8c2 0 3.9-.7 5.3-2l26.5-23.6C175.5 77.7 211.4 64 248.7 64H259c4.4 0 8.9 .2 13.2 .6zM544 320V176H496c-5.9 0-11.6-2.2-15.9-6.1l-36.9-32.8c-18.2-16.2-41.7-25.1-66.1-25.1c-25.4 0-49.8 9.7-68.3 27.1l-70.1 66.2c-10.3 9.8-10.1 26.3 .5 35.7c9.3 8.3 23.4 8.1 32.5-.3l71.9-66.4c9.7-9 24.9-8.4 33.9 1.4s8.4 24.9-1.4 33.9l-.8 .8 74.4 74.4c10 10 16.5 22.3 19.4 35.1H544zM64 336a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm528 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32z" />
    </svg>,

    <svg
      width={width}
      height={height}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.79518 1.25368C0.916504 2.13236 0.916504 3.54657 0.916504 6.375V25C0.916504 27.8284 0.916504 29.2426 1.79518 30.1213C2.67386 31 4.08808 31 6.9165 31H25.5415C28.3699 31 29.7841 31 30.6628 30.1213C31.5415 29.2426 31.5415 27.8284 31.5415 25V6.375C31.5415 3.54657 31.5415 2.13236 30.6628 1.25368C29.7841 0.375 28.3699 0.375 25.5415 0.375H6.9165C4.08808 0.375 2.67386 0.375 1.79518 1.25368ZM22.354 9.58333C22.9063 9.58333 23.354 10.031 23.354 10.5833V24.1944C23.354 24.7467 22.9063 25.1944 22.354 25.1944C21.8017 25.1944 21.354 24.7467 21.354 24.1944V10.5833C21.354 10.031 21.8017 9.58333 22.354 9.58333ZM11.104 13.9861C11.104 13.4338 10.6563 12.9861 10.104 12.9861C9.55172 12.9861 9.104 13.4338 9.104 13.9861L9.104 24.1945C9.104 24.7467 9.55172 25.1945 10.104 25.1945C10.6563 25.1945 11.104 24.7467 11.104 24.1945V13.9861ZM17.229 17.3889C17.229 16.8366 16.7813 16.3889 16.229 16.3889C15.6767 16.3889 15.229 16.8366 15.229 17.3889V24.1944C15.229 24.7467 15.6767 25.1944 16.229 25.1944C16.7813 25.1944 17.229 24.7467 17.229 24.1944V17.3889Z"
        fill="white"
      />
    </svg>,
  ];

  return icon[value];
};
