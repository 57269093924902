import React, { useState, useEffect, useRef } from 'react'
import {
	Row,
	Col,
    Toast,
    Button,
} from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import { TAG_COLORS } from '../constants'

export default inject(
	'zigna_aiStore',
	'authStore',
	'providerPriceCompareStore'
)(
	observer(({ zigna_aiStore, authStore, providerPriceCompareStore }) => {
        const [notificationList, setNotificationList] = useState([]);

        const addNotification = (elem) => {
            setNotificationList([elem, ...notificationList])
        }

        const removeNotification = (elem) => {
            setNotificationList(notificationList.filter(e => e.ts !== elem))
        }

        useEffect(() => {
            if (zigna_aiStore.notification) {
                const { type = 'info', message } = zigna_aiStore.notification || {}
                if ( type && message ) addNotification({ type, message, ts: new Date().valueOf() })
            }
        }, [zigna_aiStore.notification])

        return (
            <div
                style={{
                    position: 'absolute',
                    top: 65,
                    right: 10,
                    zIndex: 1500
                }}
            >
                {
                    notificationList.map((elem) => {
                        return (
                            <Toast style={{ ...TAG_COLORS[elem.type], margin: 3 }} key={elem.ts} onClose={() => { removeNotification(elem.ts) }} show={true} delay={10000} autohide>
                                <Toast.Header>
                                    <strong className="me-auto" style={{ textTransform: 'capitalize' }}>{elem.type}</strong>
                                    <small></small>
                                </Toast.Header>
                                <Toast.Body>{elem.message}</Toast.Body>
                            </Toast>
                        )
                    })
                }
            </div>
        );
    })
)