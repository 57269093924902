import React, { useEffect, useState, useMemo } from "react";
import CommonTable from "../common/COmmonWIthSpan";
import { Button } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSort,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";

/** For future use - formatting only */
import "xlsx";
import millify from "millify";
import * as XLSX from "xlsx-js-style";

import CustomPagination from "../../Pagination";
import RequestExtraData from "../common/RequestExtraData";

const Table = ({ dataset, zigna_aiStore, filterSet, payerFilter }) => {
  const [selectedRates, setSelectedRates] = useState([]);
  const [showRelevant, setShowRelevant] = useState(false);

  const [sortOrder, setSortOrder] = useState([
    { field: "order", order: "desc" },
    { field: "grouping", order: "asc" },
    { field: "classification", order: "asc" },
  ]);
  const [showHidden, setShowHidden] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const selectColumn = (col, checked) => {
    if (checked) {
      setSelectedRates([...selectedRates, col]);
    } else {
      setSelectedRates(selectedRates.filter((i) => i !== col));
    }
  };
  const getRateDiff = (base, comparingEntity) => {
    if (!base || !comparingEntity) return;

    let percentage = (comparingEntity / base) * 100;

    return percentage.toFixed(1);
  };

  const getFormatedData = (val) => {
    if (!val) return "";

    if (val > 100) return <b style={{ color: "red" }}>{val}%</b>;
    else return <b style={{ color: "green" }}>{val}%</b>;
  };
  function customSort(arr) {
    arr.sort(function (a, b) {
      const aContainsExcellus = a.startsWith("Excellus");
      const bContainsExcellus = b.startsWith("Excellus");

      if (aContainsExcellus && bContainsExcellus) {
        const aNumber = parseInt(a.split(" ").pop());
        const bNumber = parseInt(b.split(" ").pop());

        if (!isNaN(aNumber) && !isNaN(bNumber)) {
          return aNumber - bNumber;
        }

        return a.localeCompare(b);
      }

      if (aContainsExcellus) {
        return -1;
      }

      if (bContainsExcellus) {
        return 1;
      }

      // If neither string starts with "Excellus", try to parse them as integers
      if (a.split(" ")[0] == b.split(" ")[0]) {
        // const aInt = parseInt(a.split(" ").pop(), 10);
        // const bInt = parseInt(b.split(" ").pop(), 10);

        // if (!isNaN(aInt) && !isNaN(bInt)) {
        //   return aInt - bInt;
        // }

        // return a.localeCompare(b);
        const aInt = parseInt(a.split(" ").pop().match(/\d+/)[0], 10);
        const bInt = parseInt(b.split(" ").pop().match(/\d+/)[0], 10);

        if (!isNaN(aInt) && !isNaN(bInt)) {
          const aprefix = a.split("").slice(0, -3).join(" ");
          const bprefix = b.split("").slice(0, -3).join(" ");
          if (aprefix === bprefix) {
            return aInt - bInt;
          } else {
            return aprefix.localeCompare(bprefix);
          }
        }
      } else {
        return a.localeCompare(b);
      }
    });
    return arr; // Return the sorted array
  }

  const handleSort = (field) => {
    let newSortOrder = [...sortOrder];
    const index = newSortOrder.findIndex(
      (sortField) => sortField.field === field
    );

    if (index === -1) {
      // Field not in sort order, add ascending
      newSortOrder = [...newSortOrder, { field, order: "asc" }];
    } else if (newSortOrder[index].order === "asc") {
      // Field is already ascending, change to descending
      newSortOrder[index].order = "desc";
    } else {
      // Field is descending, remove from sort order
      newSortOrder.splice(index, 1);
    }

    setSortOrder(newSortOrder);
  };

  const getSortIcon = (field) => {
    let item = sortOrder.find((i) => i.field == field);
    if (!item) {
      return (
        <FontAwesomeIcon
          icon={faSort}
          style={{ fontSize: 16, marginLeft: 2 }}
        />
      );
    } else if (item.order == "asc") {
      return (
        <FontAwesomeIcon
          icon={faSortUp}
          style={{ fontSize: 16, marginLeft: 2 }}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faSortDown}
          style={{ fontSize: 16, marginLeft: 2 }}
        />
      );
    }
  };
  const transformData = (dataset, withregion = false) => {
    let rate_type = payerFilter.find((i) => i.field == "rate_type")?.value
      ?.value;
    let headers = [];
    let allGroups = Object.values(
      _.groupBy(
        dataset.filter(
          (i) =>
            i[payerFilter.find((i) => i.field == "rate_type")?.value?.value] > 0
        ),
        (i) =>
          i.tin +
          i.billing_code +
          i.group1 +
          i.group2 +
          i.group3 +
          i.npi +
          (withregion ? i.region : "")
      )
    );

    let items = [];
    allGroups.map((gp) => {
      let item = {
        proc_count: null,
      };
      if (gp.length == 1 && gp[0].reporting_entity_name == "CMS") {
        return;
      }
      Object.values(_.groupBy(gp, "reporting_entity_name")).map(
        (gp2, index) => {
          _.orderBy(gp2, [rate_type], ["desc"]).map((re, index) => {
            let n = re.reporting_entity_name.replace(/\./g, "");
            if (n !== "CMS") {
              headers.push(n + (" RATE " + (index + 1)));
              item[n + (" RATE " + (index + 1))] = { ...re };
            } else {
              item[n] = { ...re };
            }
            item = {
              ...item,
              tin: re.tin,
              billigCode: re.billing_code,
              npi: re.npi,
            };
            item["grouping"] = re.group1;
            item["classification"] = re.group2;
            item["specialization"] = re.group3;
            item["npp"] = re.npp_indicator;
            item["region"] = re.region;
            item["provider_type"] = re.provider_type;
            item["tax_id_name"] = re.tax_id_name;
            item["group_name"] = re.group_name;

            if (
              n == "Excellus" &&
              re.proc_count &&
              re.proc_count > item["proc_count"]
            ) {
              item["proc_count"] = Number(re.proc_count);
            }

            item["npi_count"] = re.npi_count;
          });

          // let values={}

          // re.map(i=>{

          //   values[i.service_code]=i.negotiated_rate
          //   values[`diff_${i.service_code}`]=0

          // })

          // item["CMS"]={negotiated_rate_non_facility:95.6,negotiated_rate_facility:95.6}
        }
      );

      if (item.tin) items.push(item);
    });

    // let groupedData=groupArray(dataset,"tin","billing_code","group1","group2","npi","reporting_entity_name")

    // let items=[]
    // let headers=[]

    // Object.keys(groupedData).map(i=>{

    // let tin=i
    // let billigCode
    // let npi=""
    // let group1=""
    // let group2=""

    // Object.keys(groupedData[i]).map(j=>{
    //   billigCode=j

    //   Object.keys(groupedData[i][j]).map(k=>{
    //     group1=k
    //     Object.keys(groupedData[i][j][k]).map(l=>{
    //       group2=l
    //       Object.keys(groupedData[i][j][k][l]).map(m=>{
    //         npi=m
    //         let item={tin,billigCode,npi:m,group1,group2}
    //           Object.keys(groupedData[i][j][k][l][m]).map(n=>{

    //       Object.values(_.orderBy(groupedData[i][j][k][l][m][n],["non_facility","facility"],["desc","desc"])).map((re,index)=>{

    //         if(n!=="CMS")
    //         { headers.push(n+(" RATE "+(index+1)))
    //         item[n+(" RATE "+(index+1))]={...re}
    //        }
    //        else{
    //          item[n]={...re}
    //        }
    //         // item["CMS"]={negotiated_rate_non_facility:95.6,negotiated_rate_facility:95.6}
    //         item["grouping"]=re.group1
    //         item["classification"]=re.group2
    //         item["npp"]=re.npp_indicator
    //       })

    //     })
    //     items.push(item)
    //       })
    //     })

    //   })
    // })

    // })

    // selectedRates.map(i=>{
    //   headers=headers.filter(h=>h!==i)
    //   headers.push(i)
    // })

    let groupByItems = _.groupBy(items, (i) => i.tin + i.billigCode);

    let combinedItems = [];
    _.orderBy(Object.values(groupByItems), ["proc_count"], ["desc"]).map(
      (i) => {
        let combinedAllopathic = [];
        let combinedOthers = [];
        let Allopathic = i.filter((iteratee) => {
          return (
            iteratee.grouping == "Allopathic & Osteopathic Physicians" &&
            iteratee.proc_count > 0
          );
        });
        let other = _.orderBy(
          i.filter((iteratee) => {
            return (
              iteratee.grouping !== "Allopathic & Osteopathic Physicians" &&
              iteratee.proc_count > 0
            );
          }),
          "specialization",
          "asc"
        );

        if (Allopathic.length > 0) {
          Allopathic.map((j, index) => {
            if (index == 0) {
              let item = {
                ...j,
              };
              combinedAllopathic.push(item);
            } else {
              let keys = Object.keys(j).filter((i) => i.includes("RATE"));
              let combinedItem = null;
              for (let index = 0; index < combinedAllopathic.length; index++) {
                let item = _.cloneDeep(combinedAllopathic[index]);
                let shouldCombine = true;
                for (let key of _.uniq(headers)) {
                  if (item[key] && j[key]) {
                    if (item[key][rate_type] == j[key][rate_type]) {
                      item[key]["npi_count"] =
                        item[key]["npi_count"] + j[key]["npi_count"];
                    }

                    if (item[key][rate_type] !== j[key][rate_type]) {
                      shouldCombine = false;
                      break;
                    }
                  } else {
                    if ((item[key] && !j[key]) || (!item[key] && j[key])) {
                      shouldCombine = false;
                      break;
                    }
                  }
                }

                if (shouldCombine) {
                  combinedItem = true;
                  //console.log(item, j, "proc reveal");
                  combinedAllopathic[index] = {
                    ...j,
                    ...item,
                    proc_count: item["proc_count"] + j["proc_count"],
                    specialization: "-",
                    classification: "Multiple",
                    // classaraay: combinedAllopathic[index]?.classaraay
                    //   ? [
                    //       ...combinedAllopathic[index]?.classaraay,
                    //       j.classification + "-" + j.specialization,
                    //     ]
                    //   : [
                    //       item.classification + "-" + item.specialization,
                    //       j.classification + "-" + j.specialization,
                    //     ],
                  };

                  break;
                }
              }
              if (!combinedItem) {
                combinedItem = {
                  ...j,
                };
                combinedAllopathic.push(combinedItem);
              }
            }
          });
        }
        if (other.length > 0) {
          other.map((j, index) => {
            if (index == 0) {
              let item = {
                ...j,
              };
              combinedOthers.push(item);
            } else {
              let keys = Object.keys(j).filter((i) => i.includes("RATE"));
              let combinedItem = null;
              for (let index = 0; index < combinedOthers.length; index++) {
                let item = _.cloneDeep(combinedOthers[index]);
                let shouldCombine = true;
                for (let key of _.uniq(headers)) {
                  if (item[key] && j[key]) {
                    if (item[key][rate_type] == j[key][rate_type]) {
                      item[key]["npi_count"] =
                        item[key]["npi_count"] + j[key]["npi_count"];
                    }
                    if (item.classification !== j.classification) {
                      shouldCombine = false;
                      break;
                    }
                    if (item[key][rate_type] !== j[key][rate_type]) {
                      shouldCombine = false;
                      break;
                    }
                  } else {
                    if ((item[key] && !j[key]) || (!item[key] && j[key])) {
                      shouldCombine = false;
                      break;
                    }
                  }
                }

                if (shouldCombine) {
                  combinedItem = true;
                  combinedOthers[index] = {
                    ...j,
                    ...item,
                    proc_count: item["proc_count"] + j["proc_count"],
                    specialization: "Multiple",
                    //classification: "",
                  };

                  break;
                }
              }
              if (!combinedItem) {
                combinedItem = {
                  ...j,
                };
                combinedOthers.push(combinedItem);
              }
            }
          });
        }

        let nonProcItems = [...i.filter((i) => !i.proc_count)];
        let notProcCombined = [];
        let nonProcEntity = {
          order: 1000,
        };
        nonProcItems.map((j, index) => {
          let keys = Object.keys(j).filter((i) => i.includes("RATE"));
          for (let key of [...keys, "CMS"]) {
            if (key == "CMS") {
              notProcCombined.push({ ...j[key], reporting_entity_name: "CMS" });
            } else {
              let exist = notProcCombined.findIndex(
                (rt) =>
                  rt?.reporting_entity_name == j[key]?.reporting_entity_name &&
                  rt?.[rate_type] == j[key]?.[rate_type]
              );
              if (exist > -1) {
                notProcCombined[exist].npi_count =
                  notProcCombined[exist].npi_count + j[key].npi_count;
              } else notProcCombined.push(j[key]);
            }
          }
        });

        Object.values(_.groupBy(notProcCombined, "reporting_entity_name")).map(
          (gp2, index) => {
            _.orderBy(gp2, [rate_type], ["desc"]).map((re, index) => {
              let n = re.reporting_entity_name.replace(/\./g, "");
              if (n == "CMS") {
                nonProcEntity["CMS"] = { ...re };
              } else {
                nonProcEntity[n + (" RATE " + (index + 1))] = { ...re };

                nonProcEntity = {
                  ...nonProcEntity,
                  tin: re.tin,
                  billigCode: re.billing_code,
                  npi: re.npi,
                };
                nonProcEntity["grouping"] = "Multiple";
                nonProcEntity["classification"] = "Multiple";
                nonProcEntity["specialization"] = "-";
                nonProcEntity["npp"] = re.npp_indicator;
                nonProcEntity["region"] = re.region;
                nonProcEntity["provider_type"] = re.provider_type;
                nonProcEntity["tax_id_name"] = re.tax_id_name;
                nonProcEntity["group_name"] = re.group_name;

                if (n == "Excellus" && re.proc_count) {
                  nonProcEntity["proc_count"] = Number(re.proc_count);
                }

                nonProcEntity["npi_count"] = re.npi_count;
              }
            });

            // let values={}

            // re.map(i=>{

            //   values[i.service_code]=i.negotiated_rate
            //   values[`diff_${i.service_code}`]=0

            // })

            // item["CMS"]={negotiated_rate_non_facility:95.6,negotiated_rate_facility:95.6}
          }
        );

        combinedItems = [
          ...combinedItems,
          ...combinedAllopathic.filter((i) => i.proc_count),
          ...combinedOthers.filter((i) => i.proc_count),
        ];
        if (nonProcItems.length) {
          combinedItems.push(nonProcEntity);
        }
        // if (
        //   i[0].grouping == "Allopathic & Osteopathic Physicians" &&
        //   i.length > 1
        // ) {
        //   combinedItems.push({
        //     ...i[0],
        //     classification: "",
        //     specialization: "-",
        //   });
        // } else {
        //   combinedItems = [...combinedItems, ...i];
        // }
      }
    );

    items = combinedItems;
    console.log(items);
    headers = showHidden
      ? headers
      : headers.filter((i) => !selectedRates.find((j) => j == i));
    let allHeaders = headers;
    // headers=selectedHeader?.length?headers.filter(i=>{
    //   if(selectedHeader.find(j=>i.includes(j.name))){
    //    return true
    //   }
    //   else{
    //    return false
    //   }
    // }):headers
    // })

    // if(showRelevant){
    //   items=items.filter(item=>item.CMS?.Facility||item.CMS?.["Non-Facility"])
    // }
    if (sortOrder.length) {
      console.log("indise sort", sortOrder);
      items = _.orderBy(
        items,
        sortOrder.map((i) => i.field),
        sortOrder.map((i) => i.order)
      );
    }

    return { items, headers: customSort(headers) };
  };

  const { items, headers } = useMemo(
    () => transformData(dataset),
    [showRelevant, sortOrder, dataset, selectedRates, showHidden]
  );

  useEffect(() => {
    setShowHidden(false);
    setSelectedRates([]);
  }, [dataset]);

  let columns = [
    {
      Header: "",
      sticky: "left",
      id: "_blank",
      columns: [
        {
          Header: (props) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleSort("tin")}
            >
              Organization Tax ID {getSortIcon("tin")}
            </div>
          ),
          accessor: `tin`,
          width: 140,
          mainGroup: true,
        },

        {
          Header: (props) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleSort("grouping")}
            >
              NUCC Grouping {getSortIcon("grouping")}
            </div>
          ),

          accessor: `grouping`,
          width: 180,
        },

        {
          Header: (props) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleSort("classification")}
            >
              NUCC Classification {getSortIcon("classification")}
            </div>
          ),
          textHeader: "Allowed Per Unit",

          accessor: `classification`,

          width: 150,
          Cell: (props) => <>{props.value}</>,
        },
        {
          Header: (props) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleSort("specialization")}
            >
              NUCC Specialization {getSortIcon("specialization")}
            </div>
          ),

          accessor: `specialization`,

          width: 150,
          Cell: (props) => (
            <>{props.value == "Not Classified" ? "" : props.value}</>
          ),
        },
        {
          Header: (props) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => handleSort("billigCode")}
            >
              Billing Code {getSortIcon("billigCode")}
            </div>
          ),
          textHeader: "Allowed Per Unit",
          align: "left",
          accessor: `billigCode`,
          width: 100,
          Cell: (props) => <>{props.value}</>,
        },
        {
          Header: "Non-Physician Practitioner (NPP) Indicator ",
          textHeader: "Allowed Per Unit",
          align: "left",
          width: 160,
          accessor: `npp`,
          Cell: (props) => <>{props.value}</>,
        },
        {
          Header: "Utilization #",
          accessor: `proc_count`,
          width: 110,
          align: "right",
        },
      ],
    },
    {
      Header: "CMS",
      sticky: "left",
      columns: [
        {
          Header: `${
            payerFilter.find((i) => i.field == "rate_type")?.value?.value
          } Global / Base Rate`,
          accessor: `CMS.${
            payerFilter.find((i) => i.field == "rate_type")?.value?.value
          }`,
          width: 100,
          align: "right",
        },

        {
          Header: "Utilization $",
          accessor: `Excellus1.proc_count$`,
          width: 110,
          align: "right",
          Cell: (props) => {
            return props.row.original?.proc_count &&
              props.row.original?.CMS?.[
                payerFilter.find((i) => i.field == "rate_type")?.value?.value
              ]
              ? (
                  props.row.original?.proc_count *
                  props.row.original?.CMS?.[
                    payerFilter.find((i) => i.field == "rate_type")?.value
                      ?.value
                  ]
                ).toFixed(1)
              : "";
          },
        },
      ],
    },
    ..._.uniq(headers).map((i) => ({
      Header: (
        <div className="d-flex flex-row justify-content-center">
          {i}
          <input
            type="checkbox"
            checked={selectedRates.findIndex((j) => j == i) > -1}
            className="mr-2 mx-2"
            onChange={(e) => selectColumn(i, e.target.checked)}
          />
          (
          {selectedRates.findIndex((j) => j == i) > -1
            ? "Unselect to unhide"
            : "Select to hide"}
          )
        </div>
      ),
      accessor: i,
      columns: [
        {
          Header: `${
            payerFilter.find((i) => i.field == "rate_type")?.value?.value
          } Global / Base Rate`,
          accessor: `${i}.${
            payerFilter.find((i) => i.field == "rate_type")?.value?.value
          }`,
          width: 100,
          Cell: (props) =>
            props.row.original?.[i]?.[
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            ] || "",
          align: "right",
        },
        {
          Header: "% Medicare",
          accessor: `${i}.diff`,
          width: 100,
          align: "right",

          Cell: (props) => {
            return getFormatedData(
              getRateDiff(
                props.row.original?.CMS?.[
                  payerFilter.find((i) => i.field == "rate_type")?.value?.value
                ],
                props.row.original?.[i]?.[
                  payerFilter.find((i) => i.field == "rate_type")?.value?.value
                ]
              )
            );
          },
        },

        {
          Header: "Utilization $",
          accessor: `${i}.proc_count$`,
          width: 110,
          align: "right",
          Cell: (props) => {
            return props.row.original?.proc_count &&
              props.row.original?.[i]?.[
                payerFilter.find((i) => i.field == "rate_type")?.value?.value
              ]
              ? (
                  props.row.original?.proc_count *
                  props.row.original?.[i]?.[
                    payerFilter.find((i) => i.field == "rate_type")?.value
                      ?.value
                  ]
                ).toFixed(1)
              : "";
          },
        },
        // {
        //   Header: "npi count",
        //   accessor: `${i}.npi_count`,
        //   width: 110,
        //   align: "right",
        // },
      ],
    })),
  ];
  console.log(items, "all items");
  const downloadExcel = (data) => {
    let { items, headers } = transformData(data, true);
    console.log(items);
    let allcolumns = [
      {
        Header: "",

        columns: [
          {
            Header: "Organization Tax ID",
            accessor: `tin`,
            width: 115,
          },
          {
            Header: "Tax ID Name",
            accessor: `tax_id_name`,
            width: 115,
            enableRowSpan: true,
          },
          {
            Header: "Fee Schedule",
            accessor: `provider_type`,
            width: 60,
          },
          {
            Header: "Fee Schedule Name",
            accessor: `group_name`,
            width: 115,
            enableRowSpan: true,
          },
          {
            Header: "Region",
            textHeader: "Region",
            hidden: false,
            width: 60,
            accessor: `region`,
          },
          {
            Header: "NUCC Grouping",
            enableRowSpan: true,
            accessor: `grouping`,
            width: 120,
          },

          {
            Header: "NUCC Classification",
            textHeader: "Allowed Per Unit",

            accessor: `classification`,
            enableRowSpan: true,
            width: 150,
          },

          {
            Header: "NUCC Specialization",
            accessor: `specialization`,
          },
          {
            Header: "Billing Code",
            textHeader: "Allowed Per Unit",
            align: "center",
            accessor: `billigCode`,

            enableRowSpan: true,
            width: 80,
          },
          {
            Header: "Non-Physician Practitioner (NPP) Indicator ",
            textHeader: "Allowed Per Unit",

            width: 60,
            accessor: `npp`,
          },
          {
            Header: "Utilization #",
            accessor: `proc_count`,
            width: 100,
            align: "right",
          },
        ].filter((i) => !i.hidden),
      },
      {
        Header: "CMS",
        sticky: "left",
        columns: [
          {
            Header: `${
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            } Global/ Base Rate`,
            accessor: `CMS.${
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            }`,
            width: 100,
            align: "right",
          },

          {
            Header: "Utilization $",
            accessor: `CMS.proc_count`,
            width: 100,
            value: (data) =>
              _.get(
                data,
                `CMS.${
                  payerFilter.find((i) => i.field == "rate_type")?.value?.value
                }`
              ) && _.get(data, `proc_count`)
                ? _.get(
                    data,
                    `CMS.${
                      payerFilter.find((i) => i.field == "rate_type")?.value
                        ?.value
                    }`
                  ) * _.get(data, `proc_count`)
                : "",

            align: "right",
          },
        ],
      },
      ..._.uniq(headers).map((i) => ({
        Header: i,
        accessor: i,
        columns: [
          {
            Header: `${
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            } Global/ Base Rate`,
            accessor: `${i}.${
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            }`,
            width: 100,
            Cell: (props) =>
              props.row.original?.[i][
                payerFilter.find((i) => i.field == "rate_type")?.value?.value
              ] || "",
            align: "right",
          },
          {
            Header: "% Medicare",
            accessor: `${i}.${
              payerFilter.find((i) => i.field == "rate_type")?.value?.value
            }`,
            value: (data) =>
              getRateDiff(
                _.get(
                  data,
                  `CMS.${
                    payerFilter.find((i) => i.field == "rate_type")?.value
                      ?.value
                  }`
                ),
                _.get(
                  data,
                  `${i}.${
                    payerFilter.find((i) => i.field == "rate_type")?.value
                      ?.value
                  }`
                )
              ),
            formatedValue: (value) => (value ? value + "%" : ""),
            color: (value) =>
              value ? (value > 100 ? "FF7B7B" : "32cd32") : "FFFFFF",
          },

          {
            Header: "Utilization $",
            accessor: `${i}.proc_count`,
            width: 110,
            align: "right",
            value: (data) =>
              _.get(
                data,
                `${i}.${
                  payerFilter.find((i) => i.field == "rate_type")?.value?.value
                }`
              ) && _.get(data, `proc_count`)
                ? _.get(
                    data,
                    `${i}.${
                      payerFilter.find((i) => i.field == "rate_type")?.value
                        ?.value
                    }`
                  ) * _.get(data, `proc_count`)
                : "",
          },
          // {
          //   Header: "Npi Count",
          //   accessor: `${i}.npi_count`,
          //   width: 110,
          //   align: "right",
          // },
        ],
      })),
    ];
    let merge = [];

    let xlrows = [];
    let accessors = [];
    let config = [];
    let styledCells = [];
    allcolumns.map((i, index) => {
      if (!xlrows?.[0]) {
        xlrows.push([]);
        xlrows.push([]);
      }
      if (i.columns) {
        merge.push({
          s: { r: 0, c: xlrows[0].length },
          e: { r: 0, c: xlrows[0].length + i.columns.length - 1 },
        });
        for (let j in i.columns) {
          xlrows[0].push(i.Header);
          accessors.push(
            i?.columns?.[j]?.value
              ? i?.columns?.[j]?.value
              : i?.columns?.[j]?.accessor
          );
          config.push(i?.columns?.[j]);
          xlrows[1].push(i?.columns?.[j].Header);
        }
      }
    });

    let mergeStartIndex = 0;
    let mergeEndIndex = 0;
    let prevCode = null;
    for (let i in items) {
      if (items[i].billigCode !== prevCode && prevCode !== null) {
        if (prevCode != null) {
          //  merge.push(
          //  { s: { r: Number(mergeStartIndex)+2, c: 3},e: { r: Number(mergeEndIndex)+2, c: 3 }},
          //  { s: { r: Number(mergeStartIndex)+2, c: 2},e: { r: Number(mergeEndIndex)+2, c: 2 }},
          //  { s: { r: Number(mergeStartIndex)+2, c: 1},e: { r: Number(mergeEndIndex)+2, c: 1 }},
          //  { s: { r: Number(mergeStartIndex)+2, c: 0},e: { r: Number(mergeEndIndex)+2, c: 0 }}

          //  )
          mergeStartIndex = i;
        }
      } else {
        mergeEndIndex = i;
        if (i == items.length - 1) {
          if (prevCode != null) {
            //  merge.push(
            //    { s: { r: Number(mergeStartIndex)+2, c: 3},e: { r: Number(mergeEndIndex)+2, c:  3 }},
            //    { s: { r: Number(mergeStartIndex)+2, c: 2},e: { r: Number(mergeEndIndex)+2, c:  2 }},
            //    { s: { r: Number(mergeStartIndex)+2, c: 1},e: { r: Number(mergeEndIndex)+2, c:  1 }},
            //    { s: { r: Number(mergeStartIndex)+2, c: 0},e: { r: Number(mergeEndIndex)+2, c:  0 }}

            //    )
            mergeStartIndex = i;
          }
        }
      }

      prevCode = items[i].billigCode;

      xlrows.push(
        accessors.map((j, xi) => {
          if (typeof j == "function") {
            if (config[xi].color && j(items[i])) {
              styledCells.push({
                address: XLSX.utils.encode_cell({ r: xlrows.length, c: xi }),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: config[xi].color(j(items[i])) },
                  },
                  //font: { color: { rgb: config[xi].color(j(items[i])) } }
                },
              });
            }
            return config[xi].formatedValue
              ? config[xi].formatedValue(j(items[i]))
              : j(items[i]);
          } else {
            if (config[xi].color && _.get(items[i], j)) {
              styledCells.push({
                address: XLSX.utils.encode_cell({ r: xlrows.length, c: xi }),
                style: {
                  fill: {
                    patternType: "solid",
                    fgColor: { rgb: config[xi].color(_.get(items[i], j)) },
                  },
                  // font: { color: { rgb: config[xi].color(_.get(items[i],j)) } }
                },
              });
            }
            return config[xi].formatedValue
              ? config[xi].formatedValue(_.get(items[i], j))
              : _.get(items[i], j);
          }
        })
      );
    }

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.aoa_to_sheet(xlrows);

    for (let i in styledCells) {
      ws[styledCells[i].address].s = styledCells[i].style;
    }

    // // Add conditional formatting
    //  ws["A2"].s = { // A2 cell style
    //    fill: { patternType: 'solid', fgColor: { rgb: "FF0000" } }, // Red fill
    //    color: { rgb: "FF0000" }
    //  };

    // // Merge cells in rows and columns
    // ws["A1"].s = { // A1 cell style
    //   alignment: { horizontal: "center" }, // Center text horizontally
    //   font: { bold: true } // Bold font
    // };
    // ws["B1"].s = { alignment: { horizontal: "center" } }; // Center text horizontally
    // ws["C1"].s = { alignment: { horizontal: "center" } }; // Center text horizontally
    // ws["A1"].t = "s"; // Set the type of A1 to string

    // // Merge cells in rows and columns
    // ws["A1"].t = "s"; // Set the type of A1 to string
    // ws["A1"].s = { alignment: { horizontal: "center" } }; // Center text horizontally
    // ws["B1"].s = { alignment: { horizontal: "center" } }; // Center text horizontally
    // ws["C1"].s = { alignment: { horizontal: "center" } }; // Center text horizontally
    // ws["A1"].v = "Merged Header"; // Set the value of A1 to "Merged Header"
    // ws["A1"].s = { alignment: { horizontal: "center" }, font: { bold: true } }; // Center and bold
    // const merge = [
    //   { s: { r: 1, c: 0 }, e: { r: 2, c: 0 } },{ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } },
    // ];
    ws["!merges"] = merge;
    // Create a style for merged cells
    var mergeStyle = {
      alignment: { horizontal: "center" },
      font: { bold: true },
    };
    var firstRowRange = XLSX.utils.decode_range(
      XLSX.utils.encode_range({ r: 0, c: 0 }, { r: 0, c: xlrows[0].length })
    );

    // Apply style to the entire first row
    for (var col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
      var cellAddress = XLSX.utils.encode_cell({
        r: firstRowRange.s.r,
        c: col,
      });

      // if(cellAddress)
      if (ws?.[cellAddress]) ws[cellAddress].s = mergeStyle;
      // Object.assign(ws[cellAddress].s, { alignment: { horizontal: "center" }, font: { bold: true } });
    }
    // XLSX.utils.format_cell(ws["A1"]); // Apply the style to the merged cells

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Create a blob from the workbook and save it as an Excel file
    XLSX.writeFile(
      wb,
      `rate_utilization_view_formatted_${new Intl.DateTimeFormat("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format()}.xlsx`
    );
  };
  const handleDownloadData = async (type) => {
    let data = [];

    data = await zigna_aiStore.getRateUtilizationDataByTin(
      {
        fields: [
          ...filterSet.map((i) => {
            return {
              field: i.field,
              value: i.value?.value
                ? i.value.value
                : i.value?.map((j) => j.value),
            };
          }),
          ...payerFilter.map((i) => {
            return {
              field: i.field,
              value: i.value?.value
                ? i.value.value
                : i.value?.map((j) => j.value),
            };
          }),
        ],
        downoadFile: true,
      },
      true
    );
    if (type == "raw") {
      downoadRaw(data);
    } else {
      downloadExcel(data);
    }
  };
  const downoadRaw = (data) => {
    data = data.map((i) => {
      i["Fee Schedule"] = i.provider_type;
      i["NUCC Grouping"] = i.group1;
      i["NUCC Classification"] = i.group2;
      i["NUCC Specialization"] = i.group3;
      delete i.provider_type;
      delete i.group1;
      delete i.group2;
      delete i.group3;
      return i;
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workbook,
      `rate_utilization_view_raw_${new Intl.DateTimeFormat("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format()}.xlsx`
    );
  };

  return (
    <div>
      {zigna_aiStore?.professionalRateChoiceCount >
      process.env.REACT_APP_MAX_OD_ROW_LIMIT ? (
        <RequestExtraData
          message={
            <span>
              The selected filters returned{" "}
              <b>{millify(zigna_aiStore?.professionalRateChoiceCount)}</b>{" "}
              records.{" "}
              {zigna_aiStore?.professionalRateChoiceCount < 5000000
                ? "A sample of"
                : ""}{" "}
              <b>{millify(process.env.REACT_APP_MAX_OD_ROW_LIMIT)}</b> ordered
              rows are being displayed here, You can place a request to get all
              data by clicking on Raise request
              {zigna_aiStore?.professionalRateChoiceCount > 5000000
                ? `, only if amount of data is less than 5M records. Please adjust your filters to narrow the criteria, if you would like to Raise request.`
                : "."}
            </span>
          }
          disabled={zigna_aiStore?.professionalRateChoiceCount > 5000000}
          onConfirm={(fields) => {
            zigna_aiStore.placeRateUtilizationDataRequestByTin(
              {
                fields: [
                  ...filterSet.map((i) => {
                    return {
                      field: i.field,
                      value: i.value?.value
                        ? i.value.value
                        : i.value?.map((j) => j.value),
                    };
                  }),
                  ...payerFilter.map((i) => {
                    return {
                      field: i.field,
                      value: i.value?.value
                        ? i.value.value
                        : i.value?.map((j) => j.value),
                    };
                  }),
                ],
                downoadFile: true,
                ...fields,
                request_type: "professional_rate_utilization_view_by_tin",
              },
              zigna_aiStore.setNotification
            );
          }}
        />
      ) : null}
      <div className="d-flex w-100 justify-content-end px-4 py-2">
        {selectedRates.length > 0 ? (
          <>
            <Button onClick={() => setShowHidden(!showHidden)}>
              {showHidden ? "Hide selected rates" : "Show hidden rates"}
            </Button>
          </>
        ) : (
          ""
        )}
      </div>
      {items.length ? (
        <div>
          <span className="downloadBtn ">
            <svg
              width="20"
              height="20"
              viewBox="0 0 123 121"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4_39)">
                <path
                  d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
                  fill="#B54491"
                />
              </g>
              <defs>
                <clipPath id="clip0_4_39">
                  <rect width="122.88" height="120.89" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="ml-5" onClick={() => handleDownloadData("raw")}>
              Raw Data
            </span>
          </span>
          <span className="downloadBtn ">
            <svg
              width="20"
              height="20"
              viewBox="0 0 123 121"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4_39)">
                <path
                  d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
                  fill="#B54491"
                />
              </g>
              <defs>
                <clipPath id="clip0_4_39">
                  <rect width="122.88" height="120.89" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              className="ml-5"
              onClick={() => handleDownloadData("formatted")}
            >
              Formatted Data
            </span>
          </span>
          <div
            style={{
              overflow: "scroll",
              height: "93.5vh",
              width: "100%",
              marginBottom: 10,
              fontFamily: "arial",
              fontSize: 16,
              marginTop: 12,
            }}
          >
            <CommonTable
              sticky
              stickyTop={0}
              columns={columns}
              data={items.slice(activePage * 100, (activePage + 1) * 100)}
              className="zignaAi_table_1 zignaAi_newTable"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
                position: "sticky",
                left: 0,
              }}
              className="py-4"
            >
              <CustomPagination
                active={activePage}
                pages={Number(items.length / 100)}
                setActive={(page) => setActivePage(page)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ fontSize: 60, marginTop: 60 }}
          />
          <span className="mt-4 text-center" style={{ fontSize: 18 }}>
            Due of limitations in the data samples, we are unable to retrieve
            details for the specified filter. Please provide more specific
            information, such as the TIN ID, to ensure accurate results
          </span>
        </div>
      )}
    </div>
  );
};

export default Table;
