import axios from "axios";

export const url = process.env.REACT_APP_API_ENDPOINT;
// export const url = 'http://localhost:3001/api/v1'
let axiosConfig = axios;
axiosConfig.interceptors.request.use(
  function (config) {
    setLoading(true);
    if (!config.headers.Authorization) {
      const user = localStorage.getItem("currentUser");
      let token = "";
      if (user) {
        const usr = JSON.parse(user);
        token = usr?.token;
      }
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  function (response) {
    setLoading(false);
    return response;
  },
  function (error) {
    setLoading(false);
    console.log(error.response, "error.response");
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("currentUser");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
export default axiosConfig;
let reqCount = 0;
function setLoading(isLoading) {
  console.log(isLoading);
  let ele = document.getElementById("root");
  if (isLoading) {
    reqCount++;
    isLoading = true;
    ele.classList.add("showLoading");
  } else {
    reqCount--;
    if (reqCount > 0) {
      // Do nothing as of now
    } else {
      reqCount = 0;
      setTimeout(() => {
        ele.classList.remove("showLoading");
      }, 0);
    }
  }
}
