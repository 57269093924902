import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import FilterWidget from "../../components/ZignaAi/RateUtilizationByTin/FilterUtilization";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { getYearFromString, getMonthFromString } from "../../Utilities";
import { useLocation } from "react-router-dom";

import DataTable from "../../components/ZignaAi/RateUtilizationByTin/DataTable";

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    const location = useLocation();

    const [filterSet, setFilterSet] = useState([]);
    const [payerFilter, setPayerFilter] = useState([]);
    const [error, setError] = useState(false);
    useEffect(() => {
      zigna_aiStore.professionalRateChoiceData = [];
      zigna_aiStore.professionalRateChoiceCount = 0;
      zigna_aiStore.professionalType = [];
      zigna_aiStore.professionalOptionalFilter = {};
      setFilterSet([]);
      setPayerFilter([
        {
          field: "rate_type",
          value: {
            name: "Non-facility",
            value: "non_facility",
          },
        },
        {
          field: "mfr",
          value: {
            name: "All NPI's",
            value: "all",
          },
        },
        {
          field: "mrr",
          value: {
            name: "All Rates",
            value: "all",
          },
        },
      ]);
    }, [location]);

    useEffect(() => {
      setError(false);
    }, [filterSet]);

    let currentDb = (authStore?.currentUser?.perms?.dblist || []).find(
      (i) => i.dbname == authStore?.currentUser?.db_name
    );

    if (
      getYearFromString(currentDb.label) < 23 ||
      (getYearFromString(currentDb.label) == 23 &&
        getMonthFromString(currentDb.label) < 10)
    )
      return (
        <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
          <h3>Feature not available for current database</h3>

          <p style={{ fontSize: 18 }}>
            This feature is available for november 23 or later database.
          </p>
        </div>
      );
    else
      return (
        <>
          <Row>
            <Col xs={12} xl={12} className="mb-4">
              <FilterWidget
                filterSet={filterSet}
                payerFilter={payerFilter}
                setPayerFilter={setPayerFilter}
                setFilterSet={setFilterSet}
                setError={() => setError(true)}
              />
            </Col>
            <Row
              className={` ${
                zigna_aiStore.professionalRateChoiceData.length == 0 &&
                error == false &&
                "blurDiv"
              } m-0 p-0`}
            >
              <Col xs={12} xl={12} className="mb-4">
                {zigna_aiStore.professionalRateChoiceData.length == 0 ? (
                  <div className={error == false ? "blurOverlay" : ""}>
                    <center>
                      <h5>
                        {error
                          ? "No filter, too much data. Please narrow filter criteria before trying to retrieve data"
                          : "Please Select Filter"}
                      </h5>
                    </center>
                  </div>
                ) : (
                  <DataTable
                    dataset={zigna_aiStore.professionalRateChoiceData}
                    filterSet={filterSet}
                    payerFilter={payerFilter}
                    zigna_aiStore={zigna_aiStore}
                  />
                )}
              </Col>
            </Row>
          </Row>
        </>
      );
  })
);
