import React, { useEffect ,useState} from 'react'
import { Col, Row, Card, Button, Form, Badge } from '@themesberg/react-bootstrap'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom'


let selectAllOptionH = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionP = { id: 0, name: 'Select All', value: 'All' };
let selectAllOptionC = { id: 0, name: 'Select All', value: 'All'};
let selectAllOptionType = { id: 0, name: 'Select All', value: 'All'};
const customStyles = {
	control: (base) => ({
		...base,
		background: '#e7e3f9',
		height: 44,
		minHeight: 44,
		minWidth: 100,
		width: 240,
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#c3b9f1',
			}
		},
	}),
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: '#c3b9f1',
		}
	},
}
export default inject('zigna_aiStore')(
	observer(({zigna_aiStore, hideModifier }) => {
		const npiSelectRef = useRef()
		let location = useLocation();
		const [providerType,setProviderType]=useState({id:"facility",name:"Facility",value:"facility"})
		useEffect(() => {
            zigna_aiStore.getDrgReferenceTable(providerType.value)
			
		}, [providerType.value])

		


		
		
	

		return (
			<Card border="light" className="shadow-sm filterSection">
				<Card.Body>
					<h5>
						Filter{' '}
					</h5>
					<Row>
						
							<Col className=" col-auto col-auto">
						<div className="mb-3">
								<label className="form-label">Provider type <span className='text-danger'>*</span></label>
								<div className="d-flex align-items-center h45 NPI-select">
									<Select
										className={`select-menu`}
										value={providerType}
										onChange={(e, data) => {
											   setProviderType(e)
                                               zigna_aiStore.getDrgReferenceTable(e.value)
										}}
										
										getOptionLabel={(option) => `${option.name}`}
										styles={customStyles}
										options={
											[
												{id:"ancillary",name:"Ancillary",value:"ancillary"},
												{id:"facility",name:"Facility",value:"facility"}
											]
										}
									
										
										
										
									/>
								</div>
							</div>
						</Col>
				
						
					</Row>
				</Card.Body>
			</Card>
		)
	})
)
