import React from "react";
import { CommonTable } from "../../common/CommonTable";
import _ from "lodash";

/** For future use - formatting only */
import "xlsx";

const Table = ({ dataset }) => {
  let columns = [
    {
      Header: "Billing Code",
      accessor: "billing_code",

      align: "center",
    },
    {
      Header: "Individual NPI",
      accessor: "npi",
      align: "left",
    },

    {
      Header: "Organization Tax ID",
      accessor: "tin",
      width: 130,
      align: "center",
    },
    {
      Header: "NUCC Grouping",
      accessor: "group1",

      align: "center",
    },
    {
      Header: "NUCC Classification",
      accessor: "group2",

      align: "center",
    },
    {
      Header: "NUCC Specialization",
      accessor: "group3",

      align: "center",
    },
    {
      Header: "Non-Physician Practitioner (NPP) Indicator",
      accessor: "npp_indicator",
      align: "left",
    },
  ];
  // if(!includeCMS)
  //   columns=columns.filter(col=>col.Header==="CMS")

  // let columns = [
  //   {
  //     Header: "",
  //     sticky: "left",
  //     id: "_blank",
  //     columns: [
  //       {
  //         Header: (props) => (
  //           <div
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onClick={() => handleSort("tin")}
  //           >
  //             Tax ID
  //           </div>
  //         ),
  //         accessor: `tin`,
  //         width: 115,
  //         mainGroup: true,
  //       },
  //       {
  //         Header: (props) => (
  //           <div
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //             }}
  //             onClick={() => handleSort("billigCode")}
  //           >
  //             billing code
  //           </div>
  //         ),
  //         textHeader: "Allowed Per Unit",
  //         align: "left",
  //         accessor: `billing_code`,
  //         width: 100,
  //         Cell: (props) => <>{props.value}</>,
  //       },
  //     ],
  //   },
  //   {
  //     Header: "CMS",
  //     sticky: "left",
  //     columns: [
  //       {
  //         Header: "Non-Facility Global / Base Rate",
  //         accessor: `CMS.non_facility`,
  //         width: 100,
  //         align: "right",
  //       },

  //       // {
  //       //   Header: "Utilization #",
  //       //   accessor: `CMS.proc_count`,
  //       //   width: 110,
  //       //   align: "right",
  //       // },
  //       {
  //         Header: "Utilization $",
  //         accessor: `CMS.proc_count$`,
  //         width: 110,
  //         align: "right",
  //         Cell: (props) => {
  //           return props.row.original?.CMS?.proc_count
  //             ? (
  //                 props.row.original?.CMS?.proc_count *
  //                 props.row.original?.CMS?.["non_facility"]
  //               ).toFixed(1)
  //             : "";
  //         },
  //       },
  //     ],
  //   },
  //   ..._.uniq(headers).map((i) => ({
  //     Header: <div className="d-flex flex-row justify-content-center">{i}</div>,
  //     accessor: i,
  //     columns: [
  //       {
  //         Header: "Non-Facility Global / Base Rate",
  //         accessor: `${i}.non_facility`,
  //         width: 100,
  //         align: "right",
  //       },
  //       {
  //         Header: "% Medicare",
  //         accessor: `${i}.diff`,
  //         width: 100,
  //         align: "right",

  //         Cell: (props) => {
  //           return getFormatedData(
  //             getRateDiff(
  //               props.row.original?.CMS?.["non_facility"],
  //               props.row.original?.[i]?.["non_facility"]
  //             )
  //           );
  //         },
  //       },
  //       // {
  //       //   Header: "Utilization #",
  //       //   accessor: `${i}.proc_count`,
  //       //   width: 110,
  //       //   align: "right",
  //       // },
  //       {
  //         Header: "Utilization $",
  //         accessor: `${i}.proc_count$`,
  //         width: 110,
  //         align: "right",
  //         Cell: (props) => {
  //           return props.row.original?.[i]?.proc_count
  //             ? (
  //                 props.row.original?.[i]?.proc_count *
  //                 props.row.original?.[i]?.["non_facility"]
  //               ).toFixed(1)
  //             : "";
  //         },
  //       },
  //     ],
  //   })),
  // ];

  return (
    <>
      <div
        style={{
          overflow: "scroll",
          maxHeight: "80.5vh",
          minHeight: "30vh",
          width: "100%",
          marginBottom: 10,
          fontFamily: "arial",
          fontSize: 16,
        }}
      >
        <CommonTable
          sticky
          stickyTop={0}
          columns={columns}
          data={dataset}
          className="zignaAi_table_1 zignaAi_newTable"
        />
      </div>
    </>
  );
};

export default Table;
