import React from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'
import FilterWidget from '../../components/ZignaAi/DRGcostBreakdown/FilterWidget'
import { inject, observer } from 'mobx-react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import PriceComparision from '../../components/ZignaAi/FilteredDrgList/PriceComparision'
import DataTable from '../../components/ZignaAi/DRGcostBreakdown/DataTable'

export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let location = useLocation();
		useEffect(() => {
			zigna_aiStore.resetdrgcostBreakdown()
			
		},[location])
		


		return (
			<>
				<Row>
					<Col xs={12} xl={12} className="mb-4">
						<FilterWidget />
					</Col>
					<Row className={` ${zigna_aiStore.drgcostbreakdownData.length ==0&&'blurDiv'} m-0 p-0`}>
						<Col xs={12} xl={12} className="mb-4">
						{
							zigna_aiStore.drgcostbreakdownData.length ==0?
							<div className='blurOverlay'><h5>Please select Filter</h5></div>
							:<DataTable dataset={zigna_aiStore.drgcostbreakdownData} payer={zigna_aiStore.drgCostBreakdownSelected.comparingEntity?.value}/>
						}
						</Col>

						
						
					</Row>
				</Row>
			</>
		)
	})
)
