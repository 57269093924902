import React from "react";
import { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";
import { inject, observer } from "mobx-react";

import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { addString, convertPrice } from "../../../Utilities";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip, useTooltip } from "@nivo/tooltip";
Chart.register(ChartDataLabels);

let chartMargin = { top: 50, right: 80, bottom: 50, left: 250 };
const BAR_MAX_HEIGHT = 25;
const CustomBarComponent = ({
  bar: { x, y, width, height, color, data, pType },
  ...rest
}) => {
  const h = height > BAR_MAX_HEIGHT ? BAR_MAX_HEIGHT : height;
  const { showTooltipFromEvent, hideTooltip } = useTooltip();
  // console.log(data)
  const getConvertValue = (value) => {
    return pType == "percentage"
      ? addString(parseInt(value), "suf", "%")
      : convertPrice(parseInt(value));
  };
  return (
    <rect
      x={x}
      y={y + height / 2 - h / 2}
      width={width}
      height={h}
      rx={3}
      fill={color}
      onMouseEnter={(event) =>
        showTooltipFromEvent(
          <BasicTooltip
            id={data.indexValue}
            value={getConvertValue(data?.value)}
          />,
          event
        )
      }
      onMouseLeave={() => hideTooltip()}
      onMouseMove={(event) =>
        showTooltipFromEvent(
          <BasicTooltip
            id={data?.indexValue}
            value={getConvertValue(data?.value)}
          />,
          event
        )
      }
    />
  );
};
const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
  const MAX_LINE_LENGTH = 25;
  const MAX_LINES = 2;
  const LENGTH_OF_ELLIPSIS = 3;
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
  const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    "gm"
  );
  const splitValues = value
    .replace(trimWordsOverLength, "$1...")
    .match(groupWordsByLength)
    .slice(0, 2)
    .map((val, i) => (
      <tspan
        key={val}
        dy={14 * i}
        x={-10}
        // style={{ fontFamily: 'sans-serif', fontSize: '11px' }}
      >
        {val}
      </tspan>
    ));
  return (
    <g transform={`translate(${x},${y})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  );
};
export default inject("zigna_aiStore")(
  observer(({ zigna_aiStore }) => {
    const DataLabelOutside = ({ bars }) => {
      return bars.map((bar) => {
        const {
          key,
          width,
          height,
          x,
          y,
          data: { value },
        } = bar;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var textWidth = 20 + ctx.measureText(parseInt(value)).width;
        return (
          <g key={key} transform={`translate(${x}, ${y})`}>
            <text
              transform={`translate(${width + textWidth}, ${height / 2 + 5})`}
              textAnchor="middle"
              // fontSize="11px"
            >
              {/* YOUR LABEL HERE */}
              {convertPrice(parseInt(value))}
            </text>
          </g>
        );
      });
    };

    let colors = [
      "#3D2260",
      "#FFF2CC",
      "#C7E9B0",
      "#FFB4B4",
      "#E9EDC9",
      "#95BDFF",
      "#DFFFD8",
      "#B4E4FF",
    ];
    let groupedBuHospitalData = _.groupBy(
      zigna_aiStore.procedureCostByHospitalData,
      "reporting_entity_name"
    );
    let hospitals = _.uniq(
      _.map(zigna_aiStore.procedureCostByHospitalData, "provider_name")
    );
    let series = Object.keys(groupedBuHospitalData).map((key) => {
      return {
        label: key,
        barThickness: 24,
        backgroundColor:
          colors[Object.keys(groupedBuHospitalData).indexOf(key)],
        datalabels: {
          anchor: "end",
          clamp: true,
          align: "end",
          offset: 0,
          font: {
            weight: "bold",
          },
        },
        data: hospitals.map((hp) => {
          let data = groupedBuHospitalData[key].filter((d) => {
            return d.provider_name == hp;
          });
          return data.length == 0 ? 0 : data[0].avg_rate.toFixed(2);
        }),
      };
    });

    function formatLabel(str, maxwidth) {
      var sections = [];
      var words = str.split(" ");
      var temp = "";

      words.forEach(function (item, index) {
        if (temp.length > 0) {
          var concat = temp + " " + item;

          if (concat.length > maxwidth) {
            sections.push(temp);
            temp = "";
          } else {
            if (index == words.length - 1) {
              sections.push(concat);
              return;
            } else {
              temp = concat;
              return;
            }
          }
        }

        if (index == words.length - 1) {
          sections.push(item);
          return;
        }

        if (item.length < maxwidth) {
          temp = item;
        } else {
          sections.push(item);
        }
      });

      return sections;
    }
    // function drawMap() {
    // 	d3.select('#PCHgraph').select('svg').remove()

    //     var data = {
    //       labels:
    //       hospitals
    //       ,
    //       series: series,

    //     };

    //     var chartWidth       = 800,
    //         barHeight        = 20,
    //         groupHeight      = barHeight * data.series.length,
    //         gapBetweenGroups = 10,
    //         spaceForLabels   = 150,
    //         spaceForLegend   = 150;

    //     // Zip the series data together (first values, second values, etc.)
    //     var zippedData = [];
    //     for (var i=0; i<data.labels.length; i++) {
    //       for (var j=0; j<data.series.length; j++) {
    //         zippedData.push(data.series[j].values[i]);
    //       }
    //     }

    //     // Color scale
    //     var color = d3.scaleOrdinal()
    // .range(["#3d2260",
    //   "#553e74",
    //   "#6e5988",
    //   "#86759c",
    //   "#9e91b0",
    //   "#b6acc3",
    //   "#cfc8d7",
    //   "#e7e3eb"]);
    //     var chartHeight = barHeight * zippedData.length + gapBetweenGroups * data.labels.length;

    //     var x = d3.scaleLinear()
    //         .domain([0, d3.max(zippedData)])
    //         .range([0, chartWidth]);

    //     var y = d3.scaleLinear()
    //         .range([chartHeight + gapBetweenGroups, 0]);

    //     var yAxis = d3.axisLeft()
    //         .scale(y)
    //         .tickFormat('')
    //         .tickSize(0);

    //     var xAxis = d3.axisBottom(x).tickFormat(function(d){
    //     return d;
    //     });

    //     // Specify the chart area and dimensions
    //     var chart = d3
    // 		.select('#PCHgraph').append('svg')
    //         .attr("width", spaceForLabels + chartWidth + spaceForLegend)
    //         .attr("height", chartHeight +30);

    //        // console.log(chartHeight);

    //     // Create bars
    //     var bar = chart.selectAll("g")
    //         .data(zippedData)
    //         .enter().append("g")
    //         .attr("transform", function(d, i) {
    //           return "translate(" + spaceForLabels + "," + (i * barHeight + gapBetweenGroups * (0.5 + Math.floor(i/data.series.length))) + ")";
    //         });

    //     // Create rectangles of the correct width
    //     bar.append("rect")
    //         .attr("fill", function(d,i) { return color(i % data.series.length); })
    //         .attr("class", "bar")
    //         .attr("width", x)
    //         .attr("height", barHeight - 1);

    //     /* // Add text label in bar
    //     bar.append("text")
    //         .attr("x", function(d) { return x(d) - 3; })
    //         .attr("y", barHeight / 2)
    //         .attr("fill", "red")
    //         .attr("dy", ".35em")
    //         .text(function(d) { return d; }); */

    //     // Draw labels
    //     bar.append("text")
    //         .attr("class", "label")
    //         .attr("x", function(d) { return - 120; })
    //         .attr("y", groupHeight / 2)
    //         .attr("dy", ".35em")
    //         .text(function(d,i) {
    //           if (i % data.series.length === 0)
    //             return data.labels[Math.floor(i/data.series.length)];
    //           else
    //             return ""});

    //     chart.append("g")
    //           .attr("class", "y axis")
    //           .attr("transform", "translate(" + spaceForLabels + ", " + -gapBetweenGroups/2 + ")")
    //           .call(yAxis);

    //      chart.append("g")         // Add the X Axis
    //             .attr("class", "x axis")
    //             .attr("transform", "translate(" + spaceForLabels + "," + chartHeight + ")")
    //             .call(xAxis);

    //     // Draw legend
    //     var legendRectSize = 18,
    //         legendSpacing  = 4;

    //     var legend = chart.selectAll('.legend')
    //         .data(data.series)
    //         .enter()
    //         .append('g')
    //         .attr('transform', function (d, i) {
    //             var height = legendRectSize + legendSpacing;
    //             var offset = -gapBetweenGroups/2;
    //             var horz = spaceForLabels + chartWidth + 40 - legendRectSize;
    //             var vert = i * height - offset;
    //             return 'translate(' + horz + ',' + vert + ')';
    //         });

    //     legend.append('rect')
    //         .attr('width', legendRectSize)
    //         .attr('height', legendRectSize)
    //         .style('fill', function (d, i) { return color(i); })
    //         .style('stroke', function (d, i) { return color(i); });

    //     legend.append('text')
    //         .attr('class', 'legend')
    //         .attr('x', legendRectSize + legendSpacing)
    //         .attr('y', legendRectSize - legendSpacing)
    //         .text(function (d) { return d.label; });

    // }
    const dataHorBar = {
      labels: hospitals.map((i) => formatLabel(i, 20)),
      datasets: series,
    };

    console.log(
      // dataHorBar,
      JSON.parse(JSON.stringify(zigna_aiStore.procedureCostByHospitalData))
    );

    return (
      <div
        style={{
          width: "100%",
          height: 100 + zigna_aiStore.procedureCostByHospitalData.length * 50,
        }}
      >
        <ResponsiveBar
          barComponent={CustomBarComponent}
          theme={{
            fontSize: ".9rem",
            axis: {
              domain: {
                line: {
                  stroke: "#777777",
                  strokeWidth: 1,
                },
              },
            },
          }}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            DataLabelOutside,
          ]}
          enableLabel={false}
          data={zigna_aiStore.procedureCostByHospitalData}
          keys={["avg_rate"]}
          indexBy="provider_name"
          borderRadius={3}
          margin={chartMargin}
          padding={0.3}
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={["#8B6CBC", "#8B6CBC", "#8B6CBC"]}
          enableGridX={false}
          enableGridY={false}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            // tickValues: [1000, 2000, 3000, 4000, 5000],
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",

            legendOffset: 0,
            format: function (value) {
              return convertPrice(parseInt(value));
            },
          }}
          axisLeft={{
            legend: "",

            renderTick: HorizontalTick,
            tickPadding: 4,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[]}
          barAriaLabel={function (e) {
            return (
              e.id + ": " + e.formattedValue + " in payerName: " + e.indexValue
            );
          }}
          tooltip={(bar, color, label) => {
            return (
              <b style={{ background: "white" }}>
                {bar.indexValue}: {convertPrice(parseInt(bar.value))}
              </b>
            );
          }}

          // Add this line to sort the chart in descending order based on data value
        />
      </div>
    );
  })
);
