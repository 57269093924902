import React,{useState} from 'react';
import { CommonTable } from '../../common/CommonTableWithGroup';
import _ from "lodash"
import { Col, Row ,Card} from '@themesberg/react-bootstrap'
import { convertPrice } from '../../../../Utilities';
import * as XLSX from 'xlsx';
import Filters from "../../DataAuditReferenceTable/Tables/filters/DrgCodeByHospital"
/** For future use - formatting only */

 

// const convertDataSetToColumns=(dataset)=>{
//   let dataArr=[]
//   _.groupBy(dataset,i=>i.)
// }
const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];


const transformToColumns = (arr) => {
  const cols = Object.keys(arr && arr.length ? arr[0] : []);
  const rows = {};
  arr.forEach((col) => {
    if (!rows[col.reporting_entity_name]) rows[col.reporting_entity_name] = col;    
  });
  const index = cols.indexOf('reporting_entity_name');
  cols.splice(index, 1);
  return [rows, [...new Set(cols)]];
};

const Table=({ dataset })=>{
  const [dataType,setDataType]=useState("raw")
    const [rows, cols] = transformToColumns(
      dataset || []
    );

const transformDataSet=(data)=>{
 
    let obj=_.groupBy(data,i=>i.reporting_entity_name+i.billing_code_type+i.state+i.provider_type+i.provider_name)

   return Object.values(obj).map((rows) => {
        let item={
          sortIndex:0
        }
        rows.forEach((row) => {
item.reporting_entity_name = row.reporting_entity_name
item.billing_code_type = row.billing_code_type
item.state = row.state
item.provider_type = row.provider_type
item.provider_name = row.provider_name
item[row.month]=row.total_counts
if(row.total_counts){
  item.sortIndex=item.sortIndex+1
}
        })
return item
    })
}
    


 let columns=   [
     
          {
            Header: 'Payer',
            accessor: 'reporting_entity_name',
            enableRowSpan: true
          },

          {
            Header:"Provider Type",
            accessor: 'provider_type',

          },
         

          {
            Header: 'Code Type',
            accessor: 'billing_code_type',
          },
          {
            Header: 'State',
                        accessor:'state',
                        width:80
          },
          {
            Header:"Facility Name",
            accessor: 'provider_name',
          },
          {
            Header: 'Count',
            
           
            columns:_.orderBy(_.uniqBy(dataset,i=>i.month),i=>(monthNames.findIndex(ind=>ind==i.month)),['asc']).map(i=>({
                Header: i.month,
                accessor: i.month,
                align:"right",
                width:80,
                Cell: cv => (<> {convertPrice(cv.value,{skipCurrency : true})} </>)
             
            }))
            
            
          },
          


        
    ]
    
    function useInstance(instance) {
        const { allColumns } = instance;
      
        let rowSpanHeaders = [];
      
        allColumns.forEach((column, i) => {
          const { id, enableRowSpan } = column;
      
          if (enableRowSpan !== undefined) {
            rowSpanHeaders = [
              ...rowSpanHeaders,
              { id, topCellValue: null, topCellIndex: 0 }
            ];
          }
        });
      
        Object.assign(instance, { rowSpanHeaders });
      }



      const downloadExcel = (data) => {

        let accessors=[{
          "name":"",
          accessor:"reporting_entity_name"
        },
        {
          name:"__EMPTY2",
          accessor:"billing_code_type"
        },
      {
        name:"__EMPTY3",
                accessor:"state"

      },
      {
name:"__EMPTY4",
                accessor:"provider_type"
      },
      {
        name:"__EMPTY5",
        accessor:"provider_name"
      }
      
      ]
         let firstRow=
          {
            "":"PAYER",
            '__EMPTY2':"CODE TYPE",
            '__EMPTY3':"STATE",
            '__EMPTY4':"PROVIDER TYPE",
            '__EMPTY5':"FACILITY NAME"
           
        
          }
          let months= _.orderBy(_.uniqBy(dataset,i=>i.month),i=>(monthNames.findIndex(ind=>ind==i.month)),['asc'])
        
          const merge = [
            { s: { r: 0, c: 0 }, e: { r: 0, c:4 } },
            { s: { r: 0, c: 5 }, e: { r: 0, c: months.length-1+5 } },
          
          ];
         months.map((i,index)=>{
          if(index>0){
            firstRow[`__Empty_${i.month}`]=i.month
            accessors.push({
              name:`__Empty_${i.month}`,
              accessor:`${i.month}`
            })
          }
          else{
            firstRow[`Count`]=i.month
            accessors.push({
              name:`Count`,
              accessor:`${i.month}`
            })
          }
         })
        
        
        
        
        
         let formatedJson=[firstRow]
         
         _.map(data,item=>{
        let obj={}
        
        accessors.map(i=>{
          obj[i.name]=item[i.accessor]
        })
        formatedJson.push(obj)
        
         })
            
                  const worksheet = XLSX.utils.json_to_sheet(formatedJson);
                  worksheet['!merges'] = merge;
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                  XLSX.writeFile(workbook, 'DataSheet.xlsx');
                };
            
                const downoadRaw=(data)=>{
                  const worksheet = XLSX.utils.json_to_sheet(data);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
                  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
                  XLSX.writeFile(workbook, 'DataSheet.xlsx');
                }
                

    return (

      <div>
              {/* <Col xs={12} className='d-flex justify-content-end py-2'>
  <div className="btn-group" role="group" aria-label="Data-type">
        <button type="button" onClick={()=>setDataType("raw")} className={`btn ${dataType=="raw"?"btn-primary":"btn-outline-primary"}`}>Raw data</button>
  <button type="button" onClick={()=>setDataType("relevant")} className={`btn ${dataType=="relevant"?"btn-primary":"btn-outline-primary"}`}>Relevant data</button>
</div>
</Col> */}

<Card     border='light'
        >
<Card.Header className='pb-0'>
          <Row className='align-items-center'>
            <Col col={12} className='d-flex justify-content-between'>
              <h6>
              Count of DRG codes posted by hospitals
              
              </h6>
        
            </Col>
          </Row>
        </Card.Header>
        <Card.Body  className='pb-4'>
        <Filters dataType={dataType}/>
                            </Card.Body>
      </Card>
      <div  style={{paddingBottom:16,paddingTop:16}}>
      <span className="downloadBtn ">
								<svg
									width="20"
									height="20"
									viewBox="0 0 123 121"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_4_39)">
										<path
											d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
											fill="#B54491"
										/>
									</g>
									<defs>
										<clipPath id="clip0_4_39">
											<rect width="122.88" height="120.89" fill="white" />
										</clipPath>
									</defs>
								</svg>
								<span className="ml-5" onClick={()=>downoadRaw(dataset)}>
									Raw Data
								</span>
							</span>
     <span className="downloadBtn ">
								<svg
									width="20"
									height="20"
									viewBox="0 0 123 121"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_4_39)">
										<path
											d="M84.58 47C85.2961 46.2624 86.1525 45.6755 87.0988 45.2737C88.0451 44.872 89.0621 44.6635 90.0902 44.6607C91.1182 44.6578 92.1364 44.8605 93.0849 45.257C94.0335 45.6534 94.8931 46.2355 95.6133 46.9691C96.3336 47.7026 96.8998 48.5728 97.2788 49.5285C97.6578 50.4841 97.8418 51.5059 97.82 52.5337C97.7983 53.5615 97.5712 54.5746 97.1522 55.5133C96.7332 56.4521 96.1306 57.2975 95.38 58L66.09 86.88C64.6464 88.2981 62.7036 89.0927 60.68 89.0927C58.6564 89.0927 56.7136 88.2981 55.27 86.88L26.4 58.37C24.9413 56.9365 24.1118 54.9823 24.094 52.9372C24.0762 50.8921 24.8715 48.9237 26.305 47.465C27.7385 46.0063 29.6927 45.1768 31.7378 45.159C33.7829 45.1412 35.7513 45.9365 37.21 47.37L53.1 63.12L53.26 7.65C53.3468 5.65118 54.2063 3.76414 55.6572 2.38657C57.1081 1.009 59.0371 0.248502 61.0378 0.265358C63.0384 0.282213 64.9544 1.0751 66.3818 2.47692C67.8093 3.87874 68.6369 5.78 68.69 7.78L68.54 62.78L84.58 47ZM0 113.48L0.1 83.3C0.0733066 82.2639 0.255513 81.233 0.635757 80.2689C1.016 79.3047 1.58649 78.427 2.31323 77.688C3.03996 76.9491 3.90804 76.364 4.86573 75.9677C5.82341 75.5715 6.85109 75.3721 7.88748 75.3815C8.92387 75.3909 9.94776 75.6089 10.8981 76.0225C11.8484 76.4361 12.7058 77.0368 13.419 77.7888C14.1322 78.5408 14.6866 79.4288 15.0493 80.3997C15.412 81.3706 15.5755 82.4046 15.53 83.44L15.46 105.44C46.1267 105.5 76.7633 105.5 107.37 105.44L107.44 83.32C107.459 81.2725 108.29 79.3163 109.751 77.8816C111.212 76.447 113.183 75.6514 115.23 75.67C117.277 75.6886 119.234 76.5197 120.668 77.9806C122.103 79.4415 122.899 81.4125 122.88 83.46L122.78 113.46H122.69C122.6 115.432 121.758 117.294 120.337 118.663C118.916 120.032 117.023 120.804 115.05 120.82C79.23 120.887 43.4367 120.887 7.67 120.82C5.69389 120.814 3.79555 120.049 2.36785 118.683C0.940148 117.317 0.0923821 115.454 0 113.48H0Z"
											fill="#B54491"
										/>
									</g>
									<defs>
										<clipPath id="clip0_4_39">
											<rect width="122.88" height="120.89" fill="white" />
										</clipPath>
									</defs>
								</svg>
								<span className="ml-5" onClick={()=>downloadExcel(_.orderBy(transformDataSet(dataset),["sortIndex"],["desc"])|| [])}>
									Formatted Data
								</span>
           
							</span>
              </div>
<CommonTable
            columns={columns}
            data={_.orderBy(transformDataSet(dataset),["sortIndex"],["desc"])|| []}
            className='zignaAi_table_1 zignaAi_newTable'
          />
       
       
    
       </div> 
           
    
    
         
     
    );
                }

                export default Table

