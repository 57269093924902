import React, { useEffect, useMemo, useState, Suspense } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";

import { inject, observer } from "mobx-react";

import DataBarchart from "../../components/ZignaAi/DataAuditReferenceTable/DataBarchart";
import DistinctBillingCodesGraph from "../../components/ZignaAi/DataAuditReferenceTable/DistinctBillingCodesGraph";
import MapChart from "../../components/ZignaAi/DataAuditReferenceTable/NpisMaps";

import WellmarkData from "../../data/DataAudit/main.json";

import DrgCodesByHospitalWithNtype from "../../components/ZignaAi/DataAuditReferenceTable/Tables/DrgCodesByHospitalWithNtype";
import Select from "react-select";
import millify from "millify";
import { convertToMonth, convertToQuater } from "../../../src/Utilities";
import _, { escape } from "lodash";
import Preloader from "../../components/Preloader";
const customStyles = {
  control: (base) => ({
    ...base,
    background: "#e7e3f9",
    height: 44,
    minHeight: 44,
    minWidth: 100,
    width: "100%",
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#c3b9f1",
      };
    },
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#c3b9f1",
    };
  },
};

export default inject(
  "zigna_aiStore",
  "authStore"
)(
  observer(({ zigna_aiStore, authStore }) => {
    let dataType = zigna_aiStore.DataAuditDataType;

    const setDataType = (type) => {
      zigna_aiStore.setDataAuditDataType(type);
    };
    const [AuditData, setAuditData] = useState([]);
    const [BCTVData, setBCTVData] = useState([]);
    const [slectedData, setSelected] = useState({});
    const [selectedDataSet, setSelectedDataset] = useState([]);
    const [selectedMap, setSelectedMap] = useState("FACILITY");
    const [selectedCodeType, setSelectedCodeType] = useState({
      value: "CPT",
      name: "CPT",
    });
    const [selectedMonth, setSelectedMonth] = useState("");
    const [loader, setLoader] = useState(false);
    const BCTVGrouped = _.groupBy(
      _.orderBy(
        BCTVData.filter(
          (i) =>
            i.reporting_entity_name?.toUpperCase() ==
              slectedData?.REPORTING_ENTITY_NAME?.toUpperCase() &&
            i.billing_code_type == selectedCodeType?.value &&
            i.data_type == dataType
        ),
        (i) => new Date(i.month),
        ["asc"]
      ),
      (i) => convertToMonth(i.month)
    );

    const BCTVOptions = useMemo(() => {
      let options = _.uniqBy(BCTVData, (i) => i.billing_code_type)
        .map((i) => ({
          name: i.billing_code_type,
          value: i.billing_code_type,
        }))
        .filter((i) =>
          BCTVData.find(
            (j) =>
              j.reporting_entity_name?.toUpperCase() ==
                slectedData?.REPORTING_ENTITY_NAME?.toUpperCase() &&
              j.billing_code_type == i?.value &&
              j.data_type == dataType
          )
        );
      setSelectedCodeType(options[0]);
      return options;
    }, [BCTVData, slectedData, dataType]);
    // console.log(BCTVGrouped,"bctv")
    // useEffect(()=>{

    // 		if(authStore?.currentUser?.db_name=="wellmark")
    // 		 {
    // 			setAuditData(WellmarkData)
    // 			setSelected(WellmarkData[0])
    // 		    setSelectedDataset(WellmarkData.filter(i=>i.REPORTING_ENTITY_NAME==WellmarkData[0].REPORTING_ENTITY_NAME))
    // 			setBCTVData(BCTVWellmark)
    // 			setSelectedMonth(_.uniqBy(BCTVWellmark,i=>i.month)[0]?.month)
    // 			zigna_aiStore.getStaticChartData()
    // 		}
    // 		  else{
    // 			setBCTVData(BCTVExcellus)
    // 			setAuditData(ExellusData)
    // 			setSelected(ExellusData[0])
    // 			zigna_aiStore.getStaticChartData()
    // 		    setSelectedDataset(ExellusData.filter(i=>i.REPORTING_ENTITY_NAME==ExellusData[0].REPORTING_ENTITY_NAME))
    // 			setSelectedMonth(_.uniqBy(BCTVExcellus,i=>i.month)[0]?.month)
    // 		  }
    // },[authStore?.currentUser?.db_name])

    useEffect(() => {
      setLoader(true);
      zigna_aiStore.getStaticChartData();
    }, [authStore?.currentUser?.db_name]);

    useEffect(() => {
      setBCTVData(zigna_aiStore.staticBCBVData);
    }, [zigna_aiStore.staticBCBVData?.length]);

    useEffect(() => {
      if (zigna_aiStore.staticChartData?.length == 0) {
        return;
      }

      let data = zigna_aiStore.staticChartData.map((i) => {
        if (dataType == "raw") {
          return {
            MONTH: i["month"],
            REPORTING_ENTITY_NAME: i["reporting_entity_name"],
            POSTED_FILES: i["posted_files"],
            AVERAGE_FILE_SIZE: i["avg_file_size"],
            TOTAL_RECORDS: i["total_records"],
            TOTAL_NPIS: i["total_npis"],
            RELEVANT_NPIS: i["relevant_npis"],
            ZIP_CODES: i["total_zip_codes"],
            REGIONS: i["total_regions"],
            DERIVED: i["total_nt_d"],
            "FEE SCHEDULE": i["total_nt_fs"],
            NEGOTIATED: i["total_nt_neg"],
            "PER DIEM": i["total_nt_pd"],
            PERCENTAGE: i["total_nt_per"],
            INSTITUTIONAL: i["total_bc_inst"],
            PROFESSIONAL: i["total_bc_prof"],
            APC: i["total_records_bc_apc"],
            "AP-DRG": i["total_records_bc_ap-drg"],
            "APR-DRG": i["total_records_bc_apr-drg"],
            CDT: i["total_records_bc_cdt"],
            CPT: i["total_records_bc_cpt"],
            "CSTM-ALL": i["total_records_bc_cstm-all"],
            EAPG: i["total_records_bc_eapg"],
            HCPCS: i["total_records_bc_hcpcs"],
            HIPPS: i["total_records_bc_hipps"],
            ICD: i["total_records_bc_icd"],
            LOCAL: i["total_records_bc_local"],
            "MS-DRG": i["total_records_bc_msdrg"],
            NDC: i["total_records_bc_ndc"],
            RC: i["total_records_bc_rc"],
            PROC: i["total_records_bc_proc"],

            APC_DC: i["total_distinct_bc_apc"],
            "AP-DRG_DC": i["total_distinct_bc_ap-drg"],
            "APR-DRG_DC": i["total_distinct_bc_apr-drg"],
            CDT_DC: i["total_distinct_bc_cdt"],
            CPT_DC: i["total_distinct_bc_cpt"],
            "CSTM-ALL_DC": i["total_distinct_bc_cstm-all"],
            EAPG_DC: i["total_distinct_bc_eapg"],
            HCPCS_DC: i["total_distinct_bc_hcpcs"],
            HIPPS_DC: i["total_distinct_bc_hipps"],
            ICD_DC: i["total_distinct_bc_icd"],
            LOCAL_DC: i["total_distinct_bc_local"],
            "MS-DRG_DC": i["total_distinct_bc_msdrg"],
            NDC_DC: i["total_distinct_bc_ndc"],
            RC_DC: i["total_distinct_bc_rc"],
            PROC_DC: i["total_distinct_bc_proc"],

            PT_H: i["total_npi_pt_h"],
            PT_A: i["total_npi_pt_a"],
            PT_C: i["total_npi_pt_c"],
          };
        } else {
          return {
            MONTH: i["month"],
            REPORTING_ENTITY_NAME: i["reporting_entity_name"],
            POSTED_FILES: i["posted_files"],
            AVERAGE_FILE_SIZE: i["avg_file_size"],
            TOTAL_RECORDS: i["relevant_records"],
            TOTAL_NPIS: i["relevant_npis"],
            RELEVANT_NPIS: i["relevant_npis"],
            ZIP_CODES: i["relevant_zip_codes"],
            REGIONS: i["relevant_regions"],
            DERIVED: i["relevant_nt_d"],
            "FEE SCHEDULE": i["relevant_nt_fs"],
            NEGOTIATED: i["relevant_nt_neg"],
            "PER DIEM": i["relevant_nt_pd"],
            PERCENTAGE: i["relevant_nt_per"],
            INSTITUTIONAL: i["relevant_bc_inst"],
            PROFESSIONAL: i["relevant_bc_prof"],
            APC: i["relevant_records_bc_apc"],
            "AP-DRG": i["relevant_records_bc_ap-drg"],
            "APR-DRG": i["relevant_records_bc_apr-drg"],
            CDT: i["relevant_records_bc_cdt"],
            CPT: i["relevant_records_bc_cpt"],
            "CSTM-ALL": i["relevant_records_bc_cstm-all"],
            EAPG: i["relevant_records_bc_eapg"],
            HCPCS: i["relevant_records_bc_hcpcs"],
            HIPPS: i["relevant_records_bc_hipps"],
            ICD: i["relevant_records_bc_icd"],
            LOCAL: i["relevant_records_bc_local"],
            "MS-DRG": i["relevant_records_bc_msdrg"],
            NDC: i["relevant_records_bc_ndc"],
            RC: i["relevant_records_bc_rc"],
            PROC: i["relevant_records_bc_proc"],

            APC_DC: i["relevant_distinct_bc_apc"],
            "AP-DRG_DC": i["relevant_distinct_bc_ap-drg"],
            "APR-DRG_DC": i["relevant_distinct_bc_apr-drg"],
            CDT_DC: i["relevant_distinct_bc_cdt"],
            CPT_DC: i["relevant_distinct_bc_cpt"],
            "CSTM-ALL_DC": i["relevant_distinct_bc_cstm-all"],
            EAPG_DC: i["relevant_distinct_bc_eapg"],
            HCPCS_DC: i["relevant_distinct_bc_hcpcs"],
            HIPPS_DC: i["relevant_distinct_bc_hipps"],
            ICD_DC: i["relevant_distinct_bc_icd"],
            LOCAL_DC: i["relevant_distinct_bc_local"],
            "MS-DRG_DC": i["relevant_distinct_bc_msdrg"],
            NDC_DC: i["relevant_distinct_bc_ndc"],
            RC_DC: i["relevant_distinct_bc_rc"],
            PROC_DC: i["relevant_distinct_bc_proc"],

            PT_H: i["relevant_npi_pt_h"],
            PT_A: i["relevant_npi_pt_a"],
            PT_C: i["relevant_npi_pt_c"],
          };
        }
      });
      console.log(data, "all data");
      setAuditData(_.orderBy(data, ["REPORTING_ENTITY_NAME"], ["asc"]));
      let filteredSelectedData =
        data.find(
          (i) =>
            i.REPORTING_ENTITY_NAME ==
            (authStore?.currentUser?.db_name?.includes("excellus")
              ? "Excellus"
              : "Wellmark Blue Cross and Blue Shield of Iowa")
        ) || data[0];
      setSelected(filteredSelectedData);
      setSelectedDataset(
        _.orderBy(
          data.filter(
            (i) =>
              i.REPORTING_ENTITY_NAME ==
              (filteredSelectedData.REPORTING_ENTITY_NAME ||
                data[0].REPORTING_ENTITY_NAME)
          ),
          (i) => new Date(i.MONTH),
          ["asc"]
        )
      );
      setLoader(false);
    }, [zigna_aiStore.staticChartData?.length, dataType]);

    useEffect(() => {
      if (slectedData?.REPORTING_ENTITY_NAME) {
        zigna_aiStore.npiDistributionAcrossCountry({
          reporting_entity_name: slectedData.REPORTING_ENTITY_NAME,
          data_type: dataType,
        });
        zigna_aiStore.getStaticBCBVData();
      }

      if (BCTVOptions.find((i) => i.value == selectedCodeType?.value)) {
      } else {
        setSelectedCodeType(BCTVOptions?.[0]);
      }
    }, [slectedData?.REPORTING_ENTITY_NAME, dataType]);

    let uniquerTypes = useMemo(() => {
      return _.uniqBy(zigna_aiStore.npiDistributionMap, (i) => i.provider_type);
    }, [zigna_aiStore?.npiDistributionMap]);

    let months = useMemo(() => {
      return _.uniqBy(zigna_aiStore.npiDistributionMap, (i) =>
        convertToMonth(i.month)
      );
    }, [zigna_aiStore?.npiDistributionMap]);

    useEffect(() => {
      if (uniquerTypes?.length)
        setSelectedMap({ name: "FACILITY", value: "FACILITY" });
      if (months?.length)
        setSelectedMonth(
          _.orderBy(months, (i) => new Date(i.month))[months?.length - 1].month
        );
    }, [uniquerTypes, months]);

    useEffect(() => {
      zigna_aiStore.getdDrgByHospitalCountWithNtype();
    }, []);

    if (selectedDataSet.length)
      return (
        <>
        {loader ? <Suspense fallback={<Preloader />}></Suspense> :(
          <>
          <Row mb={8}>
            <Col xs={4}>
              <Select
                className={`select-menu`}
                value={{
                  name: slectedData.REPORTING_ENTITY_NAME,
                  value: slectedData.REPORTING_ENTITY_NAME,
                }}
                onChange={(e) => {
                  setSelected(
                    AuditData.find((i) => i.REPORTING_ENTITY_NAME == e.value)
                  );
                  setSelectedDataset(
                    _.orderBy(
                      AuditData.filter(
                        (i) => i.REPORTING_ENTITY_NAME == e.value
                      ),
                      (i) => new Date(i.MONTH),
                      ["asc"]
                    )
                  );
                }}
                getOptionLabel={(option) => `${option.name}`}
                styles={customStyles}
                options={_.uniqBy(
                  AuditData,
                  (i) => i.REPORTING_ENTITY_NAME
                ).map((i) => ({
                  name: i.REPORTING_ENTITY_NAME,
                  value: i.REPORTING_ENTITY_NAME,
                }))}
              />
            </Col>
            <Col xs={8} className="d-flex justify-content-end">
              <div className="btn-group" role="group" aria-label="Data-type">
                <button
                  type="button"
                  onClick={() => setDataType("raw")}
                  className={`btn ${
                    dataType == "raw" ? "btn-primary" : "btn-outline-primary"
                  }`}
                >
                  Raw Data
                </button>
                <button
                  type="button"
                  onClick={() => setDataType("relevant")}
                  className={`btn ${
                    dataType == "relevant"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                >
                  Relevant Data
                </button>
              </div>
            </Col>

            <Col xs={12} className="mt-4">
              {slectedData && (
                <div className="d-flex flex-row">
                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Posted files"}
                    value={selectedDataSet}
                    keyName={"POSTED_FILES"}
                  />

                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Avg. file size"}
                    value={selectedDataSet}
                    keyName={"AVERAGE_FILE_SIZE"}
                    type={"text"}
                  />

                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Total NPIs"}
                    value={selectedDataSet}
                    keyName={"TOTAL_NPIS"}
                  />

                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Total records"}
                    value={selectedDataSet}
                    keyName={"TOTAL_RECORDS"}
                  />
                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Total regions"}
                    value={selectedDataSet}
                    keyName={"REGIONS"}
                  />

                  <Datacard
                    db={authStore?.currentUser?.db_name}
                    title={"Zip codes"}
                    value={selectedDataSet}
                    keyName={"ZIP_CODES"}
                  />
                </div>
              )}
            </Col>
            <Col>
              {slectedData && (
                <Row
                  className={` ${
                    zigna_aiStore.filteredDRGData.length == 0 && "blurDiv"
                  } mt-4 p-0`}
                >
                  <Col xs={12} xl={12} className="mb-4">
                    <Row>
                      <Col
                        xs={6}
                        className="mb-4 mr-0"
                        style={{ paddingRight: 4, paddingLeft: 4 }}
                      >
                        <Card border="light" className="d-flex h-100 p-0">
                          <Card.Body className="d-flex h-100 flex-column p-0">
                            <div className="d-flex justify-content-between">
                              <h6
                                className="fw400 p-3"
                                style={{ fontWeight: "600" }}
                              >
                                Billing Class
                              </h6>
                              {GetLegeng(
                                selectedDataSet?.map((i) =>
                                  convertToMonth(i.MONTH)
                                )
                              )}
                            </div>

                            <div style={{ height: "100%", width: "100%" }}>
                              <DataBarchart
                                title={"Billing Class"}
                                rotation={-45}
                                padding={{ top: 32 }}
                                responsive={true}
                                // barThickness={38}
                                autoBarThickness={true}
                                data={selectedDataSet.map((i) => ({
                                  month: i.MONTH,
                                  data: [
                                    {
                                      label: "institutional",
                                      value: i?.INSTITUTIONAL,
                                      color: "#3d2260",
                                    },
                                    {
                                      label: "professional",
                                      value: i?.PROFESSIONAL,
                                      color: "#1976d2",
                                    },
                                  ],
                                }))}
                                isGrouped={true}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col
                        xs={6}
                        className="mb-4"
                        style={{ paddingRight: 4, paddingLeft: 4 }}
                      >
                        <Card border="light" className="">
                          <Card.Body className="p-0">
                            <div className="d-flex justify-content-between">
                              <h6
                                className="fw400 p-3"
                                style={{ fontWeight: "600" }}
                              >
                                Provider Type
                              </h6>
                              {GetLegeng(
                                selectedDataSet?.map((i) =>
                                  convertToMonth(i.MONTH)
                                )
                              )}
                            </div>
                            <div className="overflow-x-scroll">
                              <DataBarchart
                                title={"Provider Type"}
                                padding={{ top: 32 }}
                                // barThickness={38}
                                autoBarThickness={true}
                                rotation={-45}
                                responsive={true}
                                data={selectedDataSet.map((i) => ({
                                  month: i.MONTH,
                                  data: [
                                    {
                                      label: "Hospital",
                                      value: i?.PT_H,
                                    },
                                    {
                                      label: "Ancillary",
                                      value: i?.PT_A,
                                    },
                                    {
                                      label: "Professional",
                                      value: i?.PT_C,
                                    },
                                  ],
                                }))}
                                isGrouped={true}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col
                        xs={12}
                        className="mb-4"
                        style={{ paddingRight: 4, paddingLeft: 4 }}
                      >
                        <Card border="light" className="d-flex h-100">
                          <Card.Body className="d-flex h-100 flex-column p-0">
                            <div className="d-flex justify-content-between">
                              <h6
                                className="fw400 p-3"
                                style={{ fontWeight: "600" }}
                              >
                                Negotiation Type
                              </h6>
                              {GetLegeng(
                                selectedDataSet?.map((i) =>
                                  convertToMonth(i.MONTH)
                                )
                              )}
                            </div>

                            <div style={{ height: "100%", width: "100%" }}>
                              <DataBarchart
                                half={true}
                                padding={{ top: 32 }}
                                rotation={-45}
                                autoBarThickness={true}
                                responsive={true}
                                data={selectedDataSet.map((i) => ({
                                  month: i.MONTH,
                                  data: [
                                    {
                                      label: "per diem",
                                      value: i?.["PER DIEM"],
                                      color: "#2b2764",
                                    },
                                    {
                                      label: "derived",
                                      value: i?.["DERIVED"],
                                      color: "#efa536",
                                    },

                                    {
                                      label: "negotiated",
                                      value: i?.["NEGOTIATED"],
                                      color: "#00dc8c",
                                    },
                                    {
                                      label: "fee schedule",
                                      value: i?.["FEE SCHEDULE"],
                                      color: "#16a4d8",
                                    },
                                    {
                                      label: "percentage",
                                      value: i?.["PERCENTAGE"],
                                      color: "#d64e12",
                                    },
                                  ],
                                }))}
                                isGrouped={true}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={6} className="mb-4">
                        <Card border="light">
                          <Card.Body style={{ height: 940 }}>
                            <div className="d-flex justify-content-between">
                              {" "}
                              <h6
                                className="fw400"
                                style={{ fontWeight: "600" }}
                              >
                                Record Counts By Billing Code Type
                              </h6>
                              {GetLegeng(
                                selectedDataSet?.map((i) =>
                                  convertToMonth(i.MONTH)
                                )
                              )}
                            </div>

                            <DataBarchart
                              title={"Billing Code"}
                              padding={{
                                right: 24,
                                bottom: 24,
                              }}
                              horizontal
                              data={selectedDataSet.map((i) => ({
                                month: i.MONTH,
                                data: [
                                  {
                                    label: "CPT",
                                    value: i?.CPT,
                                  },
                                  {
                                    label: "HCPCS",
                                    value: i?.HCPCS,
                                  },
                                  { label: "MS-DRG", value: i?.["MS-DRG"] },
                                  {
                                    label:
                                      authStore?.currentUser?.db_name ==
                                      "wellmark"
                                        ? "APR-DRG"
                                        : "AP-DRG",
                                    value:
                                      i?.[
                                        authStore?.currentUser?.db_name ==
                                        "wellmark"
                                          ? "AP-DRG"
                                          : "AP-DRG"
                                      ],
                                  },
                                  {
                                    label: "APR-DRG",

                                    value: i?.["APR-DRG"],

                                    //mapped:i?.[authStore?.currentUser?.db_name=="wellmark"?"APR-DRG_PM":"AP-DRG_PM"]?parseInt(i?.[authStore?.currentUser?.db_name=="wellmark"?"APR-DRG_PM":"AP-DRG_PM"]*100):""
                                  },
                                  {
                                    label: "RC",
                                    value: i?.RC,
                                  },
                                  {
                                    label: "CDT",
                                    value: i?.CDT,
                                  },

                                  {
                                    label: "EAPG",
                                    value: i?.EAPG,
                                  },

                                  {
                                    label: "CSTM-ALL",
                                    value: i?.["CSTM-ALL"],
                                  },
                                  {
                                    label: "APC",
                                    value: i?.["APC"],
                                  },
                                  {
                                    label: "NDC",
                                    value: i?.["NDC"],
                                  },
                                  {
                                    label: "ICD",
                                    value: i?.ICD,
                                  },
                                  {
                                    label: "HIPPS",
                                    value: i?.["HIPPS"],
                                  },
                                  {
                                    label: "PROC",
                                    value: i?.["PROC"],
                                  },

                                  { label: "LOCAL", value: i?.LOCAL },
                                ],
                              }))}
                              responsive
                              isGrouped={true}
                            />
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col xs={6} className="mb-4">
                        <Card border="light">
                          <Card.Body style={{ height: 940 }}>
                            <div className="d-flex justify-content-between">
                              {" "}
                              <h6
                                className="fw400"
                                style={{ fontWeight: "600" }}
                              >
                                Unique Billing Codes By Code Type
                              </h6>
                              {GetLegeng(
                                selectedDataSet?.map((i) =>
                                  convertToMonth(i.MONTH)
                                )
                              )}
                            </div>
                            {/* <center><div style={{fontSize:12}}>
									(x%) - percentage match with the code table
									</div></center> */}

                            <DataBarchart
                              title={"Unique billing codes"}
                              horizontal
                              padding={{
                                right: 24,
                                bottom: 24,
                              }}
                              data={selectedDataSet.map((i) => ({
                                month: i.MONTH,
                                data: [
                                  {
                                    label: "CPT",
                                    value: i?.CPT_DC,
                                    //mapped:i?.CPT_PM?parseInt(i?.CPT_PM*100):""
                                  },
                                  {
                                    label: "HCPCS",
                                    value: i?.HCPCS_DC,
                                    //mapped:i?.HCPCS_PM?parseInt(i?.HCPCS_PM*100):""
                                  },
                                  {
                                    label: "MS-DRG",
                                    value: i?.["MS-DRG_DC"],
                                    //mapped:i?.["MS-DRG_PM"]?parseInt(i?.["MS-DRG_PM"]*100):""
                                  },
                                  {
                                    label:
                                      authStore?.currentUser?.db_name ==
                                      "wellmark"
                                        ? "APR-DRG"
                                        : "AP-DRG",
                                    value:
                                      i?.[
                                        authStore?.currentUser?.db_name ==
                                        "wellmark"
                                          ? "AP-DRG_DC"
                                          : "AP-DRG_DC"
                                      ],
                                  },
                                  {
                                    label: "APR-DRG",

                                    value: i?.["APR-DRG_DC"],

                                    //mapped:i?.[authStore?.currentUser?.db_name=="wellmark"?"APR-DRG_PM":"AP-DRG_PM"]?parseInt(i?.[authStore?.currentUser?.db_name=="wellmark"?"APR-DRG_PM":"AP-DRG_PM"]*100):""
                                  },
                                  {
                                    label: "RC",
                                    value: i?.["RC_DC"],
                                  },
                                  {
                                    label: "APC",
                                    value: i?.APC_DC,
                                    //mapped:i?.APC_PM?parseInt(i?.APC_PM*100):""
                                  },
                                  {
                                    label: "CSTM-ALL",
                                    value: i?.["CSTM-ALL_DC"],
                                    //mapped:i?.["CSTM-ALL_PM"]?parseInt(i?.["CSTM-ALL_PM"]*100):""
                                  },

                                  { label: "CDT", value: i?.["CDT_DC"] },
                                  {
                                    label: "ICD",
                                    value: i?.["ICD_DC"],
                                  },
                                  {
                                    label: "LOCAL",
                                    value: i?.["LOCAL_DC"],
                                  },
                                  { label: "NDC", value: i?.["NDC_DC"] },
                                  {
                                    label: "HIPPS",
                                    value: i?.["HIPPS_DC"],
                                  },
                                  {
                                    label: "PROC",
                                    value: i?.["PROC_DC"],
                                  },

                                  {
                                    label: "EAPG",
                                    value: i?.["EAPG_DC"],
                                  },
                                ],
                              }))}
                              isGrouped={true}
                              responsive
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      {
                        <Col xs={6} className="mb-4">
                          <Card border="light">
                            <Card.Body>
                              <h6
                                className="fw400"
                                style={{ fontWeight: "600" }}
                              >
                                Unique Billing Codes By Version
                              </h6>
                              <div className="d-flex justify-content-between mt-1">
                                {GetLegeng(
                                  Object.keys(BCTVGrouped)?.map((i) =>
                                    convertToMonth(i)
                                  )
                                )}
                                {console.log(BCTVGrouped, "bctv")}
                                <Select
                                  className={`select-menu`}
                                  onChange={(e, data) => {
                                    setSelectedCodeType(e);
                                  }}
                                  value={selectedCodeType}
                                  getOptionLabel={(option) => `${option.name}`}
                                  // styles={customStyles}
                                  options={BCTVOptions}
                                />

                                {/* <nav class="nav flex-row">
{_.orderBy(_.uniqBy(BCTVData,i=>convertToQuater(i.month)),i=>new Date(i.month).getMonth(),["asc"])?.map(i=>(
	<a class={`nav-link ${selectedMonth==i.month?"active border border-primary bg-primary text-white":""}`} style={{textTransform:"capitalize"}} aria-current="page" onClick={()=>setSelectedMonth(i.month)}>{convertToQuater(i.month)}</a>
))  }


</nav> */}
                              </div>

                              <div>
                                {Object.keys(BCTVGrouped).length ? (
                                  <DataBarchart
                                    isGrouped={true}
                                    autoBarThickness
                                    data={
                                      Object.keys(BCTVGrouped).map((i) => ({
                                        month: i,
                                        data: BCTVGrouped[i].map((j) => ({
                                          label: j.billing_code_type_version,
                                          value: j.counts || undefined,
                                        })),
                                      })) || []
                                    }
                                  />
                                ) : (
                                  <center>No data available</center>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      }
                    </Row>
                  </Col>

                  <Col xs={12} className="mb-4">
                    <Row>
                      <Col xs={12} className="d-flex flex-column mb-2">
                        <h5 className="mb-4">
                          NPI Distribution Across{" "}
                          {dataType == "raw" ? "Country" : "State"}
                        </h5>

                        <div className="d-flex align-items-center justify-content-between">
                          <nav class="nav ">
                            {_.orderBy(months, (i) => new Date(i.month), [
                              "asc",
                            ]).map((i) => {
                              return (
                                <a
                                  class={`nav-link ${
                                    selectedMonth == i.month
                                      ? "active border border-primary bg-primary text-white"
                                      : ""
                                  }`}
                                  aria-current="page"
                                  onClick={() => setSelectedMonth(i.month)}
                                >
                                  {convertToMonth(i.month)}
                                </a>
                              );
                            })}
                          </nav>

                          <Select
                            className={`select-menu`}
                            onChange={(e, data) => {
                              setSelectedMap(e);
                            }}
                            value={selectedMap}
                            getOptionLabel={(option) => `${option.name}`}
                            // styles={customStyles}
                            options={
                              [
                                { name: "PROFESSIONAL", value: "PROFESSIONAL" },
                                { name: "FACILITY", value: "FACILITY" },
                              ]
                              // uniquerTypes.map(i=>({
                              // 	name:i.provider_type,
                              // 	value:i.provider_type
                              // })).filter(j=>zigna_aiStore.npiDistributionMap.find(k=>k.month==selectedMonth&&k.provider_type==j.value))
                            }
                          />
                        </div>
                      </Col>
                      {console.log("npi types", JSON.stringify(uniquerTypes))}

                      {["FACILITY", "PROFESSIONAL"].map((i) => {
                        if (selectedMap?.value == i) {
                          return (
                            <Col
                              xs={12}
                              className="mb-4"
                              style={{
                                display:
                                  selectedMap?.value == i ? "block" : "none",
                              }}
                            >
                              <Card border="light">
                                <Card.Body>
                                  <h5
                                    className="fw400"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {i}
                                  </h5>
                                  <MapChart
                                    dataType={dataType}
                                    db={authStore?.currentUser?.db_name}
                                    type={i}
                                    month={selectedMonth}
                                    mapData={zigna_aiStore.npiDistributionMap}
                                    payer={slectedData?.REPORTING_ENTITY_NAME}
                                  />
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        } else return null;
                      })}
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <DrgCodesByHospitalWithNtype
            sticky
            reporting_entity_name={slectedData?.REPORTING_ENTITY_NAME}
            dataType={dataType}
            db={authStore?.currentUser?.db_name}
            dataset={zigna_aiStore.DrgByHospitalCountWithNtype}
          />
          <br />
          <br />
        </>
        )}
        </>
      );
    else return null;
  })
);

const Datacard = ({ title, value, keyName, type, db }) => {
  return (
    <Card border="light" className="mx-1 d-flex" style={{ flexGrow: 1 }}>
      <Card.Body>
        <span
          className="fw400"
          style={{
            fontWeight: "600",
            textTransform: "uppercase",
            color: "#000",
          }}
        >
          {title}
        </span>
        <div className="d-flex flex-column align-center mt-2">
          {value.map((i) => {
            return (
              <div
                className=" numColor d-flex align-items-center"
                style={{ fontSize: 14, textTransform: "capitalize" }}
              >
                <div style={{ width: 70 }}>{convertToMonth(i.MONTH)}</div> -{" "}
                &nbsp;
                <b>{type == "text" ? i[keyName] : millify(i[keyName])}</b>
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

const DatacardSmall = ({ title, value, db }) => {
  return (
    <Col xs={6} className="d-flex p-2">
      <span
        style={{
          fontSize: 14,
          fontWeight: "700",
          fontFamily: "arial",
          width: 60,
        }}
      >
        <b>{title}</b>
      </span>
      &nbsp;: &nbsp; &nbsp;
      <div className="d-flex align-center" style={{ width: 40 }}>
        <span className="" style={{ fontSize: 14 }}>
          {value}
        </span>
      </div>
    </Col>
  );
};

const GetLegeng = (data) => {
  let color = [
    "#3d2260",
    "#8294C4",
    "#7CB9E8",
    "#D4ADFC",
    "#0071c5",
    "#7d5bec",
    "#3b65ce",
    "#22105d",
  ];
  return (
    <div className="d-flex flex-wrap align-items-center pr-2">
      {data.map((i, index) => (
        <div
          style={{
            fontSize: 12,
            paddingRight: 12,
            textTransform: "capitalize",
          }}
          className="d-flex align-items-center mr-2"
          key={`leg${index}`}
        >
          <div
            style={{ height: 10, width: 15, background: color[index] }}
          ></div>
          &nbsp; {i}
        </div>
      ))}
    </div>
  );
};
