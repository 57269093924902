import React from 'react';

const ColorScale = ({ isHorizontal }) => {
  const getColorScaleStyle = () => {
    const commonStyle = {
      width: isHorizontal ? '200px' : '12px',
      height: isHorizontal ? '12px' : '200px',
      background: isHorizontal
        ? 'linear-gradient(to right, #FF0000, #FFFF00, #00FF00, #0000FF)'
        : 'linear-gradient(to bottom, #c10100, #f38251, #f9d276, #90c6e4)',
    };

    return commonStyle;
  };

  return (
    <div className={`d-flex flex-column align-items-center justify-content-center`}>
          <small className="legend-label">Very High</small>
      <div style={getColorScaleStyle()}></div>
      <div className="legend-labels">
      
        <small className="legend-label">Unconcentrated</small>
      </div>
    </div>
  );
};

export default ColorScale;
