// import axiosConfig from 'axiosConfig'
import React, { Component } from "react";
import axiosConfig, { url } from "./Config";
import Fetcher from "./fetcher";

export class Zigna_aiService extends Component {
  getListOfPayers = (body) => {
    // getDashboardCategories
    return axiosConfig.post(`${url}/getListOfPayers`, body);
  };
  getCategoryList = (body) => {
    // getDashboardCategories
    return axiosConfig.post(`${url}/getDashboardCategories`, body);
  };
  getStateMapDataList = (state) => {
    //return axiosConfig.get('./data/stateMapDataList.json')
    return axiosConfig.post(`${url}/getStateMapData`, state);
  };
  getRegionalMapDataList = (state) => {
    //return axiosConfig.get('./data/regionalMapDataList.json')
    return axiosConfig.post(`${url}/getReginalMapData`, state);
  };
  getPayerList = (state) => {
    //`${url}/getPayerProcCountList`
    return axiosConfig.post(`${url}/getPayerProcCountList`, state);
  };
  getFilteredDRGPayerList = (state) => {
    //`${url}/getPayerProcCountList`
    return axiosConfig.post(`${url}/getFilteredDRGPayerList`, state);
  };
  getFilteredDRGBillingCodes = (state) => {
    //`${url}/getPayerProcCountList`
    return axiosConfig.post(`${url}/getFilteredDRGBillingCodes`, state);
  };
  getFilteredDRGBillingCodeType = (state) => {
    //`${url}/getPayerProcCountList`
    return axiosConfig.post(`${url}/getFilteredDRGBillingCodeType`, state);
  };
  getmodifierList = (body) => {
    return axiosConfig.post(`${url}/getmodifierList`, body);
  };
  getPayerPriceCompList = (body) => {
    console.log("service call");
    return axiosConfig.post(`${url}/getPayerPriceComparison`, body);
  };

  getHospitalList = (state) => {
    return axiosConfig.post(`${url}/getHospitallist`, state);
  };
  getAncillaryList = (state) => {
    return axiosConfig.post(`${url}/getAncillarylist`, state);
  };

  getFilteredDRGHospitalList = (state) => {
    return axiosConfig.post(`${url}/getFilteredDRGHospitalList`, state);
  };

  getFilteredDRGAncillaryList = (state) => {
    return axiosConfig.post(`${url}/getFilteredDRGAncillaryList`, state);
  };

  getNpiList = (body) => {
    return axiosConfig.post(`${url}/getNPIlist`, body);
  };
  getBillingCodeTypeList = (body) => {
    return axiosConfig.post(`${url}/getBillingCodeTypeList`, body);
  };
  getProceduresCatList = (body) => {
    return axiosConfig.post(`${url}/getCategoryList`, body);
  };
  getLevel1DescList = (body) => {
    return axiosConfig.post(`${url}/getDescriptionList`, body);
  };
  getProcedureNameList = (body) => {
    return axiosConfig.post(`${url}/getProcedureList`, body);
  };

  downloadPriceCompData = () => {
    return axiosConfig.get("./data/download.json");
  };
  getOverviewDRGList = (state) => {
    return axiosConfig.post(`${url}/getTopPrefDRGs`, state);
  };
  getTopPrefEntityList = (state) => {
    return axiosConfig.post(`${url}/getTopPrefEntityList`, state);
  };
  getHopitalCostDiffList = () => {
    return axiosConfig.get("./data/hospitalCostDiffList.json");
  };
  getOverviewCPTList = (state) => {
    return axiosConfig.post(`${url}/getTopPrefCPTs`, state);
  };

  gettopProceduresL4 = (state) => {
    return axiosConfig.post(`${url}/getTopPrefCPTsL4`, state);
  };
  gettopProceduresNonPrefL4 = (state) => {
    return axiosConfig.post(`${url}/getTopNonPrefCPTsL4`, state);
  };
  getFilteredDRGListData = (state) => {
    return axiosConfig.post(`${url}/getFilteredDRGListData`, state);
  };
  getDrgReferenceTable = (state) => {
    return axiosConfig.post(`${url}/getDrgReferenceTable`, state);
  };
  /*  Get procedure by hsopital */

  getPCHcodeType = (body) => {
    return axiosConfig.post(`${url}/getPCHcodeType`, body);
  };
  getPCHcity = (body) => {
    return axiosConfig.post(`${url}/getPCHcity`, body);
  };
  getPCHreportingEntity = (body) => {
    return axiosConfig.post(`${url}/getPCHreportingEntity`, body);
  };
  getPCHnegotiatedType = (body) => {
    return axiosConfig.post(`${url}/getPCHnegotiated`, body);
  };

  getPCHl1description = (body) => {
    return axiosConfig.post(`${url}/getPCHl1description`, body);
  };
  getPCHbillingCodeModifiers = (body) => {
    return axiosConfig.post(`${url}/getPCHbillingCodeModifiers`, body);
  };

  getProcedureCostByHospital = (body) => {
    return axiosConfig.post(`${url}/getProcedureCostByHospital`, body);
  };

  /*DRG cost breakdown */

  getDRGcostProviderName = (body) => {
    return axiosConfig.post(`${url}/drgcostbreakdown/getProviderName`, body);
  };

  getDRGcostreportingEntity = (body) => {
    return axiosConfig.post(
      `${url}/drgcostbreakdown/getreportingentityname`,
      body
    );
  };

  getDRGcostbreakdown = (body) => {
    return axiosConfig.post(`${url}/drgcostbreakdown/getbreakdown`, body);
  };
  getDRGcodeCategory = (body) => {
    return axiosConfig.post(`${url}/drgcostbreakdown/getcodecategory`, body);
  };

  getDRGcostallentity = (body) => {
    return axiosConfig.post(
      `${url}/drgcostbreakdown/getallreportingentityname`,
      body
    );
  };

  /*DRG cost breakdown */

  /*  Get procedure audit report*/
  getHospitalCountDrg = (type) => {
    return axiosConfig.get(`${url}/getHospitalCountDrg?data_type=${type}`);
  };

  getDistingDRGByNegotiations = (type) => {
    return axiosConfig.get(
      `${url}/getDistingDRGByNegotiations?data_type=${type}`
    );
  };

  getReportingEntityDrgByHospital = () => {
    return axiosConfig.post(`${url}/getReportingEntityDrgByHospital`);
  };

  getMonthDrgByHospital = (body) => {
    return axiosConfig.post(`${url}/getMonthDrgByHospital`, body);
  };

  getProviderDrgByHospital = (body) => {
    return axiosConfig.post(`${url}/getProviderDrgByHospital`, body);
  };
  getcodeDrgByHospital = (body) => {
    return axiosConfig.post(`${url}/getcodeDrgByHospital`, body);
  };

  getstateDrgByHospital = (body) => {
    return axiosConfig.post(`${url}/getstateDrgByHospital`, body);
  };
  getdDrgByHospitalCount = (body) => {
    return axiosConfig.post(`${url}/getdDrgByHospitalCount`, body);
  };

  getdDrgByHospitalCountWithNtype = (body) => {
    return axiosConfig.post(`${url}/getdDrgByHospitalCountWithNtype`, body);
  };

  npiDistributionAcrossCountry = (body) => {
    return axiosConfig.post(`${url}/npi_dist_across_Ccountry`, body);
  };
  getStaticChartData = (body) => {
    return axiosConfig.post(`${url}/getSaticChartData`, body);
  };

  getStaticBCBVData = (body) => {
    return axiosConfig.get(`${url}/getSaticBCBVData`, body);
  };

  getHistoricalRequests = () => {
    return axiosConfig.post(`${url}/getAllOnDemandRequests`, {});
  };

  placeReRequest = (body) => {
    return axiosConfig.post(`${url}/placeReRequest`, body);
  };

  //Provider Extract
  getProviderExtractFilters = (body = []) => {
    return axiosConfig.post(`${url}/getProviderExtractFilters`, body);
  };
  getProviderExtractResults = (body) => {
    return axiosConfig.post(`${url}/getProviderExtractResults`, body);
  };
  getProviderExtractResultsCharts = (body) => {
    return axiosConfig.post(`${url}/getProviderExtractResultsCharts`, body);
  };

  getFacilityScoreCardScores = () => {
    return axiosConfig.post(`${url}/facilityScorecard-scores`);
  };

  getFacilityScoreCardNPIs = () => {
    return axiosConfig.post(`${url}/facilityScorecard-npis`);
  };

  //professional screeens
  getProfessionalRegions = () => {
    return axiosConfig.post(`${url}/professional/distictregions`);
  };
  getProfessionalProvider = () => {
    return axiosConfig.post(`${url}/professional/distictprovider`);
  };
  getProfessionalTin = (region, provider_type) => {
    return axiosConfig.post(`${url}/professional/disticttin`, {
      region,
      provider_type,
    });
  };
  getProfessionalOptionalFilters = (body) => {
    return axiosConfig.post(`${url}/professional/distinctOptionalFilter`, body);
  };
  getProfessionalOptionalFilters2 = (body) => {
    return Fetcher.post(`/professional/distinctOptionalFilter`, body);
  };
  getRateChoiceData = (body) => {
    return axiosConfig.post(`${url}/professional/RateChoiceView`, body);
  };
  getRateChoiceDataByTin = (body) => {
    return axiosConfig.post(`${url}/professional/RateChoiceViewByTin`, body);
  };
  getRateInsightsByTin = (body) => {
    return axiosConfig.post(`${url}/professional/RateInsights`, body);
  };
  getAllNpiDetails = (body) => {
    return axiosConfig.post(`${url}/professional/getnpiDetails`, body);
  };
  getRateUtilizationData = (body) => {
    return axiosConfig.post(`${url}/professional/UtilizationView`, body);
  };
  getRateUtilizationDataByTin = (body) => {
    return axiosConfig.post(`${url}/professional/UtilizationViewByTin`, body);
  };
  getRateInsightData = (body) => {
    return axiosConfig.post(`${url}/professional/RateInsights2`, body);
  };
  saveUserFilters = (body) => {
    return axiosConfig.post(`${url}/saveUserFilters`, body);
  };
  fetchUserFilters = () => {
    return axiosConfig.get(`${url}/fetchUserFilters`);
  };
  getSignedUrl = (body) => {
    return axiosConfig.post(`${url}/getSignedUrl`, body);
  };
}
