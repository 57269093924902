import React from 'react'
import { Accordion, Card, Col, Row,ButtonGroup,Button,Badge } from '@themesberg/react-bootstrap'

import CategoryWidgets from '../../components/ZignaAi/competitiveLandscape/CategoryWidgets'

import { inject, observer } from 'mobx-react'
import StateMapWidget from '../../components/ZignaAi/competitiveLandscape/StateMapWidget'
import RegionalMapWidget from './../../components/ZignaAi/facilityScorecard/RegionalMap'
import PayerListWidget from './../../components/ZignaAi/facilityScorecard/RegionStaticTable'
import FilterWidget from '../../components/ZignaAi/competitiveLandscape/FilterWidget'
import { useEffect ,useState} from 'react'
import { useLocation } from 'react-router-dom'
import Select from "../../components/ui/Dropdown"
import ScatterPlot from "../../components/ZignaAi/facilityScorecard/ScatterPlot"
import _ from "lodash"
import StarRating from '../../components/ui/StarRating'
export default inject('zigna_aiStore')(
	observer(({ zigna_aiStore }) => {
		let location = useLocation();
		const [provider,setProvider]=useState(null)
		const [region,setRegion]=useState(null)
		const [npiData,setNpiData] = useState([])
		const [selectedMortalityMeasure,setSelectedMeasure]=useState("AMI")
		useEffect(() => {
			 zigna_aiStore.getFacilityScoreCardScores()
			 zigna_aiStore.getFacilityScoreCardNPIs()
		},[location, zigna_aiStore.userType])

const formatValue=(value)=>{

	if((!value &&typeof value!="number")|| value<0){
		return "N/A"
	}
	if(typeof value=="number"){
		return value.toFixed(2)
	}
	return value

}


console.log(JSON.parse(JSON.stringify(zigna_aiStore?.facilityScoreCardScore)))

		return (
			<>
				<Row className="justify-content-md-center">
						<Col xs={12} xl={12} className="mb-4">
						<Card border="light" className="shadow-sm filterSection">
					<Card.Body>
					<label className="form-label">Region Name <span className='text-danger'>*</span></label>
						<Select
						options={_.uniqBy(zigna_aiStore?.facilityScoreCardScore.map(i=>({id:i.region,name:i.region,value:i.region,...i})),"region")}
						getOptionLabel={(option) => `${option.name}`}
						value={region}
						onChange={val=>{setRegion(val);setProvider({...val,name:val?.provider_name,id:val?.provider_name})}}
                          />

					</Card.Body>
					</Card>
						</Col>
				</Row>
				

				<Row>
					<Col xs={12} xl={12} className="mb-5">
						<Row>
							
					
							<Col xs={12} lg={6} xl={6} xxl={6}>
								
										<RegionalMapWidget />
									
							</Col>
                            <Col xs={12} xl={6} xxl={6}>
							
                                    <PayerListWidget selectedRegion={region}  />
						
							</Col>
							<Col>
							{region?<Row className='pb-2 mt-4'>
					<Col xs={12}><ScatterPlot region={region?.name}/></Col>

				</Row>:null} 
							</Col>
							<Col xs={12}>
							<Row className="justify-content-md-center">
						<Col xs={12} xl={12} className="mt-4">
						<Card border="light" className="shadow-sm filterSection">
					<Card.Body>
					<label className="form-label">Provider Name <span className='text-danger'>*</span></label>
						<Select
						options={zigna_aiStore?.facilityScoreCardScore.filter(i=>i.region==region?.name).map(i=>({id:i.provider_name,name:i.provider_name,value:i.provider_name,...i}))}
						getOptionLabel={(option) => `${option.name}`}
						value={provider}
						onChange={val=>setProvider(val)}
                          />

					</Card.Body>
					</Card>
						</Col>
				</Row>
							</Col>
						

							{provider&&<><Col xs={12} className='mt-4'>
							<Card border="light" className="shadow-sm filterSection">
					<Card.Body>
					<Row>
						<Col xs={12}> 	<div className='d-flex align-items-center mb-2'>
					<h4 className='d-flex'>{provider?.provider_name} &nbsp;</h4>
					<Badge bg="primary" className='p-2 ' style={{fontSize:12}}>{provider?.npi}</Badge>
					</div></Col>
						<Col >
					
					
					<p>
					3558 Fieldcrest Road ,Garden City ,New York


					</p>
					<div style={{maxWidth:400}}>
                     <div className='d-flex justify-content-between'>Overall rating: &nbsp;<StarRating rating={provider?.hospital_overall_rating}/></div>
					
					</div>
			
						</Col>
						<Col >
					
					<div style={{maxWidth:400}}>

					 <div className='d-flex justify-content-between'>Telephone number : <b>{provider?.telephone_number}</b></div>
					 <div className='d-flex justify-content-between'>Ownership : <b>{provider?.hospital_ownership}</b></div>
					 <div className='d-flex justify-content-between'>Provide Emergency Services : <b>{provider?.emergency_services}</b></div>
					 <div className='d-flex justify-content-between'>Facility type : <b>{provider?.hospital_type}</b></div>

					</div>
			
						</Col>
						<Col >
					
					<div style={{maxWidth:400}}>


					 <div className='d-flex justify-content-between'>Region : <b>{provider?.region}</b></div>
					 <div className='d-flex justify-content-between'>County : <b>{provider?.county}</b></div>
					 <div className='d-flex justify-content-between'>Zip : <b>{provider?.zip}</b></div>
					 <div className='d-flex justify-content-between'>State : <b>{provider?.state}</b></div>
					</div>
			
						</Col>
					</Row>
					
					
					</Card.Body>
					</Card>
							</Col>
							
							<Col xs={12} xl={12} className='mt-4'>
							<Card border="light" className="shadow-sm filterSection">
					<Card.Body>
					<Card.Title className='mb-4'>Benchmark and Scores</Card.Title>
					<Accordion defaultActiveKey={'0'} alwaysOpen>
      <Accordion.Item eventKey="0" >
        <Accordion.Header><b className='text-primary'>30 Days Mortality Measures</b></Accordion.Header>
        <Accordion.Body>

		<ButtonGroup size="sm" className="mb-3 ">
        <Button  variant={selectedMortalityMeasure=="AMI"?'primary':"outline-primary"} onClick={()=>setSelectedMeasure("AMI")}>Acute Myocardial Infarction</Button>
        <Button variant={selectedMortalityMeasure=="HF"?'primary':"outline-primary"} onClick={()=>setSelectedMeasure("HF")}>Heart Failure</Button>
        <Button variant={selectedMortalityMeasure=="PN"?'primary':"outline-primary"} onClick={()=>setSelectedMeasure("PN")}>Pnuenomia</Button>
		<Button variant={selectedMortalityMeasure=="COPD"?'primary':"outline-primary"} onClick={()=>setSelectedMeasure("COPD")}>Coronary Artery Bypass Graft</Button>
		<Button  variant={selectedMortalityMeasure=="CABG"?'primary':"outline-primary"} onClick={()=>setSelectedMeasure("CABG")}>Chronic Obstructive Pulmonary Disease</Button>
      </ButtonGroup>
	{selectedMortalityMeasure=="AMI"&&	<Card border="light" >
					    <Card.Body>
							<Row>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
									<label>Benchmark</label>

									<h3 className='mt-2'>  {formatValue(provider?.mort_30_ami_benchmark)} &nbsp; </h3>
                               
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Baseline Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_ami_baseline_rate)} &nbsp; </h3>
								
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Performance Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_ami_performance_rate)} &nbsp; </h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Measure Score</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_ami_measure_score)}</h3>
								</Col>
							</Row>
						</Card.Body>
						</Card>}

						{selectedMortalityMeasure=="HF"&&<Card border="light" >
					    <Card.Body>
							<Row>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
									<label>Benchmark</label>

									<h3 className='mt-2'>  {formatValue(provider?.mort_30_hf_benchmark)}</h3>
                               
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Baseline Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_hf_baseline_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Performance Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_hf_performance_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Measure Score</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_hf_measure_score)}</h3>
								</Col>
							</Row>
						</Card.Body>
						</Card>}

				{selectedMortalityMeasure=="PN"&&		<Card border="light" >
					    <Card.Body>
							<Row>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
									<label>Benchmark</label>

									<h3 className='mt-2'>  {formatValue(provider?.mort_30_pn_benchmark)}</h3>
                               
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Baseline Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_pn_baseline_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Performance Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_pn_performance_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Measure Score</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_pn_measure_score)}</h3>
								</Col>
							</Row>
						</Card.Body>
						</Card>}


					{selectedMortalityMeasure=="COPD"&&	<Card border="light" >
					    <Card.Body>
							<Row>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
									<label>Benchmark</label>

									<h3 className='mt-2'>  {formatValue(provider?.mort_30_copd_benchmark)}</h3>
                               
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Baseline Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_copd_baseline_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Performance Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_copd_performance_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Measure Score</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_copd_measure_score)}</h3>
								</Col>
							</Row>
						</Card.Body>
						</Card>}


					{selectedMortalityMeasure=="CABG"&&	<Card border="light" >
					    <Card.Body>
							<Row>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
									<label>Benchmark</label>

									<h3 className='mt-2'>  {formatValue(provider?.mort_30_cabg_benchmark)}</h3>
                               
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Baseline Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_cabg_baseline_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Performance Rate</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_cabg_performance_rate)}</h3>
								</Col>
								<Col xs={12} md={6} lg={3} className='d-flex flex-column align-items-center justify-content-center'>
								<label>Measure Score</label>
								<h3 className='mt-2'> {formatValue(provider?.mort_30_cabg_measure_score)}</h3>
								</Col>
							</Row>
						</Card.Body>
						</Card>}
			</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1" className='mt-4'>
        <Accordion.Header><b className='text-primary'>30 Days Readmission Rate</b></Accordion.Header>
        <Accordion.Body>
			<Row>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>AMI Acute Myocardial Infarction</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_ami_acute_myocardial_infarction_ami_30_day_readmission_rate_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>CABG rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_cabg_rate_of_readmission_for_cabg_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>COPD rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_copd_rate_of_readmission_for_chronic_obstructive_pulmonary_disease_copd_patients_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>HF rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_hf_heart_failure_hf_30_day_readmission_rate_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Hip Knee rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_hip_knee_rate_of_readmission_after_hip_or_knee_replacement_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2}>
					<Card border="light"  style={{minHeight:130}} className='mt-4'>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>After discharge rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.readm_30_hip_knee_rate_of_readmission_after_hip_or_knee_replacement_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
			
			</Row>
			</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2" className='mt-4'>
        <Accordion.Header><b className='text-primary'>Medicare Spending per Beneficiary</b></Accordion.Header>
        <Accordion.Body>
		<Row>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Baseline rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.mspb_1_baseline_rate)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Performance rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.mspb_1_performance_rate)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Measure score</label></center>

							<h3 className='mt-2'>{formatValue(provider?.mspb_1_measure_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				</Row>
			</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3" className='mt-4'>
        <Accordion.Header><b className='text-primary'>Complex Hip And Knee Measures</b></Accordion.Header>
        <Accordion.Body>
		<Row>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Baseline rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.comp_hip_knee_baseline_rate)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Performance rate</label></center>

							<h3 className='mt-2'>{formatValue(provider?.comp_hip_knee_performance_rate)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Measure score</label></center>

							<h3 className='mt-2'>{formatValue(provider?.comp_hip_knee_measure_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				</Row>
			</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="4" className='mt-4'>
        <Accordion.Header><b className='text-primary'>Out Patient Measures</b> </Accordion.Header>
        <Accordion.Body>
		<Row>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Unplanned Hospital Visits After Colonoscopy</label></center>

							<h3 className='mt-2'>{formatValue(provider?.op_32_rate_of_unplanned_hospital_visits_after_colonoscopy_per_1000_colonoscopies_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Inpatient admissions for patients receiving</label></center>

							<h3 className='mt-2'>{formatValue(provider?.op_35_adm_rate_of_inpatient_admissions_for_patients_receiving_outpatient_chemotherapy_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>ED visits for patients receiving outpatient</label></center>

							<h3 className='mt-2'>{formatValue(provider?.op_35_ed_rate_of_emergency_department_ed_visits_for_patients_receiving_outpatient_chemotherapy_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={4} xl={3} xxl={2} className='mt-4'>
					<Card border="light"  style={{minHeight:130}}>
						<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
						<center><label>Unplanned hospital visits after hospital outpatient</label></center>

							<h3 className='mt-2'>{formatValue(provider?.op_36_ratio_of_unplanned_hospital_visits_after_hospital_outpatient_surgery_score)}</h3> 
						</Card.Body>
					</Card>
				</Col>
				</Row>
			</Accordion.Body>
			</Accordion.Item>
			</Accordion>
						</Card.Body>
						</Card>
							</Col></>}
						</Row>
					</Col>


				</Row>

			</>
		)
	})
)



