
import React from 'react'
import ReactDOM from 'react-dom'
import {  BrowserRouter as Router} from 'react-router-dom'
import { observer, Provider } from 'mobx-react'

// core styles
import './scss/volt.scss'

// vendor styles
import 'react-datetime/css/react-datetime.css'

import HomePage from './pages/HomePage'
import ScrollToTop from './components/ScrollToTop'
import { Zigna_aiStore } from './store/Zigna_aiStore'
import { Zigna_aiService } from './store/Zigna_aiService'
import { AuthStore } from './store/AuthStore';
import { AuthenticationService } from './store/AuthenticationService';
import { ProvidersPriceCompareStore } from './components/ZignaAi/providersPriceCompare/store/ProvidersPriceCompareStore'
import { ProvidersPriceCompareService } from './components/ZignaAi/providersPriceCompare/store/ProvidersPriceCompareService'
import { ProvidersCPTExtractService } from './components/ZignaAi/providersCPTExtract/store/ProvidersCPTExtractService'
import { ProvidersCPTExtractStore } from './components/ZignaAi/providersCPTExtract/store/ProvidersCPTExtractStore'
import Notify from './components/Notify'

import CacheBuster from 'react-cache-buster';
import pkg from '../package.json';

let zigna_aiStore = new Zigna_aiStore(new Zigna_aiService())
let authStore = new AuthStore(new AuthenticationService())
let providerPriceCompareStore = new ProvidersPriceCompareStore(new ProvidersPriceCompareService)
let providersCPTExtractStore = new ProvidersCPTExtractStore(new ProvidersCPTExtractService)
ReactDOM.render(
	<CacheBuster
		currentVersion={pkg.version}
		isEnabled={true} //If false, the library is disabled.
		isVerboseMode={false} //If true, the library writes verbose logs to console.
		//loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
		metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
	>
		<Router>
			<Provider 
					zigna_aiStore={zigna_aiStore} 
					authStore={authStore} 
					providerPriceCompareStore={providerPriceCompareStore}
					providersCPTExtractStore={providersCPTExtractStore}
				>
				<ScrollToTop />
				<HomePage />
				<Notify />
			</Provider>
		</Router>
	</CacheBuster>,
	document.getElementById('root')
)
