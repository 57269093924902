import React from "react";

import {
  useFlexLayout,
  useResizeColumns,
  useTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
function useInstance(instance) {
  const { allColumns } = instance;

  let rowSpanHeaders = [];

  allColumns.forEach((column, i) => {
    const { id, enableRowSpan } = column;

    if (enableRowSpan !== undefined) {
      rowSpanHeaders = [
        ...rowSpanHeaders,
        { id, topCellValue: null, topCellIndex: 0, mainGroup: null },
      ];
    }
  });

  Object.assign(instance, { rowSpanHeaders });
}

const CommonTable = ({ columns, data, className, sticky, stickyTop }) => {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = React.useMemo(
    () => ({
      maxWidth: 250, // maxWidth is only used as a limit for resizing
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    rowSpanHeaders,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    (hooks) => {
      hooks.useInstance.push(useInstance);
    },
    useResizeColumns,
    useSticky
  );

  const headerProps = (props, { column }) => getStyles(props, "center");

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        textAlign: align,
        border: "1px solid grey",
      },
    },
  ];
  const memoizedPrepareRows = React.useCallback(prepareRow, [rows]);
  // Render the UI for your table
  return (
    <div className="parentTable" style={{ overflowY: "none" }}>
      <table
        style={{
          position: "relative",
          borderCollapse: "separate",
          zIndex: 2,
          borderSpacing: 0,
        }}
        {...getTableProps()}
        className={`table table-borderless ${className} zignaAi_newTable`}
      >
        <thead
          style={
            sticky
              ? {
                  position: "sticky",
                  top: stickyTop > -1 ? stickyTop : 58,
                  zIndex: 1000,
                }
              : {}
          }
        >
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(headerProps)}
                  className="th"
                  style={{
                    ...column.getHeaderProps(headerProps).style,
                    width: column.width,
                    minWidth: column.width,
                  }}
                  onClick={column.onClick}
                >
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          <TableRows rows={rows} prepareRow={memoizedPrepareRows} />
        </tbody>
      </table>
    </div>
  );
};

const TableRows = React.memo(
  ({ rows, prepareRow }) => {
    console.log("rerendered");
    const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
    const getStyles = (props, align = "left") => [
      props,
      {
        style: {
          textAlign: align,
          border: "1px solid #D3D3D3",
        },
      },
    ];
    return (
      <>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row?.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.isRowSpanned) return null;
                else {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        ...cell.getCellProps(cellProps).style,
                        background: cell.getCellProps(cellProps)?.style
                          ?.position
                          ? "#f0f0f0"
                          : "#fff",

                        width: cell.column.width,
                        minWidth: cell.column.width,
                        verticalAlign: "middle",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => prevProps.rows === nextProps.rows
);

export default React.memo(CommonTable);
