import { Button, Card, Col, Row } from '@themesberg/react-bootstrap'
import React, { useEffect } from 'react'
import usStateJson from './../../../data/ZignaAi/us-states.json'
import usStateJson1 from './../../../data/ZignaAi/us-states_counties_zip.json'


import * as d3 from 'd3'
import * as topojson from 'topojson'
import { inject, observer } from 'mobx-react'

export default inject('zigna_aiStore', 'authStore')(
	observer(({ zigna_aiStore, authStore }) => {
		useEffect(() => {
			drawMap()

			console.log(JSON.parse(JSON.stringify(zigna_aiStore.regionalMapDataList)), JSON.parse(JSON.stringify(zigna_aiStore.selectedState)))
		}, [zigna_aiStore.regionalMapDataList, zigna_aiStore.selectedState])
		function drawMap() {
			d3.select('#usCountyMap').select('svg').remove()
			let data = [...zigna_aiStore.regionalMapDataList]
			var margin_choropleth = {
					top: 10,
					left: 10,
					bottom: 10,
					right: 10,
				},
				width = parseInt(d3.select('#usCountyMap').style('width')),
				width = width - margin_choropleth.left - margin_choropleth.right,
				mapRatio = 0.5,
				height = width * mapRatio

			// D3 Projection
			var projection = d3.geoAlbersUsa()
			// .scale(width)
			// .translate([width / 2, height / 2])

			// Define path generator
			var path = d3.geoPath().projection(projection)

			var viewboxwidth = width * 1
			var viewboxheight = height

			// d3.json(usStateJson, function (json) {
			var centered = null
			var formatComma = d3.format(',')
			var fill = d3.scaleLog().domain([10, 500]).range(['brown', 'steelblue'])

			var svg = d3
				.select('#usCountyMap')
				.append('svg')
				.attr('preserveAspectRatio', 'xMidYMid meet')
				.attr('viewBox', '0 0 ' + viewboxwidth + ' ' + viewboxheight + '')

			let us = usStateJson
			// var states = topojson.feature(us, us.objects.states),
			let state = us.features.filter(function (d) {
				return d.id == zigna_aiStore.selectedState?.id
			})[0]
			projection.scale(1).translate([0, 0])

			var b = path.bounds(state),
				s =
					0.95 /
					Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height),
				t = [
					(width - s * (b[1][0] + b[0][0])) / 2,
					(height - s * (b[1][1] + b[0][1])) / 2,
				]

			projection.scale(s).translate(t)

			svg
				.append('path')
				.datum(state)
				.attr('class', 'outline')
				.attr('d', path)
				.attr('id', 'land')

			let map = svg.append('g').attr('id', 'usCounties')
			let d3USCountyJson = topojson
				.feature(usStateJson1, usStateJson1.objects.zip_codes_for_the_usa)
				.features?.filter(
					(item) =>
						item?.properties?.state.toLowerCase() ==
						zigna_aiStore.selectedState?.id.toLowerCase()
				)
	
			let usMergedCustomData = [
				...d3USCountyJson
					?.concat(data)
					.reduce(
						(m, o) =>
							m.set(o?.properties?.zip, Object.assign(m.get(o.zip) || {}, o)),
						new Map()
					)
					.values(),
			]
			map
				.selectAll('path')
				// .data(topojson.feature(us, us.objects.counties).features)
				.data(usMergedCustomData)
				.enter()
				.append('path')
				.attr('d', path)
				.attr('county-id', function (d) {
					return d.id
				})
				// .attr('clip-path', 'url(#clip-land)')
				.attr('class', 'county')
				.style('fill', function (d) {
					return d.value == 0 ? '#5e3f93' : '#eaECF5'
				})
				.append('title')
				.html((d) => d.hospital)
			let zoomBtnPositionX = viewboxwidth - 30
			let zoomBtnPositionY = viewboxheight - 70
			svg
				.append('g')
				.attr('class', 'D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomIn')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>+')
				.on('click', zoomIn)
			svg
				.select('g.D3zoomBtn')
				.attr(
					'transform',
					'translate(' + zoomBtnPositionX + ',' + zoomBtnPositionY + ')'
				)
				.append('foreignObject')
				.attr('class', 'd3MapZoomOut')
				.attr('transform', 'translate(0,28)')
				.attr('width', 26)
				.attr('height', 26)
				// .append('xhtml:div')
				.html('<span>─')
				.on('click', zoomOut)
			const zoom = d3.zoom().scaleExtent([1, 8]).on('zoom', zoomed)

			function zoomed() {
				map
					.selectAll('path') // To prevent stroke width from scaling
					.attr('transform', d3.event.transform)
			}

			svg.call(zoom)
			let zoomfactor = 1
			function zoomOut() {
				if (zoomfactor > 1) {
					zoomfactor = zoomfactor - 0.5
					zoom.scaleTo(svg.transition().duration(750), zoomfactor)
				}
			}
			function zoomIn() {
				zoomfactor = zoomfactor + 0.5
				zoom.scaleTo(svg.transition().duration(750), zoomfactor)
			}
		}
		return (
			<Card border="light" className="shadow-sm">
				<Card.Header>
					<Row className="align-items-center">
						<Col>
							<h5>
								Regional Map - {zigna_aiStore.selectedState?.properties?.name}
							</h5>
						</Col>
						<Col className="text-end legendSection">
							<div className="">
								<div className="legendRow">
									<div className="legendColor legendC1"></div>
									<div className="legendValue">Selected payer</div>
								</div>
							</div>
							<div className="">
								<div className="legendRow">
									<div className="legendColor legendC2"></div>
									<div className="legendValue">Others</div>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					<div id="usCountyMap" className="d3Map"></div>
				</Card.Body>
			</Card>
		)
	})
)
